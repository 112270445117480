import { Button, Tooltip } from 'launchpad';

import Variation from 'components/Variation';
import { VariationColor } from 'components/VariationColor';
import { useIsOverflowing } from 'hooks/useIsOverflowing';

import { LegacyReleaseTrackerPopoverFlagEnvironmentSummary } from '../FlagReleaseSummary/LegacyReleaseTrackerPhasePopover';

import styles from '../FlagReleaseSummary/LegacyReleaseTrackerPhasePopover.module.css';

export function FlagEnvironmentVariationsSummary({
  variationRootClassName,
  variationDisplayClassName,
  variations,
}: {
  variationRootClassName?: string;
  variationDisplayClassName?: string;
  variations: LegacyReleaseTrackerPopoverFlagEnvironmentSummary['flagVariations'];
}) {
  const { ref: variationsDisplayRef, isOverflowing: isVariationNameOverflowing } = useIsOverflowing<HTMLSpanElement>();

  if (variations.length === 1) {
    const variation = (
      <Variation
        className={variationRootClassName}
        variationDisplayClassName={variationDisplayClassName}
        variationDisplayRef={variationsDisplayRef}
        key={0}
        value={variations[0].value}
        name={variations[0].name}
        color={variations[0].color}
      />
    );

    return isVariationNameOverflowing.width ? (
      <Tooltip targetClassName={variationRootClassName} placement="bottom" content={variations[0].name}>
        <Button kind="minimal" className={styles.tooltipButton}>
          {variation}
        </Button>
      </Tooltip>
    ) : (
      variation
    );
  }

  return (
    <Tooltip
      targetClassName={variationRootClassName}
      placement="bottom"
      content={`Serving ${variations.map(({ name }) => name).join(', ')}`}
    >
      <Button kind="minimal" className={styles.tooltipButton}>
        <div className={styles.multipleVariationsWrapper}>
          {variations.map((v, index) => (
            <VariationColor key={index} fill={v.color} className={styles.multipleVariationColorDiamond} />
          ))}
        </div>
      </Button>
    </Tooltip>
  );
}
