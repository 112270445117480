import { useContext } from 'react';
import { Key } from 'react-aria';
import { arrayMoveAfter, arrayMoveBefore } from '@gonfalon/collections';
import { shortcutsList, useCreateShortcut, useDeleteShortcut, useUpdateShortcutOrdering } from '@gonfalon/rest-api';
import { RoutePatternContext } from '@gonfalon/router';
import { ToastQueue } from '@launchpad-ui/components';
import { useSuspenseQueries } from '@tanstack/react-query';

import { transformShortcutForUI } from './internal/transformShortcutForUI';
import { availableShortcuts, Shortcut } from './types';

export const useShortcuts = ({ projectKey }: { projectKey: string }) => {
  const routePattern = useContext(RoutePatternContext);
  const routeSupportsShortcuts = routePattern
    ? availableShortcuts[routePattern as keyof typeof availableShortcuts]
    : undefined;

  const [shortcutsQuery] = useSuspenseQueries({
    queries: [shortcutsList({ projectKey })],
  });

  const shortcuts = shortcutsQuery.data.items.map((shortcut) => transformShortcutForUI(shortcut));

  const { mutate: deleteShortcutMutation } = useDeleteShortcut({
    onError() {
      ToastQueue.warning('An error occurred while deleting the shortcut');
    },
  });

  const { mutate: createShortcutMutation } = useCreateShortcut({
    onError: () => {
      ToastQueue.warning('An error occurred while creating the shortcut');
    },
  });

  const { mutate: reorderShortcutsMutation } = useUpdateShortcutOrdering({
    onError: () => {
      ToastQueue.warning('An error occurred while reordering the shortcuts');
    },
  });

  const createShortcut = (shortcut: Shortcut) => {
    createShortcutMutation({
      input: {
        ...shortcut,
        context: {
          ...shortcut.context,
          environmentKeys: Array.from(shortcut.context.environmentKeys),
        },
      },
    });
  };

  const deleteShortcut = (shortcutKey: Key) => {
    deleteShortcutMutation({ projectKey, shortcutKey: shortcutKey as string });
  };

  const reorderShortcut = (shortcutKey: Key, position: 'before' | 'after', otherKeys: Set<Key>) => {
    const nextShortcuts = shortcuts;
    if (nextShortcuts.length === 1) {
      return;
    }

    const target = nextShortcuts.find((it) => it.key === shortcutKey);
    const others: typeof shortcuts = [];
    for (const otherKey of otherKeys) {
      const other = nextShortcuts.find((it) => it.key === otherKey);
      if (other) {
        others.push(other);
      }
    }
    const nextList =
      position === 'after'
        ? arrayMoveAfter(nextShortcuts, others, target)
        : arrayMoveBefore(nextShortcuts, others, target);

    let didChange = false;
    for (const [index, item] of nextShortcuts.entries()) {
      if (item !== nextList[index]) {
        didChange = true;
        break;
      }
    }

    if (!didChange) {
      return;
    }

    const shortcutKeys = nextList.map((item) => item?.key).filter(Boolean) as string[];

    reorderShortcutsMutation({
      projectKey,
      body: {
        shortcutKeys,
      },
    });
  };

  return {
    shortcuts,
    createShortcut,
    routeSupportsShortcuts,
    reorderShortcut,
    deleteShortcut,
  };
};
