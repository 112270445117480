import { createElement } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@launchpad-ui/icons';
import classNames from 'clsx';

import { DataList } from 'components/ui/datalist';
import UserAttributesList from 'components/UserAttributesList';
import { UserPopoverContentWithDate } from 'components/UserPopoverContentWithDate';
import { AccessDecision } from 'utils/accessUtils';
import { track } from 'utils/analyticsUtils';
import { User } from 'utils/userUtils';

import '@launchpad-ui/form/style.css';

function renderUserLink(url: string, useRouter: boolean, label: string) {
  if (useRouter) {
    const component = Link;
    const props = {
      to: url,
      onClick: () => {
        track('Additional User Information Link Clicked');
      },
    };
    return createElement(component, props, label);
  }

  const component = 'a';
  const props = {
    href: url,
    onClick: () => {
      track('Additional User Information Link Clicked');
    },
  };
  return createElement(component, props, label);
}

type UserPopoverContentProps = {
  enableExpiringUserTargets?: boolean;
  user?: User;
  useRouter?: boolean;
  linkToPage?: boolean;
  variationId?: string;
  userKey: string;
  originalExpirationDate?: number | null;
  updatedExpirationDate?: number | null;
  updatedExpirationVariationId?: string | null;
  openDatePickerKey?: string;
  updateExpiringTargetsAccess?: AccessDecision;
  onChangeUserTargetingExpiration?: ({
    originalExpirationDate,
    updatedExpirationDate,
  }: {
    originalExpirationDate?: number | null;
    updatedExpirationDate?: number | null;
  }) => void;
  onHandlePopoverInteraction?(): void;
  onClickRemovalDate?(): void;
};

/* eslint-disable import/no-default-export */
export default function UserPopoverContent({
  user,
  useRouter = false,
  enableExpiringUserTargets = true,
  linkToPage = false,
  variationId,
  userKey,
  originalExpirationDate,
  updatedExpirationDate,
  updatedExpirationVariationId,
  openDatePickerKey,
  updateExpiringTargetsAccess,
  onChangeUserTargetingExpiration,
  onHandlePopoverInteraction,
  onClickRemovalDate,
}: UserPopoverContentProps) {
  const link = user?.siteLink() || undefined;
  const classes = classNames('u-pt-s');
  const renderUserLinkWithIcon = () => (
    <div className="u-flex u-mh-l u-mv-m">
      <div className="UserPopover--userInformationHeader">Target information</div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={link}
        className="UserPopover-userInformationHeader--link"
        onClick={() => {
          track('Additional User Information Link Clicked');
        }}
      >
        <span className="UserPopover-userInformationHeader--linkIcon">
          <Icon name="link-external" size="small" />
        </span>
      </a>
    </div>
  );

  const renderUserData = () => {
    const className = classNames({ 'u-fs-sm fs-exclude': !enableExpiringUserTargets });
    return (
      <div className={className}>
        <div className={classes}>
          {linkToPage && user?.exists() && link ? (
            <>
              {enableExpiringUserTargets ? (
                renderUserLinkWithIcon()
              ) : (
                <p className="u-tc">{renderUserLink(link, useRouter, 'View settings')}</p>
              )}
              <UserAttributesList userAttributes={user.attributes} />
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  };

  return !enableExpiringUserTargets ? (
    <DataList className="fs-exclude">{renderUserData()}</DataList>
  ) : (
    <UserPopoverContentWithDate
      user={user}
      updateExpiringTargetsAccess={updateExpiringTargetsAccess}
      userKey={userKey}
      openDatePickerKey={openDatePickerKey}
      originalExpirationDate={originalExpirationDate}
      updatedExpirationDate={updatedExpirationDate}
      date={updatedExpirationDate}
      onRenderUserData={renderUserData}
      onHandlePopoverInteraction={onHandlePopoverInteraction}
      variationId={variationId}
      updatedExpirationVariationId={updatedExpirationVariationId}
      onChangeUserTargetingExpiration={onChangeUserTargetingExpiration}
      onClickRemovalDate={onClickRemovalDate}
    />
  );
}
