import { useTrackRouteError } from '@gonfalon/router';

import { CreateSupportTicketLink } from 'components/CreateSupportTicketLink';
import { trackCreateSupportTicket } from 'utils/contactSalesUtils';

export function ErrorBoundary() {
  const error = useTrackRouteError();

  if (!error) {
    return null;
  }

  return (
    <section className="u-flex-center u-mt-xl">
      <h1 className="u-fs-xxl">Something went wrong</h1>
      <p className="u-fs-l u-mt-m">
        We ran into an unexpected error displaying the applications table. We've been notified. Please try again later
        or <CreateSupportTicketLink onClick={() => trackCreateSupportTicket('WebhookForm')} />.
      </p>
    </section>
  );
}
