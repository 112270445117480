import classNames from 'clsx';
import { List } from 'immutable';
import { Tooltip } from 'launchpad';

import { VariationColor } from 'components/VariationColor';
import { colorVariation, Rollout, Variation } from 'utils/flagUtils';

import 'components/rollouts/RolloutBar.css';

type SliceProps = {
  index: number;
  weight: number;
  variation?: Variation;
  className?: string;
};

const Slice = ({ index, weight, variation, className }: SliceProps) => {
  const name = variation ? variation.getDisplay() : 'placeholder';
  const styles = {
    backgroundColor: colorVariation(index),
    width: `${Math.ceil(weight / 1000)}%`,
  };
  return (
    <span className={className} style={styles}>
      {name}
    </span>
  );
};

type RolloutBarProps = {
  className?: string;
  rollout?: Rollout | null;
  variations?: List<Variation>;
  visualrollout: boolean;
  defaultVariationIndex?: number;
  singleDimensionalWeight?: number;
};

RolloutBar.defaultProps = {
  rollout: null,
  referenceWeight: 0,
  visualrollout: false,
};

/* eslint-disable import/no-default-export */
export default function RolloutBar({
  rollout,
  visualrollout,
  variations,
  className,
  defaultVariationIndex,
  singleDimensionalWeight,
}: RolloutBarProps) {
  const isSingleDimensional = Boolean(singleDimensionalWeight);

  const findRolloutVariations = () =>
    rollout && variations
      ? variations
          .map((variation, index) =>
            rollout.variations.find(
              (variationWeight) => variationWeight.variation === index && !variationWeight._untracked,
            ),
          )
          .filter((variation) => !!variation)
      : List();

  let rolloutVariations = findRolloutVariations().map((variationWeight) => {
    const index = variationWeight.variation;
    const variation = variations?.get(index);
    const weight = rollout?.getWeightForIndex(index) || 0;
    return <Slice key={variation?._key} index={index} weight={weight} variation={variation} />;
  });

  if (typeof defaultVariationIndex === 'number') {
    const variation = variations?.get(defaultVariationIndex);
    if (variation && rollout) {
      const weight = 100000 - rollout?.getTotalRolloutPercentage();
      const slice = (
        <Slice
          key={`defaultVariation-${variation._key}`}
          index={defaultVariationIndex}
          weight={weight}
          variation={variation}
          className={weight === 100000 ? '' : 'u-ml-s'}
        />
      );
      rolloutVariations = rolloutVariations.push(slice);
    }
  }

  const renderTooltip = (variation: Variation, index: number, weight: number) => {
    const name = variation.getDisplay();
    const percent = `${weight / 1000.0}%`;
    const color = colorVariation(index);
    return (
      <span key={variation._key} className="RolloutBarLegend-variation">
        <span className="RolloutBarLegend-name">
          <VariationColor size="tiny" fill={color} /> {name}
        </span>
        <span className="RolloutBarLegend-rollout">{percent}</span>
      </span>
    );
  };

  const renderRolloutTooltip = () => {
    if (isSingleDimensional) {
      const percent = `${(singleDimensionalWeight || 0) / 1000.0}%`;
      return (
        <span className="RolloutBarLegend">
          <span key="RolloutBarLegend-singleDimensional" className="RolloutBarLegend-variation">
            <span className="RolloutBarLegend-name">
              <VariationColor size="tiny" fill={colorVariation(0)} />
            </span>
            <span className="RolloutBarLegend-rollout">{percent}</span>
          </span>
        </span>
      );
    }
    const legend = variations
      ?.map((variation, index) => {
        const weighted = rollout && rollout.variations.find((variationWeight) => variationWeight.variation === index);
        return renderTooltip(variation, index, weighted ? weighted.weight : 0);
      })
      .toArray();

    return <span className="RolloutBarLegend">{legend}</span>;
  };

  let total = 0;

  if (rollout !== null) {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    total = rollout!.variations.reduce(
      (sum: number, variationWeight: { weight: number }) => sum + variationWeight.weight,
      0,
    ); /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }

  const classes = classNames('RolloutBar', className, {
    'is-full': total >= 100000,
  });

  const slices = () => {
    if (isSingleDimensional) {
      return <Slice key="single-dimension-slice" index={0} weight={singleDimensionalWeight || 0} />;
    }
    return rollout !== null ? rolloutVariations : null;
  };

  return (
    <Tooltip placement="right" content={renderRolloutTooltip()} targetClassName="RolloutBar-popover-target">
      <div className={classes}>
        <div className="RolloutBar-slices">{slices()}</div>
        {visualrollout}
      </div>
    </Tooltip>
  );
}
