import { useEffect, useState } from 'react';
import { formatJSON } from '@gonfalon/strings';
import { Icon } from '@launchpad-ui/icons';

import { ModuleEmptyState } from 'components/ui/module';
import {
  createPolicyStatement,
  PolicyStatement as PolicyStatementRecord,
  PolicyStatementObject,
  PolicyStatementRecordPlainType,
} from 'utils/policyUtils';

import PolicyStatementContainer from './PolicyStatementContainer';

import styles from './styles.module.css';

export type SimplePolicyEditorProps = {
  editingIndex?: number;
  onChange: (val: string) => void;
  onStartEditingStatement: (index: number) => void;
  onStopEditingStatement: (keepIndex?: boolean) => void;
  statements: PolicyStatementObject[];
  customEffects?: {
    allow: string;
    deny: string;
  };
  hideActions?: boolean;
};

/* eslint-disable import/no-default-export */
export default function SimplePolicyEditor({
  customEffects,
  editingIndex,
  hideActions,
  statements,
  onChange,
  onStartEditingStatement,
  onStopEditingStatement,
}: SimplePolicyEditorProps) {
  const [shouldFocusStatement, setShouldFocusStatement] = useState(false);
  const [shouldFocusAddStatementBtn, setShouldFocusAddStatementBtn] = useState(false);

  useEffect(
    () => () => {
      handleDoneEditing(true);
    },
    [],
  );

  function handleDoneEditing(keepIndex?: boolean) {
    onStopEditingStatement(keepIndex);
    setShouldFocusStatement(false);
    setShouldFocusAddStatementBtn(true);
  }

  function handleAddStatement() {
    const emptyStatement = createPolicyStatement({
      resources: [],
      actions: hideActions ? ['*'] : [],
      negateActions: false,
      negateResources: false,
      effect: '',
    });
    const index = statements.length;
    // append empty statement to end of statements list
    handleChange(emptyStatement, index);
    handleEditStatement(index);
    setShouldFocusStatement(false);
    setShouldFocusAddStatementBtn(false);
  }

  function handleRemoveStatement(index: number) {
    handleChange(undefined, index);
    handleDoneEditing();
  }

  function handleEditStatement(index: number) {
    onStartEditingStatement(index);
  }

  function handleChange(statement: PolicyStatementRecord | undefined, index: number) {
    const newValue = [...statements];
    if (statement) {
      newValue[index] = transformStatementRecordToObject(statement);
    } else {
      newValue.splice(index, 1);
    }
    onChange(formatJSON(JSON.stringify(newValue)));
  }

  function transformStatementObjectToRecord(statement: PolicyStatementObject) {
    const negateActions = !!(statement.notActions && statement.notActions.length);
    const negateResources = !!(statement.notResources && statement.notResources.length);
    const resources = (negateResources ? statement.notResources : statement.resources) || [];
    return createPolicyStatement({
      actions: (negateActions ? statement.notActions : statement.actions) || [],
      effect: statement.effect,
      negateActions,
      negateResources,
      resources: resources.filter((r) => r !== ''),
    });
  }

  function transformStatementRecordToObject(statement: PolicyStatementRecord) {
    const { actions, effect, negateActions, negateResources, resources }: PolicyStatementRecordPlainType =
      statement.toJS();
    const filteredResources = resources.filter((r) => r !== '');
    return {
      actions: negateActions ? undefined : actions,
      effect,
      notActions: negateActions ? actions : undefined,
      notResources: negateResources ? filteredResources : undefined,
      resources: negateResources ? undefined : filteredResources,
    } as PolicyStatementObject;
  }

  const canRemove = statements.length > 1;
  const isEditingStatement = editingIndex !== -1;

  const canFocusOnLastStatement = !isEditingStatement && statements.length > 0 && !!statements[0].effect;

  return (
    <div className={styles.statementContainer}>
      {statements.map((statement, index) => (
        <PolicyStatementContainer
          customEffects={customEffects}
          hideActions={hideActions}
          canEdit={!isEditingStatement}
          canRemove={canRemove}
          isEditing={index === editingIndex}
          key={index}
          onCancel={handleDoneEditing}
          onSubmit={(newValue) => handleChange(newValue, index)}
          onSubmitSuccess={handleDoneEditing}
          onEdit={() => handleEditStatement(index)}
          onRemove={() => handleRemoveStatement(index)}
          shouldFocusOnMount={shouldFocusStatement}
          shouldFocusOnAdded={canFocusOnLastStatement && index === statements.length - 1}
          statement={transformStatementObjectToRecord(statement)}
        />
      ))}
      {!isEditingStatement && (
        <ModuleEmptyState
          className={styles.addStatement}
          interactive
          onClick={handleAddStatement}
          shouldFocusOnButton={shouldFocusAddStatementBtn}
        >
          <Icon name="add" size="small" subtle /> Add statement
        </ModuleEmptyState>
      )}
    </div>
  );
}
