import { releaseGuardian } from '@gonfalon/dogfood-flags';
import { useMeasuredRolloutStatus as useMeasuredRolloutStatusQuery } from '@gonfalon/rest-api';
import { useProjectKey } from '@gonfalon/router';

export function useMeasuredRolloutStatus(environmentKey: string, flagKey: string) {
  const projectKey = useProjectKey();
  const { data: measuredRolloutStatuses } = useMeasuredRolloutStatusQuery(
    {
      projectKey,
      environmentKey,
      flagKey,
    },
    { enabled: releaseGuardian() },
  );

  return measuredRolloutStatuses?.find((status) => status.flagKey === flagKey)?.measuredRolloutStatus;
}
