import { type AddRuleInstruction, type Clause, type SegmentSemanticInstruction } from './types';
type RuleUpdates = {
  clauseToUpdate?: Clause;
  weight?: number;
  contextKind?: string;
  description?: string;
};
export function makeUpdatedInstructionsWithUpdatedRule(
  instructions: SegmentSemanticInstruction[],
  ruleIdToUpdate: string,
  updates: RuleUpdates,
) {
  const { clauseToUpdate, weight, contextKind, description } = updates;
  const existingRuleInstructionIdx = instructions.findIndex(
    (instruction) => 'rule' in instruction && instruction.rule._id === ruleIdToUpdate,
  );

  if (existingRuleInstructionIdx !== -1) {
    const updatedRuleInstruction = instructions[existingRuleInstructionIdx] as AddRuleInstruction;

    if (clauseToUpdate) {
      const existingRuleClauseIdx = updatedRuleInstruction.rule.clauses.findIndex(
        (clause) => clause._id === clauseToUpdate._id,
      );

      if (existingRuleClauseIdx !== -1) {
        updatedRuleInstruction.rule.clauses.splice(existingRuleClauseIdx, 1, clauseToUpdate);
      }
    }

    if (weight !== undefined) {
      updatedRuleInstruction.rule.weight = weight;
    }

    if (contextKind !== undefined) {
      updatedRuleInstruction.rule.rolloutContextKind = contextKind;
    }

    if (description !== undefined) {
      updatedRuleInstruction.rule.description = description;
    }

    const instructionsWithUpdatedRule = instructions;
    instructionsWithUpdatedRule.splice(existingRuleInstructionIdx, 1, updatedRuleInstruction);
    return instructionsWithUpdatedRule;
  }
  return instructions;
}
