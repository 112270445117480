import {
  FocusEvent,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import type {
  ActionMeta,
  ClearIndicatorProps,
  DropdownIndicatorProps,
  GroupHeadingProps,
  LoadingIndicatorProps,
  MenuPlacement,
  MenuPosition,
  MenuProps,
  MultiValueProps,
  MultiValueRemoveProps,
  OptionProps as RSOptionProps,
  SingleValueProps,
  ValueContainerProps,
} from 'react-select';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { isString } from '@gonfalon/es6-utils';
import { useThemeValue } from '@gonfalon/theme';
import { Tooltip } from '@launchpad-ui/core';
import { Icon } from '@launchpad-ui/icons';
import { LDMultiKindContext, LDSingleKindContext } from 'launchdarkly-js-client-sdk';

import {
  selectCustomControlStyles,
  selectCustomIndicatorStyles,
  selectCustomInputStyles,
  selectCustomMenuListStyles,
  selectCustomMenuPortalStyles,
  selectCustomMenuStyles,
  selectCustomMultiValueLabelStyles,
  selectCustomMultiValueRemoveStyles,
  selectCustomMultiValueStyles,
  selectCustomOptionStyles,
  selectCustomPlaceholderStyles,
  selectCustomSingleValueStyles,
} from './reactSelectUtils';

type LDContext = LDSingleKindContext | LDMultiKindContext;

const createFieldErrorId = (fieldIdentifier?: string | string[]) =>
  fieldIdentifier ? `${[...fieldIdentifier].join('')}-err` : undefined;

export type GroupedOption = {
  label: string;
  options: OptionProps[];
};

//taken from @types/react-select/src/types.d.ts
export type OptionProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  id?: number;
  key?: string;
  index?: number;
  isDisabled?: boolean;
  isFocused?: boolean;
  isSelected?: boolean;
  isLoading?: boolean;
  label?: string | ReactElement | number | boolean;
  onClick?: MouseEventHandler;
  onMouseOver?: MouseEventHandler;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  disabled?: boolean;
  disabledReason?: string;
  isHeading?: boolean;
  context?: LDContext;
  className?: string;
  options?: OptionProps[];
  isCreated?: boolean;
  redacted?: boolean;
  type?: 'number' | 'string' | 'boolean' | 'json';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // allow custom properties that React Select will pass in for options
};

export type AriaOnFocusProps = {
  focused?: { value: string; label: string; contextKind?: string; redacted?: boolean }; // this is currently SelectContextAttribute-specific; refactor to repurpose
  options?: OptionProps[];
};

export type AriaOnChangeProps = {
  label: string;
};

export type FilterOption = { data: OptionProps; value: string | number };

export type StylesObject = {
  menu?: object;
  menuList?: object;
  menuPortal?: object;
  container?: object;
  valueContainer?: object;
  singleValue?: object;
  multiValue?: object;
  multiValueLabel?: object;
  multiValueRemove?: object;
  control?: object;
  option?: object;
  group?: object;
  groupHeading?: object;
  placeholder?: object;
  dropdownIndicator?: object;
  clearIndicator?: object;
  input?: object;
  selectContainer?: object;
};

export type ComponentsObject = {
  SingleValue?: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ((singleValueProps: SingleValueProps<any>) => ReactNode) | ((option: OptionProps) => ReactNode);
  IndicatorSeparator?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Menu?: (menuProps: MenuProps<any>) => ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  MultiValue?: (multiValueProps: MultiValueProps<any>) => ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  MultiValueLabel?: (multiValueLabelProps: MultiValueProps<any>) => ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  MultiValueRemove?: (multiValueRemoveProps: MultiValueRemoveProps<any>) => ReactNode;
  LoadingIndicator?: (props: LoadingIndicatorProps) => ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Option?: (props: RSOptionProps<any>) => ReactNode;
  Group?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  GroupHeading?: (props: GroupHeadingProps<any>) => ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ValueContainer?: (props: ValueContainerProps<any>) => ReactNode;
};

export type CustomSelectAction = { action: 'set-value' | 'input-change' | 'input-blur' | 'menu-close' };

export type CustomSelectProps<T = OptionProps> = {
  className?: string;
  classNamePrefix?: string;
  isLoading?: boolean;
  onFocus?: () => void;
  styles?: StylesObject;
  customComponents?: ComponentsObject;
  isMulti?: boolean;
  componentType?: 'Select' | 'Creatable';
  value?: string | object | number;
  options?: object | object[];
  onChange?(selectedOption: OptionProps | OptionProps[] | null, actionMeta: ActionMeta<OptionProps>): void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  getOptionLabel?(option?: OptionProps): string;
  isClearable?: boolean;
  isClearableButtonText?: string;
  isSearchable?: boolean;
  isDisabled?: boolean;
  disabled?: boolean;
  defaultMenuIsOpen?: boolean;
  closeMenuOnSelect?: boolean;
  onMenuOpen?(): void;
  onMenuClose?(): void;
  filterOption?: null | ((option: T, inputValue: string) => boolean);
  onInputChange?(input: string, action: CustomSelectAction): void;
  noOptionsMessage?({ inputValue }: { inputValue: string }): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  innerRef?: RefObject<any>;
  isInPortal?: boolean;
  tabSelectsValue?: boolean;
  autoFocus?: boolean;
  autoSort?: boolean;
  backspaceRemoves?: boolean;
  blurInputOnSelect?: boolean;
  formatCreateLabel?(value: string): string | JSX.Element;
  id?: string;
  name?: string;
  required?: boolean;
  formatGroupLabel?(option: { label: string }): void | ReactElement | undefined | string;
  formatOptionLabel?(
    option: OptionProps,
    { context }: { context: string },
  ): void | JSX.Element | undefined | string | number | null;
  isOptionDisabled?(selectedOption: OptionProps | OptionProps[] | null): boolean;
  customMultiValueRemoveTooltip?: string;
  menuIsOpen?: boolean;
  isValidNewOption?(input?: string): void | boolean; //Disable creating new option in CustomCreatable
  controlShouldRenderValue?: boolean;
  backspaceRemovesValue?: boolean;
  inputValue?: string;
  ariaLabel?: string;
  ariaLabelledBy?: string;
  'aria-describedby'?: string;
  customAriaLiveMessageOnFocus?(props: AriaOnFocusProps): string;
  customAriaLiveMessageOnChange?(props: AriaOnChangeProps): string;
  onCreateOption?: (inputValue: string) => void;
  menuPlacement?: MenuPlacement;
  menuPosition?: MenuPosition;
  menuShouldScrollIntoView?: boolean;
};

// type could probably use a cleanup - React Hook Form overrides some of these
export type HookFormSelectProps<T, F extends FieldValues> = CustomSelectProps<T> & UseControllerProps<F>;

type CustomSelectPropsWithAria<T> = CustomSelectProps<T> & {
  ariaLiveMessages?: {
    onFocus?(props: AriaOnFocusProps): string;
    onChange?(props: AriaOnChangeProps): string;
  };
};

function CustomizedSelectComponent<T>({
  componentType,
  styles,
  customComponents,
  innerRef,
  disabled,
  isDisabled,
  isInPortal,
  options,
  autoSort,
  onMenuOpen,
  onMenuClose,
  menuIsOpen,
  id,
  ariaLabel,
  ariaLabelledBy,
  customAriaLiveMessageOnFocus,
  customAriaLiveMessageOnChange,
  isClearableButtonText = 'Clear all',
  ...props
}: CustomSelectProps<T>) {
  const themeValue = useThemeValue() ?? 'default';

  const defaultComponentRef = useRef();
  // use either the provided "innerRef" or a default ref
  const componentRef = innerRef || defaultComponentRef;

  useEffect(() => {
    setAriaDisabled();
  }, []);

  const setAriaDisabled = () => {
    // react-select does not support the aria-disabled attribute as of 03/09/2022, so we add it ourselves
    // https://github.com/JedWatson/react-select/blob/b0411ff46bc1ecf45d9bca4fb58fbce1e57f847c/packages/react-select/src/components/Input.js
    // get control ref of react-select
    // find input in control
    // add aria-disabled to input
    const input = componentRef?.current?.controlRef?.querySelector?.('input');
    input?.setAttribute('aria-disabled', !!disabled);
  };

  const baseStyles = {
    /*
       defaultStyles: built in css styles provided by react-select
       customStyles: LD css style overrides
       styles: Component css style overrides
       learn more: https://react-select.com/styles#style-object
     */

    menu: (defaultStyles: StylesObject) => ({
      ...defaultStyles,
      ...selectCustomMenuStyles(themeValue),
      ...styles?.menu,
      pointerEvents: 'auto',
    }),
    menuList: (defaultStyles: StylesObject) => ({
      ...defaultStyles,
      borderRadius: 'inherit',
      ...selectCustomMenuListStyles,
      ...styles?.menuList,
    }),
    menuPortal: (defaultStyles: StylesObject) => ({
      ...defaultStyles,
      ...selectCustomMenuPortalStyles,
      ...styles?.menuPortal,
    }),
    container: (defaultStyles: StylesObject) => ({
      ...defaultStyles,
      ...styles?.container,
    }),
    valueContainer: (defaultStyles: StylesObject) => ({
      ...defaultStyles,
      padding: '4px',
      ...styles?.valueContainer,
    }),
    singleValue: (defaultStyles: StylesObject) => ({
      ...defaultStyles,
      ...selectCustomSingleValueStyles,
      ...styles?.singleValue,
    }),
    multiValue: (defaultStyles: StylesObject) => ({
      ...defaultStyles,
      ...selectCustomMultiValueStyles,
      ...styles?.multiValue,
    }),
    multiValueLabel: (defaultStyles: StylesObject) => ({
      ...defaultStyles,
      ...selectCustomMultiValueLabelStyles,
      ...styles?.multiValueLabel,
    }),
    multiValueRemove: (defaultStyles: StylesObject) => ({
      ...defaultStyles,
      ...selectCustomMultiValueRemoveStyles,
      ...styles?.multiValueRemove,
    }),
    control: (defaultStyles: StylesObject, state: { isFocused: boolean; isDisabled: boolean }) => {
      const customStyles = selectCustomControlStyles(themeValue, state.isFocused, state.isDisabled);
      return {
        ...defaultStyles,
        ...customStyles,
        ...styles?.control,
      };
    },
    noOptionsMessage: (defaultStyles: StylesObject) => ({
      ...defaultStyles,
      color: 'var(--lp-color-text-field-disabled)',
    }),
    option: (defaultStyles: StylesObject, state: { isSelected: boolean; isFocused: boolean; isDisabled: boolean }) => {
      const customStyles = selectCustomOptionStyles(themeValue, state.isSelected, state.isFocused, state.isDisabled);
      return {
        ...defaultStyles,
        ...customStyles,
        ...styles?.option,
      };
    },
    group: (defaultStyles: StylesObject) => ({
      ...defaultStyles,
      ...styles?.group,
    }),
    groupHeading: (defaultStyles: StylesObject) => ({
      ...defaultStyles,
      color: 'var(--field-placeholder-color)',
      ...styles?.groupHeading,
    }),
    placeholder: (defaultStyles: StylesObject) => ({
      ...defaultStyles,
      ...selectCustomPlaceholderStyles(themeValue),
      ...styles?.placeholder,
    }),
    dropdownIndicator: (defaultStyles: StylesObject) => ({
      ...defaultStyles,
      ...selectCustomIndicatorStyles(themeValue),
      paddingLeft: '0.1875rem',
      ...styles?.dropdownIndicator,
    }),
    input: (defaultStyles: StylesObject) => ({
      ...defaultStyles,
      ...selectCustomInputStyles,
      ...styles?.input,
    }),
    clearIndicator: (defaultStyles: StylesObject) => ({
      ...defaultStyles,
      ...selectCustomIndicatorStyles(themeValue),
      paddingRight: '0.1875rem',
      paddingTop: '0.1875rem',
      ...styles?.clearIndicator,
    }),
  };

  // react-select has a components prop that allows you to replace any part
  // of react-select.
  // learn more and see examples here: https://react-select.com/components

  const customDropdownIndicator = (dropdownProps: DropdownIndicatorProps) => (
    <components.DropdownIndicator {...dropdownProps}>
      <Icon name="chevron-down" size="medium" />
    </components.DropdownIndicator>
  );

  const customClearIndicator = (clearProps: ClearIndicatorProps) => {
    const handleKeyDown = (event: React.KeyboardEvent<SVGSVGElement>) => {
      switch (event.key) {
        case 'ArrowDown':
        case 'ArrowUp':
        case 'ArrowLeft':
        case 'ArrowRight':
          event.stopPropagation();
          event.preventDefault();
          break;
        case 'Enter':
        case ' ':
          clearProps.clearValue();
          break;
        default:
          break;
      }
    };

    const finalProps = {
      ...clearProps,
      innerProps: {
        ...clearProps.innerProps,
        'aria-hidden': false,
      },
    };

    return (
      <components.ClearIndicator {...finalProps}>
        <Tooltip content={isClearableButtonText}>
          <Icon
            name="cancel"
            role="button"
            tabIndex={0}
            aria-label={isClearableButtonText}
            onKeyDown={handleKeyDown}
            size="small"
          />
        </Tooltip>
      </components.ClearIndicator>
    );
  };

  const customMultiValueRemove = (multiValueRemoveProps: MultiValueRemoveProps) => (
    <components.MultiValueRemove {...multiValueRemoveProps}>
      {/* --gray-black */}
      {isDisabled ? (
        <></>
      ) : (
        <Tooltip content={props.customMultiValueRemoveTooltip || 'Clear tag'} placement="right">
          <Icon
            name="cancel"
            style={{ color: 'var(--lp-color-text-ui-primary-base)', top: '-0.09375rem' }}
            size="small"
          />
        </Tooltip>
      )}
    </components.MultiValueRemove>
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Component: any = componentType === 'Select' ? Select : CreatableSelect;
  const finalOptions =
    autoSort && options
      ? Object.values(options).sort(
          (a, b) =>
            isString(a.label) && isString(b.label) && a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
        )
      : options;

  let menuPortalTarget = null;
  // when the select is inside a portal (such as a modal), we need to set `menuPortalTarget`
  // to the viewport-sized container so that the select menu isn't constrained to the portal.
  // For our application, this is a div with class "Modal"
  if (isInPortal) {
    // get the innerRef element for react-select's "Control" internal component,
    const selectControlElement = componentRef?.current?.controlRef;

    // this ensures that we are selecting the correct menuPortalTarget when using nested modals
    setTimeout(() => {
      menuPortalTarget = selectControlElement?.closest?.('[aria-modal]') || document.querySelector('[aria-modal]');

      if (!menuPortalTarget) {
        // Fall back on using the body which has reasonable behavior most of the time (as long
        // as the page isn't scrolled).
        menuPortalTarget = document.body;
      }
    });
  }

  const isMenuOpenRef = useRef(false);

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      // allow arrow down to open the dropdown without any other event propagation
      if (event.key === 'ArrowDown') {
        event.stopPropagation();
      }

      if ((event.key === 'Escape' || event.key === 'ArrowUp' || event.key === 'ArrowDown') && isMenuOpenRef.current) {
        event.nativeEvent.stopImmediatePropagation();
        event.stopPropagation();
      }
    },
    [isMenuOpenRef],
  );

  const handleMenuOpen = useCallback(() => {
    isMenuOpenRef.current = true;
    onMenuOpen?.();
  }, [onMenuOpen]);

  const handleMenuClose = useCallback(() => {
    isMenuOpenRef.current = false;
    onMenuClose?.();
  }, [onMenuClose]);

  // We need to conditionally add in custom aria live message functions only if they're present
  // because there's no exported default functions we can fall back to in react-select.
  // Passing an empty function will override base functionality.
  const allProps: CustomSelectPropsWithAria<T> = { ...props };

  if ([customAriaLiveMessageOnFocus, customAriaLiveMessageOnChange].some((fn) => !!fn)) {
    allProps.ariaLiveMessages = {};
  }

  if (customAriaLiveMessageOnFocus) {
    allProps.ariaLiveMessages = { ...allProps.ariaLiveMessages, onFocus: customAriaLiveMessageOnFocus };
  }

  if (customAriaLiveMessageOnChange) {
    allProps.ariaLiveMessages = { ...allProps.ariaLiveMessages, onChange: customAriaLiveMessageOnChange };
  }

  return (
    <>
      <Component
        inputId={id}
        ref={componentRef}
        className="CustomSelect"
        styles={baseStyles}
        menuPlacement="auto"
        isDisabled={isDisabled || disabled}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: customDropdownIndicator,
          ClearIndicator: customClearIndicator,
          MultiValueRemove: customMultiValueRemove,
          ...customComponents,
        }}
        menuPortalTarget={menuPortalTarget}
        options={finalOptions || []}
        onKeyDown={handleKeyDown}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        menuIsOpen={menuIsOpen}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        aria-describedby={props['aria-describedby'] || createFieldErrorId(id || props.name)}
        {...allProps}
      />
    </>
  );
}

export function CustomSelect<T>({ styles, ...props }: CustomSelectProps<T>) {
  return <CustomizedSelectComponent<T> componentType="Select" styles={styles} {...props} />;
}

export function CustomCreatable<T>({ styles, ...props }: CustomSelectProps<T>) {
  return (
    <CustomizedSelectComponent
      componentType="Creatable"
      styles={{ ...styles, menu: { overflowWrap: 'anywhere' } }}
      {...props}
    />
  );
}

export function HookFormSelect<T, F extends FieldValues>({
  control,
  name,
  rules,
  shouldUnregister = false,
  ...props
}: HookFormSelectProps<T, F>) {
  // https://react-hook-form.com/api/usecontroller
  const { field } = useController({ name, control, rules, shouldUnregister });

  // if you need to use an onChange or onBlur, you can do it via the rules prop
  // it's the same as the register props: https://react-hook-form.com/api/useform/register
  return (
    <CustomizedSelectComponent<T>
      componentType="Select"
      {...props}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      name={field.name}
    />
  );
}

export function HookFormCreateable<T, F extends FieldValues>({
  control,
  name,
  rules,
  shouldUnregister = false,
  styles,
  ...props
}: HookFormSelectProps<T, F>) {
  // https://react-hook-form.com/api/usecontroller
  const { field } = useController({ name, control, rules, shouldUnregister });

  // if you need to use an onChange or onBlur, you can do it via the rules prop
  // it's the same as the register props: https://react-hook-form.com/api/useform/register
  return (
    <CustomizedSelectComponent<T>
      componentType="Creatable"
      styles={{ ...styles, menu: { overflowWrap: 'anywhere' } }}
      {...props}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      name={field.name}
    />
  );
}
