import { isDataExportEnabled } from '@gonfalon/dogfood-flags';

import { parseCreationDate } from './parseCreationDate';
import { parseEvaluationDate } from './parseEvaluationDate';
import { parseSDKAvailability } from './parseSDKAvailability';
import { parseState } from './parseState';
import { parseStatusName } from './parseStatusName';
import { parseTargeting } from './parseTargeting';
import { parseType } from './parseType';
import { UIFlagFilter } from './types';

export function parseUIFlagFilterFromBrowser(searchParams: URLSearchParams) {
  const filters: UIFlagFilter = {};

  if (searchParams.has('q')) {
    filters.query = searchParams.get('q') ?? undefined;
  }

  if (searchParams.has('maintainerId')) {
    filters.maintainerId = searchParams.get('maintainerId') ?? undefined;
  }

  if (searchParams.has('maintainerTeamKey')) {
    filters.maintainerTeamKey = searchParams.get('maintainerTeamKey') ?? undefined;
  }

  if (searchParams.has('filterEnv')) {
    filters.filterEnv = searchParams.get('filterEnv') ?? undefined;
  }

  if (searchParams.has('status')) {
    filters.status = parseStatusName(searchParams.get('status'));
  }

  if (searchParams.has('type')) {
    filters.type = parseType(searchParams.get('type'));
  }

  if (searchParams.has('sdkAvailability')) {
    filters.sdkAvailability = parseSDKAvailability(searchParams.get('sdkAvailability'));
  }

  if (searchParams.has('evaluated')) {
    filters.evaluated = parseEvaluationDate(searchParams.get('evaluated'));
  }

  if (isDataExportEnabled()) {
    if (searchParams.has('hasDataExport')) {
      filters.hasDataExport = searchParams.get('hasDataExport') === 'true';
    }
  }

  if (searchParams.has('hasExperiment')) {
    filters.hasExperiment = searchParams.get('hasExperiment') === 'true';
  }

  if (searchParams.has('tag')) {
    filters.tags = searchParams.getAll('tag').filter((tag) => tag !== '');
  }

  if (searchParams.has('targeting')) {
    filters.targeting = parseTargeting(searchParams.get('targeting'));
  }

  if (searchParams.has('state')) {
    filters.state = parseState(searchParams.get('state'));
  }

  if (searchParams.has('created')) {
    filters.created = parseCreationDate(searchParams.get('created'));
  }

  if (searchParams.has('contextKindTargeted')) {
    filters.contextKindTargeted = searchParams.get('contextKindTargeted') ?? undefined;
  }

  if (searchParams.has('contextKindsEvaluated')) {
    filters.contextKindsEvaluated = searchParams
      .getAll('contextKindsEvaluated')
      .filter((contextKind) => contextKind !== '');
  }

  if (searchParams.has('followerId')) {
    filters.followerId = searchParams.get('followerId') ?? undefined;
  }

  if (searchParams.has('codeReferences')) {
    filters.codeReferences = searchParams.get('codeReferences') === 'true';
  }

  if (searchParams.has('applicationEvaluated')) {
    filters.applicationEvaluated = searchParams.get('applicationEvaluated') ?? undefined;
  }

  if (searchParams.has('segmentTargeted')) {
    filters.segmentTargeted = searchParams.get('segmentTargeted') ?? undefined;
  }

  if (searchParams.has('releasePipeline')) {
    filters.releasePipeline = searchParams.get('releasePipeline') ?? undefined;
  }

  return filters;
}
