import { useEffect, useState } from 'react';
import { DateRangeFilter } from '@gonfalon/datetime';
import { enableCriticalAuditLogActionsFilter, enableMostRecentAuditLogHistory } from '@gonfalon/dogfood-flags';
import { useIsInNewIA } from '@gonfalon/ia-migration';
import { isSameDay } from 'date-fns';
import { List } from 'immutable';
import type { FilterOption } from 'launchpad';
import { Checkbox, Filter } from 'launchpad';

import { AuditLogActionFilterContainer } from 'components/auditLog/AuditLogActionFilterContainer';
import DocumentationLink from 'components/DocumentationLink';
import HelpTooltip from 'components/HelpTooltip';
import { SearchField } from 'components/ui/forms';
import { Cell, CellLayoutValue } from 'components/ui/grid';
import { TableHeaderToolbar } from 'components/ui/tableHeaderToolbar';
import UpsellInlineBannerAuditLog from 'components/upsells/upsellInlineBanners/UpsellInlineBannerAuditLog';
import { AriaLabel } from 'types/accessibilityLabels';
import { auditLogLimitDate, AuditLogViewKind, Query } from 'utils/auditLogUtils';
import { createDefaultShortcuts } from 'utils/dateUtils';
import { trackMembersHistoryTabDateFilterApplyButtonClicked } from 'utils/memberUtils';
import { PolicyStatement } from 'utils/policyUtils';
import { getDocumentationUrl } from 'utils/urlUtils';

import styles from './AuditLogToolbar.module.css';

export type AuditLogToolbarProps = {
  hideSearch?: boolean;
  onSearch(q: Query): void;
  query: Query;
  searchPlaceholder?: string;
  auditLogLimit: number;
  services?: List<string>;
  auditLogView: AuditLogViewKind;
  onFilterByPolicy: (policy: PolicyStatement) => void;
};

const AuditLogToolbar = (props: AuditLogToolbarProps) => {
  const isInIA = useIsInNewIA();
  const { auditLogView, query, hideSearch, services, onFilterByPolicy, onSearch } = props;
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    selectedStartDate: query.after,
    selectedEndDate: query.before,
  });
  const [searchValue, setSearchValue] = useState(query.get('q', ''));
  const [filterByCriticalActions, setFilterByCriticalActions] = useState(query.get('critical', false));

  useEffect(() => {
    setSelectedDateRange({ selectedStartDate: query.after, selectedEndDate: query.before });
    setSearchValue(query.get('q', ''));
    setFilterByCriticalActions(query.critical);
  }, [query.before, query.after, query.q, query.critical]);

  const renderSingleResourceActionFilter =
    auditLogView === 'flag' || auditLogView === 'segment' || auditLogView === 'member' || auditLogView === 'team';
  const AuditLogHistoryDescriptionDocLink = getDocumentationUrl('home/observability/audit-log-history');

  const renderSearch = () => {
    const { searchPlaceholder } = props;
    return (
      <Cell>
        <SearchField
          key={searchValue}
          name="search"
          value={searchValue}
          onChange={(q) => onSearch(query.set('q', q))}
          placeholder={searchPlaceholder || 'Search your audit log'}
          aria-label={AriaLabel.SEARCH_AUDIT_LOG}
          testId="AuditLog-textFilter"
        />
      </Cell>
    );
  };

  const renderCriticalActionsFilters = () => {
    const enabled = enableCriticalAuditLogActionsFilter().length > 0;
    if (!enabled) {
      return <></>;
    }

    return (
      <Cell>
        <Checkbox
          name="criticalChanges"
          aria-label="Filter audit logs by critical actions"
          checked={filterByCriticalActions}
          onChange={(e) => onSearch(query.set('critical', e.target.checked))}
        >
          <p className={styles.criticalChanges}>
            <span className="u-mr-xs">Critical changes only</span>
            <HelpTooltip>Automatically filter audit logs by high impact and security change events.</HelpTooltip>
          </p>
        </Checkbox>
      </Cell>
    );
  };

  const renderDateFilter = () => {
    const { selectedStartDate, selectedEndDate } = selectedDateRange;
    const { auditLogLimit } = props;
    const isAuditLogLimited = auditLogLimit > 0;
    const upsellBanner = <UpsellInlineBannerAuditLog auditLogLimit={auditLogLimit} />;

    const handleDatesChange = (dates: Date[]) => {
      const [startDate, endDate] = dates;
      setSelectedDateRange({
        selectedStartDate: startDate as unknown as number,
        selectedEndDate: endDate as unknown as number,
      });
    };

    const handleDatesConfirm = () => {
      if (auditLogView === AuditLogViewKind.TEAM || auditLogView === AuditLogViewKind.MEMBER) {
        trackMembersHistoryTabDateFilterApplyButtonClicked();
      }
      setIsDatePickerOpen(false);
      onSearch(
        query.setDates({
          startDate: selectedStartDate,
          endDate: selectedEndDate,
        }),
      );
    };

    const handleDatesCancel = () => {
      setIsDatePickerOpen(false);
      setSelectedDateRange({ selectedStartDate: query.after, selectedEndDate: query.before });
    };

    const handleDateRangePickerInteraction = (nextIsOpen: boolean) => {
      setIsDatePickerOpen(nextIsOpen);
      if (!nextIsOpen) {
        setSelectedDateRange({ selectedStartDate: query.after, selectedEndDate: query.before });
      }
    };

    return (
      <Cell>
        <DateRangeFilter
          createShortcuts={!isAuditLogLimited ? createDefaultShortcuts : undefined}
          isOpen={isDatePickerOpen}
          dates={[selectedStartDate, selectedEndDate]}
          onChange={handleDatesChange}
          onConfirm={handleDatesConfirm}
          onCancel={handleDatesCancel}
          onInteraction={handleDateRangePickerInteraction}
          triggerTestId="AuditLog-dateFilter"
          disabledDays={isAuditLogLimited ? [{ before: auditLogLimitDate(auditLogLimit) }] : undefined}
          footerBanner={isAuditLogLimited ? upsellBanner : undefined}
          renderSelectionFn={enableMostRecentAuditLogHistory() ? renderSelectionFn : undefined}
          label={enableMostRecentAuditLogHistory() ? 'Show' : undefined}
          optionalDateSelection={enableMostRecentAuditLogHistory()}
        />
      </Cell>
    );
  };

  const renderServiceFilter = () => {
    const selectedService = query.get('service', '');
    const onClear = () => {
      onSearch(query.set('service', ''));
    };
    const onChange = (item: FilterOption) => {
      onSearch(query.set('service', item.value));
    };
    return (
      <Cell>
        <Filter
          name="Service"
          description={selectedService ? selectedService : 'All'}
          options={
            services ? services.toArray().map((s) => ({ value: s, name: s, isChecked: s === selectedService })) : []
          }
          isClearable={!!query.get('service', '')}
          onClear={onClear}
          onSelect={onChange}
        />
      </Cell>
    );
  };

  return (
    <TableHeaderToolbar
      title={isInIA ? '' : 'History'}
      description={isInIA ? null : headerDescription(AuditLogHistoryDescriptionDocLink)}
      showBorder={false}
      renderSearch={hideSearch ? undefined : renderSearch}
    >
      {renderCriticalActionsFilters()}
      {renderSingleResourceActionFilter && enableMostRecentAuditLogHistory() && (
        <Cell layout={CellLayoutValue.AUTO}>
          <AuditLogActionFilterContainer
            onUpdateFilters={onFilterByPolicy}
            selectedFilter={auditLogView}
            query={query}
          />
        </Cell>
      )}
      {renderDateFilter()}
      {!!(services && services.size) && renderServiceFilter()}
    </TableHeaderToolbar>
  );
};

const headerDescription = (AuditLogHistoryDescriptionDocLink: string) => (
  <span>
    Search or filter to find specific logs. To learn more,{' '}
    <DocumentationLink component="AuditLogHistory" href={AuditLogHistoryDescriptionDocLink} />.
  </span>
);

const renderSelectionFn = ({
  startDate,
  endDate,
}: {
  isHour?: boolean;
  isSingleDay: boolean;
  startDate?: Date | number;
  endDate?: Date | number;
}) => {
  if (startDate && endDate) {
    const shortcuts = createDefaultShortcuts();

    for (const s of shortcuts) {
      const { label, dateRange } = s;
      const [d1, d2] = dateRange;
      if (d1 && d2) {
        if (isSameDay(startDate, d1) && isSameDay(endDate, d2)) {
          return label;
        }
      }
    }
  }
  // use default
  return null;
};

/* eslint-disable import/no-default-export */
export default AuditLogToolbar;
