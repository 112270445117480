export const EXPERIMENT_SAVED_KEY = 'experimentSavedNotification';

/**
 * Set's an experiment as saved in the store.
 **/
export function setSavedExperimentNotification() {
  window.sessionStorage.setItem(EXPERIMENT_SAVED_KEY, 'true');
}

/**
 * Fetches the saved experiment flag from the store.
 */
export function getSavedExperimentNotification() {
  const val = window.sessionStorage.getItem(EXPERIMENT_SAVED_KEY);
  return val === 'true';
}

/**
 * Clears the saved experiment flag from the store.
 */
export function removeSavedExperimentNotification() {
  window.sessionStorage.removeItem(EXPERIMENT_SAVED_KEY);
}
