import { useLoaderData } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { getMetricGroup } from 'components/experimentation/common/api/metric';

import { loader } from '../MetricGroups/metricGroupDetails/MetricGroupDetails';

export const METRIC_GROUPS_QUERY_KEY = 'METRIC_GROUPS';

type Props = {
  projectKey: string;
  metricGroupKey: string;
};

/**
 * Query fetches a single metric group
 *
 * @param param.projectKey - the key of the project the metric group belongs to
 * @param param.metricGroupKey - the key of the metric group
 * @returns
 */
export function metricGroupQuery({ projectKey, metricGroupKey }: Props) {
  return {
    queryFn: async () => getMetricGroup(projectKey, metricGroupKey),
    queryKey: [METRIC_GROUPS_QUERY_KEY, projectKey, metricGroupKey],
  };
}

/**
 * Hook fetches a single metric group
 *
 * @param param.projectKey - the key of the project the metric group belongs to
 * @param param.metricGroupKey - the key of the metric group
 */
export function useMetricGroup({ projectKey, metricGroupKey }: Props) {
  const { metricGroup: initialData } = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  return useQuery({ ...metricGroupQuery({ projectKey, metricGroupKey }), initialData });
}
