import { useNavigate } from 'react-router-dom';
import { List } from 'immutable';

import { blur as formActionBlur, destroy, disableSubmit, enableSubmit, initialize } from 'actions/forms';
import { useDispatch } from 'hooks/useDispatch';
import { GetState, GlobalDispatch, GlobalState } from 'reducers';
import WebhookAPI, { WebhookResponse } from 'sources/WebhookAPI';
import { generateSecret } from 'utils/cryptoUtils';
import { FormRecord } from 'utils/formUtils';
import { GenerateActionType } from 'utils/reduxUtils';
import { Webhook, WebhookForm, WebhookFormProps, WebhookRequestOptions } from 'utils/webhookUtils';

const fetchWebhooksAction = () => ({ type: 'webhooks/REQUEST_WEBHOOKS' }) as const;
const fetchWebhooksActionDone = (response: WebhookResponse) =>
  ({
    type: 'webhooks/REQUEST_WEBHOOKS_DONE',
    response,
  }) as const;
const fetchWebhooksActionFailed = (error: Error) => ({ type: 'webhooks/REQUEST_WEBHOOKS_FAILED', error }) as const;

function fetchWebhooks() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(fetchWebhooksAction());
    return WebhookAPI.getAllWebhooks()
      .then((response) => dispatch(fetchWebhooksActionDone(response)))
      .catch((error) => dispatch(fetchWebhooksActionFailed(error)));
  };
}

function shouldFetchWebhooks(state: GlobalState) {
  return !state.webhooks.get('lastFetched') && !state.webhooks.get('isFetching');
}

function fetchWebhooksIfNeeded() {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchWebhooks(getState())) {
      await dispatch(fetchWebhooks());
    }
  };
}

const editWebhookAction = (field: keyof WebhookFormProps, modified: FormRecord<WebhookForm>) =>
  ({
    type: 'webhooks/EDIT_WEBHOOK',
    field,
    webhook: modified,
  }) as const;

const webhookFormSelector = (state: GlobalState) => state.webhookForm;

function editWebhook(field: keyof WebhookFormProps, value: string | boolean | List<string> | null) {
  return (dispatch: GlobalDispatch, getState: GetState) => {
    const form = webhookFormSelector(getState());
    let modified = form.modified.set(field, value);

    if (field === 'sign' && !form.wasChanged('secret')) {
      modified = modified.set('secret', value ? generateSecret() : null);
    }

    return dispatch(editWebhookAction(field, modified));
  };
}

const createWebhookAction = (webhook: Webhook) => ({ type: 'webhooks/CREATE_WEBHOOK', webhook }) as const;
const createWebhookActionDone = (created: Webhook) =>
  ({
    type: 'webhooks/CREATE_WEBHOOK_DONE',
    webhook: created,
  }) as const;
const createWebhookActionFailed = (webhook: Webhook, error: Error) =>
  ({
    type: 'webhooks/CREATE_WEBHOOK_FAILED',
    webhook,
    error,
  }) as const;

export function useCreateWebhook() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (webhook: Webhook, options: WebhookRequestOptions = {}) => {
    dispatch(createWebhookAction(webhook));
    return WebhookAPI.createWebhook(webhook)
      .then((created) => {
        options.pathOnDone && navigate(options.pathOnDone);
        return dispatch(createWebhookActionDone(created));
      })
      .catch((error) => {
        if (error.get('status') === 403) {
          options.pathOnDone && navigate(options.pathOnDone);
        }
        return dispatch(createWebhookActionFailed(webhook, error));
      });
  };
}

const updateWebhookAction = (oldWebhook: Webhook, newWebhook: Webhook) =>
  ({
    type: 'webhooks/UPDATE_WEBHOOK',
    oldWebhook,
    newWebhook,
  }) as const;
const updateWebhookActionDone = (webhook: Webhook) => ({ type: 'webhooks/UPDATE_WEBHOOK_DONE', webhook }) as const;
const updateWebhookActionFailed = (newWebhook: Webhook, error: Error) =>
  ({
    type: 'webhooks/UPDATE_WEBHOOK_FAILED',
    webhook: newWebhook,
    error,
  }) as const;

export function useUpdateWebhook() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (oldWebhook: Webhook, newWebhook: Webhook, options: WebhookRequestOptions = {}) => {
    dispatch(updateWebhookAction(oldWebhook, newWebhook));
    return new Promise((resolve, reject) => {
      WebhookAPI.updateWebhook(oldWebhook, newWebhook)
        .then((webhook) => {
          options.pathOnDone && navigate(options.pathOnDone);
          dispatch(updateWebhookActionDone(webhook));
          resolve(webhook);
        })
        .catch((error) => {
          if (error.get('status') === 403) {
            options.pathOnDone && navigate(options.pathOnDone);
          }
          dispatch(updateWebhookActionFailed(newWebhook, error));
          reject(error);
        });
    });
  };
}

const deleteWebhookAction = (webhook: Webhook) => ({ type: 'webhooks/DELETE_WEBHOOK', webhook }) as const;
const deleteWebhookActionDone = (webhook: Webhook) => ({ type: 'webhooks/DELETE_WEBHOOK_DONE', webhook }) as const;
const deleteWebhookActionFailed = (webhook: Webhook, error: Error) =>
  ({
    type: 'webhooks/DELETE_WEBHOOK_FAILED',
    webhook,
    error,
  }) as const;

export function useDeleteWebhook() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (webhook: Webhook, options: WebhookRequestOptions = {}) => {
    dispatch(deleteWebhookAction(webhook));
    return WebhookAPI.deleteWebhook(webhook)
      .then(() => {
        options.pathOnDone && navigate(options.pathOnDone);
        dispatch(deleteWebhookActionDone(webhook));
      })
      .catch((error) => {
        options.pathOnDone && navigate(options.pathOnDone);
        dispatch(deleteWebhookActionFailed(webhook, error));
      });
  };
}

const WebhookActionCreators = {
  fetchWebhooksAction,
  fetchWebhooksActionDone,
  fetchWebhooksActionFailed,
  editWebhookAction,
  createWebhookAction,
  createWebhookActionDone,
  createWebhookActionFailed,
  updateWebhookAction,
  updateWebhookActionDone,
  updateWebhookActionFailed,
  deleteWebhookAction,
  deleteWebhookActionDone,
  deleteWebhookActionFailed,
  disableSubmit,
  enableSubmit,
  initialize,
  destroy,
  blur: formActionBlur,
};

export type WebhookAction = GenerateActionType<typeof WebhookActionCreators>;

export { fetchWebhooksIfNeeded as fetchWebhooks, editWebhook };
