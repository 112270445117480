import { Suspense, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useOutletContext } from 'react-router-dom';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import {
  enableDynamicLayers,
  enableExperimentEventsTab,
  halfwayIA,
  isExperimentArchivingEnabled,
} from '@gonfalon/dogfood-flags';
import { useIsInNewIA } from '@gonfalon/ia-migration';
import { useLayers, useMember } from '@gonfalon/rest-api';
import { useParam, useProjectKey } from '@gonfalon/router';
import { ProgressBar } from '@launchpad-ui/components';
import { fromJS } from 'immutable';
import { Button, Navigation, NavigationItem, Tooltip } from 'launchpad';

import actionTypes from 'actionTypes/experimentation';
import AppHeader from 'components/AppHeader';
import { TimeAgoDate } from 'components/dateFormatting';
import { ArchiveExperimentBanner } from 'components/experimentation/common/components/ArchiveExperimentBanner';
import { useFlag } from 'components/experimentation/common/hooks/useFlag';
import { useUpdateExperimentName } from 'components/experimentation/common/hooks/useUpdateExperimentName';
import {
  ExperimentProvider,
  useExperimentProvider,
} from 'components/experimentation/common/providers/ExperimentProvider';
import { usePostExperimentationExperienceContext } from 'components/experimentation/common/providers/PostExperimentationExperienceProvider';
import { ExperimentV2, Flag, Iteration, RUNNING } from 'components/experimentation/common/types';
import {
  getFlagConfigVersion,
  getFlagKeysForIteration,
  getVisibleIteration,
  isExperimentArchived,
} from 'components/experimentation/common/utils/experiment';
import { getSavedExperimentNotification } from 'components/experimentation/common/utils/savedNotification';
import MemberProfileLink from 'components/MemberProfileLink/MemberProfileLink';
import { PageNav } from 'components/PageNav';
import AppSkeletonBody from 'components/SkeletonLoader/AppSkeletonBody';
import UserAvatar from 'components/UserAvatar';
import { Breadcrumbs } from 'routers/Breadcrumbs';
import { Member as MemberRecord } from 'utils/accountUtils';
import { trackExperimentEvent } from 'utils/analyticsUtils';
import Logger from 'utils/logUtils';
import { truncateString } from 'utils/stringUtils';

import { ExperimentDetailsNotFound } from '../ExperimentDetailsNotFound';

import styles from './ExperimentDetailsPage.module.css';

/**
 * The container for the experiment details page
 * @returns JSX.Element
 */

const logger = Logger.get('ExperimentDetailsPage');

type experimentDetailsDesignContext = {
  experiment: ExperimentV2;
  currentIteration: Iteration | undefined;
  previousIterations: Iteration[] | undefined;
  flag: Flag;
  enablePostExperimentationExperience: boolean;
  restoreExperiment: () => void;
};

export function ExperimentDetailsPage() {
  const projKey = useProjectKey();
  const envKey = useSelectedEnvironmentKey();
  const experimentKey = useParam('experimentKey');
  const location = useLocation();

  const currentTab = location.pathname.split('/').pop();

  const experimentQuery = useExperimentProvider();

  const [flagKey] = experimentQuery.experiment
    ? getFlagKeysForIteration(getVisibleIteration(experimentQuery.experiment))
    : [];
  const maintainerId = experimentQuery.experiment && experimentQuery.experiment._maintainerId;

  const { data: member } = useMember({ memberId: maintainerId || '' });
  const isInNewIA = useIsInNewIA();

  const { data: layerList } = useLayers(
    {
      projectKey: projKey,
      params: { filter: `reservations.experimentKey contains ${experimentKey}` },
    },
    { enabled: enableDynamicLayers() },
  );
  const isInLayer = layerList?.items && layerList?.items.length > 0;

  const {
    isError: isFlagError,
    error: flagError,
    isPending: isFlagLoading,
    data: flag,
  } = useFlag({
    projectKey: projKey,
    flagKey,
    environmentKey: envKey,
    flagConfigVersion: getFlagConfigVersion(experimentQuery.experiment?.currentIteration, flagKey),
  });

  const [experimentName, setExperimentName] = useState<string>(experimentQuery.experiment?.name ?? '');
  const nameMutation = useUpdateExperimentName({ projKey, envKey, experimentKey });
  const updateExperimentName = (name: string) => {
    setExperimentName(name);
    nameMutation.mutate(name);
  };

  const dispatchReduxAction = useDispatch();

  const { enablePostExperimentationExperience } = usePostExperimentationExperienceContext();

  useEffect(() => {
    if (experimentQuery.experiment?.name) {
      if (experimentName === '') {
        setExperimentName(experimentQuery.experiment.name);
      } else {
        updateExperimentName(experimentQuery.experiment.name);
      }
    }

    return () => {
      if (getSavedExperimentNotification()) {
        dispatchReduxAction({ type: actionTypes.EXPERIMENT_SAVED_DONE });
      }
    };
  }, [experimentQuery.experiment]);

  const getImmutableMember = () => new MemberRecord(fromJS(member));
  const isExperimentRunning = experimentQuery.experiment?.currentIteration.status === RUNNING;

  const disableArchiveReason = isExperimentRunning
    ? 'You cannot archive a running experiment.'
    : isInLayer
      ? 'You cannot archive an experiment that is in a layer.'
      : undefined;
  const isArchived = experimentQuery.experiment && isExperimentArchived(experimentQuery.experiment);
  const archivedDate = experimentQuery.experiment?.archivedDate;

  if (experimentQuery.status === 'error') {
    if (experimentQuery.error instanceof Response && experimentQuery.error.status === 404) {
      return (
        <div className={styles.notFoundContainer}>
          <ExperimentDetailsNotFound />
        </div>
      );
    }
    if (experimentQuery.error instanceof Error) {
      logger.error(experimentQuery.error.message);
    }
  }

  if (isFlagLoading || (experimentQuery.status === 'pending' && experimentQuery.fetchStatus === 'fetching')) {
    return <AppSkeletonBody />;
  }

  if (isFlagError && flagError instanceof Error) {
    logger.error(flagError.message);
  }

  const navItems = [
    { name: 'Design', to: 'design' },
    { name: 'Results', to: 'results' },
    { name: 'Iterations', to: 'iterations' },
  ];

  if (enableExperimentEventsTab()) {
    navItems.push({ name: 'Events', to: 'events' });
  }

  const renderArchiveButton = () => (
    <Button
      type="button"
      onClick={() => {
        if (isArchived) {
          experimentQuery.restore();
          trackExperimentEvent('Experiment Details Experiment Restored', {
            experimentStatus: experimentQuery.experiment?.currentIteration.status,
          });
        } else {
          experimentQuery.archive();
          trackExperimentEvent('Experiment Details Experiment Archived', {
            experimentStatus: experimentQuery.experiment?.currentIteration.status,
          });
        }
      }}
      disabled={!!disableArchiveReason}
    >
      {isArchived ? 'Restore experiment' : 'Archive experiment'}
    </Button>
  );

  return (
    <>
      {isExperimentArchivingEnabled() && archivedDate && <ArchiveExperimentBanner archivedDate={archivedDate} />}
      <AppHeader
        isSeamless={isInNewIA}
        className={styles['ExperimentDetailsPage-appHeader']}
        breadcrumbs={
          <Breadcrumbs
            breadcrumbs={[
              {
                path: halfwayIA() ? '/projects/:projKey/experiments' : '/:projKey/:envKey/experiments',
                element: 'Experiments',
                children: [
                  {
                    path: `:experimentKey/${currentTab}/*`,
                    element: truncateString(experimentName, 100),
                  },
                ],
              },
            ]}
          />
        }
      >
        <div className={styles['ExperimentDetailsPage-header']}>
          {isExperimentArchivingEnabled() && (
            <div className={styles['ExperimentDetailsPage-archive']}>
              {disableArchiveReason ? (
                <Tooltip placement="bottom-start" content={disableArchiveReason}>
                  <span tabIndex={0} role="button">
                    {renderArchiveButton()}
                  </span>
                </Tooltip>
              ) : (
                renderArchiveButton()
              )}
            </div>
          )}
        </div>
      </AppHeader>

      <PageNav className={styles['ExperimentDetailsPage-navContainer']}>
        <Navigation
          title="Experiment details menu"
          items={navItems}
          className={styles['ExperimentDetailsPage-navItemsContainer']}
        >
          {(item) => <NavigationItem key={item.to} name={item.name} to={item.to} />}
        </Navigation>
        <div className={styles['ExperimentDetailsPage-metadata']}>
          <p className={`u-ml-l ${styles['ExperimentDetailsPage-status']}`}>
            Created <TimeAgoDate datetime={experimentQuery.experiment?._creationDate} />
          </p>
          <div className={styles['ExperimentDetailsPage-maintainer']}>
            <span>Maintained by</span>
            <UserAvatar className={styles.icon} avatarUrl={getImmutableMember()._avatarUrl} />
            <MemberProfileLink member={getImmutableMember()} className={styles['ExperimentDetailsPage-memberLink']} />
          </div>
        </div>
      </PageNav>

      <Suspense fallback={<ProgressBar aria-label="Loading…" isIndeterminate />}>
        <Outlet
          context={
            {
              experiment: { ...experimentQuery.experiment },
              flag,
              currentIteration: experimentQuery.experiment?.currentIteration,
              previousIterations: experimentQuery.experiment?.previousIterations,
              enablePostExperimentationExperience,
              restoreExperiment: () => experimentQuery.restore(),
            } as experimentDetailsDesignContext
          }
        />
      </Suspense>
    </>
  );
}

export function Component() {
  return (
    <ExperimentProvider>
      <ExperimentDetailsPage />
    </ExperimentProvider>
  );
}

Component.displayName = 'ExperimentDetailsPageContainer';

export function useExperimentDetailsDesignContext() {
  return useOutletContext<experimentDetailsDesignContext>();
}
