import { Text } from 'react-aria-components';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { useIsInNewIA } from '@gonfalon/ia-migration';
import { toReleasePipeline, toReleases } from '@gonfalon/navigator';
import { Release } from '@gonfalon/release-pipelines';
import { useReleasePipeline } from '@gonfalon/rest-api';
import { useProjectKey } from '@gonfalon/router';
import { Button, Menu, MenuItem, MenuTrigger, Popover, Separator } from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';

import { useCanEditReleasePipeline } from 'components/releases/common/useCanEditReleasePipeline';
import { useCheckAccess } from 'utils/accessUtils';

import styles from './ManageReleasePopover.module.css';

export type ManageReleasePopoverProps = {
  isArchived: boolean;
  release: Release;
  setShowRemoveReleaseModal: (shouldShow: boolean) => void;
  setShowStartReleasePipelineModal: (shouldShow: boolean) => void;
};

export enum ReleasePipelineMoveErrorReasons {
  ARCHIVED = 'Archived flags cannot be moved',
  OLD_SHELL = 'Enable the new experience to move this flag',
  PERMISSION = 'You do not have permission to move this flag',
  COMPLETED_RELEASE = 'You cannot move a flag with a completed release',
}

export function ManageReleasePopover({
  isArchived,
  release,
  setShowRemoveReleaseModal,
  setShowStartReleasePipelineModal,
}: ManageReleasePopoverProps) {
  const isInNewIA = useIsInNewIA();
  const projectKey = useProjectKey();
  const environmentKey = useSelectedEnvironmentKey();
  const releasePipelineQuery = useReleasePipeline({ projectKey, releasePipelineKey: release.releasePipelineKey });
  const checkAccess = useCheckAccess();
  const canRemoveReleasePipeline = checkAccess('removeReleasePipeline').isAllowed;
  const hasPermission = checkAccess('replaceReleasePipeline').isAllowed;
  let moveDisabledReason = hasPermission ? '' : ReleasePipelineMoveErrorReasons.PERMISSION;
  if (isArchived) {
    moveDisabledReason = ReleasePipelineMoveErrorReasons.ARCHIVED;
  }
  if (!isInNewIA) {
    moveDisabledReason = ReleasePipelineMoveErrorReasons.OLD_SHELL;
  }
  const canEditReleasePipeline = useCanEditReleasePipeline({ isLegacy: releasePipelineQuery.data?._isLegacy ?? true });

  return (
    <MenuTrigger>
      <Button>
        <Icon name="arrow-connect" size="small" />
        {release.name}
        <Icon name="chevron-down" size="small" />
      </Button>
      <Popover placement="bottom end">
        {release.releasePipelineDescription && (
          <>
            <div className={styles.releasePipelineDescription}>{release.releasePipelineDescription}</div>
            <Separator className={styles.releasePipelineDescriptionSeparator} />
          </>
        )}
        <Menu
          onAction={(key) => {
            if (key === 'replaceReleasePipeline') {
              setShowStartReleasePipelineModal(true);
            } else if (key === 'removeReleasePipeline') {
              setShowRemoveReleaseModal(true);
            }
          }}
        >
          <MenuItem
            href={
              toReleases({
                projectKey,
                environmentKey,
                releasePipelineKey: release.releasePipelineKey,
              }).pathname
            }
          >
            <Text slot="label">
              <Icon name="arrow-connect" size="small" />
              View all releases in this pipeline
            </Text>
          </MenuItem>
          <MenuItem
            href={toReleasePipeline({ projectKey, releasePipelineKey: release.releasePipelineKey }).pathname}
            isDisabled={!canEditReleasePipeline.canEdit}
          >
            <Text slot="label">
              <Icon name="gear" size="small" />
              Manage release pipeline
            </Text>
            {!canEditReleasePipeline.canEdit && <Text slot="description">{canEditReleasePipeline.reason}</Text>}
          </MenuItem>
          <MenuItem id="replaceReleasePipeline" isDisabled={!!moveDisabledReason}>
            <Text slot="label">
              <Icon name="arrow-right-thin" size="small" />
              Move to different pipeline
            </Text>
            {moveDisabledReason && <Text slot="description">{moveDisabledReason}</Text>}
          </MenuItem>
          {canRemoveReleasePipeline && (
            <>
              <Separator />
              <MenuItem id="removeReleasePipeline" variant="destructive">
                <Text slot="label">
                  <Icon name="delete" size="small" />
                  Cancel release
                </Text>
              </MenuItem>
            </>
          )}
        </Menu>
      </Popover>
    </MenuTrigger>
  );
}
