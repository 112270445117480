import { forEach } from '@gonfalon/es6-utils';
import { Variation } from '@gonfalon/flags';

import DocumentationLink from 'components/DocumentationLink';
import { RandomizationUnitLabel } from 'components/experimentation/common/components/RandomizationUnitLabel';
import { Clause, Flag, Rule, WeightedExperimentRollout } from 'components/experimentation/common/types';
import {
  createImmutableVariations,
  createRolloutFromVariations,
} from 'components/experimentation/common/utils/immutable';
import { renderRuleDescription as renderRuleClauseDescription } from 'components/experimentation/common/utils/rule';
import RolloutBar from 'components/rollouts/RolloutBar';
import { DataList, DataListItem } from 'components/ui/datalist';
import { Cell, CellLayoutValue, Grid } from 'components/ui/grid';
import { Module, ModuleContent } from 'components/ui/module';
import { VariationColor } from 'components/VariationColor';
import { trackExperimentEvent } from 'utils/analyticsUtils';
import { colorVariation } from 'utils/flagUtils';
import { getDocumentationUrl } from 'utils/urlUtils';

import { variationLabel } from '../../../common/utils/flag';

import styles from './SetAudienceViewMode.module.css';
const variationReassignmentDocLink = getDocumentationUrl('home/experimentation/allocation#about-carryover-bias');

export type SetAudienceViewModeProps = {
  rules: Rule[];
  selectedRuleId?: string;
  selectedRuleDescription?: string;
  selectedRuleClauses?: Clause[];
  rollout: WeightedExperimentRollout;
  variations?: Flag['variations'];
  ignoreNonAllocationVariations?: boolean;
  className?: string;
  experimentLink?: React.ReactNode;
  selectedRandomizationUnit: string | undefined;
  isCurrentIteration?: boolean;
};

export function SetAudienceViewMode({
  rules,
  selectedRuleId,
  selectedRuleDescription,
  selectedRuleClauses,
  rollout,
  variations,
  className = '',
  experimentLink = null,
  selectedRandomizationUnit = '',
  isCurrentIteration = true,
  ignoreNonAllocationVariations = true,
}: SetAudienceViewModeProps) {
  let firstWeightedVariationId: string | undefined;
  let firstWeightedVariationIndex: number | undefined;
  let firstWeightedVariationObj: Variation | undefined;

  forEach(rollout.variationWeights, (v, k) => {
    if (v > 0) {
      firstWeightedVariationId = k;
      return false;
    }
  });

  forEach(variations, (v, k) => {
    if (v._id === firstWeightedVariationId && !firstWeightedVariationIndex) {
      firstWeightedVariationIndex = k;
      firstWeightedVariationObj = v;
      return false;
    }
  });

  const contextKind = (
    <span>
      <RandomizationUnitLabel randomizationUnit={selectedRandomizationUnit} /> contexts
    </span>
  );
  const selectedRuleIndex = rules.findIndex((rule) => rule._id === selectedRuleId);
  const selectedRule = rules[selectedRuleIndex];
  const clauses = selectedRuleClauses ?? selectedRule?.clauses;

  function renderRuleDescription() {
    if (selectedRuleDescription) {
      return selectedRuleDescription;
    }

    if (selectedRule?.description) {
      return selectedRule.description;
    }

    if (selectedRuleIndex !== -1) {
      return `Rule ${selectedRuleIndex + 1}`;
    }

    return 'Default rule';
  }

  if (variations && firstWeightedVariationObj && firstWeightedVariationIndex !== undefined) {
    const immutableRollout = createRolloutFromVariations(variations, rollout.variationWeights);
    const immutableVariations = createImmutableVariations(variations);
    const remainingPercent = (100000 - immutableRollout.getTotalRolloutPercentage()) / 1000;
    const percentInExperiment = immutableRollout.getTotalRolloutPercentage()
      ? immutableRollout.getTotalRolloutPercentage() / 1000
      : '0';

    return (
      <Module className={className}>
        <ModuleContent isSnug isSeamless>
          <DataList>
            <DataListItem className={styles['SetAudienceViewMode-DataListItem']}>
              <Grid>
                <Cell className={styles['SetAudienceViewMode-header']} layout={CellLayoutValue.TWO_OF_TWELVE}>
                  <h4>Targeting Rule</h4>
                </Cell>
                <Cell layout={CellLayoutValue.NINE_OF_TWELVE}>
                  <div className={styles['SetAudienceViewMode-rule']}>
                    <p className={styles['SetAudienceViewMode-description-header']}>{renderRuleDescription()}</p>
                    {selectedRule ? (
                      clauses.map((clause) => <p key={clause._id}>If {renderRuleClauseDescription(clause)}</p>)
                    ) : (
                      <p>Any traffic that does not match other targeting rules for the feature flag</p>
                    )}
                  </div>
                </Cell>
              </Grid>
            </DataListItem>
            <DataListItem className={styles['SetAudienceViewMode-DataListItem']}>
              <Grid>
                <Cell className={styles['SetAudienceViewMode-header']} layout={CellLayoutValue.TWO_OF_TWELVE}>
                  <h4>Traffic Allocation</h4>
                </Cell>
                <Cell layout={CellLayoutValue.NINE_OF_TWELVE}>
                  <RolloutBar
                    variations={immutableVariations}
                    referenceWeight={immutableRollout.getTotalRolloutPercentage()}
                    visualrollout
                    rollout={immutableRollout}
                    defaultVariationIndex={rollout.defaultVariation}
                  />
                  <div className={styles['SetAudienceViewMode-rolloutDetails']}>
                    <div className={styles['SetAudienceViewMode-rolloutDetails-left']}>
                      <p>
                        <strong>{percentInExperiment}%</strong> of {contextKind} {isCurrentIteration ? 'will be' : ''}{' '}
                        in the experiment{' '}
                      </p>
                      {variations.map((variation, index) => {
                        if (ignoreNonAllocationVariations && immutableRollout.getWeightForIndex(index) === 0) {
                          return null;
                        } else {
                          return (
                            <span
                              key={variation._id}
                              className={styles['SetAudienceViewMode-rolloutDetails-variation']}
                            >
                              <p className={styles['SetAudienceViewMode-rolloutDetails-variationCell']}>
                                <strong>{`${rollout.variationWeights[variation._id] / 1000}%`}</strong>
                              </p>
                              <div>
                                <VariationColor
                                  className={styles['SetAudienceViewMode-rolloutDetails-variationColor']}
                                  size="small"
                                  fill={colorVariation(index)}
                                />
                                {variationLabel(variation)}
                              </div>
                            </span>
                          );
                        }
                      })}
                    </div>

                    <div className="u-tr u-d-grid">
                      <div>
                        <p>
                          The remaining <strong>{remainingPercent}%</strong> of {contextKind}{' '}
                          {isCurrentIteration ? 'will be' : ''} served
                        </p>
                        <p>
                          <span className={styles.AudienceVariationControl}>
                            <strong>Control</strong>
                            <VariationColor
                              className={styles['SetAudienceViewMode-rolloutDetails-variationColor']}
                              size="small"
                              fill={colorVariation(rollout.defaultVariation)}
                            />
                            {variationLabel(variations[rollout.defaultVariation])}
                          </span>
                        </p>
                      </div>

                      <div className="u-mt-m">
                        <p>
                          If the default rule is evaluated for context kinds other than {contextKind} those contexts
                          will be served
                        </p>
                        <p>
                          <span className={styles.AudienceVariationControl}>
                            <VariationColor
                              className={styles['SetAudienceViewMode-rolloutDetails-variationColor']}
                              size="small"
                              fill={colorVariation(firstWeightedVariationIndex)}
                            />
                            {firstWeightedVariationObj.name}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Cell>
              </Grid>
            </DataListItem>
            {experimentLink && (
              <DataListItem className={styles['SetAudienceViewMode-DataListItem']}>
                <Grid>
                  <Cell className={styles['SetAudienceViewMode-header']} layout={CellLayoutValue.TWO_OF_TWELVE}>
                    <h4>Running Experiment</h4>
                  </Cell>
                  <Cell layout={CellLayoutValue.NINE_OF_TWELVE}>{experimentLink}</Cell>
                </Grid>
              </DataListItem>
            )}
            {!rollout.canReshuffle && (
              <DataListItem className={styles['SetAudienceViewMode-DataListItem']}>
                <Grid>
                  <Cell className={styles['SetAudienceViewMode-header']} layout={CellLayoutValue.TWO_OF_TWELVE}>
                    Advanced
                  </Cell>
                  <Cell layout={CellLayoutValue.NINE_OF_TWELVE}>
                    <p className={styles['SetAudienceViewMode-description-header']}>
                      {contextKind} will remain assigned to their initial variation
                    </p>
                    <p className={styles['SetAudienceViewMode-advanced-description-text']}>
                      For more accurate experiment results, we do not recommend keeping {contextKind} assigned to their
                      initial variation. To learn more about variation assignments,{' '}
                      <DocumentationLink
                        href={variationReassignmentDocLink}
                        onTrackEvent={() =>
                          trackExperimentEvent('Create Experiment Documentation Clicked', {
                            stepName: 'selectAllocation',
                          })
                        }
                        component="SetAudienceViewMode"
                      />
                    </p>
                  </Cell>
                </Grid>
              </DataListItem>
            )}
          </DataList>
        </ModuleContent>
      </Module>
    );
  }

  return null;
}
