import './styles.css';

export type StaticSortableItemProps = {
  children?: React.ReactNode;
};

/* eslint-disable import/no-default-export */
export default function StaticSortableItem({ children }: StaticSortableItemProps) {
  return (
    <div className="Sortable-item">
      <div className="Sortable-item-content">{children}</div>
    </div>
  );
}
