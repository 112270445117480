import { useApplicationVersionCount } from '@gonfalon/rest-api';
import { pluralize } from '@gonfalon/strings';
import { Tooltip } from 'launchpad';

import { ApplicationSupportChip } from './applications/ApplicationSupportChip';

export type ApplicationSupportedOperatorProps = {
  supported: boolean;
  applicationKey: string;
};

export const ApplicationSupportedOperatorViewOnly = ({
  supported,
  applicationKey,
}: ApplicationSupportedOperatorProps) => {
  const { data, isPending } = useApplicationVersionCount({
    applicationKey,
    params: { supported },
  });

  const supportedText = supported ? 'supported' : 'unsupported';

  return (
    <>
      is{' '}
      <Tooltip
        content={isPending ? '...' : `${data?.totalCount} ${supportedText} ${pluralize('version', data?.totalCount)}`}
        placement="top"
      >
        <ApplicationSupportChip supported={supported} />
      </Tooltip>{' '}
      for
    </>
  );
};
