import { Immutable } from 'immer';
import { OrderedMap } from 'immutable';
import { normalize, schema } from 'normalizr';
import qs from 'qs';

import {
  ApprovalRequest,
  ApprovalRequestsResponse,
  ApprovalRequestSummariesResponse,
  ApprovalRequestSummary,
  ApprovalsSemanticPatchInstructions,
  createApprovalRequest,
  getAddReviewersSemanticPatchInstructions,
} from 'utils/approvalsUtils';
import http, { jsonToImmutable, jsonToImmutableError, middleware } from 'utils/httpUtils';

import {
  ApprovalRequestAddReviewersPatchType,
  ApprovalRequestApplyPostType,
  ApprovalRequestDeleteByIdType,
  ApprovalRequestForFlagCopyPostType,
  ApprovalRequestGetByIdType,
  ApprovalRequestPostType,
  ApprovalRequestReviewPostType,
} from './types/approvalsAPI';
import { UrlProps } from './types/utils';
import { getFlagByKey } from './FlagAPI';

function createApprovalRequestPostBody({
  comment,
  description,
  instructions,
  notifyMemberIds,
  notifyTeamKeys,
  executionDate,
  operatingOnId,
  integrationConfig,
  resourceId,
}: ApprovalRequestPostType) {
  const body: ApprovalRequestPostType = {
    comment,
    description,
    instructions,
    notifyMemberIds,
    notifyTeamKeys,
    integrationConfig,
    resourceId,
  };

  if (executionDate) {
    body.executionDate = executionDate;
  }

  if (operatingOnId) {
    body.operatingOnId = operatingOnId;
  }

  return body;
}

export async function postApprovalRequest(approvalRequestProps: ApprovalRequestPostType) {
  const url = '/api/v2/approval-requests';
  const body = createApprovalRequestPostBody(approvalRequestProps);
  return http
    .post(url, {
      beta: true,
      body,
    })
    .then(jsonToImmutable)
    .then(createApprovalRequest)
    .catch(jsonToImmutableError);
}

export const postApprovalRequestForFlagConfiguration = async ({
  projKey,
  envKey,
  flagKey,
  comment,
  description,
  instructions,
  notifyMemberIds,
  notifyTeamKeys,
  executionDate,
  operatingOnId,
  integrationConfig,
}: UrlProps & ApprovalRequestPostType) => {
  const url = `/api/v2/projects/${projKey}/flags/${flagKey}/environments/${envKey}/approval-requests`;
  const body: ApprovalRequestPostType = createApprovalRequestPostBody({
    comment,
    description,
    instructions,
    notifyMemberIds,
    notifyTeamKeys,
    executionDate,
    operatingOnId,
    integrationConfig,
  });

  return http
    .post(url, {
      beta: true,
      body,
    })
    .then(jsonToImmutable)
    .then(createApprovalRequest)
    .catch(jsonToImmutableError);
};

export const postApprovalRequestForFlagCopy = async ({
  projKey,
  envKey,
  flagKey,
  comment,
  description,
  notifyMemberIds,
  notifyTeamKeys,
  source,
  includedActions,
  excludedActions,
}: UrlProps & ApprovalRequestForFlagCopyPostType) => {
  const url = `/api/v2/projects/${projKey}/flags/${flagKey}/environments/${envKey}/approval-requests-flag-copy`;
  const body: ApprovalRequestForFlagCopyPostType = { comment, description, notifyMemberIds, notifyTeamKeys, source };
  if (includedActions) {
    body.includedActions = includedActions;
  }
  if (excludedActions) {
    body.excludedActions = excludedActions;
  }
  return http
    .post(url, {
      beta: true,
      body,
    })
    .then(jsonToImmutable)
    .then(createApprovalRequest)
    .catch(jsonToImmutableError);
};

const approvalRequestEntity = new schema.Entity('approvalRequests', {}, { idAttribute: '_id' });

type ApprovalRequestExpandParam = Immutable<{
  project?: boolean;
  environment?: boolean;
  flag?: boolean;
  environments?: boolean;
}>;

function convertExpandParamToString(expand: ApprovalRequestExpandParam) {
  return Object.entries(expand)
    .reduce((keys, [key, includeKey]) => (includeKey ? [...keys, key] : keys), [] as string[])
    .join(',');
}

export type GetApprovalRequestsForAccountOptions = Immutable<{
  params?: {
    q?: string;
    filter?: string;
    expand?: ApprovalRequestExpandParam;
  };
}>;
export async function getApprovalRequestsForAccount(options: {
  params: {
    expand: { project: true; flag: true; environment: true } | { project: true; flag: true; environments: true };
    filter?: string;
  };
}): Promise<ApprovalRequestSummariesResponse>;
export async function getApprovalRequestsForAccount(options: {
  params: {
    expand?:
      | { project?: boolean; flag?: boolean; environment?: boolean }
      | { project?: boolean; flag?: boolean; environments?: boolean };
    filter?: string;
  };
}): Promise<ApprovalRequestsResponse>;
export async function getApprovalRequestsForAccount(
  options?: GetApprovalRequestsForAccountOptions,
): Promise<ApprovalRequestsResponse> {
  let url = '/api/v2/approval-requests';

  if (options?.params) {
    let expand: string | undefined;
    if (options.params.expand) {
      expand = convertExpandParamToString(options.params.expand);
    }
    url += qs.stringify({ ...options.params, expand }, { addQueryPrefix: true });
  }

  return http
    .get(url, { beta: true })
    .then(middleware.json)
    .then((rawJSON) => {
      const data = normalize(rawJSON, { items: [approvalRequestEntity] });

      data.entities.approvalRequests =
        data.entities.approvalRequests &&
        Object.entries(data.entities.approvalRequests).reduce(
          (approvalRequests, [id, ar]) => ({ ...approvalRequests, [id]: createApprovalRequest(ar as ApprovalRequest) }),
          {} as { [id: string]: ApprovalRequest },
        );

      return data;
    })
    .catch(jsonToImmutableError);
}

export type GetApprovalRequestByIdOptions = Immutable<{
  approvalRequestId: string;
  expand?: ApprovalRequestExpandParam;
}>;

export async function getApprovalRequestById(options: {
  approvalRequestId: string;
  expand: { project: true; flag: true; environment: true } | { project: true; flag: true; environments: true };
}): Promise<ApprovalRequestSummary>;
export async function getApprovalRequestById(options: {
  approvalRequestId: string;
  expand?: never;
}): Promise<ApprovalRequest>;
export async function getApprovalRequestById({
  approvalRequestId,
  expand,
}: GetApprovalRequestByIdOptions): Promise<ApprovalRequest | ApprovalRequestSummary> {
  let url = `/api/v2/approval-requests/${approvalRequestId}`;
  if (expand) {
    url += qs.stringify({ expand: convertExpandParamToString(expand) }, { addQueryPrefix: true });
  }
  return http
    .get(url, { beta: true })
    .then(middleware.json)
    .then(async (rawJSON) => {
      const approvalRequest = createApprovalRequest(rawJSON);
      if (approvalRequest.project && approvalRequest.flag && approvalRequest.environments) {
        let environmentKeys: string[] = [];

        if (approvalRequest.getResourceKind() === 'FlagConfiguration') {
          if (approvalRequest.environments) {
            environmentKeys = approvalRequest.environments.map((env) => env.key).toArray();
          } else if (approvalRequest.environment) {
            environmentKeys = [approvalRequest.environment.key];
          }
        }

        const flagWithConfigForEnv = await getFlagByKey(
          approvalRequest.project.key,
          approvalRequest.flag.key,
          environmentKeys,
        );

        return approvalRequest.set('flag', flagWithConfigForEnv);
      }
      return approvalRequest;
    })
    .catch(jsonToImmutableError);
}

export const getApprovalRequestsForFlagConfiguration = async ({
  projKey,
  envKey,
  flagKey,
}: UrlProps): Promise<OrderedMap<string, ApprovalRequest>> => {
  const url = `/api/v2/projects/${projKey}/flags/${flagKey}/environments/${envKey}/approval-requests`;
  return http
    .get(url, {
      beta: true,
    })
    .then(jsonToImmutable)
    .then((approvalRequests) =>
      approvalRequests
        .get('items')
        .map(createApprovalRequest)
        .reduce(
          (accum: OrderedMap<string, ApprovalRequest>, curr: ApprovalRequest) => accum.set(curr.get('_id'), curr),
          OrderedMap(),
        ),
    )
    .catch(jsonToImmutableError);
};

export const getApprovalRequestByIdForFlagConfiguration = async ({
  projKey,
  envKey,
  flagKey,
  requestId,
}: UrlProps & ApprovalRequestGetByIdType): Promise<ApprovalRequest> => {
  const url = `/api/v2/projects/${projKey}/flags/${flagKey}/environments/${envKey}/approval-requests/${requestId}`;
  return http
    .get(url, {
      beta: true,
    })
    .then(jsonToImmutable)
    .then(createApprovalRequest)
    .catch(jsonToImmutableError);
};

export async function postReviewApprovalRequest({
  comment,
  kind,
  requestId,
}: ApprovalRequestReviewPostType): Promise<ApprovalRequest> {
  const url = `/api/v2/approval-requests/${requestId}/reviews`;
  return http
    .post(url, {
      beta: true,
      body: {
        comment,
        kind,
      },
    })
    .then(jsonToImmutable)
    .then(createApprovalRequest)
    .catch(jsonToImmutableError);
}

export const postReviewApprovalRequestForFlagConfiguration = async ({
  projKey,
  envKey,
  flagKey,
  comment,
  kind,
  requestId,
}: UrlProps & ApprovalRequestReviewPostType): Promise<ApprovalRequest> => {
  const url = `/api/v2/projects/${projKey}/flags/${flagKey}/environments/${envKey}/approval-requests/${requestId}/reviews`;
  return http
    .post(url, {
      beta: true,
      body: {
        comment,
        kind,
      },
    })
    .then(jsonToImmutable)
    .then(createApprovalRequest)
    .catch(jsonToImmutableError);
};

export async function deleteApprovalRequest({ requestId }: ApprovalRequestDeleteByIdType) {
  const url = `/api/v2/approval-requests/${requestId}`;
  return http
    .delete(url, {
      beta: true,
    })
    .then()
    .catch(jsonToImmutableError);
}

export const deleteApprovalRequestForFlagConfiguration = async ({
  projKey,
  flagKey,
  envKey,
  requestId,
}: UrlProps & ApprovalRequestDeleteByIdType) => {
  const url = `/api/v2/projects/${projKey}/flags/${flagKey}/environments/${envKey}/approval-requests/${requestId}`;
  return http
    .delete(url, {
      beta: true,
    })
    .then()
    .catch(jsonToImmutableError);
};

export async function postApplyApprovalRequest({
  comment,
  requestId,
}: ApprovalRequestApplyPostType): Promise<ApprovalRequest> {
  const url = `/api/v2/approval-requests/${requestId}/apply`;
  return http
    .post(url, {
      beta: true,
      body: {
        comment,
      },
    })
    .then(jsonToImmutable)
    .then(createApprovalRequest)
    .catch(jsonToImmutableError);
}

export const postApplyApprovalRequestForFlagConfiguration = async ({
  projKey,
  envKey,
  flagKey,
  comment,
  requestId,
}: UrlProps & ApprovalRequestApplyPostType): Promise<ApprovalRequest> => {
  const url = `/api/v2/projects/${projKey}/flags/${flagKey}/environments/${envKey}/approval-requests/${requestId}/apply`;
  return http
    .post(url, {
      beta: true,
      body: {
        comment,
      },
    })
    .then(jsonToImmutable)
    .then(createApprovalRequest)
    .catch(jsonToImmutableError);
};

export const updateReviewersForApprovalRequestForFlagConfiguration = async ({
  projKey,
  envKey,
  flagKey,
  requestId,
  notifyMemberIds,
  notifyTeamKeys = [],
}: UrlProps & ApprovalRequestAddReviewersPatchType): Promise<ApprovalRequest> => {
  const url = `/api/v2/projects/${projKey}/flags/${flagKey}/environments/${envKey}/approval-requests/${requestId}`;
  const instructions = getAddReviewersSemanticPatchInstructions(
    notifyMemberIds,
    notifyTeamKeys,
  ) as ApprovalsSemanticPatchInstructions[];
  const body = {
    instructions,
  };
  return http
    .patch(url, {
      headers: {
        'Ld-Api-Version': 'beta',
        'Content-Type': 'application/json; domain-model=launchdarkly.semanticpatch',
      },
      body,
    })
    .then(jsonToImmutable)
    .then(createApprovalRequest)
    .catch(jsonToImmutableError);
};

export const updateReviewersForApprovalRequest = async ({
  requestId,
  notifyMemberIds,
  notifyTeamKeys = [],
}: ApprovalRequestAddReviewersPatchType): Promise<ApprovalRequest> => {
  const url = `/api/v2/approval-requests/${requestId}`;
  const instructions = getAddReviewersSemanticPatchInstructions(
    notifyMemberIds,
    notifyTeamKeys,
  ) as ApprovalsSemanticPatchInstructions[];
  const body = {
    instructions,
  };
  return http
    .patch(url, {
      headers: {
        'Ld-Api-Version': 'beta',
        'Content-Type': 'application/json; domain-model=launchdarkly.semanticpatch',
      },
      body,
    })
    .then(jsonToImmutable)
    .then(createApprovalRequest)
    .catch(jsonToImmutableError);
};
