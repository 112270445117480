import { List, Map } from 'immutable';

import { ClauseInstructionDescription } from 'components/InstructionList/ClauseInstructionDescription';
import { RolloutDescription } from 'components/InstructionList/RolloutDescription';
import Variation from 'components/Variation';
import { Clause } from 'utils/clauseUtils';
import { colorVariation, Variation as variationType } from 'utils/flagUtils';
import { AggregateRule } from 'utils/instructions/rules/types';

import { ExperimentRolloutDescription } from './ExperimentRolloutDescription';
import { RULE_NOT_FOUND } from './instructionListUtils';

export enum RuleDescriptionKind {
  DESCRIPTION_ONLY = 'descriptionOnly',
  DESCRIPTION_WITH_CLAUSE_SUMMARY = 'descriptionWithClauseSummary',
  CLAUSE_SUMMARY = 'clauseSummary',
  REORDERED_RULE = 'reorderedRule',
}

export const RuleInstructionDescription = ({
  projKey,
  envKey,
  rule,
  variations,
  showServeVariation = false,
  kind = RuleDescriptionKind.CLAUSE_SUMMARY,
}: {
  projKey: string;
  envKey: string;
  rule?: AggregateRule;
  showServeVariation?: boolean;
  variations: List<variationType>;
  kind?: RuleDescriptionKind;
}) => {
  if (!rule) {
    return <span className="u-italic">{RULE_NOT_FOUND}</span>;
  }

  const renderVariation = (index: number) => (
    <Variation
      key={index}
      color={colorVariation(index)}
      value={
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        variations.get(index)!.getDisplay() /* eslint-enable @typescript-eslint/no-non-null-assertion */
      }
    />
  );

  const serveVariation = () => {
    let variationIndex = 0;
    if (rule.variationId) {
      variations.forEach((v, i) => {
        if (v._id === rule.variationId) {
          variationIndex = i;
        }
      });
    }
    const bucketBy = rule.rolloutBucketBy;
    const rolloutContextKind = rule.rolloutContextKind;
    const rolloutWeights = rule.rolloutWeights;
    const experimentAllocation = rule.experimentAllocation;

    let content;
    if (experimentAllocation && rolloutWeights) {
      content = (
        <ExperimentRolloutDescription
          rolloutWeights={Map(rolloutWeights)}
          variations={variations}
          experimentAllocation={experimentAllocation}
        />
      );
    } else if (rolloutWeights && !experimentAllocation) {
      content = (
        <RolloutDescription
          rolloutWeights={Map(rolloutWeights)}
          contextKind={rolloutContextKind}
          bucketBy={bucketBy}
          variations={variations}
        />
      );
    } else {
      content = renderVariation(variationIndex);
    }

    return <span className="u-fw-regular"> serve {content}</span>;
  };

  const renderClause = () => (
    <span className="u-fs-md">
      {rule.clause ? (
        <ClauseInstructionDescription projKey={projKey} envKey={envKey} kind={kind} clause={rule.clause} />
      ) : (
        rule.clauses.map((clause: Clause | null, indx: number) => {
          const clauseArrLength = rule.clauses.length;
          let extraText;
          if (indx !== clauseArrLength - 1) {
            extraText = ' and ';
          }
          return (
            <ClauseInstructionDescription
              projKey={projKey}
              envKey={envKey}
              kind={kind}
              key={indx}
              clause={
                /* eslint-disable @typescript-eslint/no-non-null-assertion */
                clause! /* eslint-enable @typescript-eslint/no-non-null-assertion */
              }
              extraText={extraText}
            />
          );
        })
      )}
    </span>
  );

  if (kind === RuleDescriptionKind.DESCRIPTION_WITH_CLAUSE_SUMMARY && rule.description) {
    return (
      <>
        <span className="u-fw-semibold RuleInstructionDescription">{rule.description}</span>
        <div className="u-color-text-ui-tertiary u-ml-l RuleInstructionDescription--clauseSummary">
          {renderClause()}
          {showServeVariation && serveVariation()}
        </div>
      </>
    );
  }

  if (kind === RuleDescriptionKind.DESCRIPTION_ONLY && rule.description) {
    return <span className="u-fw-semibold RuleInstructionDescription">{rule.description}</span>;
  }

  return (
    <span className="u-fw-semibold">
      {renderClause()}
      {showServeVariation && serveVariation()}
    </span>
  );
};
