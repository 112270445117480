import { ChangeEvent, useEffect, useState } from 'react';
import { useParam } from '@gonfalon/router';
import { List } from 'immutable';
import { Filter } from 'launchpad';

import { Member } from 'utils/accountUtils';
import { AuditLogViewKind, getDefaultPolicyList, Query } from 'utils/auditLogUtils';
import { createPolicyStatement, PolicyActionAndDescription, PolicyActions, PolicyStatement } from 'utils/policyUtils';

export type FilterRowProps = {
  profile?: Member;
  policyActions: PolicyActions;
  selectedFilter: AuditLogViewKind;
  isPolicyActionsReady: boolean;
  query?: Query;
  onUpdateFilters(policy: PolicyStatement): void;
};

export function AuditLogActionFilter({
  policyActions,
  selectedFilter,
  isPolicyActionsReady,
  query,
  onUpdateFilters,
}: FilterRowProps) {
  const [searchValue, setSearchValue] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([] as string[]);

  useEffect(() => {
    setSelectedOptions(query?.get('actions')?.split(',') || []);
  }, [query]);

  const projKey = useParam('projKey', { optional: true });
  const envKey = useParam('envKey', { optional: true });
  const flagKey = useParam('flagKey', { optional: true });
  const teamKey = useParam('teamKey', { optional: true });
  const memberId = useParam('memberId', { optional: true });
  const segmentKey = useParam('segmentKey', { optional: true });
  const getResourceId = () => {
    switch (selectedFilter) {
      case AuditLogViewKind.TEAM:
        return teamKey;
      case AuditLogViewKind.FLAG:
        return flagKey;
      case AuditLogViewKind.MEMBER:
        return memberId;
      case AuditLogViewKind.SEGMENT:
        return segmentKey;
      default:
        return undefined;
    }
  };

  const transformActionIntoReadableName = (action: string) =>
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    action.replace(/([A-Z])/g, ' $1').replace(/^(\w)(.+)/, (_match, p1, p2) => p1.toUpperCase() + p2.toLowerCase());

  const createOptions = () => {
    if (isPolicyActionsReady && selectedFilter) {
      const allActions = policyActions
        .get(selectedFilter)
        .get('items')
        .toJS()
        // Create the options
        .map((t: PolicyActionAndDescription) => ({
          value: t.action,
          name: `${transformActionIntoReadableName(t.action)}`,
          isChecked: t.action === selectedOptions.find((o) => o === t.action),
        }))
        // This filter is for the search. It won't have any impact unless there is a search value
        .filter((option: { value: string; name: string }) =>
          option.name.toLowerCase().includes(searchValue.toLowerCase()),
        )
        // Sort the options by name
        .sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name));

      const selectedActions = allActions.filter((a) => a.isChecked);
      const unselectedActions = allActions.filter((a) => !a.isChecked);
      const displayActions = unselectedActions;
      if (selectedActions.length > 0) {
        // Display selected actions at the top of the list
        displayActions.unshift(...selectedActions);
      }
      return displayActions;
    }
    return [];
  };

  const handleUpdateFilters = (a?: { value: string }) => {
    const selectedValue = a?.value as string;
    const defaultPolicyList = getDefaultPolicyList(selectedFilter, projKey, envKey, getResourceId());
    let queryPolicy = defaultPolicyList.get(0);
    queryPolicy = queryPolicy && createPolicyStatement(queryPolicy);

    const isChecked = selectedOptions.length && selectedOptions.find((o) => selectedValue === o);
    if (isChecked) {
      const updatedSelectedOptions = selectedOptions.filter((o) => selectedValue !== o);
      const queryPolicyActions = updatedSelectedOptions.length ? updatedSelectedOptions : ['*'];
      const updatedQueryPolicy = queryPolicy
        ? queryPolicy.set('actions', List(queryPolicyActions))
        : createPolicyStatement({});
      onUpdateFilters && onUpdateFilters(updatedQueryPolicy);
      return;
    }

    const updatedQueryPolicy = queryPolicy
      ? queryPolicy.set('actions', List([...selectedOptions, selectedValue]))
      : createPolicyStatement({});
    onUpdateFilters && onUpdateFilters(updatedQueryPolicy);
  };

  const handleClearFilters = () => {
    const defaultPolicyList = getDefaultPolicyList(selectedFilter, projKey, envKey, getResourceId());
    let queryPolicy = defaultPolicyList.get(0);
    queryPolicy = queryPolicy && createPolicyStatement(queryPolicy);
    const updatedQueryPolicy = queryPolicy ? queryPolicy.set('actions', List(['*'])) : createPolicyStatement({});
    onUpdateFilters && onUpdateFilters(updatedQueryPolicy);
  };

  const getDescription = () => {
    if (selectedOptions.length === 0) {
      return 'All actions';
    }
    if (selectedOptions.length === 1) {
      return transformActionIntoReadableName(selectedOptions[0]);
    }
    return 'Multiple actions';
  };

  const renderActionValueSelect = () => (
    <Filter
      name="Actions"
      isClearable={selectedOptions.length > 0}
      description={getDescription()}
      options={createOptions()}
      searchValue={searchValue}
      onSearchChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
      searchAriaLabel="Filter actions"
      searchPlaceholder="Filter actions"
      onSelect={handleUpdateFilters}
      onClear={handleClearFilters}
      onStateChange={({ isOpen }: { isOpen?: boolean }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !isOpen;
      }}
      isLoading={!isPolicyActionsReady}
    />
  );

  return <div>{renderActionValueSelect()}</div>;
}
