import { ComponentType, ForwardRefExoticComponent, useCallback, useLayoutEffect, useRef, useState } from 'react';

import { getDisplayName } from 'utils/componentUtils';

type TrackViewportProps = {
  isOutsideViewport: boolean;
};

type WrapperState = {
  isOutsideViewport: boolean;
  startingOffsetTop: number | undefined;
};

function trackViewport<T>(
  ComponentToWrap: ForwardRefExoticComponent<T>,
): ComponentType<Omit<T, keyof TrackViewportProps>>;

function trackViewport<T extends TrackViewportProps = TrackViewportProps>(
  ComponentToWrap: ComponentType<Omit<T, keyof TrackViewportProps>>,
) {
  // Inject a prop representing the component's visibility
  // in the viewport.
  function Wrapper(props: Omit<T, keyof TrackViewportProps>) {
    const wrappedComponentRef = useRef<HTMLElement>(null);
    const [{ isOutsideViewport }, setState] = useState<WrapperState>({
      isOutsideViewport: false,
      startingOffsetTop: undefined,
    });
    const handleScroll = useCallback(() => {
      setState((previousState) => ({
        ...previousState,
        isOutsideViewport:
          typeof previousState.startingOffsetTop === 'number'
            ? window.scrollY > previousState.startingOffsetTop
            : false,
      }));
    }, [setState]);

    useLayoutEffect(() => {
      setState((previousState) => ({
        ...previousState,
        startingOffsetTop: __TEST__ ? 0 : wrappedComponentRef.current?.offsetTop,
      }));
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <ComponentToWrap ref={wrappedComponentRef} isOutsideViewport={isOutsideViewport} {...props} />;
  }

  Wrapper.displayName = `trackViewport(${getDisplayName(ComponentToWrap)})`;

  return Wrapper;
}

/* eslint-disable import/no-default-export */
export default trackViewport;
