import { useEffect, useState } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { components, ValueContainerProps } from 'react-select';
import { convertToKey, hookFormKeyInvalid } from '@gonfalon/strings';
import { CopyToClipboard, FormField, InlineEdit, TextArea, TextField } from 'launchpad';

import SelectMemberContainer from 'components/SelectMemberContainer';
import SelectTags from 'components/SelectTags';
import { TagKind } from 'components/tagFilter/types';
import { Member } from 'utils/accountUtils';

import { MAX_METRIC_KEY_LENGTH, NewMetricForm } from '../common';
import { FormFieldWithTooltip } from '../components/FormFieldWithTooltip';

import styles from './CreateMetricModal.module.css';

const keyFieldHint = (
  <>
    We use the key to give you friendly URLs. Keys should be at most {MAX_METRIC_KEY_LENGTH} characters and must only
    contain letters, numbers, <code>.</code>, <code>_</code> or <code>-</code>.
  </>
);

export function MetricPropertiesSection() {
  const {
    control,
    register,
    formState: { errors },
    setValue,
  } = useFormContext<NewMetricForm>();
  const [isAutoKey, setIsAutoKey] = useState<boolean>(true);
  const name = useWatch({ control, name: 'name', exact: true });
  const { field: memberField } = useController({
    name: 'maintainerId',
    control,
    rules: { required: 'Maintainer is required' },
  });
  const { ref: _memberRef, onChange: onMemberChange, ...memberFieldProps } = memberField;

  useEffect(() => {
    if (isAutoKey) {
      setValue('key', convertToKey(name ?? ''));
    }
  }, [name, isAutoKey, setValue]);

  const key = useWatch({ control, name: 'key', exact: true });

  return (
    <>
      <FormField
        LabelProps={{ className: styles.metricName }}
        name="name"
        htmlFor="name"
        label="Name"
        errorMessage={errors.name?.message}
        isRequired
        data-test-id="metric-name"
      >
        <TextField {...register('name', { required: 'Metric name is required' })} placeholder="Name your metric" />
      </FormField>
      <FormFieldWithTooltip
        name="key"
        htmlFor="key"
        label="Key"
        tooltip={keyFieldHint}
        errorMessage={errors.key?.message}
        isRequired
      >
        <InlineEdit
          onConfirm={(value: string) => {
            setIsAutoKey(false);
            setValue('key', value);
          }}
          renderInput={
            <TextField
              id="key"
              {...register('key', {
                required: 'Metric key is required',
                pattern: hookFormKeyInvalid,
                maxLength: {
                  value: MAX_METRIC_KEY_LENGTH,
                  message: `Key must be fewer than ${MAX_METRIC_KEY_LENGTH} characters`,
                },
              })}
              placeholder="Enter metric key"
            />
          }
        >
          {key ? <CopyToClipboard text={key}>{key}</CopyToClipboard> : 'Metric key will be auto generated here'}
        </InlineEdit>
      </FormFieldWithTooltip>
      <FormField
        name="description"
        htmlFor="description"
        label="Description"
        errorMessage={errors.description?.message}
        isRequired={false}
      >
        <TextArea {...register('description')} placeholder="Add a description to this metric" />
      </FormField>
      <FormField name="tags" htmlFor="tags" label="Tags" errorMessage={errors.tags?.message} isRequired={false}>
        <SelectTags<NewMetricForm>
          id="tags"
          useHookForm
          kind={TagKind.METRIC}
          control={control}
          customComponents={{ ValueContainer }}
          name="tags"
          ariaLabel="Metric tags"
          placeholder="Add tags"
        />
      </FormField>
      <FormField
        name="maintainerId"
        htmlFor="maintainerId"
        label="Maintainer"
        errorMessage={errors.maintainerId?.message}
        isRequired={false}
      >
        <SelectMemberContainer
          {...memberFieldProps}
          onChange={(member: Member) => onMemberChange({ target: { value: member?._id } })}
          placeholder="Select maintainer"
        />
      </FormField>
    </>
  );
}

function ValueContainer({ children, ...props }: ValueContainerProps) {
  return (
    <div className="u-flex u-flex-middle">
      <components.ValueContainer {...props}>{children}</components.ValueContainer>
    </div>
  );
}
