import { ReactNode } from 'react';
import cx from 'clsx';

import { useSegments } from 'hooks/useSegments';
import { Clause } from 'utils/clauseUtils';

import { RuleDescriptionKind } from './RuleInstructionDescription';

const ClauseInstruction = ({
  className,
  extraText,
  children,
}: {
  extraText?: string;
  className?: string;
  children: ReactNode;
}) => (
  <span>
    <span className={cx('ClauseInstructionDescription', className)}>{children}</span> <span>{extraText}</span>
  </span>
);

const SegmentClauseInstructionDescription = ({
  projKey,
  envKey,
  clause,
  extraText,
  className,
}: {
  projKey: string;
  envKey: string;
  clause: Clause;
  extraText?: string;
  className?: string;
}) => {
  const { segments } = useSegments({ projKey, envKey, enabled: clause.hasSegmentOp() });

  const clauseText = clause.translateToText(segments);

  return (
    <ClauseInstruction extraText={extraText} className={className}>
      {clauseText}
    </ClauseInstruction>
  );
};

export const ClauseInstructionDescription = ({
  projKey,
  envKey,
  clause,
  extraText,
  className,
  kind,
}: {
  projKey: string;
  envKey: string;
  clause: Clause;
  extraText?: string;
  className?: string;
  kind?: RuleDescriptionKind;
}) => {
  const extraClasses = cx({
    'ClauseInstructionDescription--reorderedRule': kind !== undefined && kind === RuleDescriptionKind.REORDERED_RULE,
  });

  // special-case segment clauses because fetching and processing the list of segments is expensive and we don't want to
  // do it unless we really need to
  if (clause.hasSegmentOp()) {
    return (
      <SegmentClauseInstructionDescription
        className={cx(className, extraClasses)}
        clause={clause}
        extraText={extraText}
        projKey={projKey}
        envKey={envKey}
      />
    );
  }

  const clauseText = clause.translateToText();

  return (
    <ClauseInstruction className={extraClasses} extraText={extraText}>
      {clauseText}
    </ClauseInstruction>
  );
};
