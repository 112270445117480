// https://github.com/acdlite/recompose/blob/master/src/packages/recompose/getDisplayName.js
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getDisplayName<P = any>(component?: React.ComponentType<P> | string): string | undefined {
  if (typeof component === 'string') {
    return component;
  }

  if (!component) {
    return undefined;
  }

  return component.displayName || component.name || 'Component';
}
