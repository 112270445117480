import { useEffect } from 'react';
import { enableTeamsAsFlagMaintainers, enableTeamsOfTeams } from '@gonfalon/dogfood-flags';
import { noop } from '@gonfalon/es6-utils';
import { useMember, useTeam } from '@gonfalon/rest-api';
import { List } from 'immutable';

import { fetchTeams as fetchTeamsAction } from 'actions/teams';
import {
  getOptionsForSelectTeamOrMember,
  NestedTeamOrMemberOptionsType,
  TeamOrMemberOptionType,
} from 'components/SelectTeamOrMember/SelectTeamOrMemberUtils';
import { useDispatch } from 'hooks/useDispatch';
import { useMembers } from 'hooks/useMembers';
import { useSelector } from 'hooks/useSelector';
import { useProfileEntity } from 'reducers/profile';
import { teamsListSelector, teamsRequestSelector } from 'reducers/teams';
import { createMember, Member } from 'utils/accountUtils';
import { CreateFunctionInput } from 'utils/immutableUtils';
import Logger from 'utils/logUtils';
import { ready } from 'utils/reduxUtils';
import { createTeam, createTeamsFilters, Team, TeamProps, TeamsFilters } from 'utils/teamsUtils';

function couldBeMemberId(str: string) {
  return str.length === 24 && /^[A-F0-9]+$/i.test(str);
}

const useRedux = ({
  searchQuery,
  selectedValue,
  teamOnly,
}: {
  searchQuery?: string;
  selectedValue?: string;
  teamOnly?: boolean;
}) => {
  const profile = useProfileEntity();
  const membersData = useMembers({ limit: 200, query: searchQuery, enabled: !teamOnly });
  let members = membersData?.members?.toList() || List<Member>();
  const isMembersListReady = membersData.isReady;
  const dispatch = useDispatch();
  const fetchTeams = async (teamsFilters: TeamsFilters) => dispatch(fetchTeamsAction(teamsFilters, ['members']));
  let teams: List<Team> = useSelector(teamsListSelector);
  const teamsRequest = useSelector(teamsRequestSelector);
  const isTeamsListReady = ready(teamsRequest);

  const selectedMemberQuery = useMember(
    { memberId: selectedValue ?? '' },
    {
      enabled:
        Boolean(selectedValue) &&
        couldBeMemberId(selectedValue ?? '') &&
        isMembersListReady &&
        !members.find((m) => m._id === selectedValue),
    },
  );

  const selectedTeamQuery = useTeam(
    { teamKey: selectedValue ?? '' },
    {
      enabled:
        Boolean(selectedValue) &&
        (!couldBeMemberId(selectedValue ?? '') || selectedMemberQuery.isError) &&
        isTeamsListReady &&
        !teams.find((t) => t.key === selectedValue),
    },
  );

  if (selectedMemberQuery.data && !members.find((m) => m._id === selectedMemberQuery.data._id)) {
    members = members.push(createMember(selectedMemberQuery.data as CreateFunctionInput<Member>));
  }

  if (selectedTeamQuery.data && !teams.find((t) => t.key === selectedTeamQuery.data.key)) {
    teams = teams.push(createTeam(selectedTeamQuery.data as Partial<TeamProps>));
  }

  // If teams are enabled, we need to wait for both the members and teams to be fetched. Otherwise,
  // we only need to wait for the members to be fetched.
  const isReady =
    enableTeamsOfTeams() && enableTeamsAsFlagMaintainers()
      ? isMembersListReady && isTeamsListReady
      : isMembersListReady;

  return {
    fetchTeams,
    isReady,
    members,
    profile,
    teams,
  };
};

type useTeamOrMemberOptionsProps = {
  searchQuery?: string;
  selectedValue?: string;
  teamOnly?: boolean;
};

type useTeamOrMemberOptionsType = {
  isReady: boolean;
  members: List<Member>;
  options: TeamOrMemberOptionType[] | NestedTeamOrMemberOptionsType[];
  teams: List<Team>;
  profile: Member;
};

// This hook uses the members and teams for an account and gathers a subset of
// members and teams to present in various "select member or team" dropdowns,
// used in the TeamOrMemberFilter (on the Flags dashboard) and the
// SelectTeamOrMember dropdown used to update the flag maintainer on
// the Flag settings page.
const useTeamOrMemberOptions = ({
  searchQuery,
  selectedValue,
  teamOnly,
}: useTeamOrMemberOptionsProps): useTeamOrMemberOptionsType => {
  const { fetchTeams, isReady, members, profile, teams } = useRedux({ searchQuery, selectedValue, teamOnly });
  const isEnabled = enableTeamsAsFlagMaintainers() && enableTeamsOfTeams();
  const logger = Logger.get('useTeamOrMemberOptions');
  useEffect(() => {
    if (!isReady && isEnabled) {
      fetchTeams(createTeamsFilters({ limit: 200 })).then(noop, (error) =>
        logger.error('error while fetching Teams', error),
      );
    }
  }, []);

  const options = isReady
    ? getOptionsForSelectTeamOrMember({
        input: searchQuery || '',
        members,
        profile,
        teams,
        value: selectedValue,
        /*
        if teamOnly is enabled and there are no teams on the profile show the profile
        if teamOnly is disabled do not show the profile
        */
        shouldShowProfile: (teamOnly && profile?.teams?.size === 0) || !teamOnly,
      })
    : [];

  return { isReady, members, options, teams, profile };
};

/* eslint-disable import/no-default-export */
export default useTeamOrMemberOptions;
