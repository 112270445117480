import { List } from 'immutable';
import { Tooltip } from 'launchpad';

import { DateAttr } from 'components/DateAttr';
import { DataListItem } from 'components/ui/datalist';
import { isDateAttribute, stringifyAttributeValue, UserAttributes } from 'utils/userAttributeUtils';

import styles from './UserAttributesList.module.css';

export type AttributeProps = {
  name: string;
  value: string | number | object | List<string> | Map<string, string | List<string>>;
  isPrivate?: boolean;
};

export function Attribute({ name, value, isPrivate }: AttributeProps) {
  return (
    <DataListItem className={styles.AttributeContainer}>
      <div className="u-w-100 u-wb-break u-fw-medium u-mr-l">{name}</div>
      <div className="u-w-100 u-o-hidden u-ow-break">
        {isPrivate ? (
          <PrivateAttr />
        ) : (
          <div>{isDateAttribute(name, value) ? <DateAttr value={value} /> : stringifyAttributeValue(value)}</div>
        )}
      </div>
    </DataListItem>
  );
}

function PrivateAttr() {
  return (
    <Tooltip
      interactionKind="hover"
      placement="right"
      content="This attribute is private so LaunchDarkly does not receive its value."
    >
      <div className="u-subtle u-voice">Marked as private</div>
    </Tooltip>
  );
}

type UserAttributesListProps = {
  userAttributes: UserAttributes;
};

/* eslint-disable import/no-default-export */
export default function UserAttributesList({ userAttributes }: UserAttributesListProps) {
  const baseAttrs = userAttributes.getBaseAttributes();
  const customAttrs = userAttributes.getCustomAttributes() || [];
  const privateAttrs = userAttributes.getPrivateAttrs();

  const items = [
    ...Array.from(baseAttrs, ([key, value]) => ({ key: `-${key}`, name: key, value, isPrivate: false })),
    ...Array.from(privateAttrs, (attr) => ({ key: `private-${attr}`, name: attr, value: attr, isPrivate: true })),
    ...Array.from(customAttrs, ([key, value]) => ({ key: `custom-${key}`, name: key, value, isPrivate: false })),
  ].sort((a, b) => {
    if (a.name === 'key') {
      return -1;
    }
    if (b.name === 'key') {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      {items.map((a) => (
        <Attribute key={a.key} value={a.value} name={a.name} isPrivate={a.isPrivate} />
      ))}
    </>
  );
}
