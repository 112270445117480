import {
  fetchContextAttributesByKind as fetchContextAttributesAPI,
  fetchContextAttributeValues as fetchContextAttributeValuesAPI,
} from 'components/Contexts/common/contextAPI';
import { ContextAttributesNames, ContextAttributesValues } from 'components/Contexts/types';
import { processContextAttributes } from 'components/Contexts/utils/AttributeUtils';
import { GetState, GlobalDispatch, GlobalState } from 'reducers';
import { contextAttributesNamesRequestSelector } from 'reducers/contextAttributes';
import { ImmutableServerError } from 'utils/httpUtils';
import { GenerateActionType } from 'utils/reduxUtils';

const requestContextAttributes = (contextKind: string) =>
  ({ contextKind, type: 'contextAttributes/REQUEST_CONTEXT_ATTRIBUTES' }) as const;
const requestContextAttributesDone = (contextKind: string, contextAttributes: ContextAttributesNames[]) =>
  ({ type: 'contextAttributes/REQUEST_CONTEXT_ATTRIBUTES_DONE', contextKind, contextAttributes }) as const;
const requestContextAttributesFailed = (contextKind: string, error: ImmutableServerError) =>
  ({ type: 'contextAttributes/REQUEST_CONTEXT_ATTRIBUTES_FAILED', contextKind, error }) as const;

const fetchContextAttributes =
  (projectKey: string, environmentKey: string, kind: string, prefix: string) => async (dispatch: GlobalDispatch) => {
    dispatch(requestContextAttributes(kind));
    return new Promise<void>((resolve, reject) => {
      fetchContextAttributesAPI(projectKey, environmentKey, kind, prefix)
        .then((contextAttributes) => {
          processContextAttributes(contextAttributes);
          dispatch(requestContextAttributesDone(kind, contextAttributes.items));
          resolve();
        })
        .catch((error) => {
          dispatch(requestContextAttributesFailed(kind, error as ImmutableServerError));
          reject();
        });
    });
  };

function shouldFetchContextAttributes(state: GlobalState, contextKind: string) {
  const contextAttributes = contextAttributesNamesRequestSelector(state, contextKind);
  return !contextAttributes.lastFetched && !contextAttributes.isFetching;
}

function fetchContextAttributesIfNeeded(projKey: string, envKey: string, kind: string, prefix: string) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchContextAttributes(getState(), kind)) {
      return dispatch(fetchContextAttributes(projKey, envKey, kind, prefix));
    }
  };
}

const requestContextAttributeValues = (attributeKey: string) =>
  ({ type: 'contextAttributes/REQUEST_CONTEXT_ATTRIBUTE_VALUES', attributeKey }) as const;

const requestContextAttributeValuesDone = (attributeKey: string, contextAttributeValues: ContextAttributesValues) =>
  ({ type: 'contextAttributes/REQUEST_CONTEXT_ATTRIBUTE_VALUES_DONE', attributeKey, contextAttributeValues }) as const;

const requestContextAttributeValuesFailed = (attributeKey: string, error: ImmutableServerError) =>
  ({ type: 'contextAttributes/REQUEST_CONTEXT_ATTRIBUTE_VALUES_FAILED', attributeKey, error }) as const;

function fetchContextAttributeValues(
  projectKey: string,
  environmentKey: string,
  attributeKey: string,
  kind: string,
  prefix: string = '',
) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestContextAttributeValues(attributeKey));
    try {
      const contextAttributeValues = await fetchContextAttributeValuesAPI(
        projectKey,
        environmentKey,
        kind,
        attributeKey,
        prefix,
      );
      dispatch(requestContextAttributeValuesDone(attributeKey, contextAttributeValues.items));
    } catch (error) {
      dispatch(requestContextAttributeValuesFailed(attributeKey, error as ImmutableServerError));
    }
  };
}

export { fetchContextAttributes, fetchContextAttributesIfNeeded, fetchContextAttributeValues };

const ContextAttributesActionCreators = {
  requestContextAttributes,
  requestContextAttributesDone,
  requestContextAttributesFailed,
  requestContextAttributeValues,
  requestContextAttributeValuesDone,
  requestContextAttributeValuesFailed,
};

export type ContextAttributesAction = GenerateActionType<typeof ContextAttributesActionCreators>;
