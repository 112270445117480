import { VisuallyHidden } from 'react-aria';
import { snakeCase } from '@gonfalon/es6-utils';

import { Cell } from 'components/ui/grid';

import { SeriesOption } from './types';

import './styles.css';

export const OverviewFilters = ({
  title,
  selectedOption,
  handleOverviewDateChange,
}: {
  title: string;
  selectedOption?: SeriesOption;
  handleOverviewDateChange?(event: React.ChangeEvent<HTMLInputElement>): void;
}) => {
  const makeIdFromTitle = (suffix: string) => `${snakeCase(title)}--${suffix}`;
  const name = `${snakeCase(title)}-filter`;
  return (
    <div>
      <h2 className="UsageOverview-header">{title}</h2>
      <Cell className="u-tc">
        <fieldset>
          <VisuallyHidden>
            <legend>{title} filters</legend>
          </VisuallyHidden>
          <input
            value="hour"
            onChange={handleOverviewDateChange}
            checked={selectedOption === 'hour'}
            type="radio"
            id={makeIdFromTitle('hour')}
            name={name}
          />
          <label htmlFor={`${title}--hour`}>Hour</label>

          <input
            value="day"
            onChange={handleOverviewDateChange}
            checked={selectedOption === 'day'}
            type="radio"
            id={makeIdFromTitle('day')}
            name={name}
          />
          <label htmlFor={`${title}--day`}>Day</label>

          <input
            value="month"
            onChange={handleOverviewDateChange}
            checked={selectedOption === 'month'}
            type="radio"
            id={makeIdFromTitle('month')}
            name={name}
          />
          <label htmlFor={`${title}--month`}>Month</label>
        </fieldset>
      </Cell>
    </div>
  );
};
