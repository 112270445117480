import { type UIFlagProjectFilter, flagProjectFilters } from './flagProjectFilters';

export function countActiveProjectFilters(filters: UIFlagProjectFilter | undefined) {
  if (filters === undefined) {
    return 0;
  }

  let count = 0;

  for (const key of flagProjectFilters) {
    if (filters[key] === undefined) {
      continue;
    }

    const value = filters[key];

    if (typeof value === 'string') {
      if (value !== '') {
        count++;
        continue;
      }
    }

    if (Array.isArray(value)) {
      if (value.length > 0) {
        count++;
        continue;
      }
    }

    count++;
  }

  return count;
}
