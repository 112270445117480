import { auditLogAccountHistoryLimit, auditLogHistoryLimit } from '@gonfalon/dogfood-flags';
import { List } from 'immutable';

import ResourceHistory from 'components/auditLog/ResourceHistory';
import { AuditLogViewKind, Query } from 'utils/auditLogUtils';

export type ResourceHistoryContainerProps = {
  heading?: string;
  hideSearch?: boolean;
  onCloseEntryViewer: () => void;
  onSearch: (q: Query) => void;
  onWillUnmount: () => void;
  query: Query;
  searchPlaceholder?: string;
  showHeader?: boolean;
  services?: List<string>;
  auditLogView: AuditLogViewKind;
};

/* eslint-disable import/no-default-export */
export default function ResourceHistoryContainer(props: ResourceHistoryContainerProps) {
  return (
    <ResourceHistory
      {...props}
      auditLogHistoryLimit={auditLogHistoryLimit()}
      auditLogAccountHistoryLimit={auditLogAccountHistoryLimit()}
    />
  );
}
