// Same as Response, just allows us to type the resolved value of json()
import { schemas } from '@gonfalon/openapi';
// eslint-disable-next-line import/no-internal-modules
import { GenerateExperimentResponse } from '@gonfalon/rest-api/src/internal/postGenerateExperiment';
import { Immutable } from 'immer';

export type ServerResponse<T> = Omit<Response, 'json'> & {
  json(): Promise<T>;
};

export type ServerErrorBody = {
  code: string;
  message: string;
};
export type MetricsPayload = Array<{ key: string; primary: boolean; isGroup?: boolean }>;

export type TreatmentsPayload = schemas['TreatmentsInput'];

export type FlagsPayload = Record<
  string,
  { ruleId: string; flagConfigVersion: number; notInExperimentVariationId?: string }
>;

export type IterationBody = Immutable<{
  hypothesis: string;
  canReshuffleTraffic: boolean | undefined;
  metrics: MetricsPayload;
  primarySingleMetricKey?: string;
  primaryFunnelKey?: string;
  attributes?: string[];
  flags: FlagsPayload;
  treatments: TreatmentsPayload;
  randomizationUnit?: string;
  winningReason?: string;
  notInExperimentVariationId?: string;
  aiGeneratedExperimentConfig?: GenerateExperimentResponse;
}>;

export type ExperimentDetails = {
  name: string;
  description: string;
  maintainerId: string;
  key: string;
  holdoutId?: string;
};

// TODO SC-224210 once schema is updated with standard kind we won't have to inject it here
export type CreateMetricGroupPayload = { kind: 'funnel' | 'standard' } & Omit<schemas['MetricGroupPost'], 'kind'>;

export type UpdateMetricGroupPayload = Partial<
  Pick<
    schemas['MetricGroupPost'],
    // can't find editable fields in our schema, so listing them explicitly for now
    'name' | 'description' | 'tags' | 'maintainerId'
  > & { maintainer: { key: string; kind: string } } & {
    metrics: Array<{
      key: string;
      nameInGroup?: string;
    }>;
  }
>;

export type ExperimentBody = ExperimentDetails & { iteration: IterationBody };

export const START_ITERATION = 'startIteration';
export type StartIteration = { kind: typeof START_ITERATION; changeJustification: string };

export const STOP_ITERATION = 'stopIteration';
export type StopIteration = { kind: typeof STOP_ITERATION; winningTreatmentId: string; winningReason: string };

export const UPDATE_NAME = 'updateName';
export type UpdateName = { kind: typeof UPDATE_NAME; value: string };

export const UPDATE_DESCRIPTION = 'updateDescription';
export type UpdateDescription = { kind: typeof UPDATE_DESCRIPTION; value: string };

export const ARCHIVE_EXPERIMENT = 'archiveExperiment';
export type ArchiveExperiment = { kind: typeof ARCHIVE_EXPERIMENT };

export const RESTORE_EXPERIMENT = 'restoreExperiment';
export type RestoreExperiment = { kind: typeof RESTORE_EXPERIMENT };

export type ExperimentPatchBody = Immutable<
  StartIteration | StopIteration | UpdateName | UpdateDescription | ArchiveExperiment | RestoreExperiment
>;
