import { ChangeEvent, Component } from 'react';
import { enableSemanticPatchInstructionsForSegments } from '@gonfalon/dogfood-flags';
import cx from 'clsx';
import { Button, Tooltip } from 'launchpad';

import { Comment } from 'components/Comment';
import CurrentEnvironment from 'components/CurrentEnvironment';
import ExperimentationAlert from 'components/FlagExperiments/ExperimentationAlert';
import { Confirmation } from 'components/ui/confirmation';
import { environmentValidation, flagValidation } from 'utils/confirmationUtils';
import { Environment } from 'utils/environmentUtils';
import { Flag } from 'utils/flagUtils';
import { ResourceKind } from 'utils/saveButtonUtils';
import { Segment } from 'utils/segmentUtils';
import { User } from 'utils/userUtils';

import { SegmentPendingChangesModal } from './segments/SegmentPendingChangesModal';

import 'stylesheets/components/SaveWithPendingChangesButton.css';

export type SaveButtonProps = {
  onSave(comment?: string): void;
  onEditChangeDescription(val: string): void;
  disabled: boolean;
  isLoading?: boolean;
  comment: string;
  environment: Environment;
  isCommentRequired: boolean;
  isCommentRequiredInAnyEnv: boolean;
  isEnvConfirmationRequired: boolean;
  isConfirmationRequiredInAnyEnv: boolean;
  isProjectLevelChange?: boolean;
  isAccountLevelChange?: boolean;
  testId?: string;
  resourceKind: ResourceKind;
  loadingText?: string;
  resource: User | Segment | Flag;
  saveText?: string;
  className?: string;
  saveWithCommentText?: string;
  tooltip?: string | JSX.Element;
  customSaveCopy?(): JSX.Element;
  changeAlert?: JSX.Element;
};

/* eslint-disable import/no-default-export */
export default class SaveButton extends Component<SaveButtonProps> {
  state = {
    showConfirmation: false,
    isConfirmationValid: true,
  };

  static defaultProps = {
    saveText: 'Save changes',
    saveWithCommentText: 'Save with comment',
  };

  render() {
    const {
      disabled,
      isLoading,
      testId,
      isCommentRequired,
      isProjectLevelChange,
      isAccountLevelChange,
      isCommentRequiredInAnyEnv,
      loadingText,
      saveText,
      saveWithCommentText,
      className,
      tooltip,
    } = this.props;

    const requireComment =
      !isAccountLevelChange && (isCommentRequired || (isProjectLevelChange && isCommentRequiredInAnyEnv));
    const buttonText = requireComment ? saveWithCommentText : saveText;

    const hasTooltip = tooltip ? undefined : false;

    return (
      <>
        {this.renderConfirmation()}
        <Tooltip content={tooltip} isOpen={hasTooltip}>
          <Button
            className={className}
            kind="primary"
            disabled={disabled}
            isLoading={isLoading}
            loadingText={loadingText}
            onClick={this.onClick}
            data-test-id={testId}
          >
            {buttonText}
          </Button>
        </Tooltip>
      </>
    );
  }

  renderConfirmation() {
    const {
      comment,
      isCommentRequired,
      isCommentRequiredInAnyEnv,
      isEnvConfirmationRequired,
      isProjectLevelChange,
      isConfirmationRequiredInAnyEnv,
      resource,
      resourceKind,
    } = this.props;
    const { showConfirmation } = this.state;
    if (!showConfirmation) {
      return;
    }

    const requireComment = isCommentRequired || (isProjectLevelChange && isCommentRequiredInAnyEnv);
    const requireConfirmation = isEnvConfirmationRequired || (isProjectLevelChange && isConfirmationRequiredInAnyEnv);

    const confirmation = {
      title: requireConfirmation ? 'Confirmation required' : 'Leave a comment',
      body: this.renderConfirmationBody,
      confirmLabel: requireConfirmation ? 'Confirm' : 'Save changes',
      validation: this.handleValidation(),
      testId: 'save-confirmation-button',
    };
    if (!this.state.showConfirmation) {
      return;
    }
    const canConfirm = !requireComment || !!comment.trim();

    return enableSemanticPatchInstructionsForSegments() && resourceKind === ResourceKind.SEGMENT ? (
      <SegmentPendingChangesModal
        segment={resource as Segment}
        isConfirmationRequired={requireConfirmation}
        isCommentRequired={requireComment}
        onCancel={this.handleModalCancel}
        onSave={this.handleModalConfirmV2}
        validation={this.handleValidation()}
      />
    ) : (
      <Confirmation
        {...confirmation}
        onConfirm={this.handleModalConfirm}
        onCancel={this.handleModalCancel}
        canConfirm={canConfirm}
        className="SaveWithPendingChangesButton-confirmation"
      />
    );
  }

  renderChangeContextCopy = () => {
    const { isAccountLevelChange, isProjectLevelChange, resource, resourceKind, customSaveCopy } = this.props;

    const resourceName = 'name' in resource ? resource.name : resource.attributes.name;

    if (customSaveCopy) {
      return customSaveCopy();
    }
    if (isAccountLevelChange) {
      return (
        <p>
          This will make changes to the <strong>{resourceName}</strong> {resourceKind.toLowerCase()} in your
          organization.
        </p>
      );
    }
    if (isProjectLevelChange) {
      return (
        <p>
          This will make changes to the <strong>{resourceName}</strong> {resourceKind.toLowerCase()} in all
          environments.
        </p>
      );
    }
    // presumed environment-level change
    return (
      <p>
        This will make changes to the <strong>{resourceName}</strong> {resourceKind.toLowerCase()} in the{' '}
        <CurrentEnvironment className="u-fw-semibold" /> environment.
      </p>
    );
  };

  renderConfirmationBody = () => {
    const {
      comment,
      isCommentRequired,
      isCommentRequiredInAnyEnv,
      isProjectLevelChange,
      isAccountLevelChange,
      environment,
      resourceKind,
      resource,
      changeAlert,
    } = this.props;
    const hasNonPristineExperiments =
      resourceKind === ResourceKind.FLAG ? (resource as Flag).hasNonPristineExperiments(environment.key) : false;

    const placeholderText =
      isProjectLevelChange && isCommentRequiredInAnyEnv
        ? 'Comments are required for changes in one or more environments. These changes affect all environments in this project.'
        : 'Comments are required for changes in this environment';
    const requireComment =
      !isAccountLevelChange && (isCommentRequired || (isProjectLevelChange && isCommentRequiredInAnyEnv));

    return (
      <section>
        {changeAlert}
        {this.renderChangeContextCopy()}
        {resourceKind === ResourceKind.FLAG && hasNonPristineExperiments && <ExperimentationAlert />}
        <Comment
          optional={!requireComment}
          comment={comment || ''}
          onChange={this.handleCommentChange}
          placeholder={requireComment ? placeholderText : 'Leave a comment.'}
          className={cx({ 'u-b--error': !this.state.isConfirmationValid })}
          onBlur={this.handleOnBlur}
          showAsterix={requireComment}
        />
      </section>
    );
  };

  handleSave = () => {
    const { onSave, comment } = this.props;
    onSave(comment);
  };

  handleModalConfirm = () => {
    this.handleModalCancel();
    this.handleSave();
  };

  handleModalConfirmV2 = (comment?: string) => {
    const { onSave } = this.props;
    this.handleModalCancel();
    onSave(comment);
  };

  handleModalCancel = () => {
    const { onEditChangeDescription } = this.props;
    this.setState({
      showConfirmation: false,
    });
    onEditChangeDescription('');
  };

  handleCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.props.onEditChangeDescription(event.target.value);
  };

  handleOnBlur = () => {
    const { isCommentRequired, comment, isProjectLevelChange, isCommentRequiredInAnyEnv } = this.props;
    (isCommentRequired || (isProjectLevelChange && isCommentRequiredInAnyEnv)) && !comment.trim()
      ? this.setState({ isConfirmationValid: false })
      : this.setState({ isConfirmationValid: true });
  };

  handleValidation = () => {
    const {
      environment,
      resource,
      isEnvConfirmationRequired,
      isConfirmationRequiredInAnyEnv,
      isProjectLevelChange,
      isAccountLevelChange,
    } = this.props;
    const requireConfirmation =
      !isAccountLevelChange && (isEnvConfirmationRequired || (isProjectLevelChange && isConfirmationRequiredInAnyEnv));
    if (requireConfirmation) {
      if (isEnvConfirmationRequired && !isProjectLevelChange) {
        return environmentValidation(environment);
      } else {
        return flagValidation(resource as Flag);
      }
    }
    return undefined;
  };

  onClick = () => {
    this.setState({
      showConfirmation: true,
    });
  };
}
