import {
  FeatureFlag,
  getFlagActiveVariationsFromSummary,
  getFlagVariationDisplayValue,
  getFlagVariationValueString,
} from '@gonfalon/flags';
import { ReleasePhase } from '@gonfalon/release-pipelines';
import { differenceInCalendarWeeks, format, formatDistanceToNowStrict } from 'date-fns';
import nullthrows from 'nullthrows';

import { colorVariation } from 'utils/flagUtils';

export function formatReleasePhaseActivationDate(activationDate: number) {
  return differenceInCalendarWeeks(Date.now(), activationDate) < 1
    ? formatDistanceToNowStrict(new Date(activationDate))
    : `since ${format(new Date(activationDate), 'MM/dd/yyyy')}`;
}

export const determinePhaseActivationDate = (phases: ReleasePhase[], phase: ReleasePhase, phaseIndex: number) =>
  phaseIndex === 0 ? phase._creationDate : phases[phaseIndex - 1]._completionDate;

export const determineLastCompletedPhase = (phase: ReleasePhase) => phase.complete;

export function getFlagEnvironmentSummaryVariations(flag: FeatureFlag, environmentKey: string) {
  return getFlagActiveVariationsFromSummary(nullthrows(flag.environments[environmentKey]), flag.variations).map(
    ([variationIndex, variation]) => ({
      value: getFlagVariationValueString(variation.value),
      name: getFlagVariationDisplayValue(variation),
      color: colorVariation(variationIndex),
    }),
  );
}
