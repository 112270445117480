import AllEnvironmentsMarker from 'components/Approvals/components/AllEnvironmentsMarker';
import CurrentEnvironment from 'components/CurrentEnvironment';
import { ResourceKind } from 'utils/saveButtonUtils';

export function ResourceInfo({
  resourceName,
  resourceKind = ResourceKind.FLAG,
  isGlobalResource,
}: {
  resourceName: string;
  resourceKind?: ResourceKind;
  isGlobalResource?: boolean;
}) {
  return (
    <div className="PendingChangesConfirmAndEditModal-infoCard">
      <p>
        <span>{resourceKind} | </span>
        {resourceName}
      </p>
      <p>
        <span>Environment | </span>
        {isGlobalResource ? <AllEnvironmentsMarker /> : <CurrentEnvironment />}
      </p>
    </div>
  );
}
