import { ReactElement, useEffect, useState } from 'react';
import type { ActionMeta } from 'react-select';
import { components, MultiValueProps, MultiValueRemoveProps } from 'react-select';
import { enableFollowFlagsByTeam, isApprovalByTeamEnabled, memberFilterPageSize } from '@gonfalon/dogfood-flags';
import { isArray } from '@gonfalon/es6-utils';
import { CustomSelect, OptionProps } from '@gonfalon/launchpad-experimental';
import { Icon } from '@launchpad-ui/icons';
import { List, OrderedMap, Set } from 'immutable';
import { Tooltip } from 'launchpad';

import { useCallbackIfMounted } from 'hooks/useCallbackIfMounted';
import { getMembers } from 'sources/AccountAPI';
import { Member } from 'utils/accountUtils';
import Logger from 'utils/logUtils';
import { createMemberFilters } from 'utils/memberUtils';
import { stringContains } from 'utils/stringUtils';
import { Team } from 'utils/teamsUtils';

import MemberOption from './MemberOption';
import { isTeam } from './SelectReviewersContainer';
import TeamOption from './TeamOption';

const logger = Logger.get('SelectReviewers');

export const LABELS = {
  VIEWERS: 'Members who can view approval requests',
  VIEWERS_WITH_TEAMS: 'Members and teams that can view approval requests',
  REVIEWERS: 'Members with permission to review approval requests',
  REVIEWERS_WITH_TEAMS: 'Members and teams with permission to review approval requests',
};

export type ReviewerOptionType = OptionProps & {
  email?: string;
  name?: string;
  isAdminOrOwner?: boolean;
  isCurrentMember?: boolean;
  member?: Member;
  team?: Team;
  key?: string;
};

export type SelectReviewersProps = {
  className?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  isClearable?: boolean;
  filterPendingInvites?: boolean;
  profile?: Member;
  value?: Member | Team | Array<Member | Team> | null | string;
  members: OrderedMap<string, Member>;
  reviewers: OrderedMap<string, Member>;
  teams?: OrderedMap<string, Team>;
  onChange(member?: string | string[] | null): void;
  onInputChange?(input: string): void;
  filterOutCurrentMember?: boolean;
  filterMembers?: (m: Member) => boolean;
  filterByMemberId?: Set<string>;
  suggestedMemberIds?: string[];
  isLoading: boolean;
  closeMenuOnSelect?: boolean;
  valueContainerStyles?: object;
  isTeamsMemberContainer?: boolean;
  customMultiValueRemoveTooltip?: string;
  isInPortal?: boolean;
  customNoOptionsMessage?: ReactElement | string;
  accessCheck?: string;
  ariaLabel?: string;
  showGroupOptions?: boolean;
  canClearOriginalValues?: boolean;
  isMulti?: boolean;
  defaultMenuIsOpen?: boolean;
  existingReviewers?: List<string>;
};

/* eslint-disable import/no-default-export */
export default function SelectReviewers({
  className,
  disabled,
  profile,
  members = OrderedMap(),
  reviewers = OrderedMap(),
  teams = OrderedMap(),
  filterPendingInvites,
  onChange,
  value = null,
  filterOutCurrentMember = false,
  filterMembers = () => true,
  suggestedMemberIds = [],
  isLoading,
  closeMenuOnSelect,
  valueContainerStyles = {},
  isTeamsMemberContainer,
  filterByMemberId,
  placeholder: originalPlaceholder,
  isInPortal,
  customNoOptionsMessage,
  accessCheck,
  ariaLabel,
  showGroupOptions = true,
  customMultiValueRemoveTooltip = undefined,
  canClearOriginalValues = true,
  isMulti = true,
  defaultMenuIsOpen = false,
  existingReviewers,
  ...other
}: SelectReviewersProps) {
  const [originalReviewers] = useState((value && Set<string>((value as Member[]).map((m) => m._id))) || Set<string>());
  const isCurrentMember = (member: Member) => member._id === profile?._id;
  const isSelectedMember = (member: Member) => member._id === (value as Member)?._id;
  const isSelectedTeam = (team: Team) => team.key === (value as Team)?.key;
  const [canCurrentMemberReviewRequests, setCanCurrentMemberReviewRequests] = useState(false);

  const checkCurrentMemberReviewRequestAccess = useCallbackIfMounted((fetchedMembers: OrderedMap<string, Member>) => {
    setCanCurrentMemberReviewRequests(!!fetchedMembers.find((member) => member._id === profile?._id));
  });

  useEffect(() => {
    const controller = new AbortController();
    if (profile && accessCheck) {
      getMembers(
        createMemberFilters({ query: profile?.email, limit: Math.ceil(memberFilterPageSize() / 2), accessCheck }),
        { signal: controller.signal },
      )
        .then((data) => {
          if (!controller.signal.aborted) {
            const fetchedMembers = data.get('entities').get('members');
            checkCurrentMemberReviewRequestAccess(fetchedMembers);
          }
        })
        .catch((error) => {
          logger.error(`Error requesting current member: ${error}`);
        });
    }
    return () => controller.abort();
  }, []);

  const filteredMembersList = members
    .toList()
    .filter((m) => !isCurrentMember(m) && !isSelectedMember(m) && filterMembers(m) && !reviewers.get(m._id));
  const filteredReviewersList = reviewers
    .toList()
    .filter((m) => !isCurrentMember(m) && !isSelectedMember(m) && filterMembers(m));

  // Filter out teams with 0 members
  const filteredTeamsList = teams.toList().filter((m) => !isSelectedTeam(m) && m?.members?.totalCount);
  const suggestedMembers = filteredMembersList.filter((m) => suggestedMemberIds.includes(m._id));
  const notSuggestedMembers = filteredMembersList.filter((m) => !suggestedMemberIds.includes(m._id));

  const suggestedReviewers = filteredReviewersList.filter((m) => suggestedMemberIds.includes(m._id));
  const notSuggestedReviewers = filteredReviewersList.filter((m) => !suggestedMemberIds.includes(m._id));

  let sortedMembers = [...suggestedMembers, ...notSuggestedMembers];
  let sortedReviewers = [...suggestedReviewers, ...notSuggestedReviewers];

  if (profile) {
    if (canCurrentMemberReviewRequests) {
      sortedReviewers.unshift(profile);
    } else {
      sortedMembers.unshift(profile);
    }
  }

  if (filterPendingInvites) {
    sortedMembers = sortedMembers.filter((m) => !m?.hasPendingInvite());
    sortedReviewers = sortedReviewers.filter((m) => !m?.hasPendingInvite());
  }
  const viewersOptions = sortedMembers
    .filter((d) => {
      if (filterOutCurrentMember) {
        return !isCurrentMember(d);
      } else if (existingReviewers && existingReviewers.size) {
        return !existingReviewers.some((r) => r === d._id);
      }
      return true;
    })
    .map((m) => ({
      value: m?._id,
      label: m && isCurrentMember(m) ? 'Me' : m?.getDisplayName(),
      name: m?.getFullName(),
      email: m?.email,
      key: m?._id,
      member: m,
    }));

  const membersOptions = sortedReviewers
    .filter((d) => {
      if (filterOutCurrentMember) {
        return !isCurrentMember(d);
      } else if (existingReviewers && existingReviewers.size) {
        return !existingReviewers.some((r) => r === d._id);
      }
      return true;
    })
    .map((m) => ({
      value: m?._id,
      label: m && isCurrentMember(m) ? 'Me' : m?.getDisplayName(),
      name: m?.getFullName(),
      email: m?.email,
      key: m?._id,
      member: m,
    }));

  const teamsOptions: List<{ value: string; team: Team; isDisabled: boolean }> = filteredTeamsList.map((t) => ({
    value: t?.key,
    team: t,
    isDisabled: showGroupOptions && !t?.members?.totalCount,
  }));

  const reviewersOptions = [...teamsOptions, ...membersOptions];

  const groupedOptions = [];
  if (showGroupOptions) {
    if (reviewersOptions.length) {
      groupedOptions.push({
        label: isApprovalByTeamEnabled() ? LABELS.REVIEWERS_WITH_TEAMS : LABELS.REVIEWERS,
        options: reviewersOptions,
      });
    }
    if (viewersOptions.length) {
      groupedOptions.push({
        label: isApprovalByTeamEnabled() ? LABELS.VIEWERS_WITH_TEAMS : LABELS.VIEWERS,
        options: viewersOptions,
      });
    }
  } else {
    groupedOptions.push({ label: 'Select members or teams', options: [...reviewersOptions, ...viewersOptions] });
  }
  const selectedValues = () => (value ? (value as Array<Member | Team>) : []);

  const multiValue = selectedValues()?.map((m) => {
    if (isTeam(m)) {
      return {
        value: m?.key,
        label: m?.name,
        name: m?.name,
        key: m?.key,
        team: m,
      };
    }
    return {
      value: m?._id,
      label: m && isCurrentMember(m) ? 'Me' : m?.getDisplayName(),
      name: m?.getFullName(),
      email: m?.email,
      isAdminOrOwner: m?.isAdminOrOwner(),
      isCurrentMember: m && isCurrentMember(m),
      key: m?._id,
      member: m,
    };
  });

  const renderOption = (option: ReviewerOptionType, isInMenu?: boolean) => {
    if (option.hasOwnProperty('email')) {
      return <MemberOption isInMenu={isInMenu} isMulti option={option} />;
    }
    return (
      <TeamOption
        isInMenu={isInMenu}
        teamName={option.team?.name || ''}
        memberCount={option.team?.members?.totalCount || 0}
      />
    );
  };

  const placeholder: string =
    originalPlaceholder ||
    (isApprovalByTeamEnabled() || enableFollowFlagsByTeam() ? ' Select members or teams' : 'Select members');

  const MultiValue = (multiValueProps: MultiValueProps<ReviewerOptionType>) => (
    <components.MultiValue {...multiValueProps}>{renderOption(multiValueProps.data)}</components.MultiValue>
  );

  const MultiValueRemove = (props: MultiValueRemoveProps<ReviewerOptionType>) => {
    if (!canClearOriginalValues && originalReviewers.size && props.data.key && originalReviewers.has(props.data.key)) {
      return <div className="u-mr-xs"></div>;
    }
    return (
      // Return default component style without this, the X icon is smaller and the fill color is red
      <components.MultiValueRemove {...props}>
        <Tooltip content={customMultiValueRemoveTooltip || 'Clear tag'} placement="right">
          <Icon
            name="cancel"
            style={{ color: 'var(--lp-color-text-ui-primary-base)', top: '-0.09375rem' }}
            size="small"
          />
        </Tooltip>
      </components.MultiValueRemove>
    );
  };

  const filterOption = ({ data }: ReviewerOptionType, searchValue: string) => {
    if (searchValue) {
      return (
        !filterByMemberId?.includes(data.key) &&
        (stringContains(data.label, searchValue) ||
          stringContains(data.name, searchValue) ||
          stringContains(data.email, searchValue) ||
          stringContains(data.team?.name, searchValue))
      );
    }
    return !filterByMemberId?.includes(data.key);
  };

  const handleChange = (option: OptionProps | OptionProps[] | null, actionMeta: ActionMeta<OptionProps>) => {
    // trackApprovalByTeamSelected(reviewersCount);
    if (isArray(option)) {
      const nextValueIds = option.map((d) => d.value as string);
      if (actionMeta.action === 'pop-value') {
        if (!canClearOriginalValues && originalReviewers.has(actionMeta.removedValue.value)) {
          return;
        }
      }
      onChange(nextValueIds);
    } else {
      const nextValueId = (option?.value as string) || null; // null is for react-select
      onChange(nextValueId);
    }
  };

  const customStyles = {
    control: { padding: '0.125rem 0' },
    valueContainer: {
      maxHeight: '10.625rem',
      overflow: 'auto',
      padding: '0.125rem 0.1875rem',
      ...valueContainerStyles,
    },
    menu: { marginTop: '0' },
    menuList: { paddingTop: '0' },
    multiValue: {
      alignItems: 'center',
      background: 'var(--lp-color-bg-interactive-secondary-hover)',
      borderRadius: '0.5rem',
      height: '1.75rem',
      maxWidth: '32.5rem',
      marginLeft: '0.1875rem',
      paddingLeft: '0.0625rem',
    },
    multiValueLabel: {},
    multiValueRemove: {
      borderRadius: '0 0.5rem 0.5rem 0',
      height: '100%',
      display: disabled ? 'none' : 'flex',
    },
    group: {
      paddingBottom: '0',
      paddingTop: '0',
    },
    groupHeading: {
      backgroundColor: 'var(--lp-color-bg-ui-secondary)',
      color: 'var(--field-placeholder-color)',
      marginBottom: '0',
      fontSize: '0.875rem',
      textTransform: 'none',
    },
  };

  const noOptionsMessage = isApprovalByTeamEnabled() ? 'No members or teams found' : 'No members found';
  const noOptionsMessageToUse = customNoOptionsMessage ? customNoOptionsMessage : noOptionsMessage;

  return (
    <CustomSelect
      {...other}
      ariaLabel={ariaLabel}
      isInPortal={isInPortal}
      placeholder={placeholder}
      disabled={disabled}
      styles={customStyles}
      customComponents={{ MultiValue, MultiValueRemove }}
      value={multiValue}
      options={groupedOptions}
      isClearable={false}
      isMulti={isMulti}
      onChange={handleChange}
      formatOptionLabel={(option: ReviewerOptionType) => option && renderOption(option, true)}
      getOptionLabel={(option: ReviewerOptionType) =>
        option.member?.getDisplayName() || option.team?.getDisplayName() || ''
      }
      filterOption={filterOption}
      noOptionsMessage={() => (isLoading ? 'Loading' : noOptionsMessageToUse)}
      closeMenuOnSelect={closeMenuOnSelect}
      controlShouldRenderValue
      menuPlacement="bottom"
      defaultMenuIsOpen={defaultMenuIsOpen}
      menuShouldScrollIntoView={false}
    />
  );
}
