import { Icon } from '@launchpad-ui/icons';
import { AvatarProps } from 'launchpad';

import { TeamOrMemberOptionType } from 'components/SelectTeamOrMember/SelectTeamOrMemberUtils';
import UserAvatar from 'components/UserAvatar';

export type SelectTeamOrMemberOptionProps = {
  avatarSize?: AvatarProps['size'];
  option: TeamOrMemberOptionType;
  showEmail?: boolean;
};

// This shows an individual option in the SelectTeamOrMember dropdown and
// the TeamOrMemberFilter dropdown. It either shows a team (with generic icon)
// or a member (with avatar). For members with a Firstname/Lastname, it also
// renders their email address, for easier member differentiation on large accounts.
const SelectTeamOrMemberOption = ({
  avatarSize = 'medium',
  option,
  showEmail = true,
}: SelectTeamOrMemberOptionProps) => {
  const { label, member } = option;
  const email = showEmail && member?.email && member.email !== label && (
    <div className="u-o-hidden u-to-ellipsis u-ws-nowrap">{member.email}</div>
  );
  return (
    <div className="u-flex u-flex-middle u-gap-1 u-fs-sm u-mw-100">
      <UserAvatar
        defaultIcon={member ? <Icon name="person" /> : <Icon name="group" />}
        member={member}
        size={avatarSize}
      />
      <div className="u-o-hidden u-to-ellipsis">
        {label}
        {email}
      </div>
    </div>
  );
};

/* eslint-disable import/no-default-export */
export default SelectTeamOrMemberOption;
