import { forwardRef, useCallback } from 'react';
import { validateNoSummedMetricsInFunnels } from '@gonfalon/dogfood-flags';
import { intersection } from '@gonfalon/es6-utils';
import { Metric } from '@gonfalon/metrics';

import { RandomizationUnitLabel } from 'components/experimentation/common/components/RandomizationUnitLabel';
import { SelectMetric } from 'components/experimentation/common/components/SelectMetric';
import MetricLabel from 'components/metrics/MetricLabel';
import { getMetricKind } from 'utils/MetricKind';

import styles from './MetricGroupMetricSelect.module.css';

type Props = Parameters<typeof SelectMetric>[0] & {
  isFunnel?: boolean;
  supportedRandomizationUnits?: string[];
  randomizationUnits?: string[];
};

export const MetricGroupMetricSelect = forwardRef(
  ({ randomizationUnits, supportedRandomizationUnits, isFunnel, ...props }: Props, ref) => {
    const mapMetricToOption = useCallback(
      (metric: Metric) => ({
        isDisabled:
          (isFunnel && metric.isNumeric) ||
          (isFunnel && metric.unitAggregationType === 'sum') ||
          (!!supportedRandomizationUnits?.length &&
            intersection(supportedRandomizationUnits, metric.randomizationUnits).length === 0),
      }),
      [isFunnel, supportedRandomizationUnits],
    );

    return (
      <div className="u-w-100">
        <div className="u-flex u-flex-column">
          <SelectMetric
            isMulti={false}
            menuPosition="fixed"
            mapMetricToOption={mapMetricToOption}
            showMetricType
            getGroupHeading={getGroupHeading(supportedRandomizationUnits, isFunnel)}
            ref={ref}
            {...props}
          />
          <div className={styles.randomizationUnits}>
            {randomizationUnits?.length
              ? randomizationUnits.map((rUnit) => <RandomizationUnitLabel key={rUnit} randomizationUnit={rUnit} />)
              : null}
          </div>
        </div>
      </div>
    );
  },
);

function getGroupHeading(supportedRandomizationUnits?: string[], isFunnel?: boolean) {
  return ({ label }: { label?: string }) => {
    if (label !== 'Unavailable') {
      return;
    }

    // render a list of reasons why the metric may not be available to add to the funnel group
    const disabledReasons: React.ReactElement[] = [];

    if (isFunnel) {
      disabledReasons.push(
        <>
          <MetricLabel kind={getMetricKind('custom')} isNumeric /> type
        </>,
      );
    }

    if (isFunnel && validateNoSummedMetricsInFunnels()) {
      disabledReasons.push(
        <>
          aggregated by <code>sum</code>
        </>,
      );
    }

    if (supportedRandomizationUnits?.length) {
      disabledReasons.push(
        <>
          {'cannot measure events from '}
          {supportedRandomizationUnits?.map((rUnit) => (
            <RandomizationUnitLabel key={rUnit} randomizationUnit={rUnit}></RandomizationUnitLabel>
          ))}
        </>,
      );
    }

    if (disabledReasons.length === 0) {
      return <></>;
    }

    // if there's multiple reasons, prepend and/or to the last item. e.g. "Metrics below are a conversion type and/or cannot measure events from 'user'"
    if (disabledReasons.length > 1) {
      disabledReasons[disabledReasons.length - 1] = <>and/or {disabledReasons[disabledReasons.length - 1]}</>;
    }

    // If there's more than two reasons a metric may be disabled, comma separate the list
    return <span>Metrics below are a {joinJSX(disabledReasons, disabledReasons.length > 2 ? ', ' : ' ')}</span>;
  };
}

// Similar to String.prototype.join(), will join JSX elements into a single fragment using the separator string
function joinJSX(elements: React.ReactElement[], separator: string) {
  const joined = elements.map((el, i) =>
    i === elements.length - 1 ? (
      el
    ) : (
      <>
        {el}
        {separator}
      </>
    ),
  );
  return <>{joined}</>;
}
