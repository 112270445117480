import { useEffect } from 'react';
import { FocusScope } from 'react-aria';
import { AuditLogEntryRep, groupEntriesByDay } from '@gonfalon/audit-log';
import { Time } from '@gonfalon/datetime';
import { enableMostRecentAuditLogHistory } from '@gonfalon/dogfood-flags';
import { isEmpty, noop } from '@gonfalon/es6-utils';
import { DateFormat } from '@gonfalon/format';
import { Map } from 'immutable';

import { AuditLogListItem } from 'components/auditLog/AuditLogListItem';
import { PAGE_SIZE } from 'components/auditLog/AuditLogPaginationContainer';
import EmptyState from 'components/EmptyState';
import { DataList } from 'components/ui/datalist';
import { Module, ModuleContent, ModuleHeader } from 'components/ui/module';
import usePrevious from 'hooks/usePrevious';
import { Account } from 'utils/accountUtils';
import { Query } from 'utils/auditLogUtils';
import { Project } from 'utils/projectUtils';
import { projectFromIdentifier } from 'utils/resourceUtils';

import { useSignificantEvents } from './useSignificantEvents';

import styles from './AuditLogList.module.css';

type AuditLogListProps = {
  account: Account;
  projects: Map<string, Project>;
  entries: AuditLogEntryRep[];
  query: Query;
};

/* eslint-disable import/no-default-export */
export default function AuditLogList({ account, projects, entries, query }: AuditLogListProps) {
  const { fetchSignificantEntries, showSignificantEntries, significantEvents } = useSignificantEvents();
  const previousEntries = usePrevious(entries);
  const groupedByDay = groupEntriesByDay(entries);
  const queryIsEmpty = !query.get('q');

  const moreLoaded = entries.length > PAGE_SIZE;
  useEffect(() => {
    if (showSignificantEntries) {
      const newEntries = entries.filter((entry) => !previousEntries?.includes(entry));
      fetchSignificantEntries(newEntries).catch(noop);
    }
  }, [entries.length]);

  const isSignificantEntry = (entry: AuditLogEntryRep) =>
    significantEvents?.some((e) => e.auditLogEntryId === entry._id);

  if (isEmpty(groupedByDay)) {
    return emptyState(queryIsEmpty, enableMostRecentAuditLogHistory());
  }

  return (
    <div>
      {Object.entries(groupedByDay).map(([day, items]) => {
        const dateTime = new Date(Number(day));
        return (
          <Module key={day}>
            <ModuleHeader>
              <h2>
                <Time dateFormat={DateFormat.MMM_D_YYY} datetime={dateTime} />
              </h2>
            </ModuleHeader>
            <ModuleContent isSnug>
              <DataList>
                {items.map((entry) => {
                  const focusItem = (entries.indexOf(entry) + 1) % PAGE_SIZE === 1 && moreLoaded;
                  const projectList = entry?.target?.resources?.map((r) => projectFromIdentifier(r));
                  const selectedProject =
                    projectList && projectList.length > 1 ? projects.get(projectList[0] as string) : undefined;
                  const selectedProjectName = selectedProject ? selectedProject?.get('name') : '';
                  return (
                    <FocusScope key={entry._id} autoFocus={focusItem}>
                      <AuditLogListItem
                        key={entry._id}
                        entry={entry}
                        OrganizationName={account.organization}
                        projectName={selectedProjectName}
                        query={query}
                        tabIndex={focusItem ? 0 : -1}
                        isSignificant={showSignificantEntries && isSignificantEntry(entry)}
                      />
                    </FocusScope>
                  );
                })}
              </DataList>
            </ModuleContent>
          </Module>
        );
      })}
    </div>
  );
}

const emptyState = (queryIsEmpty: boolean, isMostRecentEnabled: boolean) => {
  let titleText = 'No entries match. Choose different dates and try again.';

  if (queryIsEmpty) {
    if (isMostRecentEnabled) {
      titleText = 'There are no entries available.';
    } else {
      titleText = 'No entries for these dates. Choose different dates and try again.';
    }
  }

  return <EmptyState title={titleText} className={styles.noTopBorder} />;
};
