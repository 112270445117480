import { ComponentProps } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { enableMetricEventActivity } from '@gonfalon/dogfood-flags';
import { Icon, IconName } from '@launchpad-ui/icons';
import cx from 'classnames';
import { Button, ButtonGroup, FormField, FormHint, Label, TextField } from 'launchpad';

import { Card } from 'components/ui/card';

import { NewMetricForm } from '../common';
import { LegacyMetricUrls } from '../components/LegacyMetricUrls';
import { MetricSuccessCriteria } from '../components/MetricSuccessCriteria';
import { MetricUnit } from '../components/MetricUnit';

import { EventActivitySummary } from './EventActivitySummary';

import styles from './CreateMetricModal.module.css';

export function EventInformationSection() {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<NewMetricForm>();
  const kind = useWatch({ control, name: 'kind', exact: true });

  return (
    <>
      <h2 className={styles.sectionHeader}>Event information</h2>
      <fieldset className={styles.eventKindButtons}>
        <EventKindRadioButton icon="code-circle" value="custom">
          Custom
        </EventKindRadioButton>
        <EventKindRadioButton icon="click" value="click">
          Click
        </EventKindRadioButton>
        <EventKindRadioButton icon="preview" value="pageview">
          Page view
        </EventKindRadioButton>
      </fieldset>
      <Card className={styles.eventInformationCard}>
        <FormHint>
          <h3>{getKindHint(kind)}</h3>
        </FormHint>
        {kind === 'click' && (
          <FormField
            name="selector"
            htmlFor="selector"
            label="Click targets"
            errorMessage={errors.selector?.message}
            isRequired
          >
            <>
              <TextField
                placeholder="Enter one or more CSS selectors"
                {...register('selector', { required: 'One or more click targets are required' })}
              />
              <FormHint>Enter one or more comma-separated CSS selectors to record clicks</FormHint>
            </>
          </FormField>
        )}
        {(kind === 'click' || kind === 'pageview') && <LegacyMetricUrls />}
        {kind === 'custom' && <CustomFields />}
      </Card>
    </>
  );
}

// TODO use KindButtonGroup et al instead
// can't use launchpad's Radio component because it doesn't have a stylable container
function EventKindRadioButton({ children, icon, value }: Omit<ComponentProps<'input'>, 'type'> & { icon: IconName }) {
  const { control, setValue } = useFormContext<NewMetricForm>();
  const kind = useWatch({ control, name: 'kind', exact: true });
  const id = `eventKindRadioButton-${value}`;
  return (
    <div className={cx(styles.eventKindButton, { [styles.eventKindButtonChecked]: kind === value })}>
      <Icon size="small" name={icon} />
      <Label htmlFor={id}>{children}</Label>
      <input
        id={id}
        type="radio"
        aria-label={`Set metric kind to ${value}`}
        name="kind"
        value={value}
        checked={kind === value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValue('kind', e.target.value as 'custom' | 'pageview' | 'click');
          // clear isNumeric to prevent a stale `true` value when switching between custom & click/page-view types
          setValue('isNumeric', false);
        }}
      />
    </div>
  );
}

function getKindHint(kind: 'click' | 'pageview' | 'custom'): string {
  switch (kind) {
    case 'click':
      return 'Track if an end user clicked on a certain target';
    case 'pageview':
      return 'Track if an end user viewed a certain page';
    case 'custom':
      return 'Track events by creating your own settings';
    default:
      // assert that we've handled all kinds
      return ((k: never): never => {
        throw new Error(`Unexpected metric kind: ${k}`);
      })(kind);
  }
}

function CustomFields() {
  const {
    control,
    formState: { errors },
    register,
    setValue,
  } = useFormContext<NewMetricForm>();
  const isNumeric = useWatch({ control, name: 'isNumeric', defaultValue: false });

  return (
    <>
      <ButtonGroup spacing="compact" className={styles.customEventKindButtonGroup}>
        <Button
          kind={isNumeric ? 'default' : 'primary'}
          aria-label="This is a conversion/binary metric"
          onClick={() => setValue('isNumeric', false)}
        >
          Conversion/binary
        </Button>
        <Button
          kind={isNumeric ? 'primary' : 'default'}
          aria-label="This is a numeric metric"
          onClick={() => setValue('isNumeric', true)}
        >
          Numeric
        </Button>
      </ButtonGroup>
      <FormHint>
        {isNumeric ? 'Track changes in a value against a baseline' : 'Track when an end user takes an action'}
      </FormHint>
      <FormField
        name="eventKey"
        htmlFor="eventKey"
        label="Event key"
        errorMessage={errors.eventKey?.message}
        isRequired
      >
        <TextField placeholder="Enter event key" {...register('eventKey', { required: 'An event key is required' })} />
      </FormField>
      {enableMetricEventActivity() ? <EventActivitySummary /> : null}
      <div className={`u-flex u-flex-top u-gap-2 ${styles.criteriaWrapper}`}>
        {isNumeric && (
          <MetricUnit
            {...register('unit', { required: 'Unit is required for numeric metrics' })}
            errorMessage={errors.unit?.message}
          />
        )}
        <MetricSuccessCriteria {...register('successCriteria')} />
      </div>
    </>
  );
}
