import { useEffect } from 'react';
import { isEqual, noop } from '@gonfalon/es6-utils';

import { fetchContextKindsIfNeeded as fetchContextKindsAction } from 'actions/contextKinds';
import { contextKindsRequestSelector, contextKindsSelector, unhiddenContextKindsSelector } from 'reducers/contextKinds';
import { ready } from 'utils/reduxUtils';

import { useDispatch } from './useDispatch';
import { useSelector } from './useSelector';

type UseContextKindsOptions = {
  projKey: string;
};

export const useContextKinds = ({ projKey }: UseContextKindsOptions) => {
  const dispatch = useDispatch();
  const fetchContextKinds = () => {
    dispatch(fetchContextKindsAction(projKey)).then(noop, noop);
  };

  const contextKinds = useSelector(contextKindsSelector);
  const contextKindsRequest = useSelector(contextKindsRequestSelector);
  const unhiddenContextKinds = useSelector(unhiddenContextKindsSelector, isEqual);
  const isReady = ready(contextKindsRequest);

  useEffect(() => {
    fetchContextKinds();
  }, []);

  return {
    contextKinds,
    unhiddenContextKinds,
    isReady,
  };
};
