import { FlagEvent } from 'ia-poc/services/accelerate/queries';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map } from 'immutable';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import { AuditLogAction } from 'actions/auditLog';
import { GlobalState } from 'reducers';
import registry from 'reducers/registry';
import { AuditLogEntry, Query } from 'utils/auditLogUtils';
import { nilFilter } from 'utils/collectionUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import { createImmutableState, ImmutableMap } from 'utils/immutableUtils';

import paginate from './paginate';

const createEntry = (entry?: AuditLogEntry, timestamp?: number) =>
  createImmutableState({
    isFetching: false,
    lastFetched: timestamp,
    error: null,
    entity: entry,
  });

type SignificantEventsStateType = {
  error?: ImmutableServerError | null;
  isFetching: boolean;
  lastFetched?: number;
  entities: FlagEvent[];
  query: Query;
};

const createSignificantEventsCollections = (flagEvents: FlagEvent[], timestamp?: number) =>
  createImmutableState({
    isFetching: false,
    lastFetched: timestamp,
    error: null,
    entities: flagEvents,
    query: new Query(),
  } as unknown as SignificantEventsStateType);

const auditLogEntry = (state = createEntry(), action: AuditLogAction) => {
  switch (action.type) {
    case 'auditLog/REQUEST_ENTRY':
      return state.set('isFetching', true);
    case 'auditLog/REQUEST_ENTRY_FAILED':
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        error: action.error,
        entity: null,
      });
    case 'auditLog/REQUEST_ENTRY_DONE':
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        error: null,
        entity: action.entry,
      });
    default:
      return state;
  }
};

const auditLogEntities = (state = Map<string, ReturnType<typeof createEntry>>(), action: AuditLogAction) => {
  switch (action.type) {
    case 'auditLog/REQUEST_ENTRIES_DONE':
      return state.merge(
        action.response
          .get('entities')
          .get('entries')
          .map((e) => createEntry(e, action.timestamp)),
      );
    default:
      return state;
  }
};

const auditLogDetails = (state = Map<string, ReturnType<typeof auditLogEntry>>(), action: AuditLogAction) => {
  switch (action.type) {
    case 'auditLog/REQUEST_ENTRY':
    case 'auditLog/REQUEST_ENTRY_FAILED':
    case 'auditLog/REQUEST_ENTRY_DONE':
      return state.update(action.entryId, (e) => auditLogEntry(e || createEntry(), action));
    default:
      return state;
  }
};

const auditLogPagination = paginate({
  types: ['auditLog/REQUEST_ENTRIES', 'auditLog/REQUEST_ENTRIES_FAILED', 'auditLog/REQUEST_ENTRIES_DONE'],
  invalidateTypes: ['auditLog/INVALIDATE_ENTRIES'],
  mapActionToKey: (action) => action.key,
});

export const auditLogQuery = (state = Map<string, Query>(), action: AuditLogAction): Map<string, Query> => {
  switch (action.type) {
    case 'auditLog/SAVE_ALL_QUERIES':
      return state.set(action.query.pathname, action.query);
    default:
      return state;
  }
};

const selectedFlagInsightChanges = (state: { date?: number; entryIds?: string[] } = {}, action: AuditLogAction) => {
  switch (action.type) {
    case 'auditLog/SHOW_INSIGHTS_DETAIL':
      return {
        date: action.date,
        entryIds: action.entries,
      };
    case 'auditLog/HIDE_INSIGHTS_DETAIL':
      return {};
    default:
      return state;
  }
};

const significantFlagEventsForAuditLogEntries = (
  state = createSignificantEventsCollections([]),
  action: AuditLogAction,
) => {
  switch (action.type) {
    case 'auditLog/REQUEST_SIGNIFICANT_EVENTS':
      return state.set('isFetching', true);
    case 'auditLog/REQUEST_SIGNIFICANT_EVENTS_DONE':
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        error: null,
        entities: state.get('entities').concat(action.collection.items),
      });
    case 'auditLog/REQUEST_SIGNIFICANT_EVENTS_FAILED':
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        error: action.error,
        entities: [],
      });
    default:
      return state;
  }
};

export const auditLog = combineReducers({
  entities: auditLogEntities,
  details: auditLogDetails,
  pagination: auditLogPagination,
  selectedFlagInsightChanges,
  significantEvents: significantFlagEventsForAuditLogEntries,
});

export const auditLogSelector = (state: GlobalState) => state.auditLog;

export const auditLogEntriesSelector = createSelector(auditLogSelector, (log) =>
  log.entities.map((e) => e.get('entity')).filter((e) => !!e),
);

export const auditLogSignificantEventsSelector = (state: GlobalState) => state.auditLog.significantEvents;

export const auditLogPaginationSelector = (state: GlobalState, { query }: { query: Query }) =>
  auditLogSelector(state).pagination.get(query.toQueryString());

export const auditLogEntrySelector = createSelector<
  GlobalState,
  { entryId?: string; match?: { params: { entryId: string } } },
  ReturnType<typeof auditLogSelector>,
  string | undefined,
  ImmutableMap<{ lastFetched: number | null; isFetching: boolean; entity: AuditLogEntry }>
>(
  auditLogSelector,
  (_, props) => props.entryId || props.match?.params.entryId,
  (log, entryId) =>
    fromJS({
      lastFetched: log.details.getIn([entryId, 'lastFetched']),
      isFetching: log.details.getIn([entryId, 'isFetching']),
      entity: log.details.getIn([entryId, 'entity']),
    }),
);

export const selectedFlagInsightChangesSelector = (state: GlobalState) =>
  auditLogSelector(state).selectedFlagInsightChanges;
export const selectedFlagInsightDateSelector = (state: GlobalState) => selectedFlagInsightChangesSelector(state).date;
export const selectedFlagInsightEntryIdsSelector = (state: GlobalState) =>
  selectedFlagInsightChangesSelector(state).entryIds;
export const selectedFlagInsightEntriesSelector = createSelector(
  selectedFlagInsightEntryIdsSelector,
  auditLogEntriesSelector,
  (entryIds, entries) =>
    (entryIds ? List(entryIds.map((id) => entries.get(id))) : List<AuditLogEntry>()).filter(nilFilter),
);

export const auditLogQuerySelector = (state: GlobalState) => state.auditLogQuery;

registry.addReducers({
  auditLog,
  auditLogQuery,
});
