import { createContext, useMemo } from 'react';
import cx from 'clsx';

import { Space } from '../theme';

import styles from './Columns.module.css';

export type ColumnsProps = {
  gap?: Space;
  alignY?: 'top' | 'center' | 'bottom';
  children: React.ReactNode;
  className?: string;
};

type ColumnContextValue = { gap: Space };

// Once the `gap` property is widely supported for flexbox, we can get rid
// of this context. This is required for now so users don't need to
// pass `gap` on every child `Column` in order to ensure consistent spacing.
export const ColumnContext = createContext<ColumnContextValue>({
  gap: '0',
});

export function Columns({ gap = '0', alignY = 'top', children, className }: ColumnsProps) {
  const columnContextValue = useMemo(
    () => ({
      gap,
    }),
    [gap],
  );

  return (
    <ColumnContext.Provider value={columnContextValue}>
      <div className={cx(styles.columns, styles[`gap-${gap}`], styles[`alignY-${alignY}`], className)}>{children}</div>
    </ColumnContext.Provider>
  );
}
