import { AuditLogEntryRep, EntrySummary } from '@gonfalon/audit-log';
import { LinkButton } from '@launchpad-ui/components';
import cx from 'clsx';

import { DataListItem } from 'components/ui/datalist';
import { Cell, Grid } from 'components/ui/grid';
import { AlignValue, CellLayoutValue, JustifyValue } from 'components/ui/grid/types';
import { Query } from 'utils/auditLogUtils';

import styles from './AuditLogListItem.module.css';
export type AuditLogListItemProps = {
  entry: AuditLogEntryRep;
  OrganizationName: string;
  projectName: string;
  query: Query;
  tabIndex?: number;
  isSignificant?: boolean;
};

export function AuditLogListItem({
  entry,
  OrganizationName,
  query,
  projectName,
  tabIndex,
  isSignificant,
}: AuditLogListItemProps) {
  return (
    <DataListItem tabIndex={tabIndex} className={cx({ [styles.isSignificant]: isSignificant })}>
      <Grid justify={JustifyValue.BETWEEN} align={AlignValue.TOP}>
        <Cell layout={CellLayoutValue.TEN_OF_TWELVE} className="u-flex u-flex-top">
          <EntrySummary fromNow entry={entry} organizationName={OrganizationName} projectName={projectName} />
        </Cell>
        <Cell layout={CellLayoutValue.AUTO}>
          <LinkButton
            id={`${entry._id}-details`}
            data-test-id="auditLog-details"
            size="small"
            href={{
              pathname: query.getPathname(`/${entry._id}/details`),
              search: query.toHistorySearch(),
            }}
            aria-labelledby={`${entry._id}-details ${entry._id}-title`}
          >
            Details
          </LinkButton>
        </Cell>
      </Grid>
    </DataListItem>
  );
}
