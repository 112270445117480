import { Fragment } from 'react';
import { Time } from '@gonfalon/datetime';
import { noop } from '@gonfalon/es6-utils';
import { DateFormat } from '@gonfalon/format';
import { type InstructionKind, type Segment, type SegmentSemanticInstruction } from '@gonfalon/segments';
import { capitalize } from '@gonfalon/strings';

import { CollapsibleInstructionListItem } from 'components/InstructionList/CollapsibleInstructionListItem';
import { InstructionListItem } from 'components/InstructionList/InstructionListItem';
import { InstructionListChangeKind } from 'components/InstructionList/instructionListUtils';
import { NestedInstructionListItem } from 'components/InstructionList/NestedInstructionListItem';

import { useOriginalSegment } from '../hooks/useOriginalSegment';

import { SegmentRuleInstructionDescription } from './SegmentRuleInstructionDescription';

import styles from './SegmentInstructionsList.module.css';

export type SegmentInstructionsListProps = {
  segment: Segment;
  instructions: SegmentSemanticInstruction[];
};

const INSTRUCTION_CATEGORY = {
  INCLUDED: 'Included targets',
  EXCLUDED: 'Excluded targets',
  RULES: 'Rules',
};

export function SegmentInstructionsList({ instructions, segment }: SegmentInstructionsListProps) {
  const originalSegment = useOriginalSegment({ segmentKey: segment.key });
  const instructionElementsByCategory: { [category: string]: JSX.Element[] } = {
    [INSTRUCTION_CATEGORY.INCLUDED]: [],
    [INSTRUCTION_CATEGORY.EXCLUDED]: [],
    [INSTRUCTION_CATEGORY.RULES]: [],
  };

  const editRuleInstructionsByRuleId: { [ruleId: string]: JSX.Element[] } = {};

  const makeListItem = ({
    instructionKind,
    changeKind,
    contextKind,
    instructionValues,
    index,
  }: {
    instructionKind: InstructionKind;
    changeKind: InstructionListChangeKind;
    contextKind: string;
    instructionValues: string[];
    index: number;
  }) => (
    <InstructionListItem key={`${instructionKind}-${index}`} changeKind={changeKind} onExit={noop}>
      <span key={`${instructionValues.toString()}-${index}`}>
        {capitalize(changeKind)}{' '}
        {instructionValues.map((value, valueIdx) => (
          <Fragment key={valueIdx}>
            <code>{value}</code>
            {valueIdx !== instructionValues.length - 1 ? ', ' : ''}
          </Fragment>
        ))}{' '}
        for context kind <code>{contextKind}</code>
      </span>
    </InstructionListItem>
  );

  function getExpiringTargetCategory(contextKind: string, value: string) {
    if (contextKind === 'user') {
      if (segment.excluded && segment.excluded.includes(value)) {
        return INSTRUCTION_CATEGORY.EXCLUDED;
      }
    } else if (
      Boolean(segment.excludedContexts?.find((context) => context.contextKind === contextKind)?.values?.includes(value))
    ) {
      return INSTRUCTION_CATEGORY.EXCLUDED;
    }

    return INSTRUCTION_CATEGORY.INCLUDED;
  }

  instructions.forEach((instruction, idx) => {
    switch (instruction.kind) {
      case 'addIncludedUsers':
        instructionElementsByCategory[INSTRUCTION_CATEGORY.INCLUDED].push(
          makeListItem({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.ADD,
            contextKind: 'user',
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'addIncludedTargets':
        instructionElementsByCategory[INSTRUCTION_CATEGORY.INCLUDED].push(
          makeListItem({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.ADD,
            contextKind: instruction.contextKind,
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'removeIncludedTargets':
        instructionElementsByCategory[INSTRUCTION_CATEGORY.INCLUDED].push(
          makeListItem({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.REMOVE,
            contextKind: instruction.contextKind,
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'removeIncludedUsers':
        instructionElementsByCategory[INSTRUCTION_CATEGORY.INCLUDED].push(
          makeListItem({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.REMOVE,
            contextKind: 'user',
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'addExcludedUsers':
        instructionElementsByCategory[INSTRUCTION_CATEGORY.EXCLUDED].push(
          makeListItem({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.ADD,
            contextKind: 'user',
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'addExcludedTargets':
        instructionElementsByCategory[INSTRUCTION_CATEGORY.EXCLUDED].push(
          makeListItem({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.ADD,
            contextKind: instruction.contextKind,
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'removeExcludedUsers':
        instructionElementsByCategory[INSTRUCTION_CATEGORY.EXCLUDED].push(
          makeListItem({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.REMOVE,
            contextKind: 'user',
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'removeExcludedTargets':
        instructionElementsByCategory[INSTRUCTION_CATEGORY.EXCLUDED].push(
          makeListItem({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.REMOVE,
            contextKind: instruction.contextKind,
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'addExpiringTarget':
        const addExpiringTargetListItem = (
          <InstructionListItem
            key={`${instruction.kind}-${idx}`}
            changeKind={InstructionListChangeKind.ADD}
            onExit={noop}
          >
            Schedule removal of context kind <code>{instruction.contextKind}</code> <code>{instruction.value}</code> for{' '}
            <Time
              className={styles.time}
              datetime={instruction.removalDate}
              dateFormat={DateFormat.MM_DD_YYYY_H_MM_A_Z}
              notooltip
            />
          </InstructionListItem>
        );

        const addExpiringTargetInstructionCategory = getExpiringTargetCategory(
          instruction.contextKind,
          instruction.value,
        );
        instructionElementsByCategory[addExpiringTargetInstructionCategory].push(addExpiringTargetListItem);
        return;
      case 'removeExpiringTarget':
        const removeExpiringTargetListItem = (
          <InstructionListItem
            key={`${instruction.kind}-${idx}`}
            changeKind={InstructionListChangeKind.REMOVE}
            onExit={noop}
          >
            Delete scheduled removal of context kind <code>{instruction.contextKind}</code>{' '}
            <code>{instruction.value}</code>
          </InstructionListItem>
        );

        const removeExpiringTargetInstructionCategory = getExpiringTargetCategory(
          instruction.contextKind,
          instruction.value,
        );
        instructionElementsByCategory[removeExpiringTargetInstructionCategory].push(removeExpiringTargetListItem);
        return;
      case 'updateExpiringTarget':
        const updateExpiringTargetListItem = (
          <InstructionListItem
            key={`${instruction.kind}-${idx}`}
            changeKind={InstructionListChangeKind.CHANGE}
            onExit={noop}
          >
            Update scheduled removal of context kind <code>{instruction.contextKind}</code>{' '}
            <code>{instruction.value}</code> to{' '}
            <Time
              className={styles.time}
              datetime={instruction.removalDate}
              dateFormat={DateFormat.MM_DD_YYYY_H_MM_A_Z}
              notooltip
            />
          </InstructionListItem>
        );

        const updateExpiringTargetInstructionCategory = getExpiringTargetCategory(
          instruction.contextKind,
          instruction.value,
        );
        instructionElementsByCategory[updateExpiringTargetInstructionCategory].push(updateExpiringTargetListItem);
        return;
      case 'addRule':
        instructionElementsByCategory[INSTRUCTION_CATEGORY.RULES].push(
          <InstructionListItem
            key={`${instruction.kind}-${idx}`}
            changeKind={InstructionListChangeKind.ADD}
            onExit={noop}
          >
            <SegmentRuleInstructionDescription rule={instruction.rule} />
          </InstructionListItem>,
        );
        return;
      case 'removeRule':
        instructionElementsByCategory[INSTRUCTION_CATEGORY.RULES].push(
          <InstructionListItem
            key={`${instruction.kind}-${idx}`}
            changeKind={InstructionListChangeKind.REMOVE}
            onExit={noop}
          >
            <SegmentRuleInstructionDescription
              rule={originalSegment ? originalSegment.rules.find((rule) => rule._id === instruction.ruleId) : undefined}
            />
          </InstructionListItem>,
        );
        return;
      case 'updateRuleDescription':
        const updateRuleDescriptionElem = (
          <InstructionListItem
            key={`${instruction.kind}-${idx}`}
            changeKind={InstructionListChangeKind.CHANGE}
            onExit={noop}
          >
            Update rule description to {instruction.description}
          </InstructionListItem>
        );
        editRuleInstructionsByRuleId[instruction.ruleId]
          ? editRuleInstructionsByRuleId[instruction.ruleId].push(updateRuleDescriptionElem)
          : (editRuleInstructionsByRuleId[instruction.ruleId] = [updateRuleDescriptionElem]);
        return;
      default:
    }
  });

  Object.keys(editRuleInstructionsByRuleId).forEach((ruleId) => {
    if (editRuleInstructionsByRuleId[ruleId].length === 0) {
      return;
    }
    const ruleIdx = originalSegment ? originalSegment.rules.findIndex((rule) => rule._id === ruleId) : -1;
    const ruleNumber = ruleIdx + 1;
    const rule = originalSegment ? originalSegment.rules[ruleIdx] : undefined;
    const ruleDescription = rule ? rule.description || `Rule ${ruleNumber}` : 'rule not found';

    const insElem = (
      <NestedInstructionListItem
        subCategoryHeader="Rule"
        subCategoryDetails={ruleDescription}
        changeKind={InstructionListChangeKind.CHANGE}
        onExit={noop}
        key={ruleId}
      >
        {editRuleInstructionsByRuleId[ruleId].map((ins) => ins)}
      </NestedInstructionListItem>
    );

    instructionElementsByCategory[INSTRUCTION_CATEGORY.RULES].push(insElem);
  });

  const categoriesWithInstructions = Object.keys(instructionElementsByCategory).filter(
    (category) => instructionElementsByCategory[category].length,
  );
  return (
    <ul className={styles.container}>
      {categoriesWithInstructions.map((category, idx) => (
        <CollapsibleInstructionListItem
          key={idx}
          initialOpen={idx === 0}
          categoryHeader={category}
          categoryInsElems={instructionElementsByCategory[category]}
        />
      ))}
    </ul>
  );
}
