import { schemas } from '@gonfalon/openapi';
import { GetSegmentsParams } from '@gonfalon/rest-api';

export enum SegmentType {
  AMPLITUDE = 'amplitude',
  REGULAR = 'regular',
  NATIVE_BIG = 'native_big',
}

export enum SyncedSegmentSourceKind {
  AMPLITUDE = 'amplitude',
  HEAP = 'heap',
  SEGMENT = 'segment',
}

type SegmentTrackEventInfo = {
  action?: string;
  bucketContextKind?: string;
  contextKind?: string;
  description?: boolean;
  filter?: string;
  filterType?: 'tag' | 'source';
  isRelaySetup?: boolean;
  location?: string;
  syncedSource?: SyncedSegmentSourceKind;
  tags?: boolean;
  type?: 'individual' | 'rule' | SegmentType;
  ruleKind?: string;
  clauseKind?: string;
  operatorName?: string;
  attributeName?: string;
  isBigSegment?: boolean;
};

export type InstructionKind =
  | 'addIncludedUsers'
  | 'addIncludedTargets'
  | 'removeIncludedUsers'
  | 'removeIncludedTargets'
  | 'addExcludedUsers'
  | 'addExcludedTargets'
  | 'removeExcludedUsers'
  | 'removeExcludedTargets'
  | 'addExpiringTarget'
  | 'removeExpiringTarget'
  | 'updateExpiringTarget'
  | 'addRule'
  | 'removeRule'
  | 'updateRuleDescription';

type AddIncludedUsersInstruction = {
  kind: 'addIncludedUsers';
  values: string[];
};

type AddIncludedTargetsInstruction = {
  kind: 'addIncludedTargets';
  contextKind: string;
  values: string[];
};
type RemoveIncludedUsersInstruction = {
  kind: 'removeIncludedUsers';
  values: string[];
};

type RemoveIncludedTargetsInstruction = {
  kind: 'removeIncludedTargets';
  contextKind: string;
  values: string[];
};

type AddExcludedUsersInstruction = {
  kind: 'addExcludedUsers';
  values: string[];
};

type AddExcludedTargetsInstruction = {
  kind: 'addExcludedTargets';
  contextKind: string;
  values: string[];
};
type RemoveExcludedUsersInstruction = {
  kind: 'removeExcludedUsers';
  values: string[];
};

type RemoveExcludedTargetsInstruction = {
  kind: 'removeExcludedTargets';
  contextKind: string;
  values: string[];
};

type AddExpiringTargetInstruction = {
  kind: 'addExpiringTarget';
  removalDate: number;
  value: string;
  contextKind: string;
};

type RemoveExpiringTargetInstruction = {
  kind: 'removeExpiringTarget';
  value: string;
  contextKind: string;
};

type UpdateExpiringTargetInstruction = {
  kind: 'updateExpiringTarget';
  removalDate: number;
  value: string;
  contextKind: string;
};

type AddRuleInstruction = {
  kind: 'addRule';
  rule: Rule;
};

type RemoveRuleInstruction = {
  kind: 'removeRule';
  ruleId: string;
};

type UpdateRuleDescriptionInstruction = {
  kind: 'updateRuleDescription';
  ruleId: string;
  description: string;
};

type SegmentSemanticInstruction =
  | AddIncludedUsersInstruction
  | AddIncludedTargetsInstruction
  | RemoveIncludedUsersInstruction
  | RemoveIncludedTargetsInstruction
  | AddExcludedUsersInstruction
  | AddExcludedTargetsInstruction
  | RemoveExcludedUsersInstruction
  | RemoveExcludedTargetsInstruction
  | AddExpiringTargetInstruction
  | RemoveExpiringTargetInstruction
  | UpdateExpiringTargetInstruction
  | AddRuleInstruction
  | RemoveRuleInstruction
  | UpdateRuleDescriptionInstruction;

export type Clause = schemas['Clause'];

export type Rule = schemas['UserSegmentRule'];

export type Segment = schemas['UserSegment'];

export type SegmentTarget = schemas['SegmentTarget'];

export {
  type SegmentTrackEventInfo,
  type SegmentSemanticInstruction,
  type AddIncludedUsersInstruction,
  type RemoveIncludedUsersInstruction,
  type AddIncludedTargetsInstruction,
  type RemoveIncludedTargetsInstruction,
  type AddExcludedUsersInstruction,
  type RemoveExcludedUsersInstruction,
  type AddExcludedTargetsInstruction,
  type RemoveExcludedTargetsInstruction,
  type AddExpiringTargetInstruction,
  type RemoveExpiringTargetInstruction,
  type UpdateExpiringTargetInstruction,
  type AddRuleInstruction,
  type RemoveRuleInstruction,
  type UpdateRuleDescriptionInstruction,
};

export type UISegmentsListView = {
  sort?: GetSegmentsParams['sort'];
  query?: string;
  keys?: string[];
  tags?: string[];
  kind?: string;
};
