import { CardSelect } from 'components/CardSelect';
import { CardSelectValue } from 'components/CardSelect/CardSelect';
import MetricKind, { GuidedMetricKindDisplays } from 'utils/MetricKind';
export function MeasureOptionsSelect({ onSelect }: { onSelect: (value: string) => void }) {
  const options: CardSelectValue[] = [
    {
      value: 'count',
      name: 'Count',
      description: 'Number of times an event occurred',
      subtitle: 'ex: Number of purchases made',
      tags: [GuidedMetricKindDisplays[MetricKind.COUNT].displayName],
    },
    {
      value: 'occurrence',
      name: 'Occurrence',
      description: 'Whether an event is seen at least once',
      subtitle: 'ex: Errors',
      tags: [GuidedMetricKindDisplays[MetricKind.BINARY].displayName],
    },
    {
      value: 'value',
      name: 'Value / Size',
      description: 'Magnitude of an event that has an associated number',
      subtitle: 'ex: Latency or purchase value',
      tags: [GuidedMetricKindDisplays[MetricKind.NUMERIC].displayName],
    },
  ];

  return (
    <CardSelect items={options} ariaLabel="What do you want to measure?" disallowEmptySelection onSelect={onSelect} />
  );
}
