import { v1ReleasePipelines } from '@gonfalon/dogfood-flags';
import { useIsInNewIA } from '@gonfalon/ia-migration';

export const useCanEditReleasePipeline = ({ isLegacy }: { isLegacy: boolean }) => {
  const isInNewIA = useIsInNewIA();

  if (isInNewIA) {
    // new shell: only edit v2 pipelines
    const canEdit = isLegacy === false;
    return { canEdit, reason: !canEdit ? 'You cannot edit legacy release pipelines' : undefined };
  } else {
    if (isLegacy === false) {
      // old shell: cannot edit v2 pipelines
      return { canEdit: false, reason: 'Enable the new experience to edit this pipeline' };
    }
    // old shell: only edit v1 pipelines if v1ReleasePipelines flag = available
    const canEdit = v1ReleasePipelines() === 'Available';
    return { canEdit, reason: !canEdit ? 'You cannot edit legacy release pipelines' : undefined };
  }
};
