import { createContext, ReactNode, useContext, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { fromJS, OrderedMap } from 'immutable';

import { ManifestRecord } from 'utils/goaltenderUtils';

type GoaltenderManifests = OrderedMap<string, ManifestRecord>;

type SyncedSegmentContextValue = {
  goaltenderManifests: GoaltenderManifests;
};

const SyncedSegmentManifestContext = createContext<SyncedSegmentContextValue>({
  goaltenderManifests: fromJS({}),
});

type ProviderProps = {
  children: ReactNode;
  goaltenderManifests: GoaltenderManifests;
};

export function SyncedSegmentProvider({ children, goaltenderManifests }: ProviderProps) {
  const contextValue = useMemo(() => ({ goaltenderManifests }), [goaltenderManifests]);

  return <SyncedSegmentManifestContext.Provider value={contextValue}>{children}</SyncedSegmentManifestContext.Provider>;
}

export function useSyncedSegmentManifests() {
  const context = useContext(SyncedSegmentManifestContext);
  if (!context) {
    throw new Error('useSyncedSegmentManifest must be used within a SyncedSegmentProvider');
  }

  return context.goaltenderManifests.filter((manifest) => !!manifest?.getIn(['capabilities', 'syncedSegment']));
}
