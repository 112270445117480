import { Time } from '@gonfalon/datetime';
import { DateFormat } from '@gonfalon/format';
import { Popover } from 'launchpad';

// fix in [ch99585]
// a user attribute can truly be just about anything, so it's typed accordingly
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function DateAttr({ value }: any) {
  return (
    <Popover interactionKind="hover" placement="right">
      <Time dateFormat={DateFormat.MMM_D_YYYY_H_MM_A} datetime={value} notooltip />
      <div className="u-pa-m">
        <div className="u-w-100 u-fw-medium u-mr-l u-color-text-ui-tertiary">Raw value</div>
        <div className="u-w-100 u-o-hidden u-ow-break">{value}</div>
      </div>
    </Popover>
  );
}
