import { useFormContext } from 'react-hook-form';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { HookFormSelect, HookFormSelectProps, NewTabLink } from '@gonfalon/launchpad-experimental';
import { toContextKinds, toHref } from '@gonfalon/navigator';
import { useProjectKey } from '@gonfalon/router';
import { Alert, FormHint, Tooltip } from 'launchpad';

import { useProjectExperimentationSettings } from 'components/experimentation/common/hooks/useProjectExperimentationSettings';
import { RandomizationUnit } from 'components/experimentation/common/types';
import { AccessDecision, allowDecision } from 'utils/accessUtils';

import { NewMetricForm } from '../common';

import { FormFieldWithTooltip } from './FormFieldWithTooltip';

import styles from './MetricRandomizationUnits.module.css';

type RandomizationUnitOption = {
  label: string;
  value: string;
};

export function MetricRandomizationUnits({
  access = allowDecision(),
  selectProps = {},
}: {
  access?: AccessDecision;
  selectProps?: Partial<HookFormSelectProps<RandomizationUnitOption, NewMetricForm>>;
}) {
  const projKey = useProjectKey();

  const { data: settings, isPending, isError } = useProjectExperimentationSettings({ projKey });
  const {
    control,
    formState: { errors },
  } = useFormContext<NewMetricForm>();
  if (isError) {
    return <Alert kind="error">Failed to load randomization units</Alert>;
  }

  const isDisabled = !access.isAllowed;

  if (isPending) {
    return (
      <MetricRandomizationUnitsContainer>
        <HookFormSelect<RandomizationUnitOption, NewMetricForm>
          control={control}
          name="randomizationUnits"
          ariaLabel="Randomization units"
          isLoading={isPending}
          disabled={isDisabled}
        />
      </MetricRandomizationUnitsContainer>
    );
  }

  const options: RandomizationUnitOption[] = settings.randomizationUnits.map(toOption);

  return (
    <Tooltip content={access.getModifyFieldReason()}>
      <MetricRandomizationUnitsContainer errorMessage={errors.randomizationUnits?.message} showHint={!isDisabled}>
        <div data-test-id="metric-randomization-units">
          <HookFormSelect<RandomizationUnitOption, NewMetricForm>
            {...selectProps}
            control={control}
            rules={{ required: 'One or more randomization units are required' }}
            name="randomizationUnits"
            ariaLabel="Randomization units"
            options={options}
            isMulti
            isClearable={false}
            disabled={isDisabled}
            required
          />
        </div>
      </MetricRandomizationUnitsContainer>
    </Tooltip>
  );
}

function MetricRandomizationUnitsContainer({
  errorMessage,
  children,
  showHint = true,
}: {
  errorMessage?: string;
  children: JSX.Element;
  showHint?: boolean;
}) {
  const projKey = useProjectKey();
  const envKey = useSelectedEnvironmentKey();

  return (
    <>
      <FormFieldWithTooltip
        name="randomizationUnits"
        htmlFor="randomizationUnits"
        LabelProps={{ className: styles.label }}
        label="Randomization units"
        tooltip='Metrics can only use context kinds marked as "Available for experiments"'
        hint="Select from the list of context kinds to assign randomization units this metric can measure from"
        errorMessage={errorMessage}
        isRequired
      >
        {children}
      </FormFieldWithTooltip>
      {showHint && (
        <FormHint>
          Can’t find what you want?{' '}
          <NewTabLink
            showIcon={false}
            href={toHref(toContextKinds({ environmentKey: envKey, projectKey: projKey }))}
            text="Add a context kind for experiments"
          />
          .
        </FormHint>
      )}
    </>
  );
}

function toOption(r: RandomizationUnit): RandomizationUnitOption {
  return {
    label: r._displayName ?? r.randomizationUnit,
    value: r.randomizationUnit,
  };
}
