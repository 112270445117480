import { Time } from '@gonfalon/datetime';
import { DateFormat } from '@gonfalon/format';

import FlagDisplayForInstructions from 'components/PendingChanges/FlagDisplayForInstructions';
import { ContextTargetingExpirationInstruction } from 'utils/expiringContextTargetsUtils';
import { ExpiringTargetsInstructionKind } from 'utils/instructions/expiringTargets/types';
import { ResourceKind } from 'utils/saveButtonUtils';

import './styles.css';

export type ExpiringTargetsInstructionEntryProps = {
  expiringIns: ContextTargetingExpirationInstruction;
  targetResourceKind?: ResourceKind;
  projKey?: string;
  envKey?: string;
};

export function ExpiringTargetsInstructionEntry({
  expiringIns,
  targetResourceKind = ResourceKind.FLAG,
  projKey,
  envKey,
}: ExpiringTargetsInstructionEntryProps) {
  const kind = expiringIns.kind;
  const flagKey = expiringIns.flagKey;

  const handleTargetDisplay = () => (
    <span>
      of {expiringIns.contextKind} <code>{expiringIns.contextKey}</code>
    </span>
  );

  const handleFlagDisplay = () =>
    targetResourceKind === ResourceKind.USER && flagKey ? (
      <span>
        {' '}
        on <FlagDisplayForInstructions flagKey={flagKey} projKey={projKey} envKey={envKey} />{' '}
      </span>
    ) : null;

  const time = expiringIns.value;

  if (
    kind === ExpiringTargetsInstructionKind.ADD_EXPIRE_USER_TARGET_DATE ||
    kind === ExpiringTargetsInstructionKind.ADD_EXPIRE_TARGET_DATE
  ) {
    return (
      <span>
        <span>Schedule</span> removal {handleTargetDisplay()} for{' '}
        {time && (
          <Time className="u-fw-semibold" datetime={time} dateFormat={DateFormat.MM_DD_YYYY_H_MM_A_Z} notooltip />
        )}
        {handleFlagDisplay()}
      </span>
    );
  } else if (
    kind === ExpiringTargetsInstructionKind.UPDATE_EXPIRE_USER_TARGET_DATE ||
    kind === ExpiringTargetsInstructionKind.UPDATE_EXPIRE_TARGET_DATE
  ) {
    return (
      <span>
        <span>Update</span> scheduled removal {handleTargetDisplay()} to{' '}
        {time && (
          <Time className="u-fw-semibold" datetime={time} dateFormat={DateFormat.MM_DD_YYYY_H_MM_A_Z} notooltip />
        )}
        {handleFlagDisplay()}
      </span>
    );
  } else if (
    kind === ExpiringTargetsInstructionKind.REMOVE_EXPIRE_USER_TARGET_DATE ||
    kind === ExpiringTargetsInstructionKind.REMOVE_EXPIRE_TARGET_DATE
  ) {
    return (
      <span>
        <span>Delete scheduled removal {handleTargetDisplay()}</span>
        {handleFlagDisplay()}
      </span>
    );
  }
  return null;
}
