import { ReactElement, useCallback, useMemo, useState } from 'react';
import { Time } from '@gonfalon/datetime';
import { DateFormat } from '@gonfalon/format';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { localPoint } from '@visx/event';
import { GridColumns, GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { scaleLinear, scaleUtc } from '@visx/scale';
import { Bar, Line, LinePath } from '@visx/shape';
import { Threshold } from '@visx/threshold';
import { defaultStyles, Tooltip, TooltipWithBounds, withTooltip } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import cx from 'clsx';
import { bisector, extent } from 'd3-array';
import { curveBasis } from 'd3-shape';
import { format } from 'date-fns';

import { LegendScale, TimeSeriesIntervalPlotLegend } from './TimeSeriesIntervalPlotLegend';

import styles from './TimeSeriesIntervalPlot.module.css';

type NumberValue =
  | number
  | {
      valueOf(): number;
    };

type OptionalData<T = unknown> = undefined extends T ? { data?: T } : { data: T };

type Datum = {
  timestamp: number;
  pointEstimate: number;
  lowerBound: number;
  upperBound: number;
};

type PlotPoint<T> = Datum & OptionalData<T>;

export type TimeSeriesIntervalPlotData<T = unknown> = {
  name: string;
  color: string;
  values: Array<PlotPoint<T>>;
};

export type TimeSeriesIntervalPlotProps<T = unknown> = {
  data: Array<TimeSeriesIntervalPlotData<T>>;
  xLabel?: string;
  yLabel?: string;
  width: number;
  height: number;
  showPointEstimate?: boolean;
  showGridColumns?: boolean;
  showYAxisTicks?: boolean;
  renderTooltip?: (
    tooltipData: TimeSeriesIntervalPlotTooltipData<T>,
  ) => ReactElement<TimeSeriesIntervalPlotTooltipData<T>>;
  formatXAxisTickLabel?: (date: Date | NumberValue) => string;
  hideLegend?: boolean;
  title?: string;
};

export type Point<T> = {
  name: string;
  color: string;
  value: PlotPoint<T>;
};

export type TimeSeriesIntervalPlotTooltipData<T = unknown> = {
  nearestPoints: Array<Point<T>>;
  timestamp: number;
};

const tooltipStyles = {
  ...defaultStyles,
  background: 'var(--lp-color-text-ui-primary-base)',
  color: 'var(--lp-color-text-ui-primary-inverted)',
};

const margin = { top: 10, right: 48, bottom: 84, left: 48 };

const X0 = 0.00001;

const SELECTED = 'selected';
const DESELECTED = 'deselected';

type SelectedState = typeof SELECTED | typeof DESELECTED;

type ThresholdProps<T = unknown> = {
  data: TimeSeriesIntervalPlotData<T>;
  showPointEstimate: boolean;
  selectedState: SelectedState;
  yMax: number;
  x: (value: NumberValue) => number;
  y: (value: NumberValue) => number;
};

const ThresholdLine = <T = unknown,>({ data, selectedState, showPointEstimate, yMax, x, y }: ThresholdProps<T>) => (
  <>
    <Threshold<PlotPoint<T>>
      key={`threshold-${data.name}`}
      id={`difference-plot-threshold-${data.name}`}
      data={data.values}
      x={(d) => x(d.timestamp) ?? 0}
      y0={(d) => y(d.lowerBound) ?? 0}
      y1={(d) => y(d.upperBound) ?? 0}
      clipAboveTo={0}
      clipBelowTo={yMax}
      curve={curveBasis}
      belowAreaProps={{
        fill: data.color,
        fillOpacity: 0.2,
      }}
      aboveAreaProps={{
        fill: data.color,
        fillOpacity: 0.2,
      }}
      className={cx({
        [styles['linePath-deselected']]: selectedState === DESELECTED,
      })}
    />
    ;
    {showPointEstimate && (
      <LinePath
        key={`line-${data.name}`}
        id={`difference-plot-line-${data.name}`}
        data={data.values}
        x={(d: PlotPoint<T>) => x(d.timestamp) ?? 0}
        y={(d: PlotPoint<T>) => y(d?.pointEstimate) ?? 0}
        stroke={data.color}
        curve={curveBasis}
        strokeWidth={2}
        className={cx({
          [styles['linePath-deselected']]: selectedState === DESELECTED,
        })}
      />
    )}
  </>
);

type InteractivityProps = {
  deselectedPlots: string[];
};

const Plot = <T = unknown,>({
  data,
  width,
  height,
  yLabel,
  xLabel,
  showPointEstimate = false,
  deselectedPlots,
  showGridColumns = false,
  showYAxisTicks = false,
  formatXAxisTickLabel,
  renderTooltip,
  showTooltip,
  hideTooltip,
  tooltipData,
  tooltipTop = 0,
  tooltipLeft = 0,
}: TimeSeriesIntervalPlotProps<T> &
  WithTooltipProvidedProps<TimeSeriesIntervalPlotTooltipData<T>> &
  InteractivityProps) => {
  const leftMargin = showYAxisTicks ? margin.left + 20 : margin.left;
  const bottomMargin = !!xLabel ? margin.bottom : margin.bottom - 20;
  const xMax = width - leftMargin - margin.right;
  const yMax = height - margin.top - bottomMargin;

  const x = scaleUtc<number>({ range: [X0, xMax] });
  const y = scaleLinear<number>({ range: [yMax, 0] });

  const merged = useMemo(() => data.reduce<Array<PlotPoint<T>>>((acc, { values }) => acc.concat(values), []), [data]);

  const xDomain = extent(merged, (d) => d.timestamp);
  const yDomain = [
    Math.min(...merged.map((d) => d.lowerBound)) * 0.95,
    Math.max(...merged.map((d) => d.upperBound)) * 1.05,
  ];

  const hasXDomain = typeof xDomain[0] !== 'undefined' && typeof xDomain[1] !== 'undefined';
  const hasYDomain = typeof yDomain[0] !== 'undefined' && typeof yDomain[1] !== 'undefined';

  if (hasXDomain) {
    x.domain(xDomain);
  }
  if (hasYDomain) {
    y.domain(yDomain);
  }

  const handleTooltip = useCallback(
    (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
      const bisectDate = bisector<PlotPoint<T>, Date>((d) => new Date(d.timestamp)).left;
      const { x: xLocalPoint, y: yLocalPoint } = localPoint(event) || { x: 0 };
      const x0 = x.invert(xLocalPoint - leftMargin);

      const tooltip: TimeSeriesIntervalPlotTooltipData<T> = {
        timestamp: x0.valueOf(),
        nearestPoints: [],
      };

      for (const dataset of data) {
        const indexInDataset = bisectDate(dataset.values, x0, 1);
        const point = dataset.values[indexInDataset - 1];
        const nextPoint = dataset.values[indexInDataset];
        let pointAtHover = point;
        if (!!nextPoint?.timestamp) {
          pointAtHover =
            x0.valueOf() - point.timestamp.valueOf() > point.timestamp.valueOf() - x0.valueOf() ? nextPoint : point;
        }

        tooltip.nearestPoints.push({
          value: pointAtHover,
          color: dataset.color,
          name: dataset.name,
        });
      }

      if (tooltip.nearestPoints.length) {
        showTooltip({
          tooltipData: tooltip,
          tooltipLeft: xLocalPoint,
          tooltipTop: yLocalPoint,
        });
      }
    },
    [showTooltip, x, data, leftMargin],
  );

  const formatLabel = (tick: Date | NumberValue): string =>
    formatXAxisTickLabel ? formatXAxisTickLabel(tick) : format(tick.valueOf(), DateFormat.MMM_D);

  const getStatus = (name: string) => (deselectedPlots.includes(name) ? DESELECTED : SELECTED);

  return (
    <div className={styles.plotContainer}>
      <svg width={width} height={height} onMouseLeave={hideTooltip} onTouchCancel={hideTooltip}>
        <rect x={0} y={0} width={width} height={height} fill="var(--lp-color-bg-ui-primary)" rx={14} />
        <Group left={leftMargin} top={margin.top}>
          <GridRows scale={y} width={xMax} height={yMax} stroke="var(--lp-color-bg-ui-secondary)" />
          {showGridColumns && <GridColumns scale={x} width={xMax} height={yMax} />}
          <AxisLeft
            scale={y}
            label={yLabel}
            labelClassName={styles.axisLabel}
            tickValues={showYAxisTicks && hasYDomain ? undefined : []}
            labelOffset={showYAxisTicks ? 50 : 20}
            numTicks={6}
            stroke="var(--lp-color-border-ui-primary)"
            tickLabelProps={() => ({
              fill: 'var(--lp-color-text-ui-secondary)',
              textAnchor: 'end',
              verticalAnchor: 'middle',
              marginTop: 8,
              className: styles.tickLabel,
              scaleToFit: true,
            })}
            labelProps={{
              textAnchor: 'middle',
              width: height - margin.top - bottomMargin,
              scaleToFit: true,
              className: cx(styles.xAxisLabel, styles.axisLabel),
              style: {
                width: height - margin.top - bottomMargin,
              },
            }}
            tickLineProps={{
              stroke: 'var(--lp-color-border-ui-primary)',
            }}
          />
          <AxisBottom
            scale={x}
            top={yMax}
            label={xLabel}
            stroke="var(--lp-color-border-ui-primary)"
            labelClassName={styles.axisLabel}
            tickLabelProps={() => ({
              fill: 'var(--lp-color-text-ui-secondary)',
              textAnchor: 'middle',
              className: styles.tickLabel,
              width: 80,
              dy: 8,
            })}
            tickValues={hasXDomain ? undefined : []}
            tickFormat={formatLabel}
            hideTicks
            labelOffset={30}
            numTicks={xMax / 150}
          />

          {data.map((dataset) => (
            <ThresholdLine<T>
              key={`threshold-${dataset.name}`}
              data={dataset}
              showPointEstimate={showPointEstimate}
              yMax={yMax}
              x={x}
              y={y}
              selectedState={getStatus(dataset.name)}
            />
          ))}
        </Group>
        <Bar
          x={leftMargin}
          y={margin.top}
          width={xMax}
          height={yMax}
          fill="transparent"
          rx={14}
          onMouseEnter={handleTooltip}
          onTouchStart={handleTooltip}
          onTouchMove={handleTooltip}
          onMouseMove={handleTooltip}
        />
        {tooltipData && (
          <Line
            from={{ x: tooltipLeft, y: margin.top }}
            to={{ x: tooltipLeft, y: yMax + margin.top }}
            stroke="black"
            strokeWidth={1}
            pointerEvents="none"
            z={1000}
          />
        )}
      </svg>

      {tooltipData && (
        <>
          <Tooltip
            top={yMax + margin.top - 10}
            left={tooltipLeft}
            style={{
              ...tooltipStyles,
            }}
            className={styles.timeTooltip}
          >
            <Time datetime={tooltipData.timestamp} dateFormat={DateFormat.MMM_D_H_MM_A} />
          </Tooltip>
          {renderTooltip && (
            <TooltipWithBounds
              key={Math.random()}
              top={(tooltipTop || 0) - 12}
              left={(tooltipLeft || 0) + 12}
              style={{
                ...tooltipStyles,
                maxWidth: '500px',
              }}
              className={styles.detailsTooltip}
            >
              {renderTooltip(tooltipData)}
            </TooltipWithBounds>
          )}
        </>
      )}
    </div>
  );
};

const PlotWithTooltip = <T = unknown,>(props: TimeSeriesIntervalPlotProps<T> & InteractivityProps) =>
  withTooltip<TimeSeriesIntervalPlotProps<T> & InteractivityProps, TimeSeriesIntervalPlotTooltipData<T>>(
    (withTooltipProps) => <Plot {...withTooltipProps} />,
  )(props);

export const TimeSeriesIntervalPlot = <T = unknown,>(props: TimeSeriesIntervalPlotProps<T>) => {
  const [deselectedPlots, setDeselectedPlots] = useState<string[]>([]);
  const legendScale = props.data.reduce<LegendScale>(
    (acc, { name, color }) => {
      acc.domain.push(color);
      acc.range.push(name);

      return acc;
    },
    {
      domain: [],
      range: [],
    },
  );

  const handleSelect = (name: string) => {
    if (deselectedPlots.includes(name)) {
      const deselected = deselectedPlots.filter((s: string) => s !== name);
      setDeselectedPlots(deselected);
      return window.localStorage.setItem(props.yLabel || '', JSON.stringify(deselected));
    }
    setDeselectedPlots([...deselectedPlots, name]);
    return window.localStorage.setItem(props.yLabel || '', JSON.stringify([...deselectedPlots, name]));
  };

  return (
    <>
      <div className={styles.plotTitleContainer}>
        <h2 className={styles.plotTitle}>{props.title}</h2>
        {!props.hideLegend && (
          <TimeSeriesIntervalPlotLegend
            scale={legendScale}
            onSelectDataset={handleSelect}
            deselectedPlots={deselectedPlots}
          />
        )}
      </div>
      <PlotWithTooltip {...props} deselectedPlots={deselectedPlots} />
    </>
  );
};

export const TimeSeriesIntervalPlotResponsive = <T = unknown,>(
  props: Omit<TimeSeriesIntervalPlotProps<T>, 'height' | 'width'>,
) => (
  <ParentSize>{({ height, width }) => <TimeSeriesIntervalPlot height={height} width={width} {...props} />}</ParentSize>
);
