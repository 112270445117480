import { enableGuidedMetricCreation } from '@gonfalon/dogfood-flags';
import { Metric } from '@gonfalon/metrics';

import { NewMetricForm } from '../common';

import { GuidedMetricCreationModal } from './GuidedMetricCreationModal';
import { LegacyCreateMetricModal } from './LegacyCreateMetricModal';

export function CreateMetricModal({
  onCancel,
  onSuccess,
  defaultValues,
}: {
  onCancel: () => void;
  onSuccess?: (metric: Metric) => void;
  defaultValues?: Partial<NewMetricForm>;
}) {
  return enableGuidedMetricCreation() ? (
    <GuidedMetricCreationModal onCancel={onCancel} onSuccess={onSuccess} defaultValues={defaultValues} />
  ) : (
    <LegacyCreateMetricModal onCancel={onCancel} onSuccess={onSuccess} defaultValues={defaultValues} />
  );
}
