import { AuditLogEntryRep } from '@gonfalon/audit-log';
import { noop } from 'lodash';

import { fetchAccount as fetchAccountAction } from 'actions/account';
import { findEntries as findEntriesAction, saveAuditLogQuery as saveAuditLogQueryAction } from 'actions/auditLog';
import { useDispatch } from 'hooks/useDispatch';
import { useSelector } from 'hooks/useSelector';
import { GlobalState } from 'reducers';
import { accountSelector } from 'reducers/account';
import { auditLogEntriesSelector, auditLogPaginationSelector, auditLogQuerySelector } from 'reducers/auditLog';
import { projectsSelector } from 'reducers/projects';
import { Query } from 'utils/auditLogUtils';
import { nilFilter } from 'utils/collectionUtils';
import { ready } from 'utils/reduxUtils';

export const useResoureHistoryListContainer = (query: Query, pathname: string) => {
  const dispatch = useDispatch();

  const accountEntityState = useSelector(accountSelector);
  const account = accountEntityState?.get('entity');
  const projects = useSelector(projectsSelector)?.get('entities');
  const auditLogEntities = useSelector(auditLogEntriesSelector);
  const pagination = useSelector((state: GlobalState) => auditLogPaginationSelector(state, { query }));
  const savedQuery = useSelector(auditLogQuerySelector)?.get(pathname);

  const resourcesReady = ready(accountEntityState);

  const fetchAccount = () => {
    dispatch(fetchAccountAction()).then(noop, noop);
  };
  const findEntries = (q: Query, nextPage?: boolean) => {
    dispatch(findEntriesAction(q, nextPage)).then(noop, noop);
  };
  const saveAuditLogQuery = (q: Query) => dispatch(saveAuditLogQueryAction(q));

  let isReady = false;
  let hasError = false;
  let entries: AuditLogEntryRep[] = [];

  if (!pagination) {
    isReady = false;
    hasError = false;
  } else {
    isReady = pagination.get('pageCount') === 0 ? resourcesReady && !pagination.get('isFetching') : resourcesReady;
    hasError = !!pagination.get('error');
    entries = pagination
      .get('ids')
      .map((id: string) => auditLogEntities.get(id))
      .toList()
      .filter(nilFilter)
      .toJS();
  }

  return {
    isReady,
    hasError,
    entries,
    account,
    projects,
    savedQuery,
    fetchAccount,
    findEntries,
    saveAuditLogQuery,
  };
};
