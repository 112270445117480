import { lazy, ReactNode, Suspense } from 'react';

import StaticSortableItem from './StaticSortableItem';

const DynamicSortableItem = lazy(async () =>
  import(/* webpackChunkName: "sortable-dynamic" */ './dynamic').then((module) => ({
    default: module.DynamicSortableItem,
  })),
);

export type SortableItemProps = {
  children?: ReactNode;
  isDraggable: boolean;
  itemId?: string;
  type: string;
  hasHandle: boolean;
  hasBorder?: boolean;
  getSortIndex(key: string): number;
  onChangeSortIndex(key: string, newIndex: number): void;
};

export function SortableItem(props: SortableItemProps) {
  return (
    <Suspense fallback={<StaticSortableItem {...props} />}>
      <DynamicSortableItem {...props} />
    </Suspense>
  );
}
