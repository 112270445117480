import { type UIFlagFilter } from '@gonfalon/flag-filters';

import { flagEnvironmentFilters } from './flagEnvironmentFilters';

export function countActiveEnvironmentFilters(filters: UIFlagFilter | undefined) {
  if (filters === undefined) {
    return 0;
  }

  let count = 0;

  for (const key of flagEnvironmentFilters) {
    if (filters[key] === undefined) {
      continue;
    }

    const value = filters[key];

    if (typeof value === 'string') {
      if (value !== '') {
        count++;
        continue;
      }
    }

    if (Array.isArray(value)) {
      if (value.length > 0) {
        count++;
        continue;
      }
    }

    if (value !== undefined) {
      count++;
      continue;
    }

    count++;
  }

  return count;
}
