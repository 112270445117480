import { Metric } from '@gonfalon/metrics';
import { metricsList } from '@gonfalon/rest-api';
import { useProjectKey } from '@gonfalon/router';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { requestErrorNotification } from 'components/experimentation/common/utils/errors';
import http, { middleware } from 'utils/httpUtils';

export function useCreateMetric() {
  const queryClient = useQueryClient();
  const projKey = useProjectKey();

  return useMutation({
    mutationFn: createMetric(projKey),
    onError: requestErrorNotification('Error creating metric'),
    onSuccess: async () => queryClient.invalidateQueries({ queryKey: metricsList({ projectKey: projKey }).queryKey }),
  });
}

function createMetric(projKey: string) {
  return async function (metric: Partial<Metric>): Promise<Metric> {
    return http
      .post(`/api/v2/metrics/${projKey}`, {
        body: JSON.stringify(metric),
        beta: true,
      })
      .then(middleware.json);
  };
}
