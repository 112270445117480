import { AuditLogEntryRep } from '@gonfalon/audit-log';
import { showImportantAuditLogEntries } from '@gonfalon/dogfood-flags';

import { fetchSignificantFlagEventsForAuditLogEntries } from 'actions/auditLog';
import { useDispatch } from 'hooks/useDispatch';
import { useSelector } from 'hooks/useSelector';
import { auditLogSignificantEventsSelector } from 'reducers/auditLog';
import { currentEnvironmentKeySelector, currentProjectKeySelector } from 'reducers/projects';

export const useSignificantEvents = () => {
  const environmentKey = useSelector(currentEnvironmentKeySelector);
  const projectKey = useSelector(currentProjectKeySelector);
  const auditLogSignificantEvents = useSelector(auditLogSignificantEventsSelector);
  const showSignificantEntries = showImportantAuditLogEntries();
  const dispatch = useDispatch();

  const fetchSignificantEntries = async (entries: AuditLogEntryRep[]) =>
    dispatch(fetchSignificantFlagEventsForAuditLogEntries(entries, projectKey, environmentKey));
  return {
    showSignificantEntries,
    fetchSignificantEntries,
    significantEvents: auditLogSignificantEvents.get('entities'),
  };
};
