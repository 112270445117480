import { useState } from 'react';
import { toCreateReleasePipeline } from '@gonfalon/navigator';
import {
  trackAddReleasePipelineButtonClicked,
  trackCreateReleasePipelineButtonClickedFromOverview,
} from '@gonfalon/release-pipelines';
import { useProjectKey } from '@gonfalon/router';
import { LinkButton } from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'launchpad';

import DocumentationLink from 'components/DocumentationLink';
import { getDocumentationUrl } from 'utils/urlUtils';

export function LegacyCreateReleasePipelineFlow() {
  const [showModal, setShowModal] = useState(false);
  const projectKey = useProjectKey();

  return (
    <>
      <Button
        icon={<Icon name="arrow-connect" size="small" />}
        renderIconFirst
        onClick={() => {
          setShowModal(true);
          trackAddReleasePipelineButtonClicked();
        }}
      >
        Add release
      </Button>

      {showModal && (
        <Modal onCancel={() => setShowModal(false)}>
          <ModalHeader title="Create release pipeline" />
          <ModalBody>
            <p>
              There are no release pipelines in this project.{' '}
              <DocumentationLink
                href={getDocumentationUrl('home/releases/release-pipelines')}
                text="Learn more"
                component="CreateReleasePipelineFlow"
              />
            </p>
          </ModalBody>
          <ModalFooter
            primaryButton={
              <LinkButton
                variant="primaryFlair"
                href={toCreateReleasePipeline({ projectKey })}
                onPress={() => trackCreateReleasePipelineButtonClickedFromOverview()}
                target="_blank"
                data-test-id="create-release-pipeline-button"
              >
                Create release pipeline
              </LinkButton>
            }
            secondaryButton={<Button onClick={() => setShowModal(false)}>Cancel</Button>}
          />
        </Modal>
      )}
    </>
  );
}
