import { useMemo } from 'react';
import { EnvironmentMarker } from '@gonfalon/environments';
import { schemas } from '@gonfalon/openapi';
import { useEnvironments } from '@gonfalon/rest-api';
import { useProjectKey } from '@gonfalon/router';
import { Icon } from '@launchpad-ui/icons';
import { Chip, Tooltip } from 'launchpad';

import styles from './EventLastSeenTable.module.css';

type EventActivityResponseItem = schemas['EventActivityResponseItemRep'];
type LastSeenEnvironment = EventActivityResponseItem['environment'];

export function EventLastSeenTable({ items }: { items: EventActivityResponseItem[] }) {
  const projectKey = useProjectKey();
  const { data: environments } = useEnvironments({ projectKey });

  const { recent, older } = useMemo(
    () =>
      items.reduce<{
        recent: LastSeenEnvironment[];
        older: LastSeenEnvironment[];
      }>(
        (acc, item) => {
          if (item.lastSeen > Date.now() - 24 * 60 * 60 * 1000) {
            acc.recent.push(item.environment);
          } else {
            acc.older.push(item.environment);
          }
          return acc;
        },
        { recent: [], older: [] },
      ),
    [items],
  );

  const never: LastSeenEnvironment[] = useMemo(
    () =>
      environments?.items
        .filter(
          (env) =>
            !recent.some((item) => item.environmentKey === env.key) &&
            !older.some((item) => item.environmentKey === env.key),
        )
        .map((env) => ({
          color: env.color,
          name: env.name,
          environmentKey: env.key,
        })) ?? [],
    [environments?.items, recent, older],
  );

  // if it hasn't been seen anywhere ever, keep the message simple
  if (items.length === 0) {
    return (
      <div className={styles.group}>
        <div className={styles.item}>
          <Icon name="circle-dashed" size="small" />
          <p>No environments have received this event</p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.group}>
      <EventLastSeenTableItem environments={recent} icon={<Icon name="pulse" size="small" className={styles.active} />}>
        {haveOrHas(recent)} received events
      </EventLastSeenTableItem>
      <EventLastSeenTableItem environments={older} icon={<Icon name="half-circle" size="small" />}>
        {haveOrHas(older)} not received events in the last 24 hours
      </EventLastSeenTableItem>
      <EventLastSeenTableItem environments={never} icon={<Icon name="circle-dashed" size="small" />}>
        {haveOrHas(never)} not received events
      </EventLastSeenTableItem>
    </div>
  );
}

function haveOrHas<T>(arr: T[]) {
  return arr.length > 1 ? 'have' : 'has';
}

function EventLastSeenTableItem({
  environments,
  icon,
  children,
}: {
  environments: LastSeenEnvironment[];
  icon: JSX.Element;
  children: React.ReactNode;
}) {
  if (environments.length === 0) {
    return null;
  }

  if (environments.length === 1) {
    return (
      <div className={styles.item}>
        {icon}
        <Chip className="u-flex u-gap-1">
          <EnvironmentMarker color={environments[0].color} /> {environments[0].name}
        </Chip>{' '}
        {children}
      </div>
    );
  }

  return (
    <div className={styles.item}>
      {icon}
      <div>
        <Tooltip placement="top">
          <span className={styles.tooltipTarget}>
            {environments.length} {environments.length > 1 ? 'environments' : 'environment'}
          </span>
          <EnvironmentsPreview environments={environments} />
        </Tooltip>{' '}
        {children}
      </div>
    </div>
  );
}

function EnvironmentsPreview({ environments }: { environments: LastSeenEnvironment[] }) {
  return (
    <div className="u-flex u-flex-middle u-gap-1">
      {environments.slice(0, 3).map((env) => (
        <div key={env.environmentKey} className="u-flex u-flex-middle">
          <EnvironmentMarker color={env.color} />
          <span className="u-ml-s">{env.name}</span>
        </div>
      ))}
      {environments.length > 3 && <span className={styles.more}>+{environments.length - 3}</span>}
    </div>
  );
}
