import { CustomSelect, OptionProps } from '@gonfalon/launchpad-experimental';

import SelectTeamOrMemberOption from 'components/SelectTeamOrMember/SelectTeamOrMemberOption';
import SelectTeamOrMemberSingleValue from 'components/SelectTeamOrMember/SelectTeamOrMemberSingleValue';
import {
  findSelectedTeamOrMemberFromOptions,
  NestedTeamOrMemberOptionsType,
  TeamOrMemberOptionType,
} from 'components/SelectTeamOrMember/SelectTeamOrMemberUtils';

import './SelectTeamOrMember.css';

export type SelectTeamOrMemberProps = {
  disabled?: boolean;
  id?: string;
  onChange(selectedOption: OptionProps): void;
  onInputChange(input: string): void;
  options: TeamOrMemberOptionType[] | NestedTeamOrMemberOptionsType[];
  placeholder?: string;
  value?: string;
  isLoading?: boolean;
  className?: string;
};

const SelectTeamOrMember = ({
  disabled,
  id,
  onChange,
  onInputChange,
  options,
  placeholder,
  value = '',
  isLoading = false,
  className,
}: SelectTeamOrMemberProps) => {
  const selected = findSelectedTeamOrMemberFromOptions(value, options);

  const styles = {
    group: {
      margin: '0 0.625rem 0.625rem',
      paddingBottom: '0.625rem',
    },
    groupHeading: {
      color: 'var(--lp-color-text-ui-secondary)',
      fontSize: 'var(--lp-font-size-200)',
      letterSpacing: '1px',
      marginLeft: '0',
      paddingLeft: '0',
    },
    option: {
      padding: '0.3125rem',
    },
  };

  return (
    <div data-test-id="select-team-or-member" className={className}>
      <CustomSelect
        classNamePrefix="SelectTeamOrMember"
        ariaLabel="Select a team or member"
        isLoading={isLoading}
        customComponents={{ SingleValue: SelectTeamOrMemberSingleValue }}
        disabled={disabled}
        filterOption={() => true}
        formatOptionLabel={(option) => (
          <SelectTeamOrMemberOption option={option as unknown as TeamOrMemberOptionType} />
        )}
        getOptionLabel={(option: TeamOrMemberOptionType) => {
          if (option.team) {
            return `Team: ${option.team.getDisplayName()}`;
          }

          if (option.member) {
            return `Member: ${option.member.getDisplayName()}`;
          }

          return '';
        }}
        id={id}
        noOptionsMessage={() => 'No teams or members found'}
        onChange={onChange}
        onInputChange={onInputChange}
        options={options}
        placeholder={placeholder}
        styles={styles}
        value={selected}
      />
    </div>
  );
};

/* eslint-disable import/no-default-export */
export default SelectTeamOrMember;
