import { Metric } from '@gonfalon/metrics';

import MetricKind, { MetricGroupKind } from 'utils/MetricKind';

export const MAX_METRIC_KEY_LENGTH = 256;
export const MAX_METRIC_GROUP_KEY_LENGTH = 256;

export type MetricEventUrlKind = 'canonical' | 'exact' | 'regex' | 'substring';

export type MetricEventUrl =
  | {
      kind: 'canonical' | 'exact';
      url: string;
      pattern?: never;
      substring?: never;
    }
  | {
      kind: 'regex';
      pattern: string;
      url?: never;
      substring?: never;
    }
  | {
      kind: 'substring';
      substring: string;
      url?: never;
      pattern?: never;
    };

export type FilterableMetricDashboardItem = {
  type: 'metric' | 'metric-group';
  key: string;
  name: string;
  description?: string;
  _creationDate: number;
  experimentCount?: number;
  kind: MetricKind | MetricGroupKind;
  isNumeric?: boolean;
  tags: string[];
  _attachedFlagCount?: number;
  metricGroupCount?: number;
  metricCount?: number;
};

export function isMetricEventUrl(url: unknown): url is MetricEventUrl {
  if (!(typeof url === 'object' && url !== null && 'kind' in url)) {
    return false;
  }

  if (typeof url.kind !== 'string') {
    return false;
  }

  if (['canonical', 'exact'].includes(url.kind) && 'url' in url && typeof url.url === 'string') {
    return true;
  }

  if (url.kind === 'regex' && 'pattern' in url && typeof url.pattern === 'string') {
    return true;
  }

  if (url.kind === 'substring' && 'substring' in url && typeof url.substring === 'string') {
    return true;
  }

  return false;
}

// fields that are editable in the metric details form
export type EditMetricForm = {
  name: string;
  description?: string;
  eventKey?: string;
  unit?: string;
  successCriteria?: Metric['successCriteria'];
  urls?: Array<{ kind: MetricEventUrlKind; value: string }>;
  selector?: string;
  tags?: Array<{ label: string; value: string }> | null;
  randomizationUnits: Array<{ label: string; value: string }>;
  unitAggregationType: 'average' | 'sum';
  analysisType: 'mean' | 'percentile';
  percentileValue?: number;
  eventDefault: { disabled: boolean; value?: number };
  maintainerId?: string;
};

// fields that are included in the create metric modal
export type NewMetricForm = EditMetricForm & {
  key: string;
  kind: 'click' | 'pageview' | 'custom';
  isNumeric?: boolean;
  maintainerId: string;
};

export function toMetricEventUrl({ kind, value }: { kind: MetricEventUrlKind; value: string }): MetricEventUrl {
  switch (kind) {
    case 'regex':
      return { kind, pattern: value };
    case 'substring':
      return { kind, substring: value };
    case 'canonical':
    // fallthrough
    case 'exact':
      return { kind, url: value };
    default:
      throw new Error(`Unknown metric event url kind: ${kind}`);
  }
}

export function fromMetric(metric: Metric): EditMetricForm {
  const { urls, randomizationUnits, tags, ...rest } = metric;
  return {
    ...rest,
    randomizationUnits: randomizationUnits?.map((value) => ({ label: value, value })) ?? [],
    tags: tags?.map((value) => ({ label: value, value })),
    urls: urls?.filter(isMetricEventUrl).map(fromMetricEventUrl),
    unitAggregationType: rest?.unitAggregationType ?? 'average',
    analysisType: rest?.analysisType ?? 'mean',
    eventDefault: {
      disabled: rest.eventDefault?.disabled ?? false,
      ...(rest.eventDefault?.value !== undefined && { value: rest.eventDefault.value }),
    },
  };
}

export function fromMetricEventUrl({ kind, pattern, substring, url }: MetricEventUrl): {
  kind: MetricEventUrlKind;
  value: string;
} {
  switch (kind) {
    case 'regex':
      return { kind, value: pattern };
    case 'substring':
      return { kind, value: substring };
    case 'canonical':
    // fallthrough
    case 'exact':
      return { kind, value: url };
    default:
      throw new Error(`Unknown metric event url kind: ${kind}`);
  }
}

export const METRIC_ANALYTICS_CHART_TYPES = {
  LINE: 'LINE' as const,
  BAR: 'BAR' as const,
};

export type MetricAnalyticsChartType = keyof typeof METRIC_ANALYTICS_CHART_TYPES;

export type MetricAnalyticsTableRowValue = {
  analysisType: string;
  analysisValue?: number;
  average?: number;
  color: string;
  groupByKey?: string;
  groupByValue?: string;
  isHigherBetter: boolean;
  metricKey: string;
  metricName: string;
  numberOfEvents: number;
  originalIndex: number;
  total?: number;
  trend: { currentPeriod?: number; percentageChange: string | number; previousPeriod?: number };
  unit?: string;
};

export const SUCCESS_CRITERIA_DISPLAY_NAMES = {
  higherIsBetter: 'higher is better',
  lowerIsBetter: 'lower is better',
} as const;

export const UNIT_AGGREGATION_TYPE_DISPLAY_NAMES = {
  average: 'average',
  sum: 'sum',
} as const;
