import { useMemo } from 'react';
import { useMatches } from 'react-router-dom';
import { z } from 'zod';

const actionSchema = z.object({
  Action: z.function().returns(z.any()),
});

/**
 * Render an action for the current route.
 *
 * A route defines its action via its `handle`.
 */
export function Action() {
  const matches = useMatches();
  const currentRoute = matches.at(-1);

  const RouteAction = useMemo(() => {
    if (currentRoute === undefined) {
      return null;
    }

    const parse = actionSchema.safeParse(currentRoute.handle);
    if (!parse.success) {
      return null;
    }

    return parse.data.Action;
  }, [currentRoute]);

  return RouteAction ? (
    <div>
      <RouteAction />
    </div>
  ) : null;
}
