import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useDispatch, useSelector } from 'react-redux';
import { noop } from '@gonfalon/es6-utils';

import { fetchContextAttributesIfNeeded as fetchContextAttributesAction } from 'actions/contextAttributes';
import { SelectContextAttribute } from 'components/selectAttributes/SelectContextAttribute';
import { GlobalDispatch, GlobalState } from 'reducers';
import { contextAttributesNamesRequestSelector, contextAttributesNamesSelector } from 'reducers/contextAttributes';
import { currentEnvironmentKeySelector, currentProjectKeySelector } from 'reducers/projects';
import { Clause } from 'utils/clauseUtils';
import { USER_CONTEXT_KIND } from 'utils/constants';
import { ready } from 'utils/reduxUtils';
import { SegmentRule as RuleProps } from 'utils/segmentUtils';

export type SelectAttributeContainerProps = {
  className?: string;
  isClearable?: boolean;
  backspaceRemoves?: boolean;
  deleteRemoves?: boolean;
  disabled?: boolean;
  specialAttributeOptions?: Array<{ label: string; value: string }>;
  value?: string | null;
  contextKind?: string;
  withSegmentAttribute?: boolean;
  onChange(prop?: RuleProps | Clause | string | null, prop1?: string): void;
  placeholder?: string;
  ariaLabel?: string;
  isInPortal?: boolean;
  id?: string;
};

export const useRedux = (contextKind: string) => {
  const projKey = useSelector(currentProjectKeySelector);
  const envKey = useSelector(currentEnvironmentKeySelector);
  const contextAttributeNames = useSelector((state: GlobalState) => contextAttributesNamesSelector(state, contextKind));
  const contextAttributeNamesRequest = useSelector((state: GlobalState) =>
    contextAttributesNamesRequestSelector(state, contextKind),
  );
  const dispatch = useDispatch<GlobalDispatch>();
  const fetchContextAttributes = async (kind: string, prefix: string) =>
    dispatch(fetchContextAttributesAction(projKey, envKey, kind, prefix));
  const isReady = ready(contextAttributeNamesRequest);
  return {
    fetchContextAttributes,
    isReady,
    contextAttributeNames,
  };
};

const SelectAttributeContainer = ({
  value = null,
  contextKind: contextKindProp,
  onChange,
  className,
  isClearable,
  backspaceRemoves,
  deleteRemoves,
  disabled,
  placeholder,
  ariaLabel,
  isInPortal = false,
  id,
}: SelectAttributeContainerProps) => {
  // in case contextKind from props is an empty string
  const contextKind = contextKindProp || USER_CONTEXT_KIND;
  const { fetchContextAttributes, contextAttributeNames, isReady } = useRedux(contextKind);

  useEffect(() => {
    if (!isReady) {
      fetchContextAttributes(contextKind, '').catch(noop);
    }
  }, []);

  useEffect(() => {
    fetchContextAttributes(contextKind, '').catch(noop);
  }, [contextKind]);

  return (
    <>
      <SelectContextAttribute
        id={id}
        value={value === '' ? null : value}
        contextKind={contextKind}
        onChange={onChange}
        className={className}
        isClearable={isClearable}
        isReady={isReady}
        backspaceRemoves={backspaceRemoves}
        deleteRemoves={deleteRemoves}
        disabled={disabled}
        placeholder={placeholder}
        ariaLabel={ariaLabel}
        isInPortal={isInPortal}
        contextAttributeNames={contextAttributeNames}
      />
    </>
  );
};

/* eslint-disable import/no-default-export */
export default SelectAttributeContainer;
