import cx from 'clsx';

import GridSkeleton, { SkeletonCells } from 'components/skeletons/GridSkeleton';
import { AlignValue, Cell, CellLayoutValue, Grid, JustifyValue } from 'components/ui/grid';

import styles from './LegacyReleaseTracker.module.css';

function ReleasePipelinePhaseConnectorSVG({ hidden }: { hidden?: boolean }) {
  return (
    <svg
      className={cx({
        [styles.skeletonReleaseTrackerPhaseConnector]: !hidden,
        [styles.hiddenSkeletonReleaseTrackerPhaseConnector]: hidden,
      })}
      width="50"
      height="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="0" y1="8" x2="100%" y2="8" strokeWidth="2" />
    </svg>
  );
}

export function LegacyLoadingReleaseTrackerSkeleton() {
  const NUMBER_OF_SKELETON_CELLS = 3;

  return (
    <GridSkeleton direction="column" data-test-id="progress-indicator">
      <Grid justify={JustifyValue.START} align={AlignValue.MIDDLE} gap="0">
        {Array.from({ length: NUMBER_OF_SKELETON_CELLS }, (_, i) => (
          <Cell key={i} flex layout={CellLayoutValue.ONE_OF_TWELVE} className="u-flex-middle">
            <SkeletonCells cells={1} className={styles.rpSkeletonTrackerCell} />
            {i !== NUMBER_OF_SKELETON_CELLS - 1 ? (
              <ReleasePipelinePhaseConnectorSVG />
            ) : (
              <ReleasePipelinePhaseConnectorSVG hidden />
            )}
          </Cell>
        ))}
      </Grid>
    </GridSkeleton>
  );
}
