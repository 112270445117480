import { useMutation } from '@tanstack/react-query';

import { requestErrorNotification } from 'components/experimentation/common/utils/errors';

import { FlagsPayload, IterationBody, MetricsPayload, TreatmentsPayload } from '../../../api/api.types';
import { getFlag } from '../../../api/flag';
import { updateIteration } from '../../../api/iteration';
import { ExperimentV2, Treatment } from '../../../types';

type Iteration = ExperimentV2['currentIteration'];

type MutationProps = {
  iteration: Iteration;
  updatedFields?: IterationBody;
};
/**
 * Utility function updates the flag object on an iteration.
 *
 * @param param.projectKey - The project key
 * @param param.envKey - The environment key
 * @param param.flags - The iteration's flags to update
 */
const updateFlagConfigVersion = async ({
  projectKey,
  envKey,
  flags,
}: {
  projectKey: string;
  envKey: string;
  flags: FlagsPayload;
}) => {
  const flagsPayload: FlagsPayload = {};

  for (const [flagKey, val] of Object.entries(flags)) {
    const flag = await getFlag(projectKey, flagKey, envKey);

    flagsPayload[flagKey] = {
      ...val,
      flagConfigVersion: flag.environments[envKey].version,
    };
  }

  return flagsPayload;
};

/**
 * Utility function updates the treatment object on an iteration.
 *
 * @param params.treatment The treatment to update
 */
const updateTreatments = ({ treatments }: { treatments: Treatment[] }): TreatmentsPayload =>
  treatments.map((treatment) => ({
    name: treatment.name,
    baseline: Boolean(treatment.baseline),
    allocationPercent: treatment.allocationPercent,
    parameters:
      treatment.parameters?.map((param) => ({
        flagKey: param.flagKey || '',
        variationId: param.variationId || '',
      })) || [],
  }));

/**
 * Hook provides a mutation to update an iteration.
 *
 * @param param.projectKey - The project key
 * @param param.envKey - The environment key
 * @param param.experimentKey - The key of the experiment to update
 */
export function useUpdateIteration({
  projectKey,
  envKey,
  experimentKey,
}: {
  projectKey: string;
  envKey: string;
  experimentKey: string;
}) {
  const mutator = async ({ iteration, updatedFields }: MutationProps) => {
    const hypothesis = updatedFields?.hypothesis || iteration.hypothesis;
    const treatments =
      updatedFields?.treatments && updatedFields.treatments.length > 0
        ? updatedFields.treatments
        : updateTreatments({ treatments: iteration.treatments });

    const flags: FlagsPayload = { ...updatedFields?.flags };
    if (Object.keys(flags).length === 0) {
      for (const [flagKey, val] of Object.entries(iteration.flags)) {
        const flag = await getFlag(projectKey, flagKey, envKey);
        flags[flagKey] = {
          ruleId: val.targetingRule,
          notInExperimentVariationId: val.notInExperimentVariationId,
          flagConfigVersion: flag.environments[envKey].version,
        };
      }
    }

    const metrics: MetricsPayload = [];
    if (updatedFields?.metrics && updatedFields.metrics.length > 0) {
      metrics.push(...updatedFields.metrics);
    }
    if (metrics.length < 1) {
      metrics.push(...(iteration.metrics?.map(({ key, isGroup }) => ({ key, isGroup, primary: false })) ?? []));
    }

    const canReshuffleTraffic = updatedFields?.canReshuffleTraffic ?? iteration.canReshuffleTraffic;
    const reason = { winningReason: updatedFields?.winningReason ?? undefined };

    return updateIteration(projectKey, envKey, experimentKey, {
      hypothesis,
      canReshuffleTraffic,
      metrics,
      primarySingleMetricKey: updatedFields?.primarySingleMetricKey ?? iteration.primarySingleMetric?.key,
      primaryFunnelKey: updatedFields?.primaryFunnelKey ?? iteration.primaryFunnel?.key,
      attributes: updatedFields?.attributes ?? iteration.attributes,
      treatments,
      randomizationUnit: updatedFields?.randomizationUnit ?? iteration.randomizationUnit,
      flags: await updateFlagConfigVersion({ projectKey, envKey, flags }),
      ...reason,
    });
  };
  const mutation = useMutation({
    mutationFn: mutator,
    onError: requestErrorNotification('Could not update iteration'),
  });

  return {
    updateIteration: async ({ iteration, updatedFields }: MutationProps) =>
      mutation.mutateAsync({ iteration, updatedFields }),
  };
}
