import React, { useState } from 'react';
import { NewTabLink } from '@gonfalon/launchpad-experimental';
import { toReleasePipeline } from '@gonfalon/navigator';
import { trackAddReleasePipelineButtonClicked } from '@gonfalon/release-pipelines';
import { useProject, useReleasePipeline } from '@gonfalon/rest-api';
import { useProjectKey } from '@gonfalon/router';
import { ProgressBar } from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';
import clsx from 'clsx';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip } from 'launchpad';

import DocumentationLink from 'components/DocumentationLink';
import { getDocumentationUrl } from 'utils/urlUtils';

import { SelectReleasePipeline } from '../../common/StartReleasePipelineModal/SelectReleasePipeline/SelectReleasePipeline';
import { useStartRelease } from '../../common/useStartRelease';
import { LegacyReadOnlyReleaseTracker } from '../FlagReleaseSummary/LegacyReleaseTracker';

import styles from './LegacyAddReleasePipelineFlow.module.css';

export type LegacyAddReleasePipelineFlowProps = {
  flagKey: string;
  flagName?: string;
  buttonLabel?: string;
  setShowAddReleaseButton: (shouldShow: boolean) => void;
  setEnableReleaseQuery: (shouldEnable: boolean) => void;
  onAddReleaseStart?: () => void;
  onAddReleaseSuccess?: () => void;
  releasePipelineKey?: string;
};

export function LegacyAddReleasePipelineFlow({
  flagKey,
  flagName,
  buttonLabel = 'Add release',
  setShowAddReleaseButton,
  setEnableReleaseQuery,
  onAddReleaseStart,
  onAddReleaseSuccess,
  releasePipelineKey: providedReleasePipelineKey,
}: LegacyAddReleasePipelineFlowProps) {
  const projectKey = useProjectKey();
  const projectQuery = useProject({ projectKey });
  const [selectedReleasePipelineKey, setSelectedReleasePipelineKey] = useState<string | undefined>();
  const defaultReleasePipelineKey = projectQuery.data?.defaultReleasePipelineKey;
  // use either an externally provided key, the selected key from multiple options, or the default key
  const releasePipelineKey = providedReleasePipelineKey || selectedReleasePipelineKey || defaultReleasePipelineKey;
  const [showStartReleaseModal, setShowStartReleaseModal] = useState(false);
  const { startRelease } = useStartRelease({
    releasePipelineKey,
    onMutate() {
      closeModal();
      onAddReleaseStart?.();
    },
    onSuccess() {
      setShowAddReleaseButton(false);
      setEnableReleaseQuery(true);
      onAddReleaseSuccess?.();
    },
    onError() {
      setShowAddReleaseButton(true);
    },
  });

  function closeModal() {
    setShowStartReleaseModal(false);
  }

  // only show multiple release pipeline options if the feature is enabled, and we are not externally limited to one release pipeline
  const shouldShowMultipleReleasePipelines = !providedReleasePipelineKey;

  return (
    <>
      <Button
        icon={<Icon name="arrow-connect" size="small" />}
        renderIconFirst
        onClick={() => {
          setShowStartReleaseModal(true);
          trackAddReleasePipelineButtonClicked();
        }}
      >
        {buttonLabel}
      </Button>
      {showStartReleaseModal && (
        <LegacyStartReleasePipelineModal
          flagName={flagName || ''}
          onCancel={closeModal}
          onStart={() => startRelease(flagKey)}
          setSelectedReleasePipelineKey={setSelectedReleasePipelineKey}
          shouldShowMultipleReleasePipelines={shouldShowMultipleReleasePipelines}
          releasePipelineKey={releasePipelineKey}
        />
      )}
    </>
  );
}

export function LegacyStartReleasePipelineModal({
  flagName,
  onCancel,
  onStart,
  releasePipelineKey,
  setSelectedReleasePipelineKey,
  shouldShowMultipleReleasePipelines,
  startDisabled,
  previousReleasePipelineKey,
}: {
  flagName: string;
  onCancel: () => void;
  onStart: () => void;
  releasePipelineKey?: string;
  setSelectedReleasePipelineKey: React.Dispatch<React.SetStateAction<string | undefined>>;
  shouldShowMultipleReleasePipelines: boolean;
  startDisabled?: boolean;
  previousReleasePipelineKey?: string;
}) {
  const startOrMove = previousReleasePipelineKey ? 'Move' : 'Start';

  return (
    <Modal onCancel={onCancel}>
      {/* eslint-disable-next-line quotes */}
      <ModalHeader title={`${startOrMove} release${flagName ? ` for "${flagName}"` : ''}`} />
      <ModalBody className={styles.modalBody}>
        {shouldShowMultipleReleasePipelines ? (
          <MultipleReleasePipelines releasePipelineKey={releasePipelineKey} onChange={setSelectedReleasePipelineKey} />
        ) : (
          <SingleReleasePipeline releasePipelineKey={releasePipelineKey} />
        )}
      </ModalBody>
      <ModalFooter
        primaryButton={
          <Tooltip content={startDisabled ? 'Flag is already in selected release pipeline' : ''}>
            <Button kind="primary" onClick={onStart} data-test-id="start-release-button" disabled={startDisabled}>
              {startOrMove} release
            </Button>
          </Tooltip>
        }
        secondaryButton={<Button onClick={onCancel}>Cancel</Button>}
      />
    </Modal>
  );
}

function SingleReleasePipeline({ releasePipelineKey }: { releasePipelineKey: string | undefined }) {
  return (
    <>
      <ReleasePipelineLink releasePipelineKey={releasePipelineKey} />
      <ReleasePipelineSummary releasePipelineKey={releasePipelineKey} />
    </>
  );
}

function MultipleReleasePipelines({
  releasePipelineKey,
  onChange,
}: {
  releasePipelineKey: string | undefined;
  onChange: (releasePipelineKey?: string) => void;
}) {
  return (
    <>
      <SelectReleasePipeline
        className={styles.selectReleasePipeline}
        releasePipelineKey={releasePipelineKey}
        onChange={onChange}
      />
      <ReleasePipelineSummary releasePipelineKey={releasePipelineKey} />
      <div className={styles.blurb}>
        <p>Release pipelines provide visibility as you advance flags through your release with confidence.</p>
        <DocumentationLink
          href={getDocumentationUrl('home/releases/release-pipelines')}
          text="Learn more"
          component="AddReleasePipelineFlow"
        />
      </div>
    </>
  );
}

function ReleasePipelineLink({
  className,
  releasePipelineKey = '',
  text,
}: {
  className?: string;
  releasePipelineKey: string | undefined;
  text?: string;
}) {
  const projectKey = useProjectKey();
  const releasePipelineQuery = useReleasePipeline(
    { projectKey, releasePipelineKey },
    { enabled: !!releasePipelineKey && !!text },
  );

  const linkLabel = text ?? releasePipelineQuery.data?.name;

  return linkLabel ? (
    <NewTabLink
      className={clsx(styles.releasePipelineLink, className)}
      href={toReleasePipeline({ projectKey, releasePipelineKey }).pathname}
      text={linkLabel}
    />
  ) : null;
}

function ReleasePipelineSummary({ releasePipelineKey = '' }: { releasePipelineKey: string | undefined }) {
  const projectKey = useProjectKey();
  const releasePipelineQuery = useReleasePipeline(
    { projectKey, releasePipelineKey },
    { enabled: !!releasePipelineKey },
  );

  if (releasePipelineKey && releasePipelineQuery.isPending) {
    return (
      <div>
        <ProgressBar aria-label="Loading…" isIndeterminate data-test-id="progress-indicator" />
      </div>
    );
  }

  if (releasePipelineQuery.isSuccess) {
    return (
      <>
        {releasePipelineQuery.data.description && (
          <p className={styles.releasePipelineDescription} data-test-id="release-pipeline-description">
            {releasePipelineQuery.data.description}
          </p>
        )}
        <LegacyReadOnlyReleaseTracker
          className={styles.releasePipelinePhases}
          phases={releasePipelineQuery.data.phases.map((phase) => phase.name)}
        />
      </>
    );
  }

  return null;
}
