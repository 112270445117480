// eslint-disable-next-line no-restricted-imports
import { fromJS, OrderedMap } from 'immutable';
import { normalize, schema } from 'normalizr';

import { convertMapToOrderedMap } from 'utils/collectionUtils';
import http, { jsonToImmutable, jsonToImmutableError } from 'utils/httpUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { createJsonPatch } from 'utils/patchUtils';
import { createWebhook, Webhook } from 'utils/webhookUtils';

const webhooks = new schema.Entity('webhooks', {}, { idAttribute: '_id' });

export type WebhookResponse = ImmutableMap<{
  entities: ImmutableMap<{
    webhooks: OrderedMap<string, Webhook>;
  }>;
  result: ImmutableMap<{
    items: string[];
    totalCount: number;
  }>;
}>;

async function getAllWebhooks(): Promise<WebhookResponse> {
  return http
    .get('/api/v2/webhooks')
    .then(async (res) =>
      res.json().then((jsonRes) => {
        const data = normalize(jsonRes, { items: [webhooks] });
        return fromJS(data).withMutations((map: WebhookResponse) => {
          map.updateIn(['entities', 'webhooks'], (wh: ImmutableMap<Webhook>) =>
            convertMapToOrderedMap(wh, data.result.items),
          );
          map.updateIn(['entities', 'webhooks'], (wh: ImmutableMap<Webhook>) =>
            wh ? wh.map(createWebhook) : OrderedMap(),
          );
        });
      }),
    )
    .catch(jsonToImmutableError);
}

async function postWebhook(webhook: Webhook) {
  return http
    .post('/api/v2/webhooks', {
      body: webhook,
    })
    .then(jsonToImmutable)
    .then(createWebhook)
    .catch(jsonToImmutableError);
}

async function updateWebhook(oldWebhook: Webhook, newWebhook: Webhook) {
  const patch = createJsonPatch(oldWebhook, newWebhook);
  return http
    .patch(oldWebhook.selfLink(), {
      body: patch,
    })
    .then(jsonToImmutable)
    .then(createWebhook)
    .catch(jsonToImmutableError);
}

async function deleteWebhook(webhook: Webhook) {
  return http.delete(webhook.selfLink()).catch(jsonToImmutableError);
}

/* eslint-disable import/no-default-export */
export default {
  getAllWebhooks,
  createWebhook: postWebhook,
  updateWebhook,
  deleteWebhook,
};
