import { Icon } from '@launchpad-ui/icons';
import { Chip } from 'launchpad';

import { EventKind, getEventTypeLabel } from 'utils/liveEventUtils';
import Logger from 'utils/logUtils';
import MetricKind, { MetricGroupKind } from 'utils/MetricKind';

type MetricLabelProps = {
  kind: MetricKind | MetricGroupKind;
  isNumeric?: boolean;
};

const logger = Logger.get('MetricLabel');

const renderMetricIcon = (kind: MetricKind | MetricGroupKind) => {
  switch (kind) {
    case MetricKind.PAGEVIEW:
      return <Icon name="preview" />;
    case MetricKind.CLICK:
      return <Icon name="click" />;
    case MetricKind.CUSTOM:
      return <Icon name="code-circle" />;
    default:
      logger.warn(`Unable to render metric icon because metric kind is not recognised: ${kind}`);
      return undefined;
  }
};

const MetricLabel = ({ kind, isNumeric }: MetricLabelProps) => {
  let labelText = getEventTypeLabel(kind as string as EventKind);
  if (kind === MetricKind.CUSTOM) {
    labelText = `${getEventTypeLabel(kind as string as EventKind)}: ${isNumeric ? 'numeric' : 'conversion'}`;
  }

  return (
    <Chip size="tiny" icon={renderMetricIcon(kind)}>
      {labelText}
    </Chip>
  );
};

/* eslint-disable import/no-default-export */
export default MetricLabel;
