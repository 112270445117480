import http, { middleware } from 'utils/httpUtils';

import { AllMetricGroups, MetricGroup } from '../types';

import { CreateMetricGroupPayload } from './api.types';

/**
 * Fetches a single metric group
 */
export async function getMetricGroup(projKey: string, metricGroupKey: string) {
  const request = await http.get(
    `/api/v2/projects/${projKey}/metric-groups/${metricGroupKey}?expand=experiments,experimentCount`,
    { beta: true },
  );
  const json: MetricGroup = await middleware.json(request).catch(middleware.jsonError);
  return json;
}

/**
 * Fetches all metric groups in a project
 */
export async function getAllMetricGroups(projKey: string) {
  const request = await http.get(`/api/v2/projects/${projKey}/metric-groups`, { beta: true });
  const json: AllMetricGroups = await middleware.json(request).catch(middleware.jsonError);
  return json;
}

/**
 * Creates a new metric group
 */
export async function createMetricGroup(projKey: string, body: CreateMetricGroupPayload) {
  const request = await http.post(`/api/v2/projects/${projKey}/metric-groups`, { body, beta: true });
  const json: MetricGroup = await middleware.json(request).catch(middleware.jsonError);
  return json;
}

export async function patchMetricGroup(projKey: string, metricGroupKey: string, body: object) {
  const request = await http.patch(`/api/v2/projects/${projKey}/metric-groups/${metricGroupKey}`, { body, beta: true });
  const json: MetricGroup = await middleware.json(request).catch(middleware.jsonError);
  return json;
}

/**
 * Delete a metric group
 * */
export async function deleteMetricGroup(projKey: string, metricGroupKey: string) {
  await http
    .delete(`/api/v2/projects/${projKey}/metric-groups/${metricGroupKey}`, { beta: true })
    .catch(middleware.jsonError);
}
