import { Control, useFieldArray, useFormContext, useFormState, useWatch } from 'react-hook-form';
import { Icon } from '@launchpad-ui/icons';
import { Button, FieldError, FormField, FormHint, IconButton, Label, SelectField, TextField, Tooltip } from 'launchpad';

import { AccessDecision, allowDecision } from 'utils/accessUtils';
import { urlMatcherKindDisplayNames } from 'utils/goalUtils';
import { isAbsolute } from 'utils/urlUtils';

import { EditMetricForm, MetricEventUrlKind } from '../common';

import styles from '../CreateMetricModal/CreateMetricModal.module.css';

const formHints: { [k in MetricEventUrlKind]: string } = {
  canonical: 'Use "simple match" to target a single URL that does not include query or hash parameters',
  exact: 'Use "exact match" to target a single URL that specifies a query or hash parameter',
  substring: 'Use "substring match" to target all URLs that contain a specific string of text',
  regex: 'Use "regular expression" to target all URLs that match a regular expression',
};

type Props = {
  access?: AccessDecision;
  isSaving?: boolean;
};

export function LegacyMetricUrls({ access = allowDecision(), isSaving }: Props) {
  const { control, register } = useFormContext<EditMetricForm>();
  const { fields, append, remove } = useFieldArray({ control, name: 'urls' });
  const { errors } = useFormState<EditMetricForm>({ control, name: 'urls' });

  return (
    <Tooltip content={access.getModifyFieldReason()}>
      <div className="u-w-100 u-mt-m">
        {fields.map(({ id }, index) => (
          <div key={id} className="u-flex u-mb-l">
            <div>
              <FormField name="urls" htmlFor="urls" isRequired={false}>
                <div>
                  <div className="u-flex u-gap-2">
                    <div className="u-w5">
                      <Label>Target type</Label>
                      <SelectField
                        {...register(`urls.${index}.kind`)}
                        disabled={isSaving || !access.isAllowed}
                        aria-invalid={errors.urls?.[index] ? 'true' : 'false'}
                      >
                        {Object.entries(urlMatcherKindDisplayNames).map(([value, label]) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </SelectField>
                    </div>
                    <div className="u-flex-auto">
                      <Label>Target URL</Label>
                      <TextField
                        {...register(`urls.${index}.value`, {
                          validate: (value, formValues) => validate(value, formValues, index),
                        })}
                        disabled={isSaving || !access.isAllowed}
                        placeholder="Enter URL"
                      />
                    </div>
                    {fields.length > 1 && (
                      <div className={styles.targetURLButton}>
                        <IconButton
                          aria-label={`Remove URL ${index + 1}`}
                          icon={<Icon name="minus-circle" />}
                          onClick={() => remove(index)}
                        />
                      </div>
                    )}
                  </div>
                  {errors.urls?.[index] && (
                    <FieldError
                      name={`urls.${index}.value`}
                      errorMessage={errors.urls?.[index]?.value?.message || 'Required'}
                    />
                  )}
                  <KindHint control={control} index={index} />
                </div>
              </FormField>
            </div>
          </div>
        ))}
        <Button
          aria-label="Add URL"
          icon={<Icon name="add-circle" />}
          onClick={() => append({ kind: 'canonical', value: '' })}
          size="tiny"
          renderIconFirst
          className={styles.button}
          disabled={isSaving || !access.isAllowed}
        >
          Add target URL
        </Button>
      </div>
    </Tooltip>
  );
}

function validate(value: string, formValues: EditMetricForm, index: number): boolean | string {
  const kind = formValues.urls?.[index].kind;
  //TODO: Check for duplicates and alert

  switch (kind) {
    // TODO the backend doesn't validate this, do we really need to?
    case 'regex':
    case 'substring':
      //substring/pattern is not empty
      return value.length > 0 || 'This field is required';
    case 'canonical':
    case 'exact':
      //isNotEmpty
      //isAbsoluteURL
      return (isAbsolute(value) && value.length > 0) || 'Must be an absolute URL';
    default:
      return !!value || 'Required';
  }
}

function KindHint({ control, index }: { control: Control<EditMetricForm>; index: number }) {
  const kind = useWatch({ control, name: `urls.${index}.kind` });
  return <FormHint>{formHints[kind]}</FormHint>;
}
