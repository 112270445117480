import { useState } from 'react';
import { Controller, FormProvider, useController, useForm, useWatch } from 'react-hook-form';
import { useMatch, useParams } from 'react-router-dom';
import { userId } from '@gonfalon/constants';
import { Metric } from '@gonfalon/metrics';
import {
  Button,
  Dialog,
  Form,
  Heading,
  Input,
  Label,
  ListBox,
  ListBoxItem,
  Modal,
  ModalOverlay,
  Popover,
  ProgressBar,
  Select,
  SelectValue,
  TextArea,
  TextField,
} from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';

import { LearnMoreButton } from 'components/experimentation/common/components/LearnMoreButton/index.ts';
import { SuccessCriteria } from 'components/experimentation/common/types';
import NavigationPromptModal from 'components/NavigationPromptModal.tsx';
import SelectMemberContainer from 'components/SelectMemberContainer.tsx';
import SelectTags from 'components/SelectTags.tsx';
import { TagKind } from 'components/tagFilter/types.tsx';
import NavigationPrompt from 'routers/NavigationPrompt.tsx';
import { Member } from 'utils/accountUtils.ts';
import { trackExperimentEvent } from 'utils/analyticsUtils.ts';
import { getMetricKindDisplayIcon } from 'utils/MetricKind.ts';
import { getDocumentationUrl } from 'utils/urlUtils.ts';

import { MetricEventUrl, NewMetricForm, toMetricEventUrl } from '../common.ts';
import { MeasureOptionsSelect } from '../components/MeasureOptionsSelect.tsx';
import { useCreateMetric } from '../hooks/useCreateMetric.ts';

import { EventActivitySummary } from './EventActivitySummary/index.ts';

import styles from './GuidedMetricCreationModal.module.css';

// need to include every field or `isDirty` may be a false positive
const defaultFormValues: NewMetricForm = {
  kind: 'custom',
  isNumeric: false,
  urls: [{ kind: 'canonical', value: '' }],
  randomizationUnits: [],
  maintainerId: '',
  name: '',
  key: '',
  description: '',
  eventKey: '',
  tags: undefined,
  successCriteria: SuccessCriteria.HIGHER_THAN_BASELINE,
  unitAggregationType: 'average',
  analysisType: 'mean',
  percentileValue: undefined,
  eventDefault: { disabled: false, value: 0 },
};

export function GuidedMetricCreationModal({
  onCancel,
  onSuccess,
  defaultValues,
}: {
  onCancel: () => void;
  onSuccess?: (metric: Metric) => void;
  defaultValues?: Partial<NewMetricForm>;
}) {
  const [showPrompt, setShowPrompt] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [measureType, setMeasureType] = useState<'count' | 'occurrence' | 'value'>();
  const { mutate: createMetric, isPending: isSaving } = useCreateMetric();
  const form = useForm<NewMetricForm>({
    defaultValues: {
      ...defaultFormValues,
      maintainerId: userId(),
      ...defaultValues,
    },
  });

  const { isDirty } = form.formState;

  const params = useParams();
  const matchBuilder = useMatch({ path: '/:projKey/:envKey/experiments/create' });
  const matchMetrics = useMatch({ path: '/:projKey/metrics' });

  const getLocation = () => {
    if (params.experimentKey) {
      return 'design';
    }
    if (matchBuilder) {
      return 'builder';
    }
    if (matchMetrics) {
      return 'metricsList';
    }
    return 'unknown';
  };

  const onValid = (metric: NewMetricForm) => {
    createMetric(toMetricPost(metric), {
      onSuccess: (m) => {
        if (onSuccess) {
          onSuccess(m);
        }
        onCancel();
      },
    });
    trackExperimentEvent('Metric Created', {
      location: getLocation(),
    });
  };

  const onCancelWithPrompt = () => {
    if (isDirty) {
      setShowPrompt(true);
    } else {
      onCancel();
    }
  };

  const control = form.control;
  const register = form.register;
  const metricKind = useWatch({ control, name: 'kind', exact: true });

  const handleMeasureOptionSelect = (value: string) => {
    if (!['count', 'occurrence', 'type'].includes(value)) {
      return;
    }
    if (value === 'count') {
      form.setValue('unitAggregationType', 'sum');
      setMeasureType('count');
    } else if (value === 'occurrence') {
      form.setValue('unitAggregationType', 'average');
      form.setValue('analysisType', 'mean');
      setMeasureType('occurrence');
    } else {
      setMeasureType('value');
    }
  };

  const { field: memberField } = useController({
    name: 'maintainerId',
    control,
    rules: { required: 'Maintainer is required' },
  });
  const { ref: _memberRef, onChange: onMemberChange, ...memberFieldProps } = memberField;

  return (
    <ModalOverlay
      isOpen
      onOpenChange={(isOpen: boolean) => !isOpen && onCancelWithPrompt()}
      className={styles.guidedModalOverlay}
    >
      <Modal isOpen className={styles.noPadding}>
        <Dialog className={styles.noPadding}>
          <NavigationPrompt shouldBlock={isDirty && !isSaving} />
          {showPrompt ? (
            <NavigationPromptModal
              onCancelNavigation={() => setShowPrompt(false)}
              onConfirmNavigation={onCancel}
              body="If you close this form, you will lose any unsaved changes."
              confirmText="Discard changes"
            />
          ) : null}
          <div slot="header" className={styles.header}>
            <Heading slot="title" className={styles.formTitle}>
              Create metric
            </Heading>
            <p slot="subtitle" className={styles.formDescription}>
              Metrics measure audience behavior and performance
              {/* below is for testing, remove when done */}
              {/*eslint-disable-next-line no-console*/}
            </p>
          </div>
          <div slot="body" className={styles.formBody}>
            <FormProvider {...form}>
              <Form className={styles.createMetricForm}>
                <Controller
                  control={control}
                  name="kind"
                  render={({ field }) => (
                    <Select
                      onSelectionChange={field.onChange}
                      selectedKey={field.value}
                      className={styles.dropdownSelect}
                    >
                      <Label htmlFor="kind">Metric kind</Label>
                      <Button>
                        <Icon name={getMetricKindDisplayIcon(metricKind)} size="small" />
                        <SelectValue />
                        <Icon name="chevron-down" size="small" />
                      </Button>
                      <Popover>
                        <ListBox>
                          <ListBoxItem id="custom">Custom</ListBoxItem>
                          <ListBoxItem id="pageview">Page viewed</ListBoxItem>
                          <ListBoxItem id="click">Clicked or tapped</ListBoxItem>
                        </ListBox>
                      </Popover>
                    </Select>
                  )}
                />
                <TextField className={styles.eventKeyInput}>
                  <Label htmlFor="eventKey">Event key</Label>
                  <Input
                    {...register('eventKey', { required: 'Event key is required' })}
                    value={useWatch({ control, name: 'eventKey', exact: true })}
                    placeholder="Enter new or existing event key"
                  />
                  <EventActivitySummary />
                </TextField>
                <div>
                  <Label htmlFor="measureOption">What do you want to measure?</Label>
                  <MeasureOptionsSelect onSelect={handleMeasureOptionSelect} />
                </div>
                <TextField className={styles.textInput}>
                  <Label htmlFor="name">Metric name</Label>
                  <Input
                    {...register('name', { required: 'Metric name is required' })}
                    placeholder="ex: Button average click rate"
                  />
                </TextField>

                <TextField className={styles.descriptionInput}>
                  <Label htmlFor="name">Description</Label>
                  <TextArea {...register('description')} placeholder="Add a description to this metric" />
                </TextField>
                <div>
                  <Label htmlFor="tags">Tags</Label>
                  <div data-test-id="create-metric-tags">
                    <SelectTags<NewMetricForm>
                      id="tags"
                      useHookForm
                      kind={TagKind.METRIC}
                      control={control}
                      name="tags"
                      ariaLabel="Metric tags"
                      placeholder="Add tags"
                      className={styles.tagsInput}
                    />
                  </div>
                </div>
                <div>
                  <Label htmlFor="maintainerId">Maintainer</Label>
                  <SelectMemberContainer
                    {...memberFieldProps}
                    onChange={(member: Member) => onMemberChange({ target: { value: member?._id } })}
                    placeholder="Select maintainer"
                  />
                </div>
              </Form>
            </FormProvider>
          </div>
          <div slot="footer" className={styles.footer}>
            <LearnMoreButton
              link={getDocumentationUrl('home/observability/metrics')}
              className={styles.learnMoreButton}
            />
            <div className={styles.footerActions}>
              <Button onPress={onCancelWithPrompt}>Cancel</Button>
              <Button
                variant="primary"
                onPress={async () => form.handleSubmit(onValid)()}
                isDisabled={!isDirty || isSaving}
                data-test-id="metric-create-button"
              >
                Create metric{isSaving && <ProgressBar aria-label="Loading…" isIndeterminate size="small" />}
              </Button>
            </div>
          </div>
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
}

type MetricPost = Omit<NewMetricForm, 'tags' | 'randomizationUnits' | 'urls'> & {
  tags?: string[];
  randomizationUnits?: string[];
  urls?: MetricEventUrl[];
};

function toMetricPost(form: NewMetricForm): MetricPost {
  const { kind, urls, randomizationUnits, tags, ...rest } = form;
  const metric = {
    ...rest,
    isActive: true,
    kind,
    randomizationUnits: randomizationUnits?.map(({ value }) => value),
    tags: tags?.map(({ value }) => value),
    urls: kind === 'click' || kind === 'pageview' ? urls?.map(toMetricEventUrl) : undefined,
  };

  if (kind === 'custom') {
    metric.isNumeric = !!metric.isNumeric;
  }

  return metric;
}
