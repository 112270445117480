import type { AvatarProps, PopoverPlacement } from 'launchpad';
import { Popover } from 'launchpad';

import UserAvatar from 'components/UserAvatar';
import UserPopoverContent from 'components/UserPopoverContent';
import { User } from 'utils/userUtils';

type UserAvatarWithPopoverProps = {
  user: User;
  className?: string;
  useRouter?: boolean;
  linkToPage?: boolean;
  size?: AvatarProps['size'];
  placement?: PopoverPlacement;
};

/* eslint-disable import/no-default-export */
export default function (props: UserAvatarWithPopoverProps) {
  const { user, useRouter, linkToPage, size, className, placement } = props;

  return (
    <Popover interactionKind="hover" placement={placement}>
      <UserAvatar email={user.attributes.email} url={user.attributes.avatar} size={size} className={className} />
      <UserPopoverContent
        userKey=""
        enableExpiringUserTargets={false}
        user={user}
        useRouter={useRouter}
        linkToPage={linkToPage}
      />
    </Popover>
  );
}
