import { DetailsHTMLAttributes, forwardRef, MouseEventHandler, ReactNode, useCallback, useState } from 'react';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';

import styles from './Details.module.css';

export type DetailsProps = DetailsHTMLAttributes<HTMLDetailsElement> & {
  'data-test-id'?: string;
  initialOpen?: boolean;
  summary: ReactNode;
  summaryStyles?: string;
  prependCaret?: boolean;
};

export const Details = forwardRef<HTMLDetailsElement, DetailsProps>((props: DetailsProps, ref) => {
  const {
    open: providedOpen,
    children,
    initialOpen,
    summary,
    summaryStyles,
    prependCaret,
    'data-test-id': testId = 'details',
    ...otherProps
  } = props;
  const [isOpen, setIsOpen] = useState(props.initialOpen || false);

  const open = typeof providedOpen !== 'undefined' ? providedOpen : isOpen;

  const onClick = useCallback<MouseEventHandler<HTMLElement>>((event) => {
    event.preventDefault();
    setIsOpen((prevState) => !prevState);
  }, []);

  return (
    <details ref={ref} open={open} data-test-id={testId} {...otherProps}>
      <summary className={cx(styles.Summary, summaryStyles)} onClick={onClick}>
        {prependCaret &&
          (open ? (
            <Icon name="chevron-down" size="medium" className={styles.Marker} />
          ) : (
            <Icon name="chevron-right" size="medium" className={styles.Marker} />
          ))}
        {summary}
        {!prependCaret &&
          (open ? (
            <Icon name="chevron-up" size="medium" className={styles.Marker} />
          ) : (
            <Icon name="chevron-down" size="medium" className={styles.Marker} />
          ))}
      </summary>
      {children}
    </details>
  );
});

Details.displayName = 'Details';
