export enum MetricKind {
  CLICK = 'click',
  PAGEVIEW = 'pageview',
  CUSTOM = 'custom',
  NUMERIC = 'numeric',
  BINARY = 'binary',
  COUNT = 'count',
}

export enum MetricGroupKind {
  FUNNEL_GROUP = 'funnel',
  STANDARD_GROUP = 'standard',
}

export const MetricKindDisplayNames = {
  [MetricKind.CLICK]: 'Click',
  [MetricKind.PAGEVIEW]: 'Page view',
  [MetricKind.NUMERIC]: 'Numeric',
  [MetricKind.BINARY]: 'Binary',
  [MetricKind.CUSTOM]: 'Custom',
  [MetricGroupKind.FUNNEL_GROUP]: 'Funnel group',
  [MetricGroupKind.STANDARD_GROUP]: 'Standard group',
};

// These are the new display names, for use in the guided metric creation flow.
// TODO: clean up this file after launch (https://app.shortcut.com/launchdarkly/story/252425/clean-up-metric-type-names)
export const GuidedMetricKindDisplays = {
  [MetricKind.CLICK]: {
    displayName: 'clicked or tapped',
    displayIcon: 'click',
  },
  [MetricKind.PAGEVIEW]: {
    displayName: 'page viewed',
    displayIcon: 'device-desktop',
  },
  [MetricKind.CUSTOM]: {
    displayName: 'custom',
    displayIcon: 'code-circle-outline',
  },
  [MetricKind.COUNT]: {
    displayName: 'conversion: count',
    displayIcon: 'code-circle-outline',
  },
  [MetricKind.BINARY]: {
    displayName: 'conversion: binary',
    displayIcon: 'code-circle-outline',
  },
  [MetricKind.NUMERIC]: {
    displayName: 'numeric',
    displayIcon: 'code-circle-outline',
  },
  [MetricGroupKind.STANDARD_GROUP]: {
    displayName: 'standard group',
    displayIcon: 'code-circle-outline',
  },
  [MetricGroupKind.FUNNEL_GROUP]: {
    displayName: 'funnel group',
    displayIcon: 'code-circle-outline',
  },
} as const;

export const getMetricKindDisplayName = (kindString: string, isNumeric: boolean = false) => {
  const kind = getMetricKind(kindString);
  if (kind === 'custom') {
    return isNumeric ? MetricKindDisplayNames[MetricKind.NUMERIC] : MetricKindDisplayNames[MetricKind.BINARY];
  } else {
    return MetricKindDisplayNames[kind];
  }
};

export const getMetricKindDisplayIcon = (kindString: string, isNumeric: boolean = false) => {
  const kind = getMetricKind(kindString);
  if (kind === 'custom') {
    return isNumeric
      ? GuidedMetricKindDisplays[MetricKind.NUMERIC].displayIcon
      : GuidedMetricKindDisplays[MetricKind.BINARY].displayIcon;
  } else {
    return GuidedMetricKindDisplays[kind].displayIcon;
  }
};

export const getMetricKind = (kindString: string) => {
  switch (kindString) {
    case 'funnel':
      return MetricGroupKind.FUNNEL_GROUP;
    case 'standard':
      return MetricGroupKind.STANDARD_GROUP;
    case 'click':
      return MetricKind.CLICK;
    case 'pageview':
      return MetricKind.PAGEVIEW;
    case 'custom':
      return MetricKind.CUSTOM;
    case 'numeric':
      return MetricKind.NUMERIC;
    case 'binary':
      return MetricKind.BINARY;
    default:
      return MetricKind.CUSTOM;
  }
};

/* eslint-disable import/no-default-export */
export default MetricKind;
