import { useState } from 'react';
import cx from 'clsx';

import { DateTimePickerWithPopover } from 'components/ui/dates';

export type PendingChangesDatePopoverProps = {
  id?: string;
  className?: string;
  date?: Date;
  onSetDate(executionDate: number): void;
  shouldFocusDateInput: boolean;
  showTitle?: boolean;
  isScheduledChange?: boolean;
  disabled?: boolean;
  popoverClassName?: string;
  dateClassName?: string;
  useLongDate?: boolean;
};

export function PendingChangesDatePopover({
  id,
  className,
  dateClassName,
  date,
  onSetDate,
  shouldFocusDateInput = true,
  showTitle = true,
  isScheduledChange = false,
  disabled = false,
  useLongDate = false,
}: PendingChangesDatePopoverProps) {
  const [, setIsOpen] = useState(false);
  const [, setHasError] = useState(isScheduledChange);

  const handleSetExecutionDate = (executionDate: number) => {
    setIsOpen(false);
    setHasError(false);
    onSetDate(executionDate);
  };

  const dateInputClasses = cx('ScheduleChangesPopover--datePickerWithPopover', dateClassName);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.stopPropagation();
      e.preventDefault();
      setIsOpen(true);
    }
  };

  return (
    <div className={className}>
      <div className="u-flex">
        {showTitle && <span className="u-mt-xs u-mr-s u-ws-nowrap">Schedule change for:</span>}
        <DateTimePickerWithPopover
          id={id}
          className={dateInputClasses}
          value={date}
          isFocused={shouldFocusDateInput}
          disabled={disabled}
          disablePast
          onKeyDown={handleKeyDown}
          onChange={handleSetExecutionDate}
          onTab={handleKeyDown}
          useLongDate={!showTitle && useLongDate}
        />
      </div>
    </div>
  );
}
