import { Collection, Text } from 'react-aria-components';
import { EnvironmentMarker } from '@gonfalon/environments';
import { SearchField, TextTruncator } from '@gonfalon/launchpad-experimental';
import { Header, Menu, MenuItem, Section } from '@launchpad-ui/components';

import { SelectedEnvironment } from '../types';

import { useSelectEnvironments } from './useSelectEnvironments';

import styles from './AddEnvironmentDialog.module.css';

export function ReplaceEnvironmentMenu({
  projectKey,
  excludedIds,
  onSelect,
}: {
  projectKey: string;
  excludedIds: Set<string>;
  onSelect: (environment: SelectedEnvironment) => void;
}) {
  const { items, isPending, updateTextFilter, handleAction } = useSelectEnvironments({
    projectKey,
    excludedIds,
    onSelect,
  });

  return (
    <>
      <SearchField
        autoFocus
        className={styles.search}
        aria-label="Add environment"
        placeholder="Find environments"
        isBusy={isPending}
        onChange={updateTextFilter}
      />
      <Menu aria-label="Environments">
        {items
          .filter((section) => section.children.length)
          .map((section) => (
            <Section id={section.id} key={section.id}>
              <Header>{section.name}</Header>
              <Collection items={section.children}>
                {(item) => (
                  <MenuItem id={item.id} textValue={item.id} onAction={() => handleAction(item.id)}>
                    <Text slot="label">
                      <EnvironmentMarker color={item.color} />
                      <TextTruncator>{item.name}</TextTruncator>
                    </Text>
                  </MenuItem>
                )}
              </Collection>
            </Section>
          ))}
      </Menu>
    </>
  );
}
