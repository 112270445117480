import { ComponentProps, ReactNode, useEffect } from 'react';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { useFlagKey, useProjectKey } from '@gonfalon/router';
import nullthrows from 'nullthrows';

import { ExperimentsProvider } from 'components/experimentation/common/providers/ExperimentsProvider';
import FlagNotFound from 'components/FlagNotFound';
import ManageFlag from 'components/ManageFlag';
import AppSkeletonBody from 'components/SkeletonLoader/AppSkeletonBody';
import { useManageFlagState } from 'components/useManageFlagState';

export function Component() {
  return <LegacyFlagAdapter>{(props) => <ManageFlag {...props} />}</LegacyFlagAdapter>;
}

Component.displayName = 'ManageFlagContainer';

export function LegacyFlagAdapter({
  children,
}: {
  children: ((props: ComponentProps<typeof ManageFlag>) => ReactNode) | ReactNode;
}) {
  const projKey = useProjectKey();
  const envKey = useSelectedEnvironmentKey();
  const flagKey = useFlagKey();
  const {
    isReady,
    error,
    flag: maybeFlag,
    fetchFlag,
    ...finalProps
  } = useManageFlagState({ projKey, envKey, flagKey });

  useEffect(() => {
    if (!isReady) {
      fetchFlag();
    }
  }, [projKey, envKey, flagKey, isReady]);

  if (!isReady) {
    return <AppSkeletonBody />;
  }

  if (error) {
    return <FlagNotFound projKey={projKey} envKey={envKey} />;
  }

  const flag = nullthrows(maybeFlag, 'Expected flag');

  return (
    <ExperimentsProvider initialFlagKey={flag.key}>
      {typeof children === 'function' ? children({ ...finalProps, flag }) : children}
    </ExperimentsProvider>
  );
}
