import { ChangeEvent, HTMLInputTypeAttribute, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { noop } from '@gonfalon/es6-utils';
import type { OptionProps } from '@gonfalon/launchpad-experimental';
import { CustomSelect } from '@gonfalon/launchpad-experimental';
import { Map } from 'immutable';
import { Alert, Checkbox, CopyToClipboard, FieldError, Label, Markdown, SelectField, TextField } from 'launchpad';
import { v4 as uuidv4 } from 'uuid';

import { fetchDynamicEnumOptions } from 'actions/integrations';
import Restrict from 'components/Restrict';
import { FormGroup } from 'components/ui/forms';
import { GlobalState } from 'reducers';
import { goaltenderDynamicEnumOptionsSelector } from 'reducers/integrations';
import { FormVariable } from 'types/generated/integrationSchema';
import { FormState } from 'utils/formUtils';
import { ready, RequestState } from 'utils/reduxUtils';

type OnChangeFunction = (path: string[], value: boolean | string) => void;

export type GoaltenderFormElementValue = string | boolean | number | undefined;

export type GoaltenderFormElementProps = {
  fetchEnumOptions?: (integrationKey: string, enumKey: string) => void;
  formVar: FormVariable;
  value: GoaltenderFormElementValue;
  onChange: (path: string[], value: boolean | string) => void;
  disabled?: boolean;
  dynamicEnumOptions?: Map<string, Array<{ label: string; value: string }>>;
  integrationKey: string;
  isRestricted: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formState?: FormState<any>;
};

const getTextFieldType = (formVariable: FormVariable): HTMLInputTypeAttribute => {
  if (formVariable.isSecret) {
    return 'password';
  }
  if (formVariable.type === 'uri') {
    return 'url';
  }
  return 'text';
};

export const GoaltenderFormElement = (props: GoaltenderFormElementProps): JSX.Element => {
  const {
    fetchEnumOptions,
    formState,
    formVar,
    value,
    onChange,
    disabled,
    dynamicEnumOptions,
    integrationKey,
    isRestricted,
  } = props;

  useEffect(() => {
    if (formVar.type === 'dynamicEnum') {
      fetchEnumOptions && fetchEnumOptions(integrationKey, formVar.key);
    }
  }, [integrationKey, formVar.key, formVar.type]);

  const formElementWrapper = (formElement: JSX.Element) => (
    <>
      <FormGroup name={`config.${formVar.key}`} className={formVar.isSecret ? 'fs-exclude' : ''}>
        <Label htmlFor={`config.${formVar.key}`} required={!formVar.isOptional}>
          {formVar.name}
        </Label>
        <Restrict
          isRestricted={isRestricted}
          tooltip="You are not permitted to edit this field. Contact your administrator for more information."
          tooltipOptions={{ rootElementStyle: { display: 'block' } }}
          willDisable
        >
          {formElement}
        </Restrict>
        <Markdown className="Form-hint" source={formVar.description} allowedTags={['p', 'a', '#text']} />
      </FormGroup>
    </>
  );

  switch (formVar.type) {
    case 'string':
    case 'uri':
      return formElementWrapper(
        <TextField
          data-test-id={`config.${formVar.key}`}
          id={`config.${formVar.key}`}
          name={`config.${formVar.key}`}
          value={value as string | undefined}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(['config', formVar.key], e.target.value)}
          placeholder={formVar.placeholder}
          disabled={disabled}
          type={getTextFieldType(formVar)}
        />,
      );
    case 'enum':
      return formElementWrapper(
        <SelectField
          data-test-id={`config.${formVar.key}`}
          id={`config.${formVar.key}`}
          value={typeof value === 'boolean' ? `${value}` : value}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => onChange(['config', formVar.key], e.target.value)}
          disabled={disabled}
        >
          {formVar.allowedValues?.map((v, i) => (
            <option value={v} key={i}>
              {v}
            </option>
          ))}
        </SelectField>,
      );
    case 'boolean':
      return formElementWrapper(
        <Checkbox
          data-test-id={`config.${formVar.key}`}
          value={`config.${formVar.key}`}
          name={`config.${formVar.key}`}
          checked={value as boolean | undefined}
          onChange={(e) => onChange(['config', formVar.key], e.target.checked)}
          disabled={disabled}
        >
          {formVar.name}
        </Checkbox>,
      );
    case 'dynamicEnum':
      // TODO: Show an error inline if fetch fails
      if (!dynamicEnumOptions || !ready(dynamicEnumOptions.get(formVar.key) as RequestState | undefined)) {
        return formElementWrapper(<CustomSelect id="cueSelect-loading" onChange={noop} disabled />);
      } else if (dynamicEnumOptions.hasIn([formVar.key, 'error'])) {
        return formElementWrapper(<Alert kind="error">Error fetching options. Try again later.</Alert>);
      }

      const options: Array<{ label: string; value: string }> = dynamicEnumOptions
        .getIn([formVar.key, 'options'])
        .toJS();

      const fieldName = `config.${formVar.key}`;
      const errorMessage = formState?.getError ? formState.getError(fieldName) : undefined;
      return formElementWrapper(
        <div>
          <CustomSelect
            id="cueSelect"
            name={fieldName}
            value={value ? options.filter((o) => o.value === (value as string))[0] : undefined}
            onChange={(selectedOption: OptionProps | null) => {
              onChange(['config', formVar.key], selectedOption?.value);
            }}
            options={options}
            disabled={disabled}
            isClearable
          />
          {errorMessage && <FieldError name={fieldName} errorMessage={errorMessage} />}
        </div>,
      );
    case 'generated':
      return formElementWrapper(<GeneratedFormField formVar={formVar} value={value} onChange={onChange} />);
    default:
      return <></>;
  }
};

type GeneratedFormFieldType = {
  formVar: FormVariable;
  value: GoaltenderFormElementValue;
  onChange: OnChangeFunction;
};

const GeneratedFormField = ({ formVar, value, onChange }: GeneratedFormFieldType) => {
  const [currentValue, setCurrentValue] = useState<string>('');

  useEffect(() => {
    const generatedValue: GoaltenderFormElementValue = !value ? uuidv4() : value;
    setCurrentValue(generatedValue as string);
    onChange(['config', formVar.key], generatedValue as string);
  }, [value]);

  return (
    <div>
      <TextField
        className="hiddenFormElement"
        data-test-id={`config.${formVar.key}`}
        id={`config.${formVar.key}`}
        name={`config.${formVar.key}`}
        value={currentValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(['config', formVar.key], e.target.value)}
        placeholder={formVar.placeholder}
        disabled
      />

      <CopyToClipboard onClick={(e) => e.preventDefault()} text={currentValue}>
        {currentValue}
      </CopyToClipboard>
    </div>
  );
};

const mapStateToProps = (state: GlobalState, ownProps: GoaltenderFormElementProps) => {
  if (ownProps.formVar.type === 'dynamicEnum') {
    return {
      dynamicEnumOptions: goaltenderDynamicEnumOptionsSelector(state, ownProps.integrationKey),
    };
  }
  return {};
};

const mapDispatchToProps = {
  fetchEnumOptions: fetchDynamicEnumOptions,
};

/* eslint-disable import/no-default-export */
export default connect(mapStateToProps, mapDispatchToProps)(GoaltenderFormElement);
