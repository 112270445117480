import UpsellFeatureImage from 'components/upsells/UpsellFeatureImage';

const UpsellImagePrerequisites = () => (
  <UpsellFeatureImage
    altText="A screenshot showing flag prerequisites being used in the LaunchDarkly interface."
    fileName="prerequisites"
    height="135"
    width="551"
  />
);

/* eslint-disable import/no-default-export */
export default UpsellImagePrerequisites;
