import { ChangeEvent, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { VisuallyHidden } from 'react-aria';
import { CustomSelect, StylesObject } from '@gonfalon/launchpad-experimental';
import { ProgressBar } from '@launchpad-ui/components';
import { List, Set } from 'immutable';
import { Alert, AppliedFilter, Filter, FilterOption, Label } from 'launchpad';

import { TagFilterType } from 'components/tagFilter/types';
import { joinOrCollapseItems, stringContains } from 'utils/stringUtils';

const TAG_FILTER_COLLAPSING_THRESHOLD = 1;

export type TagFilterProps = {
  backendTags: List<string>;
  frontendTags?: Set<string> | List<string>;
  id?: string;
  isLoading: boolean;
  onClear?(): void;
  onInputChange(q: string): void;
  onSelect(
    item: Omit<FilterOption, 'name' | 'isDisabled' | 'isDivider' | 'isChecked' | 'projKey' | 'nested' | 'groupHeader'> &
      Array<{ label: string; value: string }>,
  ): void;
  selectedTags: Set<string>;
  selectedTagValue?: string[];
  type?: TagFilterType;
  filterSelectStyles?: StylesObject;
  onClickFilterButton?(): void;
  showLabel?: boolean;
};

/* eslint-disable import/no-default-export */
export default function TagFilter({
  selectedTags,
  frontendTags,
  onClear,
  onSelect,
  backendTags,
  id,
  isLoading,
  onInputChange,
  selectedTagValue,
  filterSelectStyles,
  onClickFilterButton,
  type = TagFilterType.FILTER,
  showLabel,
}: TagFilterProps) {
  const tags = frontendTags && frontendTags.size > 0 ? frontendTags : backendTags;
  const [tagSearch, setTagSearch] = useState('');
  const tagSearchRef: MutableRefObject<string | undefined> = useRef();
  useEffect(() => {
    tagSearchRef.current = tagSearch;
  }, [backendTags]);
  const isTagsEmpty = !tagSearchRef.current && !backendTags.size;

  const multipleFiltersTagOptions = useMemo(
    () => [
      ...tags
        .map((t) => ({
          value: t,
          label: t,
        }))
        .toArray(),
    ],
    [tags],
  );
  const anyTagOption =
    type === TagFilterType.FILTER
      ? [
          { value: '', name: 'Any', isChecked: selectedTags.isEmpty() },
          { isDivider: true, value: '' },
        ]
      : [];

  const emptyTagsOption = tags.isEmpty() ? [{ name: 'No tags found', value: 'empty', isDisabled: true }] : [];

  const checkedTagsList = selectedTags
    .filter((tag) => stringContains(tag, tagSearch))
    .map((t) => ({ name: t, value: t, isChecked: true }))
    .toArray();

  const uncheckedTagsList = tags
    .toArray()
    .filter((tag: string) => stringContains(tag, tagSearch))
    .filter((tag: string) => !selectedTags.includes(tag))
    .map((t) => ({ name: t, value: t }));

  const tagOptions = [...anyTagOption, ...emptyTagsOption, ...checkedTagsList, ...uncheckedTagsList];

  const tagDescription = useMemo(() => {
    if (selectedTags.isEmpty()) {
      return 'Any';
    }

    return joinOrCollapseItems(List(selectedTags), TAG_FILTER_COLLAPSING_THRESHOLD, 'tags');
  }, [selectedTags]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setTagSearch(input);
    // selecting and searching options trigger an event so we need to avoid fetching unless there was a text input change
    const inputChanged = input !== tagSearchRef.current;
    if (inputChanged) {
      onInputChange(input);
    }
  };

  const handleInputChange = (input: string) => {
    const inputChanged = input !== tagSearchRef.current;
    if (inputChanged) {
      setTagSearch(input);
      onInputChange(input);
    }
    return input;
  };

  const searchPlaceholder = 'Search for a tag by name';

  if (type === TagFilterType.SELECT) {
    const tagsLoadedAndEmpty = !isLoading && isTagsEmpty;
    if (!tagSearch && !tagSearchRef.current && isLoading) {
      return <ProgressBar aria-label="Loading…" isIndeterminate />;
    }

    return (
      <div className="TagFilter-select">
        {showLabel && <Label htmlFor={id}>{searchPlaceholder}</Label>}
        <CustomSelect
          closeMenuOnSelect={false}
          id={id}
          isMulti
          isDisabled={!isLoading && isTagsEmpty && !tagSearch}
          placeholder={searchPlaceholder}
          options={multipleFiltersTagOptions}
          value={selectedTagValue}
          onChange={onSelect}
          onInputChange={handleInputChange}
          styles={filterSelectStyles}
          tabSelectsValue={false}
          noOptionsMessage={() => (isLoading ? <ProgressBar aria-label="Loading…" isIndeterminate /> : 'No tags found')}
          autoSort
          aria-label="Select tags"
        />
        {tagsLoadedAndEmpty && (
          <Alert isInline kind="info" className="TagFilter-alert">
            There are no tags
          </Alert>
        )}
      </div>
    );
  }

  if (type === TagFilterType.APPLIED) {
    return (
      <>
        <VisuallyHidden>
          <label htmlFor={id}>{searchPlaceholder}</label>
        </VisuallyHidden>
        <AppliedFilter
          name={`Tags (${selectedTags.size})`}
          isEmpty={isTagsEmpty}
          searchValue={tagSearch}
          searchId={id}
          description={selectedTags.join(', ')}
          options={tagOptions}
          searchPlaceholder={searchPlaceholder}
          onSearchChange={handleSearchChange}
          onSelect={onSelect}
          onClearFilter={onClear}
          isLoading={isLoading}
          onClickFilterButton={onClickFilterButton}
          searchAriaLabel="Tag filter"
        />
      </>
    );
  }

  return (
    <Filter
      name="Tags"
      isEmpty={isTagsEmpty}
      isClearable={!selectedTags.isEmpty()}
      searchValue={tagSearch}
      description={tagDescription}
      options={tagOptions}
      searchPlaceholder={searchPlaceholder}
      onSearchChange={handleSearchChange}
      onSelect={onSelect}
      onClear={onClear}
      isLoading={isLoading}
      searchAriaLabel="Tag filter"
    />
  );
}
