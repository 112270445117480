import { unitsToIncludeInTimeDisplay } from '@gonfalon/measured-rollouts';
import { formatDuration, intervalToDuration } from 'date-fns';

import Variation from 'components/Variation';

import styles from './MeasuredRolloutRuleInstructionDescription.module.css';

interface Props {
  trafficAllocation?: number;
  ruleName: string;
  monitoringWindowMilliseconds?: number;
  randomizationUnit?: string;
  rollback?: boolean;
  variationName: string;
  variationColor: string;
}

export const MeasuredRolloutRuleInstructionDescription = ({
  trafficAllocation = 0,
  ruleName,
  monitoringWindowMilliseconds = 0,
  randomizationUnit,
  rollback,
  variationName,
  variationColor,
}: Props) => {
  const duration = intervalToDuration({
    start: 0,
    end: monitoringWindowMilliseconds,
  });

  const formattedDuration =
    formatDuration(duration, {
      delimiter: ', ',
      format: unitsToIncludeInTimeDisplay(duration),
    }) || '0 seconds';

  return (
    <div>
      <p>
        Serve{' '}
        <span className={styles.variationWrapper}>
          <Variation
            value={variationName}
            color={variationColor}
            className={styles.measuredRolloutInstructionVariation}
          />
        </span>
        to <strong>{trafficAllocation / 1000}%</strong> of <strong>{ruleName}</strong> traffic
      </p>
      {randomizationUnit && (
        <p>
          Allocate traffic by <code>{randomizationUnit}</code>
        </p>
      )}
      <p>
        Monitor release metrics for <strong>{formattedDuration}</strong>
      </p>
      {rollback && <p>Automatically roll back if a regression is detected</p>}
    </div>
  );
};
