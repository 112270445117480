import { useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import cx from 'clsx';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { Button } from 'launchpad';

import { DataList, DataListItem } from 'components/ui/datalist';
import { UserPopoverContentDatePickerBody } from 'components/UserPopoverContentDatePickerBody';
import { AccessDecision, denyDecision } from 'utils/accessUtils';
import { lastUsedTzid } from 'utils/dateUtils';
import { User } from 'utils/userUtils';

import Restrict from './Restrict';
import { Attribute } from './UserAttributesList';

import '@launchpad-ui/form/style.css';
import styles from './UserPopoverContentWithDate.module.css';

const deny = denyDecision();

export type UserPopoverContentWithDateProps = {
  user?: User;
  onRenderUserData?(): JSX.Element;
  date?: number | null;
  onChange?(date?: string | null): void;
  variationId?: string;
  isUserPage?: boolean;
  originalExpirationDate?: number | null;
  updatedExpirationDate?: number | null;
  updatedExpirationVariationId?: string | null;
  openDatePickerKey?: string;
  userKey: string;
  onChangeUserTargetingExpiration?({
    originalExpirationDate,
    updatedExpirationDate,
  }: {
    originalExpirationDate?: number | null;
    updatedExpirationDate?: number | null;
  }): void;
  onHandlePopoverInteraction?(): void;
  updateExpiringTargetsAccess?: AccessDecision;
  onClickRemovalDate?: () => void;
};

export function UserPopoverContentWithDate({
  onRenderUserData,
  user,
  date,
  onChange,
  variationId,
  isUserPage,
  originalExpirationDate,
  updatedExpirationVariationId,
  openDatePickerKey,
  updateExpiringTargetsAccess = deny,
  userKey,
  onChangeUserTargetingExpiration,
  onHandlePopoverInteraction,
  onClickRemovalDate,
  ...props
}: UserPopoverContentWithDateProps) {
  const forceDatePickerToShow = !isUserPage && openDatePickerKey === userKey;
  const [renderDateToggleFirst, setRenderDateToggleFirst] = useState(forceDatePickerToShow);

  const DataListClasses = cx('UserPopover', { 'u-sm-pb-m u-w6': isUserPage });

  const handleChangeUserTargetExpirationDate = (updatedExpirationDate: number | null) => {
    onHandlePopoverInteraction?.();
    onChangeUserTargetingExpiration?.({
      originalExpirationDate,
      updatedExpirationDate,
    });
  };

  const hasExpirationOnDifferentVariation =
    !!updatedExpirationVariationId && variationId !== updatedExpirationVariationId;
  const readOnly = !updateExpiringTargetsAccess.isAllowed;
  const restrictReason = hasExpirationOnDifferentVariation
    ? 'User is already scheduled for removal on a different variation. You must remove them before continuing.'
    : updateExpiringTargetsAccess.getActionReason();

  function onClickRemovalDateButton() {
    if (onClickRemovalDate) {
      onClickRemovalDate();
      onHandlePopoverInteraction?.();
    }
  }

  const removalDate =
    props.updatedExpirationDate && !hasExpirationOnDifferentVariation ? (
      <>
        <DataListItem className={styles.RemovalDateLabel}>Removal date:</DataListItem>
        <Restrict isRestricted={readOnly || hasExpirationOnDifferentVariation} willDisable tooltip={restrictReason}>
          <Button kind="link" className={styles.RemovalDateButton} onClick={onClickRemovalDateButton}>
            <p>{format(toZonedTime(props.updatedExpirationDate, lastUsedTzid()), 'EEEE, MMMM dd, yyyy hh:mm:a')}</p>
            <p>{lastUsedTzid()}</p>
          </Button>
        </Restrict>
      </>
    ) : null;

  return (
    <DataList className={DataListClasses}>
      {!renderDateToggleFirst ? (
        <div>
          <DataList className="fs-exclude">
            {removalDate}
            {!isUserPage && (
              <div
                className={
                  !(props.updatedExpirationDate && !hasExpirationOnDifferentVariation) ? '' : 'UserPopover--userInfo'
                }
                data-test-id="userAttributes"
              >
                {user && onRenderUserData ? onRenderUserData() : <Attribute value={userKey} name="key" />}
              </div>
            )}
          </DataList>
        </div>
      ) : (
        <div>
          <DataList className="fs-exclude">
            <DataListItem>
              <UserPopoverContentDatePickerBody
                className={isUserPage ? 'UserPopoverContentDatePickerBody--UserSettings' : ''}
                onCancel={() => setRenderDateToggleFirst(!renderDateToggleFirst)}
                firstDate={date ? new Date(date) : undefined}
                onChangeUserTargetExpirationDate={handleChangeUserTargetExpirationDate}
              />
            </DataListItem>
          </DataList>
        </div>
      )}
    </DataList>
  );
}
