import http, { middleware } from 'utils/httpUtils';

import { Iteration } from '../types';

import { IterationBody } from './api.types';

/**
 * Fetcher updates an iteration.
 *
 * @param projectKey - The project key
 * @param environmentKey - The environment key
 * @param experimentKey - The key of the experiment to update
 * @param body - The post body
 */
export async function updateIteration(
  projectKey: string,
  environmentKey: string,
  experimentKey: string,
  body: IterationBody,
) {
  const endpoint = `/api/v2/projects/${projectKey}/environments/${environmentKey}/experiments/${experimentKey}/iterations`;

  const request = await http.post(endpoint, { body, beta: true });
  const json: Iteration = await middleware.json(request).catch(middleware.jsonError);

  return json;
}
