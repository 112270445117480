import { useIsMutatingFlag, useReleasePipeline } from '@gonfalon/rest-api';
import { useProjectKey } from '@gonfalon/router';
import { Alert } from 'launchpad';

import { useReleaseQuery } from 'components/releases/common/releaseQueries';

import { LegacyReleaseTracker } from './LegacyReleaseTracker';
import { LegacyLoadingReleaseTrackerSkeleton } from './LegacyReleaseTrackerSkeleton';

export type LegacyFlagReleaseSummaryProps = {
  flagKey: string;
  hideAddReleaseButton: boolean;
  enableReleaseQuery: boolean;
  setAnimateCelebration: (shouldAnimate: boolean) => void;
};

export function LegacyFlagReleaseSummary({
  flagKey,
  hideAddReleaseButton,
  enableReleaseQuery,
  setAnimateCelebration,
}: LegacyFlagReleaseSummaryProps) {
  const { isLoading, isError, isSuccess, data: release } = useReleaseQuery(flagKey, { enabled: enableReleaseQuery });
  const projectKey = useProjectKey();
  const isReleaseUpdating = useIsMutatingFlag();
  const { data: pipeline } = useReleasePipeline(
    { releasePipelineKey: release?.releasePipelineKey ?? '', projectKey },
    { enabled: enableReleaseQuery && !!release?.releasePipelineKey },
  );

  if (hideAddReleaseButton && !isReleaseUpdating) {
    return null;
  }

  if (isLoading || isReleaseUpdating) {
    return <LegacyLoadingReleaseTrackerSkeleton />;
  }

  if (isError) {
    return (
      <Alert className="u-mt-m" data-test-id="error-alert" kind="error" isInline>
        Release pipeline failed to load. Try again later.
      </Alert>
    );
  }

  if (isSuccess) {
    return (
      <>
        <LegacyReleaseTracker
          phases={release.phases}
          setAnimateCelebration={setAnimateCelebration}
          isV2Pipeline={pipeline?._isLegacy === false}
        />
      </>
    );
  }

  return null;
}
