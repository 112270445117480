import { useState } from 'react';
import { isSameDay, setMinutes } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { Button, ButtonGroup } from 'launchpad';

import { DatePicker } from 'components/ui/dates/DatePicker';
import { LegacyTimeZonePicker } from 'components/ui/dates/LegacyTimeZonePicker';
import { track } from 'utils/analyticsUtils';
import { convertToTZDate, getToday, lastUsedTzid, saveTzidToCache } from 'utils/dateUtils';

import { LegacyTimePicker } from './ui/dates/LegacyTimePicker';

type UserPopoverContentDatePickerBodyProps = {
  firstDate?: Date;
  onCancel(value?: React.MouseEvent): void;
  onChangeUserTargetExpirationDate(expirationDate: number): void;
  className?: string;
};

export const UserPopoverContentDatePickerBody = ({
  firstDate,
  onCancel,
  onChangeUserTargetExpirationDate,
  className,
}: UserPopoverContentDatePickerBodyProps) => {
  const [tzid, setTzid] = useState(lastUsedTzid());
  const today: Date = getToday();
  const [addedDate, setAddedDate] = useState(firstDate);

  const dateInTZ = convertToTZDate(addedDate) || today;
  const handleDateChange = (nextDate: Date) => {
    const dateWithHour = nextDate.setHours(dateInTZ.getHours());
    const dateWithMinutes = setMinutes(dateWithHour, dateInTZ.getMinutes());
    if (nextDate) {
      setAddedDate(fromZonedTime(dateWithMinutes, tzid));
    }
  };

  const handleTimeZoneChange = (newTzid: string) => {
    if (dateInTZ) {
      saveTzidToCache(newTzid);
      setTzid(newTzid);
      handleDateChange(dateInTZ);
    }
  };

  const handleAddingNewDate = () => {
    track('Apply Date Button Clicked', 'Date Picker');
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    onChangeUserTargetExpirationDate(addedDate!.valueOf()); /* eslint-enable @typescript-eslint/no-non-null-assertion */
  };

  const handleTimeChange = (value: Date) => {
    if (value) {
      setAddedDate(fromZonedTime(new Date(value), tzid));
    }
  };

  const shouldDisablePast = isSameDay(dateInTZ, today);

  return (
    <>
      <div className={className}>
        <DatePicker disablePastDays value={dateInTZ} onChange={handleDateChange} />
        <LegacyTimePicker disablePast={shouldDisablePast} value={dateInTZ} onChange={handleTimeChange} />
        <LegacyTimeZonePicker tzid={tzid} onChange={handleTimeZoneChange} />
        <div className="DateRangeFilter-footer">
          <ButtonGroup>
            <Button size="small" onClick={onCancel}>
              Cancel
            </Button>
            <Button size="small" kind="primary" disabled={!addedDate} onClick={handleAddingNewDate}>
              Apply
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </>
  );
};
