import { useMemo } from 'react';
import { useMatches, useNavigation, useSearchParams } from 'react-router-dom';
import { useDebounce } from '@gonfalon/async';
import { Statement } from '@gonfalon/audit-log';
import { updateURLSearchParamsWithProjectContext, useProjectContext } from '@gonfalon/context';
import { ResourceSpecifier } from '@gonfalon/resource-specifiers';
import { mergeSearchParams } from '@gonfalon/router';
import invariant from 'tiny-invariant';
import { z } from 'zod';

import { addResource, deserializeActivityFilter, serializeActivityFilter } from './ChangeHistory/ChangeHistoryUtils';

const statement = z.object({
  actions: z.array(z.string()),
  effect: z.enum(['allow']),
  resources: z.array(z.string()),
});

const activitySchema = z.object({
  activity: z.union([z.array(statement), z.function().args(z.any()).returns(z.array(statement))]),
});

export const useChangeHistory = () => {
  const projectContext = useProjectContext({ optional: true });
  const navigation = useNavigation();
  const [searchParams, setSearchParams] = useSearchParams();
  const matches = useMatches();

  const filters = deserializeActivityFilter(searchParams);

  const isChangeHistoryVisible =
    navigation.state === 'loading'
      ? new URLSearchParams(navigation.location.search).has('activity')
      : searchParams.has('activity');

  const optomisticSearchParams = navigation.location
    ? new URLSearchParams(navigation.location.search)
    : new URLSearchParams();
  const specParam = optomisticSearchParams.get('spec');

  let spec =
    navigation.state === 'loading' && specParam
      ? specParam.split(',').map((s) => JSON.parse(decodeURIComponent(s)))
      : filters.spec;

  const routeSpec = useMemo(() => {
    const reversedMatches = [...matches].reverse();

    let specFromActivity: Statement[] = [];
    for (const match of reversedMatches) {
      const parse = activitySchema.safeParse(match.handle);
      if (parse.success) {
        specFromActivity =
          typeof parse.data.activity === 'function'
            ? parse.data.activity({ params: match.params, data: match.data })
            : parse.data.activity;
        break;
      }
    }

    return specFromActivity.length > 0 ? specFromActivity : undefined;
  }, [matches]);

  if (!spec && routeSpec) {
    spec = routeSpec;
  }

  const open = (props?: { policy?: Statement[]; after?: Date; sort?: 'asc' | 'desc' }) => {
    const filtersFromSpecParams = serializeActivityFilter(
      {
        spec: props?.policy ?? routeSpec,
        after: props?.after,
        sort: props?.sort,
      },
      searchParams,
    );

    setSearchParams(mergeSearchParams(searchParams, filtersFromSpecParams));
  };

  const close = () => {
    searchParams.delete('activity-auditId');
    searchParams.delete('activity');
    searchParams.delete('activity-after');
    searchParams.delete('activity-before');
    searchParams.delete('activity-q');
    searchParams.delete('activity-sort');
    searchParams.delete('spec');
    setSearchParams(searchParams);
  };

  const deleteResourceFilter = (index: number): void => {
    invariant(spec, 'spec should be defined');

    const newSpec = [...spec];
    newSpec.splice(index, 1);

    const nextFilter = serializeActivityFilter(
      {
        spec: newSpec,
      },
      searchParams,
    );

    if (projectContext) {
      const nextParams = updateURLSearchParamsWithProjectContext(nextFilter, projectContext.context);
      setSearchParams(nextParams);
    } else {
      setSearchParams(nextFilter);
    }
  };

  const addResourceFilter = (resource: ResourceSpecifier['type']): void => {
    invariant(spec, 'spec should be defined');

    const nextStatement = addResource(
      resource,
      projectContext?.project?.key || '*',
      projectContext?.selectedEnvironment?.key ? [projectContext.selectedEnvironment.key] : ['*'],
    );
    const newSpec = [...spec];
    newSpec.push(nextStatement);

    const nextFilter = serializeActivityFilter(
      {
        spec: newSpec,
      },
      searchParams,
    );

    if (projectContext) {
      const nextParams = updateURLSearchParamsWithProjectContext(nextFilter, projectContext.context);
      setSearchParams(nextParams);
    } else {
      setSearchParams(nextFilter);
    }
  };

  const auditId = searchParams.get('activity-auditId');

  const updateResourceFilter = (index: number, nextStatement: Statement) => {
    invariant(spec, 'spec should be defined');

    const newSpec = [...spec];

    newSpec[index] = nextStatement;

    const nextFilter = serializeActivityFilter(
      {
        spec: newSpec,
      },
      searchParams,
    );

    if (projectContext) {
      const nextParams = updateURLSearchParamsWithProjectContext(nextFilter, projectContext.context);
      setSearchParams(nextParams);
    } else {
      setSearchParams(nextFilter);
    }
  };

  const resetResourceFilter = (index: number, resource: ResourceSpecifier['type']) => {
    const nextStatement = addResource(
      resource,
      projectContext?.project?.key || '*',
      projectContext?.selectedEnvironment?.key ? [projectContext.selectedEnvironment.key] : ['*'],
    );
    updateResourceFilter(index, nextStatement);
  };

  const updateTextFilter_ = (text: string) => {
    const nextFilter = serializeActivityFilter(
      {
        ...filters,
        spec,
        q: text,
      },
      searchParams,
    );

    if (projectContext) {
      const nextParams = updateURLSearchParamsWithProjectContext(nextFilter, projectContext.context);
      setSearchParams(nextParams);
    } else {
      setSearchParams(nextFilter);
    }
  };

  const updateTextFilter = useDebounce(updateTextFilter_, 150);

  const updateDateFilter = ({ before, after }: { before: Date; after: Date }) => {
    const nextFilter = serializeActivityFilter(
      {
        ...filters,
        spec,
        before,
        after,
      },
      searchParams,
    );

    if (projectContext) {
      const nextParams = updateURLSearchParamsWithProjectContext(nextFilter, projectContext.context);
      setSearchParams(nextParams);
    } else {
      setSearchParams(nextFilter);
    }
  };

  return {
    hasRouteSpec: routeSpec !== undefined,
    spec,
    filters,
    open,
    close,
    isChangeHistoryVisible,
    addResourceFilter,
    updateResourceFilter,
    deleteResourceFilter,
    resetResourceFilter,
    updateTextFilter,
    updateDateFilter,
    auditId,
  };
};
