import { FeatureFlag } from '@gonfalon/flags';
import { schemas } from '@gonfalon/openapi';

import httpUtils from 'utils/httpUtils';

type Link = schemas['Link'];

// https://github.com/launchdarkly/accelerate/blob/main/backend/internal/reps/flag_events.go

type ImpactReason = 'evaluations' | 'global' | 'waiting';

type EventType =
  | 'created'
  | 'modified_targeting'
  | 'enabled_targeting'
  | 'disabled_targeting'
  | 'archived'
  | 'restored'
  | 'deleted';

type VariationEvalSummary = {
  value: any;
  beforeEvals: number;
  afterEvals: number;
};

type EvaluationsSummary = {
  variations: VariationEvalSummary[];
};

type FlagEventImpact = {
  highImpact: boolean;
  reason?: ImpactReason;
  evaluationSummary?: EvaluationsSummary;
};

type FlagEventMember = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

// type FlagEventAccess = {
//   action: string;
//   resource: string;
// };

export type FlagEvent = {
  id: string;
  projectId: string;
  projectKey: string;
  environmentId: string;
  environmentKey: string;
  flagKey: string;
  eventType: EventType;
  eventTime: number;
  description: string;
  auditLogEntryId?: string;
  member?: FlagEventMember;
  actions: string[];
  impact: FlagEventImpact;
};

export type FlagEventCollection = {
  _links: {
    [key: string]: Link;
  };
  totalCount: number;
  items: FlagEvent[];
};

type FlagEventListParams = {
  projectKey?: string;
  environmentKey?: string;
  flagKey?: string;
  from?: number;
  to?: number;
  significant?: boolean;
  limit?: number;
  auditLogIds?: string[];
  eventType?:
    | 'created'
    | 'modified_targeting'
    | 'enabled_targeting'
    | 'disabled_targeting'
    | 'archived'
    | 'restored'
    | 'deleted';
};

function toServerSideURLSearchParams(listParams: FlagEventListParams) {
  const searchParams = new URLSearchParams();

  if (listParams.projectKey) {
    searchParams.set('projectKey', listParams.projectKey);
  }

  if (listParams.environmentKey) {
    searchParams.set('environmentKey', listParams.environmentKey);
  }

  if (listParams.flagKey) {
    searchParams.set('flagKey', listParams.flagKey);
  }

  if (listParams.from) {
    searchParams.set('from', listParams.from.toString());
  }

  if (listParams.to) {
    searchParams.set('to', listParams.to.toString());
  }

  if (listParams.limit) {
    searchParams.set('limit', listParams.limit.toString());
  }

  if (listParams.significant) {
    searchParams.set('significant', listParams.significant.toString());
  }

  if (listParams.eventType) {
    searchParams.set('eventType', listParams.eventType.toString());
  }

  if (listParams.auditLogIds) {
    searchParams.set('auditLogIds', listParams.auditLogIds.toString());
  }

  return searchParams;
}

export const flagEventListQuery = (params?: FlagEventListParams) => ({
  queryKey: ['flag-events', 'list', params],
  queryFn: async () => {
    const url = new URL('/api/v2/engineering-insights/flag-events', location.origin);

    if (params) {
      url.search = toServerSideURLSearchParams(params).toString();
    }

    return (
      await httpUtils.get(url.toString(), {
        headers: {
          'LD-API-version': 'beta',
        },
      })
    ).json() as Promise<FlagEventCollection>;
  },
});

export type BaseChartSeries = { metadata: { name: string }; data: Array<{ x: number; y: number; values?: {} }> };

type StaleFlagChartParams = {
  projectKey: string;
  environmentKey: string;
};

function staleFlagChartParamsToSearchParams(params: StaleFlagChartParams) {
  const searchParams = new URLSearchParams();

  searchParams.set('projectKey', params.projectKey);
  searchParams.set('environmentKey', params.environmentKey);

  return searchParams;
}

export type StaleFlagsChartApiResponse = {
  metadata: {
    summary: {
      allFlagsCount: number;
      staleFlagsCount: number;
      notStaleFlagsCount: number;
    };
  };
};

export const staleFlagChartQuery = (params: StaleFlagChartParams) => ({
  queryKey: ['stale-flags', 'chart', 'staleness', params],
  queryFn: async () => {
    const url = new URL('/api/v2/engineering-insights/charts/flags/stale', location.origin);
    url.search = staleFlagChartParamsToSearchParams(params).toString();

    return (
      await httpUtils.get(url.toString(), {
        headers: {
          'LD-API-version': 'beta',
        },
      })
    ).json() as Promise<StaleFlagsChartApiResponse>;
  },
});

type FlagStatusChartParams = {
  projectKey: string;
  environmentKey: string;
};

function flagStatusChartParamsToSearchParams(params: FlagStatusChartParams) {
  const searchParams = new URLSearchParams();

  searchParams.set('projectKey', params.projectKey);
  searchParams.set('environmentKey', params.environmentKey);

  return searchParams;
}

export type FlagStatusChartApiResponse = {
  metadata: {
    summary: {
      seriesKey: string[];
    };
  };
  series: Array<{
    metadata: { name: string };
    data: Array<{ values: null; x: number; y: number }>;
  }>;
};

export const flagStatusChartQuery = (params: FlagStatusChartParams) => ({
  queryKey: ['stale-flags', 'chart', 'flag-status', params],
  queryFn: async () => {
    const url = new URL('/api/v2/engineering-insights/charts/flags/status', location.origin);
    url.search = flagStatusChartParamsToSearchParams(params).toString();

    return (
      await httpUtils.get(url.toString(), {
        headers: {
          'LD-API-version': 'beta',
        },
      })
    ).json() as Promise<FlagStatusChartApiResponse>;
  },
});

// https://github.com/launchdarkly/accelerate/blob/bd4a6c3c171520e0b702256d774f1f7c3bdb64b9/backend/cmd/accelerate-api/flags_api/get_stale_flags.go#L33

// type getStaleFlagsQueryParams struct {
// 	ProjectKey        string         `query:"projectKey"`
// 	Environments      ru.StringArray `query:"environmentKey"`
// 	Limit             int            `query:"limit"`
// 	Offset            int            `query:"offset"`
// 	Sort              string         `query:"sort"`
// 	Query             string         `query:"query"`
// 	MaintainerId      string         `query:"maintainerId"`
// 	MaintainerTeamKey string         `query:"maintainerTeamKey"`
// 	Tags              ru.StringArray `query:"tags"`
// }

type StaleFlagListParams = {
  projectKey: string;
  environmentKey: string[];
  limit?: number;
  offset?: number;
  sort?: 'creationDate' | '-creationDate';
  // filter?: 'needsReview' | 'readyToArchive'; this is actually computed in the Accelerate UI
  query?: string;
  maintainerId?: string;
  maintainerTeamKey?: string;
  tags?: string[];
};

function staleFlagListParamsToSearchParams(params: StaleFlagListParams) {
  const searchParams = new URLSearchParams();

  searchParams.set('projectKey', params.projectKey);

  for (const environmentKey of params.environmentKey) {
    searchParams.append('environmentKey', environmentKey);
  }

  if ((params.limit ?? 0) > 0) {
    searchParams.set('limit', String(params.limit));
  }

  if ((params.offset ?? 0) > 0) {
    searchParams.set('offset', String(params.offset));
  }

  if (params.sort) {
    searchParams.set('sort', params.sort);
  }

  // if (params.filter) {
  //   searchParams.set('filter', params.filter);
  // }

  if (params.maintainerId) {
    searchParams.set('maintainerId', params.maintainerId);
  }

  if (params.maintainerTeamKey) {
    searchParams.set('maintainerTeamKey', params.maintainerTeamKey);
  }

  if (params.tags) {
    for (const tag of params.tags) {
      searchParams.append('tags', tag);
    }
  }

  if (params.query) {
    searchParams.set('query', params.query);
  }

  return searchParams;
}

export type StaleFlagsApiResponse = {
  totalCount: number;
  items: FeatureFlag[];
};

export const staleFlagListQuery = (params: StaleFlagListParams) => ({
  queryKey: ['stale-flags', 'list', { params }],
  queryFn: async () => {
    const url = new URL('/api/v2/engineering-insights/flags/stale', location.origin);
    url.search = staleFlagListParamsToSearchParams(params).toString();
    return (
      await httpUtils.get(url.toString(), {
        headers: {
          'LD-API-version': 'beta',
        },
      })
    ).json() as Promise<StaleFlagsApiResponse>;
  },
});

type DeploymentListParams = {
  projectKey: string;
  environmentKey: string;
  serviceKey: string;
  limit?: number;
};

function deploymentListParamsToSearchParams(params: DeploymentListParams) {
  const searchParams = new URLSearchParams();

  searchParams.set('projectKey', params.projectKey);
  searchParams.set('environmentKey', params.environmentKey);
  searchParams.set('serviceKey', params.serviceKey);

  if ((params.limit ?? 0) > 0) {
    searchParams.set('limit', String(params.limit));
  }

  return searchParams;
}

export type DeploymentStatus = 'started' | 'finished' | 'failed';

export type Deployment = {
  id: string;
  duration: number;
  startedAt: number;
  endedAt: number;
  status: DeploymentStatus;
  environmentKey: string;
  serviceKey: string;
  serviceVersion: string;
};

export type DeploymentApiResponse = {
  items: Deployment[];
};

export const deploymentListQuery = (params: DeploymentListParams) => ({
  queryKey: ['deployments', 'list', params],
  queryFn: async () => {
    const url = new URL('/api/v2/engineering-insights/deployments', location.origin);
    url.search = deploymentListParamsToSearchParams(params).toString();
    return (
      await httpUtils.get(url.toString(), {
        headers: {
          'LD-API-version': 'beta',
        },
      })
    ).json() as Promise<DeploymentApiResponse>;
  },
});
