import { Map } from 'immutable';

import {
  AddClausesSemanticInstruction,
  AddValuesToClauseSemanticInstruction,
  ClauseInstructionKind,
  RemoveClausesSemanticInstruction,
  RemoveValuesFromClauseSemanticInstruction,
  UpdateClauseSemanticInstruction,
} from '../clauses/types';
import {
  AddExpireTargetDateSemanticInstruction,
  AddExpireUserTargetDateSemanticInstruction,
  ExpiringTargetsInstructionKind,
  RemoveExpireTargetDateSemanticInstruction,
  RemoveExpireUserTargetDateSemanticInstruction,
  SemanticExpiringTargetInstruction,
  SemanticExpiringUserTargetInstruction,
  UpdateExpireTargetDateSemanticInstruction,
  UpdateExpireUserTargetDateSemanticInstruction,
} from '../expiringTargets/types';
import { MembersAndTeamsInstructionKind, SemanticMembersAndTeamsInstruction } from '../membersAndTeams/types';
import {
  OffVariationSemanticInstruction,
  OnOffInstructionKind,
  StopMeasuredRolloutOnFallthrough,
  TurnFlagOffSemanticInstruction,
  TurnFlagOnSemanticInstruction,
  UpdateFallthroughVariationOrRolloutSemanticInstruction,
  UpdateFallthroughWithMeasuredRolloutSemanticInstruction,
} from '../onOff/types';
import {
  FlagPrerequisitesInstructionKind,
  SemanticAddPrerequisiteInstruction,
  SemanticPrerequisiteInstruction,
  SemanticRemovePrerequisiteInstruction,
  SemanticReplacePrerequisitesInstruction,
  SemanticUpdatePrerequisiteInstruction,
} from '../prerequisites/types';
import {
  AddRuleSemanticInstruction,
  AddRuleWithMeasuredRolloutSemanticInstruction,
  RemoveRuleSemanticInstruction,
  ReorderRulesSemanticInstruction,
  ReplaceRulesSemanticInstruction,
  RuleInstructionKind,
  SemanticRuleDescriptionInstruction,
  SemanticRuleInstructionType,
  StopMeasuredRolloutOnRule,
  UpdateRuleVariationOrRolloutSemanticInstruction,
  UpdateRuleWithMeasuredRolloutSemanticInstruction,
} from '../rules/types';
import {
  ReplaceTargetsSemanticInstruction,
  TargetsInstructionKind,
  UpdateTargetsSemanticInstruction,
} from '../targets/types';
import {
  AddUserTargetsSemanticInstruction,
  RemoveUserTargetsSemanticInstruction,
  ReplaceUserTargetsSemanticInstruction,
  UpdateUserTargetsSemanticInstruction,
  UserTargetsInstructionKind,
} from '../userTargets/types';
import {
  AddVariationSemanticInstruction,
  FlagVariationsInstructionKind,
  RemoveVariationSemanticInstruction,
  UpdateDefaultVariationSemanticInstruction,
  UpdateVariationSemanticInstruction,
  VariationSemanticInstruction,
} from '../variations/types';

export type AllFlagInstructionKinds =
  | MembersAndTeamsInstructionKind
  | ExpiringTargetsInstructionKind
  | RuleInstructionKind
  | ClauseInstructionKind
  | UserTargetsInstructionKind
  | OnOffInstructionKind
  | FlagPrerequisitesInstructionKind
  | TargetsInstructionKind
  | FlagVariationsInstructionKind;

export enum ScheduleInstructionKind {
  UPDATE_SCHEDULED_CHANGES_EXECUTION_DATE = 'updateScheduledChangesExecutionDate',
  REPLACE_SCHEDULED_CHANGES = 'replaceScheduledChangesInstructions',
}

export type SemanticInstruction =
  | TurnFlagOnSemanticInstruction
  | TurnFlagOffSemanticInstruction
  | OffVariationSemanticInstruction
  | SemanticRuleInstructionType
  | ReplaceRulesSemanticInstruction
  | UpdateUserTargetsSemanticInstruction
  | ReplaceUserTargetsSemanticInstruction
  | UpdateTargetsSemanticInstruction
  | ReplaceTargetsSemanticInstruction
  | SemanticPrerequisiteInstruction
  | UpdateFallthroughVariationOrRolloutSemanticInstruction
  | UpdateFallthroughWithMeasuredRolloutSemanticInstruction
  | SemanticExpiringUserTargetInstruction
  | SemanticExpiringTargetInstruction
  | SemanticMembersAndTeamsInstruction
  | VariationSemanticInstruction
  | StopMeasuredRolloutOnRule
  | StopMeasuredRolloutOnFallthrough;

// SemanticInstructionForKind<K> returns the instruction type corresponding to an instruction kind.
export type SemanticInstructionForKind<K extends AllFlagInstructionKinds> =
  K extends UserTargetsInstructionKind.ADD_USER_TARGETS
    ? AddUserTargetsSemanticInstruction
    : never | K extends UserTargetsInstructionKind.REMOVE_USER_TARGETS
      ? RemoveUserTargetsSemanticInstruction
      : never | K extends UserTargetsInstructionKind.REPLACE_USER_TARGETS
        ? ReplaceUserTargetsSemanticInstruction
        : never | K extends TargetsInstructionKind.ADD_TARGETS
          ? UpdateTargetsSemanticInstruction
          : never | K extends TargetsInstructionKind.REMOVE_TARGETS
            ? UpdateTargetsSemanticInstruction
            : never | K extends TargetsInstructionKind.REPLACE_TARGETS
              ? ReplaceTargetsSemanticInstruction
              : never | K extends OnOffInstructionKind.TURN_FLAG_ON
                ? TurnFlagOnSemanticInstruction
                : never | K extends OnOffInstructionKind.TURN_FLAG_OFF
                  ? TurnFlagOffSemanticInstruction
                  : never | K extends OnOffInstructionKind.UPDATE_FALLTHROUGH_VARIATION_OR_ROLLOUT
                    ? UpdateFallthroughVariationOrRolloutSemanticInstruction
                    : never | K extends OnOffInstructionKind.UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT
                      ? UpdateFallthroughWithMeasuredRolloutSemanticInstruction
                      : never | K extends OnOffInstructionKind.STOP_MEASURED_ROLLOUT_ON_FALLTHROUGH
                        ? StopMeasuredRolloutOnFallthrough
                        : never | K extends RuleInstructionKind.STOP_MEASURED_ROLLOUT_ON_RULE
                          ? StopMeasuredRolloutOnRule
                          : never | K extends RuleInstructionKind.ADD_RULE
                            ? AddRuleSemanticInstruction
                            : never | K extends RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT
                              ? AddRuleWithMeasuredRolloutSemanticInstruction
                              : never | K extends RuleInstructionKind.REMOVE_RULE
                                ? RemoveRuleSemanticInstruction
                                : never | K extends RuleInstructionKind.REORDER_RULES
                                  ? ReorderRulesSemanticInstruction
                                  : never | K extends RuleInstructionKind.REPLACE_RULES
                                    ? ReplaceRulesSemanticInstruction
                                    : never | K extends ClauseInstructionKind.ADD_CLAUSES
                                      ? AddClausesSemanticInstruction
                                      : never | K extends ClauseInstructionKind.REMOVE_CLAUSES
                                        ? RemoveClausesSemanticInstruction
                                        : never | K extends ClauseInstructionKind.UPDATE_CLAUSE
                                          ? UpdateClauseSemanticInstruction
                                          : never | K extends ClauseInstructionKind.ADD_VALUES_TO_CLAUSE
                                            ? AddValuesToClauseSemanticInstruction
                                            : never | K extends ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE
                                              ? RemoveValuesFromClauseSemanticInstruction
                                              : never | K extends RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT
                                                ? UpdateRuleVariationOrRolloutSemanticInstruction
                                                :
                                                      | never
                                                      | K extends RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT
                                                  ? UpdateRuleWithMeasuredRolloutSemanticInstruction
                                                  : never | K extends OnOffInstructionKind.UPDATE_OFF_VARIATION
                                                    ? OffVariationSemanticInstruction
                                                    :
                                                          | never
                                                          | K extends FlagPrerequisitesInstructionKind.ADD_PREREQUISITE
                                                      ? SemanticAddPrerequisiteInstruction
                                                      :
                                                            | never
                                                            | K extends FlagPrerequisitesInstructionKind.UPDATE_PREREQUISITE
                                                        ? SemanticUpdatePrerequisiteInstruction
                                                        :
                                                              | never
                                                              | K extends FlagPrerequisitesInstructionKind.REMOVE_PREREQUISITE
                                                          ? SemanticRemovePrerequisiteInstruction
                                                          :
                                                                | never
                                                                | K extends FlagPrerequisitesInstructionKind.REPLACE_PREREQUISITES
                                                            ? SemanticReplacePrerequisitesInstruction
                                                            :
                                                                  | never
                                                                  | K extends RuleInstructionKind.UPDATE_RULE_DESCRIPTION
                                                              ? SemanticRuleDescriptionInstruction
                                                              :
                                                                    | never
                                                                    | K extends ExpiringTargetsInstructionKind.ADD_EXPIRE_USER_TARGET_DATE
                                                                ? AddExpireUserTargetDateSemanticInstruction
                                                                :
                                                                      | never
                                                                      | K extends ExpiringTargetsInstructionKind.UPDATE_EXPIRE_USER_TARGET_DATE
                                                                  ? UpdateExpireUserTargetDateSemanticInstruction
                                                                  :
                                                                        | never
                                                                        | K extends ExpiringTargetsInstructionKind.REMOVE_EXPIRE_USER_TARGET_DATE
                                                                    ? RemoveExpireUserTargetDateSemanticInstruction
                                                                    :
                                                                          | never
                                                                          | K extends ExpiringTargetsInstructionKind.ADD_EXPIRE_TARGET_DATE
                                                                      ? AddExpireTargetDateSemanticInstruction
                                                                      :
                                                                            | never
                                                                            | K extends ExpiringTargetsInstructionKind.UPDATE_EXPIRE_TARGET_DATE
                                                                        ? UpdateExpireTargetDateSemanticInstruction
                                                                        :
                                                                              | never
                                                                              | K extends ExpiringTargetsInstructionKind.REMOVE_EXPIRE_TARGET_DATE
                                                                          ? RemoveExpireTargetDateSemanticInstruction
                                                                          :
                                                                                | never
                                                                                | K extends MembersAndTeamsInstructionKind.ADD_MEMBERS
                                                                            ? SemanticMembersAndTeamsInstruction
                                                                            :
                                                                                  | never
                                                                                  | K extends MembersAndTeamsInstructionKind.REMOVE_MEMBERS
                                                                              ? RemoveExpireTargetDateSemanticInstruction
                                                                              :
                                                                                    | never
                                                                                    | K extends MembersAndTeamsInstructionKind.ADD_TEAM_KEYS
                                                                                ? RemoveExpireUserTargetDateSemanticInstruction
                                                                                :
                                                                                      | never
                                                                                      | K extends FlagVariationsInstructionKind.ADD_VARIATION
                                                                                  ? AddVariationSemanticInstruction
                                                                                  :
                                                                                        | never
                                                                                        | K extends FlagVariationsInstructionKind.REMOVE_VARIATION
                                                                                    ? RemoveVariationSemanticInstruction
                                                                                    :
                                                                                          | never
                                                                                          | K extends FlagVariationsInstructionKind.UPDATE_VARIATION
                                                                                      ? UpdateVariationSemanticInstruction
                                                                                      :
                                                                                            | never
                                                                                            | K extends FlagVariationsInstructionKind.UPDATE_DEFAULT_VARIATION
                                                                                        ? UpdateDefaultVariationSemanticInstruction
                                                                                        : never;

export const instructionKindToDescription = {
  [UserTargetsInstructionKind.ADD_USER_TARGETS]: 'Add user target',
  [UserTargetsInstructionKind.REMOVE_USER_TARGETS]: 'Remove user target',
  [UserTargetsInstructionKind.REPLACE_USER_TARGETS]: 'Replace individual users with',
  [TargetsInstructionKind.ADD_TARGETS]: 'Add target',
  [TargetsInstructionKind.REMOVE_TARGETS]: 'Remove target',
  [TargetsInstructionKind.REPLACE_TARGETS]: 'Replace targets with',
  [OnOffInstructionKind.TURN_FLAG_ON]: 'Update targeting to',
  [OnOffInstructionKind.TURN_FLAG_OFF]: 'Update targeting to',
  [OnOffInstructionKind.UPDATE_FALLTHROUGH_VARIATION_OR_ROLLOUT]: 'Set default rule to',
  [OnOffInstructionKind.UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT]: 'Update default rule with measured rollout',
  [OnOffInstructionKind.STOP_MEASURED_ROLLOUT_ON_FALLTHROUGH]: 'Stop measured rollout, set default rule to',
  [RuleInstructionKind.STOP_MEASURED_ROLLOUT_ON_RULE]: 'Stop measured rollout, set rule to',
  [RuleInstructionKind.ADD_RULE]: 'Add rule',
  [RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT]: 'Add rule with measured rollout',
  [RuleInstructionKind.REMOVE_RULE]: 'Remove rule',
  [RuleInstructionKind.REORDER_RULES]: 'Reorder rules',
  [RuleInstructionKind.REPLACE_RULES]: 'Replace rules with',
  [ClauseInstructionKind.ADD_CLAUSES]: 'Add clause',
  [ClauseInstructionKind.REMOVE_CLAUSES]: 'Remove clause',
  [ClauseInstructionKind.UPDATE_CLAUSE]: 'Update clause',
  [ClauseInstructionKind.ADD_VALUES_TO_CLAUSE]: 'Add values to clause',
  [ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE]: 'Remove values from clause',
  [RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT]: 'Update rule variation to',
  [RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT]: 'Update rule with measured rollout',
  [OnOffInstructionKind.UPDATE_OFF_VARIATION]: 'Update off variation to',
  [FlagPrerequisitesInstructionKind.ADD_PREREQUISITE]: 'Add prerequisite',
  [FlagPrerequisitesInstructionKind.UPDATE_PREREQUISITE]: 'Update prerequisite',
  [FlagPrerequisitesInstructionKind.REMOVE_PREREQUISITE]: 'Remove prerequisite',
  [FlagPrerequisitesInstructionKind.REPLACE_PREREQUISITES]: 'Replace prerequisites with',
  [RuleInstructionKind.UPDATE_RULE_DESCRIPTION]: 'Update rule description',
  [MembersAndTeamsInstructionKind.ADD_MEMBERS]: 'Add members',
  [MembersAndTeamsInstructionKind.REMOVE_MEMBERS]: 'Remove members',
  [MembersAndTeamsInstructionKind.ADD_TEAM_KEYS]: 'Add Team keys',
  [ExpiringTargetsInstructionKind.ADD_EXPIRE_USER_TARGET_DATE]: 'Add scheduled date',
  [ExpiringTargetsInstructionKind.REMOVE_EXPIRE_USER_TARGET_DATE]: 'Remove scheduled date',
  [ExpiringTargetsInstructionKind.UPDATE_EXPIRE_USER_TARGET_DATE]: 'Update scheduled date',
  [ExpiringTargetsInstructionKind.ADD_EXPIRE_TARGET_DATE]: 'Add scheduled date',
  [ExpiringTargetsInstructionKind.REMOVE_EXPIRE_TARGET_DATE]: 'Remove scheduled date',
  [ExpiringTargetsInstructionKind.UPDATE_EXPIRE_TARGET_DATE]: 'Update scheduled date',
  [FlagVariationsInstructionKind.ADD_VARIATION]: 'Add variation',
  [FlagVariationsInstructionKind.REMOVE_VARIATION]: 'Remove variation',
  [FlagVariationsInstructionKind.UPDATE_VARIATION]: 'Update variation',
  [FlagVariationsInstructionKind.UPDATE_DEFAULT_VARIATION]: 'Change default',
};

export enum InstructionCategory {
  USER_TARGETS = 'USER_TARGETS',
  REPLACE_USER_TARGETS = 'REPLACE_USER_TARGETS',
  TARGETS = 'TARGETS',
  REPLACE_TARGETS = 'REPLACE_TARGETS',
  PREREQUISITES = 'PREREQUISITES',
  REPLACE_PREREQUISITES = 'REPLACE_PREREQUISITES',
  ON_OFF = 'ON_OFF',
  RULES = 'RULES',
  REPLACE_RULES = 'REPLACE_RULES',
  FALLTHROUGH = 'FALLTHROUGH',
  OFF_VARIATION = 'OFF_VARIATION',
  EXPIRE_USER_TARGETS = 'EXPIRE_USER_TARGETS',
  EXPIRE_TARGETS = 'EXPIRE_TARGETS',
  MEMBERS_AND_TEAMS = 'MEMBERS_AND_TEAMS',
  VARIATIONS = 'VARIATIONS',
  MEASURED_ROLLOUT = 'MEASURED_ROLLOUT',
}

export const InstructionKindToCategory = {
  [UserTargetsInstructionKind.ADD_USER_TARGETS]: InstructionCategory.USER_TARGETS,
  [UserTargetsInstructionKind.REMOVE_USER_TARGETS]: InstructionCategory.USER_TARGETS,
  [UserTargetsInstructionKind.REPLACE_USER_TARGETS]: InstructionCategory.REPLACE_USER_TARGETS,
  [TargetsInstructionKind.ADD_TARGETS]: InstructionCategory.TARGETS,
  [TargetsInstructionKind.REMOVE_TARGETS]: InstructionCategory.TARGETS,
  [TargetsInstructionKind.REPLACE_TARGETS]: InstructionCategory.REPLACE_TARGETS,
  [OnOffInstructionKind.TURN_FLAG_ON]: InstructionCategory.ON_OFF,
  [OnOffInstructionKind.TURN_FLAG_OFF]: InstructionCategory.ON_OFF,
  [OnOffInstructionKind.UPDATE_FALLTHROUGH_VARIATION_OR_ROLLOUT]: InstructionCategory.FALLTHROUGH,
  [OnOffInstructionKind.STOP_MEASURED_ROLLOUT_ON_FALLTHROUGH]: InstructionCategory.FALLTHROUGH,
  [OnOffInstructionKind.UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT]: InstructionCategory.FALLTHROUGH,
  [RuleInstructionKind.STOP_MEASURED_ROLLOUT_ON_RULE]: InstructionCategory.RULES,
  [RuleInstructionKind.ADD_RULE]: InstructionCategory.RULES,
  [RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT]: InstructionCategory.RULES,
  [RuleInstructionKind.REMOVE_RULE]: InstructionCategory.RULES,
  [RuleInstructionKind.REORDER_RULES]: InstructionCategory.RULES,
  [RuleInstructionKind.REPLACE_RULES]: InstructionCategory.REPLACE_RULES,
  [ClauseInstructionKind.ADD_CLAUSES]: InstructionCategory.RULES,
  [ClauseInstructionKind.REMOVE_CLAUSES]: InstructionCategory.RULES,
  [ClauseInstructionKind.UPDATE_CLAUSE]: InstructionCategory.RULES,
  [ClauseInstructionKind.ADD_VALUES_TO_CLAUSE]: InstructionCategory.RULES,
  [ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE]: InstructionCategory.RULES,
  [RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT]: InstructionCategory.RULES,
  [RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT]: InstructionCategory.RULES,
  [OnOffInstructionKind.UPDATE_OFF_VARIATION]: InstructionCategory.OFF_VARIATION,
  [FlagPrerequisitesInstructionKind.ADD_PREREQUISITE]: InstructionCategory.PREREQUISITES,
  [FlagPrerequisitesInstructionKind.UPDATE_PREREQUISITE]: InstructionCategory.PREREQUISITES,
  [FlagPrerequisitesInstructionKind.REMOVE_PREREQUISITE]: InstructionCategory.PREREQUISITES,
  [FlagPrerequisitesInstructionKind.REPLACE_PREREQUISITES]: InstructionCategory.REPLACE_PREREQUISITES,
  [RuleInstructionKind.UPDATE_RULE_DESCRIPTION]: InstructionCategory.RULES,
  [MembersAndTeamsInstructionKind.ADD_MEMBERS]: InstructionCategory.MEMBERS_AND_TEAMS,
  [MembersAndTeamsInstructionKind.REMOVE_MEMBERS]: InstructionCategory.MEMBERS_AND_TEAMS,
  [MembersAndTeamsInstructionKind.ADD_TEAM_KEYS]: InstructionCategory.MEMBERS_AND_TEAMS,
  [ExpiringTargetsInstructionKind.ADD_EXPIRE_USER_TARGET_DATE]: InstructionCategory.EXPIRE_USER_TARGETS,
  [ExpiringTargetsInstructionKind.REMOVE_EXPIRE_USER_TARGET_DATE]: InstructionCategory.EXPIRE_USER_TARGETS,
  [ExpiringTargetsInstructionKind.UPDATE_EXPIRE_USER_TARGET_DATE]: InstructionCategory.EXPIRE_USER_TARGETS,
  [ExpiringTargetsInstructionKind.ADD_EXPIRE_TARGET_DATE]: InstructionCategory.EXPIRE_TARGETS,
  [ExpiringTargetsInstructionKind.REMOVE_EXPIRE_TARGET_DATE]: InstructionCategory.EXPIRE_TARGETS,
  [ExpiringTargetsInstructionKind.UPDATE_EXPIRE_TARGET_DATE]: InstructionCategory.EXPIRE_TARGETS,
  [FlagVariationsInstructionKind.ADD_VARIATION]: InstructionCategory.VARIATIONS,
  [FlagVariationsInstructionKind.UPDATE_VARIATION]: InstructionCategory.VARIATIONS,
  [FlagVariationsInstructionKind.REMOVE_VARIATION]: InstructionCategory.VARIATIONS,
  [FlagVariationsInstructionKind.UPDATE_DEFAULT_VARIATION]: InstructionCategory.VARIATIONS,
};

export type InstructionKey = string;
export type InstructionsType = Map<InstructionKey, SemanticInstruction>;

export enum FlagConflictKind {
  PENDING_CHANGES_WILL_FAIL = 'pendingChangeWillFail',
  PROPOSED_SCHEDULED_CHANGES_WILL_FAIL = 'proposedScheduledChangesWillFail',
  PROPOSED_APPROVED_CHANGES_WILL_FAIL = 'proposedApprovedChangesWillFail',
}

export type InstructionIndexToConflictsInfo = {
  [instructionIndex: number]: ConflictsInfoForInstruction;
};

export type ConflictsInfoForInstruction = {
  conflicts: Array<{
    conflictReason: string;
    conflictKind: FlagConflictKind;
  }>;
  summaryConflictKind: FlagConflictKind;
};

export type MeasuredRolloutSemanticInstruction = {
  testVariationId: string;
  controlVariationId: string;
  randomizationUnit: string;
  onRegression?: {
    notify: boolean;
    rollback: boolean;
  };
  onProgression?: {
    notify: boolean;
    rollForward: boolean;
  };
  monitoringWindowMilliseconds?: number;
  rolloutWeight?: number;
};
