import { connect } from 'react-redux';
import { List } from 'immutable';

import { editChangeDescription } from 'actions/comments';
import SaveButton, { SaveButtonProps } from 'components/SaveButton';
import { GlobalState } from 'reducers';
import { makeChangeDescriptionSelector } from 'reducers/comments';
import { currentEnvironmentSelector, currentProjectSelector, environmentsSelector } from 'reducers/projects';
import { Environment } from 'utils/environmentUtils';
import { Flag } from 'utils/flagUtils';
import { ready } from 'utils/reduxUtils';
import { Role } from 'utils/roleUtils';
import { ResourceKind } from 'utils/saveButtonUtils';
import { Segment } from 'utils/segmentUtils';
import { User } from 'utils/userUtils';

export type SaveButtonContainerProps = SaveButtonProps & {
  onSave(comment?: string): void;
  onEditChangeDescription(val: string): void;
};

export function SaveButtonContainer(props: SaveButtonContainerProps) {
  const {
    disabled,
    isLoading,
    isCommentRequired,
    isCommentRequiredInAnyEnv,
    isProjectLevelChange,
    isAccountLevelChange,
    resource,
    resourceKind,
    comment,
    isEnvConfirmationRequired,
    onSave,
    onEditChangeDescription,
    isConfirmationRequiredInAnyEnv,
    environment,
    loadingText,
    testId,
    saveText,
    saveWithCommentText,
    className,
    tooltip,
    customSaveCopy,
    changeAlert,
  } = props;

  return (
    <SaveButton
      loadingText={loadingText}
      isLoading={isLoading}
      isCommentRequired={isCommentRequired}
      comment={comment}
      saveWithCommentText={saveWithCommentText}
      onSave={onSave}
      className={className}
      onEditChangeDescription={onEditChangeDescription}
      environment={environment}
      isConfirmationRequiredInAnyEnv={isConfirmationRequiredInAnyEnv}
      isEnvConfirmationRequired={isEnvConfirmationRequired}
      isProjectLevelChange={isProjectLevelChange}
      isAccountLevelChange={isAccountLevelChange}
      resource={resource}
      isCommentRequiredInAnyEnv={isCommentRequiredInAnyEnv}
      resourceKind={resourceKind}
      disabled={disabled}
      customSaveCopy={customSaveCopy}
      testId={testId}
      saveText={saveText}
      tooltip={tooltip}
      changeAlert={changeAlert}
    />
  );
}

type OwnProps = {
  resource?: User | Segment | Flag | Role;
  resourceKind: ResourceKind;
  isProjectLevelChange?: boolean;
  isAccountLevelChange?: boolean;
};

const mapStateToProps = (state: GlobalState, { resource, resourceKind }: OwnProps) => {
  const commentSelector = makeChangeDescriptionSelector();
  const comment = commentSelector(state, { resource });

  const currentEnvironmentState = currentEnvironmentSelector(state);
  const environment = currentEnvironmentState.get('entity');
  const currentProjectState = currentProjectSelector(state);
  const allEnvironments = environmentsSelector(state);
  const isReady = ready(currentEnvironmentState);

  const environments = isReady
    ? currentProjectState
        .getIn(['entity', 'environments'])
        .map((envId: string) => allEnvironments.getIn(['entities', envId]))
    : List();
  const requiredCommentResources = resourceKind === ResourceKind.FLAG || resourceKind === ResourceKind.SEGMENT;
  const isCommentRequired = requiredCommentResources
    ? currentEnvironmentSelector(state).getIn(['entity', 'requireComments'])
    : false;
  const isCommentRequiredInAnyEnv = requiredCommentResources
    ? environments?.some((env: Environment) => env?.requireComments)
    : false;
  const isConfirmationRequiredInAnyEnv = requiredCommentResources
    ? environments?.some((env: Environment) => env?.confirmChanges)
    : false;

  const isEnvConfirmationRequired = currentEnvironmentSelector(state).getIn(['entity', 'confirmChanges']);

  return {
    comment,
    environment,
    isCommentRequired,
    isEnvConfirmationRequired,
    isCommentRequiredInAnyEnv,
    isConfirmationRequiredInAnyEnv,
  };
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const mapDispatchToProps = (dispatch: $TSFixMe, ownProps: OwnProps) => ({
  onEditChangeDescription: (comment: string) =>
    dispatch(editChangeDescription(ownProps.resourceKind, ownProps.resource!, comment)),
}); /* eslint-disable import/no-default-export */
/* eslint-enable @typescript-eslint/no-non-null-assertion */

export default connect(mapStateToProps, mapDispatchToProps)(SaveButtonContainer);
