import { type APIFlagFilter } from '@gonfalon/rest-api';

import { creationDateRangeToDate } from './creationDateRangeToDate';
import { evaluatedDateRangeToDate } from './evaluatedDateRangeToDate';
import { type UIFlagFilter } from './types';

export function serializeUIFlagFilterForAPI(filter: UIFlagFilter) {
  const apiFilter: APIFlagFilter = {};
  apiFilter.query = filter.query;
  apiFilter.hasDataExport = filter.hasDataExport;
  apiFilter.hasExperiment = filter.hasExperiment;
  apiFilter.maintainerId = filter.maintainerId;
  apiFilter.maintainerTeamKey = filter.maintainerTeamKey;
  apiFilter.tags = filter.tags;
  apiFilter.type = filter.type;
  apiFilter.sdkAvailability = filter.sdkAvailability;
  apiFilter.filterEnv = filter.filterEnv;
  apiFilter.status = filter.status;
  apiFilter.state = filter.state;
  apiFilter.targeting = filter.targeting;
  apiFilter.contextKindTargeted = filter.contextKindTargeted;
  apiFilter.contextKindsEvaluated = filter.contextKindsEvaluated;
  apiFilter.followerId = filter.followerId;
  apiFilter.applicationEvaluated = filter.applicationEvaluated;
  apiFilter.segmentTargeted = filter.segmentTargeted;
  apiFilter.excludeSettings = filter.excludeSettings;
  apiFilter.applicationEvaluated = filter.applicationEvaluated;

  if (filter.codeReferences !== undefined) {
    apiFilter.codeReferences = filter.codeReferences ? { min: 1 } : { max: 0 };
  }

  const now = Date.now();

  if (filter.evaluated !== undefined) {
    const evaluatedDate = evaluatedDateRangeToDate(filter.evaluated, now);
    apiFilter.evaluated = evaluatedDate ? { after: evaluatedDate.getTime() } : undefined;
  }

  if (filter.created !== undefined) {
    const createdDate = creationDateRangeToDate(filter.created, now);
    apiFilter.creationDate = createdDate ? { before: createdDate.getTime() } : undefined;
  }

  return apiFilter;
}
