import { GetSegmentsParams } from '@gonfalon/rest-api';

import { UISegmentsListView } from './types';

export function parseUISegmentViewFromAPI(api: GetSegmentsParams) {
  const ui: UISegmentsListView = {};
  if (api.sort) {
    ui.sort = api.sort;
  }
  if (api.filter?.query) {
    ui.query = api.filter.query;
  }
  if (api.filter?.keys) {
    ui.keys = api.filter.keys;
  }
  if (api.filter?.tags) {
    ui.tags = api.filter.tags;
  }
  if (api.filter?.kind) {
    ui.kind = api.filter.kind;
  }
  return ui;
}
