import { ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { toCustomRoles } from '@gonfalon/navigator';
import { Map } from 'immutable';
import { FormGroup, Label } from 'launchpad';

import CustomRoleCheckbox from 'components/CustomRoleCheckbox';
import { Role } from 'utils/roleUtils';

import styles from './CustomRoleCheckboxList.module.css';

type CustomRoleCheckboxListProps = {
  canUpdateCustomRole: (role: Role) => boolean;
  customRoles: Map<string, Role>;
  explainRestrictionForCustomRole: (role: Role) => string;
  forceCustom: boolean;
  onChangeCustomRole: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedCustomRoles: string[];
};

const CustomRoleCheckboxList = ({
  canUpdateCustomRole,
  customRoles,
  explainRestrictionForCustomRole,
  forceCustom,
  onChangeCustomRole,
  selectedCustomRoles,
}: CustomRoleCheckboxListProps) => (
  <FormGroup className={styles.list}>
    <Label className="u-assistive">Custom role</Label>
    {customRoles.isEmpty() ? (
      <p className="u-voice u-fs-sm">
        You don’t have any custom roles yet. <Link to={toCustomRoles()}>Manage your roles</Link>
      </p>
    ) : (
      <>
        <div className="u-col2">
          {customRoles
            .toList()
            .sortBy((r) => r.name.toLowerCase())
            .map((role) => (
              <CustomRoleCheckbox
                isChecked={selectedCustomRoles.includes(role._id)}
                isRestricted={!forceCustom || !canUpdateCustomRole(role)}
                key={role._id}
                restrictedTooltip={explainRestrictionForCustomRole(role)}
                role={role}
                onChange={onChangeCustomRole}
              />
            ))
            .toArray()}
        </div>
      </>
    )}
  </FormGroup>
);

/* eslint-disable import/no-default-export */
export default CustomRoleCheckboxList;
