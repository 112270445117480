import cx from 'clsx';

import './styles.css';

type CardGridProps = {
  wrap?: boolean;
  justifyCenter?: boolean;
  className?: string;
  children?: React.ReactNode;
};

export function CardGrid({ justifyCenter = false, className, children, wrap = true }: CardGridProps) {
  return (
    <div className={cx('CardGrid', className, { 'u-flex-wrap': wrap, 'CardGrid--center': justifyCenter })}>
      {children}
    </div>
  );
}
