import { lazy, ReactNode, Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { NewTabLink } from '@gonfalon/launchpad-experimental';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';

import { fetchAccount as fetchAccountAction } from 'actions/account';
import DocumentationLink from 'components/DocumentationLink';
import { GlobalState } from 'reducers';
import { accountSelector } from 'reducers/account';
import { profileSelector } from 'reducers/profile';
import { Account } from 'utils/accountUtils';
import { getComponentAnalytics, trackUpsellFeatureViewed } from 'utils/analyticsUtils';
import { ready } from 'utils/reduxUtils';

import styles from './UpsellInlineBanner.module.css';

const ContactUs = lazy(async () => import(/* webpackChunkName: "ContactUs" */ 'components/contactUs/ContactUs'));

export type UpsellInlineBannerProps = {
  account?: Account;
  featureKind: string;
  title: string;
  description?: string;
  learnMoreUrl?: string;
  canManagePlans: boolean;
  isEnterpriseOnlyFeature?: boolean;
  isReady: boolean;
  className?: string;
  ctaText?: string;
  fetchAccount(): void;
  componentForAnalyticTracking: string;
  hideTitle?: boolean;
  footerContent?: ReactNode;
  contactUsModalHeaderText?: string;
};

export function UpsellInlineBanner({
  title,
  description,
  learnMoreUrl,
  canManagePlans,
  isReady,
  isEnterpriseOnlyFeature,
  className,
  ctaText = 'upgrade',
  footerContent,
  fetchAccount,
  featureKind,
  componentForAnalyticTracking,
  contactUsModalHeaderText,
  hideTitle = false,
}: UpsellInlineBannerProps) {
  useEffect(() => {
    if (!isReady) {
      fetchAccount();
    }

    trackUpsellFeatureViewed(
      featureKind,
      getComponentAnalytics({
        component: 'UpsellInlineBanner',
        type: 'view',
      }),
    );
  }, []);

  const LearnMoreLink = () => {
    if (!learnMoreUrl) {
      return <></>;
    }
    return (
      <>
        {'To learn more, '}
        {learnMoreUrl.includes('docs.launchdarkly.com') ? (
          <DocumentationLink href={learnMoreUrl} component="UpsellInlineBanner" />
        ) : (
          <NewTabLink href={learnMoreUrl} text="read about this feature" />
        )}{' '}
        <span>or</span>{' '}
      </>
    );
  };

  const CtaLink = () => {
    if (isEnterpriseOnlyFeature) {
      return (
        <Suspense fallback={null}>
          <ContactUs
            featureKind={featureKind}
            isEnterpriseOnlyFeature={isEnterpriseOnlyFeature}
            componentForAnalyticTracking={componentForAnalyticTracking}
            isLink
            modalHeaderText={contactUsModalHeaderText}
            cta="contact us."
          />
        </Suspense>
      );
    }
    if (canManagePlans) {
      return <a href="/settings/billing/plans">{ctaText}</a>;
    }
    return (
      <a href="https://launchdarkly.com/pricing/" rel="noreferrer" target="_blank">
        view plans.
      </a>
    );
  };

  return (
    <div className={cx(className, styles.upsellInlineBanner)}>
      {!hideTitle && (
        <div>
          <Icon name="star" className={styles.star} size="medium" />
        </div>
      )}
      <div className="u-grow-1">
        {!hideTitle && <span className="u-fw-medium">{title}. </span>}
        {description && <span>{description} </span>}
        <span>
          <LearnMoreLink />
          <CtaLink />
        </span>
      </div>
      {footerContent && footerContent}
    </div>
  );
}

const mapStateToProps = (state: GlobalState) => {
  const profile = profileSelector(state).get('entity');
  const account = accountSelector(state);
  const accountEntity = account?.get('entity');
  const checkAccess = accountEntity?.checkAccess({ profile });
  const updateSubscriptionAccess = checkAccess && checkAccess('updateSubscription');
  return {
    isReady: ready(account),
    canManagePlans: updateSubscriptionAccess?.isAllowed || false,
    account: accountEntity,
  };
};

const mapDispatchToProps = {
  fetchAccount: fetchAccountAction,
};
/* eslint-disable import/no-default-export */
export default connect(mapStateToProps, mapDispatchToProps)(UpsellInlineBanner);
