import { AuditLogEntryRep } from '@gonfalon/audit-log';
import { noop } from '@gonfalon/es6-utils';
import { getQueryClient } from '@gonfalon/react-query-client';
import { FlagEventCollection, flagEventListQuery } from 'ia-poc/services/accelerate/queries';
import { List } from 'immutable';

import actionTypes from 'actionTypes/auditLog';
import { GetState, GlobalDispatch, GlobalState } from 'reducers';
import { auditLogEntrySelector, auditLogSelector } from 'reducers/auditLog';
import { AuditLogEntriesResponse, getEntries, getEntryById } from 'sources/AuditLogAPI';
import { AuditLogEntry, Query } from 'utils/auditLogUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import { createPagination } from 'utils/paginationUtils';
import { GenerateActionType } from 'utils/reduxUtils';

function mapKeyToUrl(key: string): string {
  if (key === 'latest') {
    return '/api/v2/auditlog';
  } else {
    return `/api/v2/auditlog?${key}`;
  }
}

const requestEntries = (key: string) => ({ type: 'auditLog/REQUEST_ENTRIES', key }) as const;
const requestEntriesDone = (key: string, response: AuditLogEntriesResponse) =>
  ({ type: 'auditLog/REQUEST_ENTRIES_DONE', key, response }) as const;
const requestEntriesFailed = (key: string, error: ImmutableServerError) =>
  ({ type: 'auditLog/REQUEST_ENTRIES_FAILED', key, error }) as const;

const requestSignificantEventsForAuditLogEntries = (
  entries: AuditLogEntryRep[],
  projectKey: string,
  environmentKey: string,
) => ({ type: 'auditLog/REQUEST_SIGNIFICANT_EVENTS', entries, projectKey, environmentKey }) as const;

const requestSignificantEventsForAuditLogEntriesDone = (
  entries: AuditLogEntryRep[],
  projectKey: string,
  environmentKey: string,
  collection: FlagEventCollection,
) => ({ type: 'auditLog/REQUEST_SIGNIFICANT_EVENTS_DONE', entries, projectKey, environmentKey, collection }) as const;

const requestSignificantEventsForAuditLogEntriesFailed = (
  entries: AuditLogEntryRep[],
  projectKey: string,
  environmentKey: string,
  error: ImmutableServerError,
) => ({ type: 'auditLog/REQUEST_SIGNIFICANT_EVENTS_FAILED', entries, projectKey, environmentKey, error }) as const;

function fetchSignificantFlagEventsForAuditLogEntries(
  entries: AuditLogEntryRep[],
  projectKey: string,
  environmentKey: string,
) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestSignificantEventsForAuditLogEntries(entries, projectKey, environmentKey));
    return getQueryClient()
      .fetchQuery(
        flagEventListQuery({
          projectKey,
          environmentKey,
          significant: true,
          auditLogIds: entries.map((e) => e._id),
        }),
      )
      .then((response) => {
        dispatch(requestSignificantEventsForAuditLogEntriesDone(entries, projectKey, environmentKey, response));
      })
      .catch((error: ImmutableServerError) => {
        dispatch(requestSignificantEventsForAuditLogEntriesFailed(entries, projectKey, environmentKey, error));
      });
  };
}

function fetchEntries(
  query: Query,
  nextPageUrl?: string | null,
  options: { fetchUntilExhausted?: boolean; maxRequests?: number; requestCount?: number } = {},
) {
  const key = query.toQueryString();
  const policy = query.policy;
  const url = nextPageUrl || mapKeyToUrl(key);
  const { fetchUntilExhausted } = options;
  const maxRequests = options.maxRequests || 100;
  const requestCount = options.requestCount || 1;

  return async (dispatch: GlobalDispatch) => {
    dispatch(requestEntries(key));
    // @ts-expect-error policy should be defined, but the record type has it as optional
    return getEntries(url, policy)
      .then((response) => {
        if (fetchUntilExhausted) {
          const nextUrl = response.getIn(['result', '_links', 'next', 'href']);
          if (nextUrl && requestCount < maxRequests) {
            dispatch(fetchEntries(query, nextUrl, { ...options, requestCount: requestCount + 1 })).catch(noop);
          }
        }
        dispatch(requestEntriesDone(key, response));
      })
      .catch((error) => dispatch(requestEntriesFailed(key, error)));
  };
}

function loadEntries(query: Query, nextPage?: boolean) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    const key = query.toQueryString();
    const log = auditLogSelector(getState());
    const pagination = log.pagination.get(key, createPagination());
    const { isFetching, error, nextPageUrl, pageCount = 0 } = pagination.toObject();

    if (isFetching || error || (pageCount > 0 && !nextPage)) {
      return;
    }

    return dispatch(fetchEntries(query, nextPageUrl));
  };
}

const requestEntry = (entryId: string) => ({ type: 'auditLog/REQUEST_ENTRY', entryId }) as const;
const requestEntryDone = (entryId: string, entry: AuditLogEntry) =>
  ({ type: 'auditLog/REQUEST_ENTRY_DONE', entryId, entry }) as const;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const requestEntryFailed = (entryId: string, error: any) =>
  ({ type: 'auditLog/REQUEST_ENTRY_FAILED', entryId, error }) as const;

function fetchEntryById(entryId: string) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestEntry(entryId));
    return getEntryById(entryId)
      .then((entry: AuditLogEntry) => dispatch(requestEntryDone(entryId, entry)))
      .catch((error) => dispatch(requestEntryFailed(entryId, error)));
  };
}

function shouldFetchEntry(state: GlobalState, entryId: string) {
  const entry = auditLogEntrySelector(state, { match: { params: { entryId } } });
  return entry ? !entry.get('lastFetched') && !entry.get('isFetching') : true;
}

function fetchEntryByIdIfNeeded(entryId: string) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchEntry(getState(), entryId)) {
      return dispatch(fetchEntryById(entryId));
    }
  };
}

function invalidateEntries() {
  return { type: actionTypes.INVALIDATE_ENTRIES };
}

const findEntries = (query: Query, nextPage?: boolean) => loadEntries(query, nextPage);

const showInsightsDetail = (date: number, entries: List<AuditLogEntry>) =>
  ({
    type: 'auditLog/SHOW_INSIGHTS_DETAIL',
    date,
    entries: entries.map((e) => e._id).toArray(),
  }) as const;

const hideInsightsDetail = () => ({ type: 'auditLog/HIDE_INSIGHTS_DETAIL' }) as const;

const saveAuditLogQuery = (query: Query) => ({ type: 'auditLog/SAVE_ALL_QUERIES', query }) as const;

export {
  fetchEntries,
  findEntries,
  fetchEntryByIdIfNeeded as fetchEntryById,
  invalidateEntries,
  showInsightsDetail,
  hideInsightsDetail,
  fetchSignificantFlagEventsForAuditLogEntries,
  saveAuditLogQuery,
};

const AuditLogActionCreators = {
  requestEntries,
  requestEntriesDone,
  requestEntriesFailed,
  requestSignificantEventsForAuditLogEntries,
  requestSignificantEventsForAuditLogEntriesDone,
  requestSignificantEventsForAuditLogEntriesFailed,
  requestEntry,
  requestEntryDone,
  requestEntryFailed,
  showInsightsDetail,
  hideInsightsDetail,
  saveAuditLogQuery,
};

export type AuditLogAction = GenerateActionType<typeof AuditLogActionCreators>;
