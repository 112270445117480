import { UseFormReturn } from 'react-hook-form';
import { ApplicationRep } from '@gonfalon/applications';
import { z, ZodType } from 'zod';

import { Member } from 'utils/accountUtils';
import { Team } from 'utils/teamsUtils';

export enum ApplicationsTableColumns {
  NAME = 'ApplicationListTableColumn-Name',
  MORE = 'ApplicationListTableColumn-More',
}

export enum AppVersionsTableColumns {
  NAME = 'AppVersionTableListColumn-Name',
  ADOPTION = 'AppVersionTableListColumn-Adoption',
  SUPPORTED = 'AppVersionTableListColumn-Supported',
  MORE = 'AppVersionTableListColumn-More',
}

export enum ApplicationKindTypes {
  Mobile = 'mobile',
  Server = 'server',
  Browser = 'browser',
}

export enum ZodFormErrors {
  REQUIRED_FIELD_MESSAGE = 'Error: This field is required',
  EXCEEDED_MAX_CHAR_LIMIT = 'Error: This field must be at most 256 characters',
}

export type ApplicationFormTypes = {
  name: string;
  key: string;
  kind: 'mobile' | 'browser' | 'server';
  description?: string;
  maintainer?: string | Team | Member;
};

export const ApplicationFormSchema: ZodType<ApplicationFormTypes> = z.object({
  name: z.string().nonempty(ZodFormErrors.REQUIRED_FIELD_MESSAGE).max(256, ZodFormErrors.EXCEEDED_MAX_CHAR_LIMIT),
  key: z.string().nonempty(ZodFormErrors.REQUIRED_FIELD_MESSAGE).max(256, ZodFormErrors.EXCEEDED_MAX_CHAR_LIMIT),
  kind: z.nativeEnum(ApplicationKindTypes),
  description: z.string().optional(),
  maintainer: z.union([z.instanceof(Member), z.instanceof(Team), z.string()]).optional(),
});

export type ApplicationForm = z.infer<typeof ApplicationFormSchema>;

export type ApplicationModalProps = {
  application?: ApplicationRep;
  buttonText: string | React.ReactNode;
  disabledFields?: Array<keyof ApplicationForm>;
  defaultFormValues?: ApplicationForm;
  isCreate?: boolean;
  useFormValues: UseFormReturn<ApplicationForm>;
  closeModal(): void;
  handleSubmitForm: (form: ApplicationForm) => void;
};

export const applicationVersionFormSchema = z.object({
  name: z.string().nonempty(ZodFormErrors.REQUIRED_FIELD_MESSAGE).max(256, ZodFormErrors.EXCEEDED_MAX_CHAR_LIMIT),
  key: z.string().nonempty(ZodFormErrors.REQUIRED_FIELD_MESSAGE).max(256, ZodFormErrors.EXCEEDED_MAX_CHAR_LIMIT),
});

export type ApplicationVersionForm = z.infer<typeof applicationVersionFormSchema>;
export type ApplicationVersionModalProps = {
  buttonText: string | React.ReactNode;
  disabledFields?: Array<keyof ApplicationVersionForm>;
  handleSubmitForm: (form: ApplicationVersionForm) => void;
  defaultFormValues?: ApplicationVersionForm;
  useFormValues: UseFormReturn<ApplicationVersionForm>;
  isCreate: boolean;
};

export const applicationVersionSupportFormSchema = z.object({
  supported: z.enum(['true', 'false']),
});

export type ApplicationVersionSupportForm = z.infer<typeof applicationVersionSupportFormSchema>;
export type ApplicationVersionSupportModalProps = {
  buttonText: string | React.ReactNode;
  disabledFields?: Array<keyof ApplicationVersionSupportForm>;
  handleSubmitForm: (form: ApplicationVersionSupportForm) => void;
  defaultFormValues?: ApplicationVersionSupportForm;
  useFormValues: UseFormReturn<ApplicationVersionSupportForm>;
};

export const VersionParamsSchema = z.object({
  limit: z.number().optional(),
  offset: z.number().optional(),
  sort: z.enum(['name', '-name', 'creationDate', '-creationDate']).optional(),
  key: z.string().optional(),
  name: z.string().optional(),
  kind: z.string().optional(),
  autoAdded: z.boolean().optional(),
  supported: z.boolean().optional(),
});
