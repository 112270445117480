import { components, SingleValueProps } from 'react-select';

import SelectTeamOrMemberOption from 'components/SelectTeamOrMember/SelectTeamOrMemberOption';
import { TeamOrMemberOptionType } from 'components/SelectTeamOrMember/SelectTeamOrMemberUtils';

// This is what renders in the React Select input field for the selected option
const SelectTeamOrMemberSingleValue = (singleValueProps: SingleValueProps<TeamOrMemberOptionType>) => {
  const option = singleValueProps.data;
  return (
    <components.SingleValue {...singleValueProps}>
      <SelectTeamOrMemberOption avatarSize="small" option={option} showEmail={false} />
    </components.SingleValue>
  );
};

/* eslint-disable import/no-default-export */
export default SelectTeamOrMemberSingleValue;
