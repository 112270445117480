import { type ReleasePhase, type ReleaseTrackerPhaseStatus } from '@gonfalon/release-pipelines';

export const determinePhaseStatus = (
  phase: ReleasePhase,
  i: number,
  arr: ReleasePhase[],
): ReleaseTrackerPhaseStatus => {
  const isComplete = phase.complete;

  const states = {
    isActive: 'active',
    isComplete: 'complete',
    isInactive: 'inactive',
  } as const;

  // If it is the first one phase and not complete, its automatically set as active.
  if (i === 0 && !isComplete) {
    return states.isActive;
  }

  const previousPhaseIsComplete = i !== 0 && arr[i - 1].complete && !isComplete;

  if (previousPhaseIsComplete) {
    return states.isActive;
  }

  if (isComplete) {
    return states.isComplete;
  }

  return states.isInactive;
};
