import classNames from 'clsx';
import type { PopoverPlacement } from 'launchpad';
import { Tooltip } from 'launchpad';

export type UpsellTooltipProps = {
  placement?: PopoverPlacement;
  className?: string;
  children?: string | JSX.Element;
  iconText?: string;
  targetClassName?: string;
  onClick?(): void;
};

/* eslint-disable import/no-default-export */
export default function UpsellTooltip({
  targetClassName,
  children,
  placement = 'top',
  className,
  ...props
}: UpsellTooltipProps) {
  const centeredContent = (
    <div className="u-tc">
      Upgrade your plan to use this feature.
      <br />
      Click to learn more
    </div>
  );
  return (
    <Tooltip content={centeredContent} placement={placement} targetClassName={targetClassName}>
      <span {...props} className={classNames('UpsellTooltip', className)}>
        {children}
      </span>
    </Tooltip>
  );
}
