import { List, Map } from 'immutable';
import invariant from 'tiny-invariant';

import { ReleaseStrategy } from 'components/LegacyReleaseStrategyModal';
import { createClause } from 'utils/clauseUtils';
import {
  createExperimentRollout,
  ExperimentRollout,
  Flag,
  FlagConfigSetting,
  replaceRuleLabelText,
  RuleLabel,
} from 'utils/flagUtils';
import { toJS } from 'utils/immutableUtils';
import { pluralizeLastWord } from 'utils/stringUtils';

import { ClauseInstructionKind } from '../clauses/types';
import { combineExpiringTargets, makeExpiringTargetSemanticInstruction } from '../expiringTargets/helpers';
import {
  ExpiringTargetsInstructionKind,
  SemanticExpiringTargetInstruction,
  SemanticExpiringUserTargetInstruction,
} from '../expiringTargets/types';
import { MembersAndTeamsInstructionKind } from '../membersAndTeams/types';
import { combineOnOffInstructions } from '../onOff/helpers';
import { OnOffInstructionKind } from '../onOff/types';
import { combinePrerequisiteInstructions, makeUpdatePrerequisitesKey } from '../prerequisites/helpers';
import { FlagPrerequisitesInstructionKind, SemanticPrerequisiteInstruction } from '../prerequisites/types';
import { createAggregateRule, filterOutAggregateRules } from '../rules/aggregateHelpers';
import { combineRulesInstructions, makeRuleKey, sortRulesInstructions } from '../rules/helpers';
import {
  AddRuleSemanticInstruction,
  AggregateRule,
  RuleInstructionKind,
  SemanticRuleInstructionType,
} from '../rules/types';
import {
  combineTargetsInstructions,
  hasEmptyTargets,
  makeUpdateTargetsKey,
  sortTargetInstructions,
} from '../targets/helpers';
import { TargetsInstructionKind, UpdateTargetsSemanticInstruction } from '../targets/types';
import {
  combineUserTargetsInstructions,
  hasEmptyUserTargets,
  makeAddUserTargetsInstruction,
  makeUpdateUserTargetsKey,
  sortUserTargetInstructions,
} from '../userTargets/helpers';
import { UpdateUserTargetsSemanticInstruction, UserTargetsInstructionKind } from '../userTargets/types';
import { FlagVariationsInstructionKind, VariationSemanticInstruction } from '../variations/types';

import {
  AllFlagInstructionKinds,
  InstructionCategory,
  InstructionKey,
  InstructionKindToCategory,
  instructionKindToDescription,
  InstructionsType,
  SemanticInstruction,
  SemanticInstructionForKind,
} from './types';

// isInstructionOfKind is a type guard that returns true if the given instruction has the specific kind.
export function isInstructionOfKind<K extends AllFlagInstructionKinds>(
  ins: SemanticInstruction,
  kind: K,
): ins is SemanticInstructionForKind<K> {
  return ins.kind === kind;
}

// getInstructionsByKind filters out all instructions which are not of the specified kind.
export function getInstructionsByKind<K extends AllFlagInstructionKinds>(
  allInstructions: SemanticInstruction[],
  kind: K,
) {
  return allInstructions.filter((ins) => isInstructionOfKind<K>(ins, kind)) as Array<SemanticInstructionForKind<K>>;
}

export function getInstructionsByManyKinds<K extends AllFlagInstructionKinds>(
  allInstructions: SemanticInstruction[],
  kinds: K[],
) {
  return allInstructions.filter(({ kind }) => kinds.includes(kind as K)) as Array<SemanticInstructionForKind<K>>;
}

export const getActionVerb = (text: string): string[] => {
  const words = text.split(' ');
  const actionVerb = words.shift();
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  return [actionVerb!, words.join(' ')]; /* eslint-enable @typescript-eslint/no-non-null-assertion */
};

export const getInstructionDescriptionText = (
  instruction: SemanticInstruction | VariationSemanticInstruction,
  ruleLabel: RuleLabel,
) => {
  let text = instructionKindToDescription[instruction.kind];
  text = replaceRuleLabelText(text, ruleLabel);
  if (!text) {
    return `Update ${instruction.kind}`;
  }

  if (
    instruction.kind === MembersAndTeamsInstructionKind.ADD_MEMBERS ||
    instruction.kind === MembersAndTeamsInstructionKind.REMOVE_MEMBERS
  ) {
    return pluralizeLastWord(text, instruction.values.length);
  }

  if (
    instruction.kind === UserTargetsInstructionKind.ADD_USER_TARGETS ||
    instruction.kind === UserTargetsInstructionKind.REMOVE_USER_TARGETS ||
    instruction.kind === TargetsInstructionKind.ADD_TARGETS ||
    instruction.kind === TargetsInstructionKind.REMOVE_TARGETS
  ) {
    return pluralizeLastWord(text, toJS(instruction.values).length);
  }
  if (instruction.kind === ClauseInstructionKind.REMOVE_CLAUSES) {
    return pluralizeLastWord(text, instruction.clauseIds.length);
  }
  if (instruction.kind === ClauseInstructionKind.ADD_CLAUSES) {
    return pluralizeLastWord(text, instruction.clauses.length);
  }

  if (
    (instruction.kind === RuleInstructionKind.ADD_RULE ||
      instruction.kind === RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT) &&
    (instruction as AddRuleSemanticInstruction).experimentAllocation
  ) {
    const [pref] = getActionVerb(text);
    return `${pref} experiment ${ruleLabel}`;
  }

  if (
    instruction.kind === OnOffInstructionKind.UPDATE_FALLTHROUGH_VARIATION_OR_ROLLOUT &&
    instruction.experimentAllocation
  ) {
    return `${text} experiment serving`;
  }

  if (
    instruction.kind === FlagPrerequisitesInstructionKind.REPLACE_PREREQUISITES &&
    instruction.prerequisites.length === 0
  ) {
    return 'All prerequisites will be removed';
  }
  if (instruction.kind === RuleInstructionKind.REPLACE_RULES && instruction.rules.length === 0) {
    return `All ${ruleLabel}s will be removed`;
  }
  if (instruction.kind === UserTargetsInstructionKind.REPLACE_USER_TARGETS && hasEmptyUserTargets(instruction)) {
    return 'All users will be removed';
  }

  if (instruction.kind === TargetsInstructionKind.REPLACE_TARGETS && hasEmptyTargets(instruction)) {
    return `All ${instruction.contextKind} targets will be removed`;
  }

  return text;
};

export const flagConfigSettingToInstructionCategories: { [key in FlagConfigSetting]: InstructionCategory[] } = {
  on: [InstructionCategory.ON_OFF],
  targets: [
    InstructionCategory.USER_TARGETS,
    InstructionCategory.REPLACE_USER_TARGETS,
    InstructionCategory.EXPIRE_USER_TARGETS,
  ],
  contextTargets: [InstructionCategory.TARGETS, InstructionCategory.REPLACE_TARGETS],
  rules: [InstructionCategory.RULES, InstructionCategory.REPLACE_RULES],
  fallthrough: [InstructionCategory.FALLTHROUGH],
  prerequisites: [InstructionCategory.PREREQUISITES, InstructionCategory.REPLACE_PREREQUISITES],
  offVariation: [InstructionCategory.OFF_VARIATION],
};

type InstructionModifiers = {
  releaseStrategy?: ReleaseStrategy;
  flag: Flag;
  environmentKey: string;
};

export function getPendingInstructionsList(
  instructionsMap: InstructionsType,
  instructionModifiers?: InstructionModifiers,
): List<SemanticInstruction> {
  const instructionsByCategory = Map(
    instructionsMap.valueSeq().groupBy((ins) => InstructionKindToCategory[ins.kind]),
  ).map((v) => v.toList());
  // Rules instructions need special logic because they need to be ordered correctly.
  let instructions: List<SemanticInstruction> = instructionsByCategory
    .update(InstructionCategory.RULES, sortRulesInstructions)
    .update(InstructionCategory.USER_TARGETS, sortUserTargetInstructions)
    .update(InstructionCategory.TARGETS, sortTargetInstructions)
    .valueSeq()
    .flatten(true)
    .toList();

  const fallthroughIndex = instructions.findIndex(
    (ins) => ins.kind === 'updateFallthroughVariationOrRollout' && !ins.experimentAllocation,
  );
  const expAllocRuleIndex = instructions.findIndex(
    (ins) => ins.kind === RuleInstructionKind.ADD_RULE && !!ins.experimentAllocation,
  );

  if (fallthroughIndex > -1 && expAllocRuleIndex > -1 && fallthroughIndex > expAllocRuleIndex) {
    //handle edge case where instructions will be out of order if you remove exp alloc from fallthrough and
    //add it to a rule that has an index that is less than the fallthrough
    const fallthroughIns = instructions.get(fallthroughIndex);
    if (fallthroughIns) {
      return instructions.delete(fallthroughIndex).insert(0, fallthroughIns);
    }
  }

  if (typeof instructionModifiers !== 'undefined' && instructionModifiers.releaseStrategy) {
    const flag = instructionModifiers.flag;
    const environmentKey = instructionModifiers.environmentKey;
    const envFlag = flag?.environments.get(environmentKey);
    invariant(envFlag, 'Flag configuration in environment must exist to use Release Guardian');

    const offVariation = flag?.environments.get(environmentKey);
    if (typeof offVariation === 'undefined') {
      return instructions;
    }

    // If we have a turn flag on instruction, we need to re-order that to be first.
    // It's not guaranteed that it is always first unfortunately.

    const turnOnIndex = instructions.findIndex(
      (pendingIns: SemanticInstruction) => pendingIns.kind === OnOffInstructionKind.TURN_FLAG_ON,
    );
    if (turnOnIndex !== -1 && turnOnIndex !== 0) {
      const turnOnInstruction = instructions.get(turnOnIndex);

      if (turnOnInstruction) {
        instructions = instructions.delete(turnOnIndex).unshift(turnOnInstruction);
      }
    }
  }
  return instructions;
}

export function addPatchInstructions(
  instructions: InstructionsType,
  newInstructions: Iterable<SemanticInstruction>,
  replaceId?: string,
): InstructionsType {
  let result = instructions;
  if (!newInstructions) {
    return result;
  }
  for (const instruction of newInstructions) {
    const category = InstructionKindToCategory[instruction.kind];
    if (!category) {
      continue;
    }
    switch (category) {
      case InstructionCategory.ON_OFF:
        result = combineOnOffInstructions(instruction, result);
        break;
      case InstructionCategory.USER_TARGETS:
        result = combineUserTargetsInstructions(instruction as UpdateUserTargetsSemanticInstruction, result);
        break;
      case InstructionCategory.TARGETS:
        result = combineTargetsInstructions(instruction as UpdateTargetsSemanticInstruction, result);
        break;
      case InstructionCategory.PREREQUISITES:
        result = combinePrerequisiteInstructions(instruction as SemanticPrerequisiteInstruction, result);
        break;
      case InstructionCategory.RULES:
        result = combineRulesInstructions(instruction as SemanticRuleInstructionType, result, replaceId);
        break;
      case InstructionCategory.EXPIRE_USER_TARGETS:
        result = combineExpiringTargets(instruction as SemanticExpiringUserTargetInstruction, result);
        break;
      case InstructionCategory.EXPIRE_TARGETS:
        result = combineExpiringTargets(instruction as SemanticExpiringTargetInstruction, result);
        break;
      default:
        result = result.set(category, instruction);
        break;
    }
  }
  return result;
}

/**
 * Filters out semantic instructions from the pending instructions state (type: InstructionsType)
 * that correspond to the given flag setting
 */
export function removePatchInstructionsForSetting(
  instructionsState: InstructionsType,
  setting: FlagConfigSetting,
): InstructionsType {
  return instructionsState.filter((_, key) => !doesInstructionKeyCorrespondToFlagSetting(key, setting));
}

/**
 * Determines whethere the provided instructions state key
 * corresponds to instructions that would modify the provided flag setting
 */
export function doesInstructionKeyCorrespondToFlagSetting(instructionKey: InstructionKey, setting: FlagConfigSetting) {
  const categories = flagConfigSettingToInstructionCategories[setting];
  // instruction key names are used to key pending instructions state (type: InstructionsType)
  // these keys always begin with the category (type: InstructionCategory)
  return categories.some((category) => instructionKey.startsWith(category));
}

export function setPatchInstructions(
  instructions: InstructionsType,
  newInstructions: Iterable<SemanticInstruction>,
): InstructionsType {
  let result = instructions;
  for (const instruction of newInstructions) {
    const category = InstructionKindToCategory[instruction.kind];
    if (!category) {
      continue;
    }
    if (category === InstructionCategory.PREREQUISITES) {
      result = combinePrerequisiteInstructions(instruction as SemanticPrerequisiteInstruction, result);
    } else {
      const instructionStateKey = makeScheduledInstructionStateKey(instruction);
      result = result.set(instructionStateKey, instruction);
    }
  }
  return result;
}

export function removePatchInstructions(
  currState: InstructionsType,
  deleteInstructions: Iterable<SemanticInstruction>,
): InstructionsType {
  let newstate = currState;
  for (const instruction of deleteInstructions) {
    const category = InstructionKindToCategory[instruction.kind];
    if (!category) {
      continue;
    }
    const instructionStateKey = makeScheduledInstructionStateKey(instruction);
    const instructionKind = (instruction as AggregateRule).kind;

    if (
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      filterOutAggregateRules(instructionKind!) &&
      instructionKind !== ClauseInstructionKind.ADD_VALUES_TO_CLAUSE &&
      instructionKind !==
        ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE /* eslint-enable @typescript-eslint/no-non-null-assertion */
    ) {
      currState.valueSeq().forEach((ins) => {
        if (
          /* eslint-disable @typescript-eslint/no-non-null-assertion */
          (instruction as AggregateRule).ruleId === (ins as AggregateRule).ruleId &&
          filterOutAggregateRules(
            (ins as AggregateRule).kind!,
          ) /* eslint-enable @typescript-eslint/no-non-null-assertion */
        ) {
          const key = makeScheduledInstructionStateKey(ins);
          newstate = newstate.delete(key);
        }
      });
    }
    newstate = newstate.delete(instructionStateKey);
  }
  return newstate;
}

export const makeScheduledInstructionStateKey = (instruction: SemanticInstruction) => {
  const category = InstructionKindToCategory[instruction.kind];
  switch (category) {
    case InstructionCategory.USER_TARGETS:
      const { kind: targetInsKind, variationId } = instruction as UpdateUserTargetsSemanticInstruction;
      return makeUpdateUserTargetsKey(variationId, targetInsKind);
    case InstructionCategory.TARGETS:
      const {
        kind: contextTargetInsKind,
        variationId: contextVariationId,
        contextKind,
      } = instruction as UpdateTargetsSemanticInstruction;
      return makeUpdateTargetsKey(contextVariationId, contextTargetInsKind, contextKind);
    case InstructionCategory.PREREQUISITES:
      const { key: flagKey } = instruction as SemanticPrerequisiteInstruction;
      return makeUpdatePrerequisitesKey(flagKey);
    case InstructionCategory.RULES:
      return makeRuleKey(instruction as SemanticRuleInstructionType);
    default:
      return category;
  }
};

/**
 * Converts an object or Immutable.js Map into the corresponding semantic instruction Immutable.js Record,
 * according to the "kind" provided.
 */
export function makeSemanticInstruction(
  kind: AllFlagInstructionKinds | FlagVariationsInstructionKind,
  instructionProps: $TSFixMe,
): SemanticInstruction | VariationSemanticInstruction {
  switch (kind) {
    case OnOffInstructionKind.TURN_FLAG_ON:
      return { kind: OnOffInstructionKind.TURN_FLAG_ON };
    case OnOffInstructionKind.TURN_FLAG_OFF:
      return { kind: OnOffInstructionKind.TURN_FLAG_OFF };
    case OnOffInstructionKind.UPDATE_OFF_VARIATION:
      return { variationId: null, ...toJS(instructionProps), kind: OnOffInstructionKind.UPDATE_OFF_VARIATION };
    case RuleInstructionKind.ADD_RULE: {
      const jsInstructionProps = toJS(instructionProps);
      return {
        ref: '',
        ...jsInstructionProps,
        clauses: jsInstructionProps.clauses.map(createClause),
        ruleId: jsInstructionProps._key,
        kind: RuleInstructionKind.ADD_RULE,
      };
    }
    case RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT: {
      const jsInstructionProps = toJS(instructionProps);
      return {
        ref: '',
        ...jsInstructionProps,
        clauses: jsInstructionProps.clauses.map(createClause),
        ruleId: jsInstructionProps._key,
        kind: RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT,
      };
    }
    case RuleInstructionKind.REMOVE_RULE:
      return { ruleId: '', ...toJS(instructionProps), kind: RuleInstructionKind.REMOVE_RULE };
    case RuleInstructionKind.REORDER_RULES:
      return {
        ruleIds: [],
        ...toJS(instructionProps),
        kind: RuleInstructionKind.REORDER_RULES,
      };
    case RuleInstructionKind.REPLACE_RULES: {
      const jsInstructionProps = toJS(instructionProps);
      return {
        ...jsInstructionProps,
        rules: jsInstructionProps.rules.map(createAggregateRule),
        kind: RuleInstructionKind.REPLACE_RULES,
      };
    }
    case RuleInstructionKind.UPDATE_RULE_DESCRIPTION:
      return {
        ruleId: '',
        description: '',
        ...toJS(instructionProps),
        kind: RuleInstructionKind.UPDATE_RULE_DESCRIPTION,
      };
    case ClauseInstructionKind.ADD_VALUES_TO_CLAUSE:
      return {
        ruleId: '',
        clauseId: '',
        values: [],
        ...toJS(instructionProps),
        kind: ClauseInstructionKind.ADD_VALUES_TO_CLAUSE,
      };
    case ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE:
      return {
        ruleId: '',
        clauseId: '',
        values: [],
        ...toJS(instructionProps),
        kind: ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE,
      };
    case ClauseInstructionKind.ADD_CLAUSES: {
      const jsInstructionProps = toJS(instructionProps);

      if ('clauses' in jsInstructionProps && Array.isArray(jsInstructionProps.clauses)) {
        jsInstructionProps.clauses = jsInstructionProps.clauses.map((c: unknown) => toJS(c));
      }

      return {
        ruleId: '',
        clauses: [],
        ...jsInstructionProps,
        kind: ClauseInstructionKind.ADD_CLAUSES,
      };
    }
    case ClauseInstructionKind.REMOVE_CLAUSES:
      return {
        ruleId: '',
        clauseIds: [],
        ...toJS(instructionProps),
        kind: ClauseInstructionKind.REMOVE_CLAUSES,
      };
    case ClauseInstructionKind.UPDATE_CLAUSE: {
      const jsInstructionProps = toJS(instructionProps);

      return {
        ruleId: '',
        clauseId: '',
        ...jsInstructionProps,
        clause: Boolean(jsInstructionProps.clause) ? createClause(jsInstructionProps.clause) : null,
        kind: ClauseInstructionKind.UPDATE_CLAUSE,
      };
    }
    case RuleInstructionKind.STOP_MEASURED_ROLLOUT_ON_RULE: {
      const jsInstructionProps = toJS(instructionProps);
      return {
        ruleId: '',
        ...jsInstructionProps,
        kind: RuleInstructionKind.STOP_MEASURED_ROLLOUT_ON_RULE,
      };
    }
    case RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT: {
      const jsInstructionProps = toJS(instructionProps);
      if (typeof jsInstructionProps === 'object') {
        Object.entries(jsInstructionProps).forEach(([key, value]) => {
          jsInstructionProps[key] = toJS(value);
        });
      }

      let ruleExpAlloc: ExperimentRollout | undefined = jsInstructionProps.experimentAllocation;
      if (ruleExpAlloc) {
        ruleExpAlloc = createExperimentRollout(ruleExpAlloc);
        return {
          ...jsInstructionProps,
          experimentAllocation: ruleExpAlloc,
          kind: RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT,
        };
      }
      return {
        ...jsInstructionProps,
        kind: RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT,
      };
    }
    case RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT:
      return {
        ...toJS(instructionProps),
        kind: RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT,
      };
    case OnOffInstructionKind.STOP_MEASURED_ROLLOUT_ON_FALLTHROUGH: {
      const jsInstructionProps = toJS(instructionProps);
      return {
        ...jsInstructionProps,
        kind: OnOffInstructionKind.STOP_MEASURED_ROLLOUT_ON_FALLTHROUGH,
      };
    }
    case OnOffInstructionKind.UPDATE_FALLTHROUGH_VARIATION_OR_ROLLOUT: {
      const jsInstructionProps = toJS(instructionProps);

      return {
        contextKind: undefined,
        rolloutWeights: undefined,
        rolloutBucketBy: undefined,
        rolloutContextKind: undefined,
        variationId: undefined,
        ...jsInstructionProps,
        experimentAllocation: jsInstructionProps.experimentAllocation
          ? createExperimentRollout(jsInstructionProps.experimentAllocation)
          : undefined,
        kind: OnOffInstructionKind.UPDATE_FALLTHROUGH_VARIATION_OR_ROLLOUT,
      };
    }
    case OnOffInstructionKind.UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT:
      return {
        ...toJS(instructionProps),
        kind: OnOffInstructionKind.UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT,
      };
    case UserTargetsInstructionKind.ADD_USER_TARGETS:
      const jsInstructionProps = toJS(instructionProps);
      return makeAddUserTargetsInstruction(jsInstructionProps.values ?? [], jsInstructionProps.variationId || '');
    case UserTargetsInstructionKind.REMOVE_USER_TARGETS:
      return {
        values: [],
        variationId: '',
        ...toJS(instructionProps),
        kind: UserTargetsInstructionKind.REMOVE_USER_TARGETS,
      };
    case UserTargetsInstructionKind.REPLACE_USER_TARGETS:
      return {
        targets: [],
        ...toJS(instructionProps),
        kind: UserTargetsInstructionKind.REPLACE_USER_TARGETS,
      };
    case TargetsInstructionKind.ADD_TARGETS:
      return {
        contextKind: '',
        values: [],
        variationId: '',
        ...toJS(instructionProps),
        kind: TargetsInstructionKind.ADD_TARGETS,
      };
    case TargetsInstructionKind.REMOVE_TARGETS:
      return {
        contextKind: '',
        values: [],
        variationId: '',
        ...toJS(instructionProps),
        kind: TargetsInstructionKind.REMOVE_TARGETS,
      };
    case TargetsInstructionKind.REPLACE_TARGETS:
      return {
        targets: [],
        contextKind: '',
        ...toJS(instructionProps),
        kind: TargetsInstructionKind.REPLACE_TARGETS,
      };
    case MembersAndTeamsInstructionKind.ADD_MEMBERS:
      return {
        values: [],
        ...toJS(instructionProps),
        kind: MembersAndTeamsInstructionKind.ADD_MEMBERS,
      };
    case MembersAndTeamsInstructionKind.REMOVE_MEMBERS:
      return {
        values: [],
        ...toJS(instructionProps),
        kind: MembersAndTeamsInstructionKind.REMOVE_MEMBERS,
      };
    case MembersAndTeamsInstructionKind.ADD_TEAM_KEYS:
      return {
        values: [],
        ...toJS(instructionProps),
        kind: MembersAndTeamsInstructionKind.ADD_TEAM_KEYS,
      };
    case FlagPrerequisitesInstructionKind.ADD_PREREQUISITE:
      return {
        key: '',
        variationId: '',
        ...toJS(instructionProps),
        kind: FlagPrerequisitesInstructionKind.ADD_PREREQUISITE,
      };
    case FlagPrerequisitesInstructionKind.REMOVE_PREREQUISITE:
      return {
        key: '',
        ...toJS(instructionProps),
        kind: FlagPrerequisitesInstructionKind.REMOVE_PREREQUISITE,
      };
    case FlagPrerequisitesInstructionKind.UPDATE_PREREQUISITE:
      return {
        key: '',
        variationId: '',
        ...toJS(instructionProps),
        kind: FlagPrerequisitesInstructionKind.UPDATE_PREREQUISITE,
      };
    case FlagPrerequisitesInstructionKind.REPLACE_PREREQUISITES:
      return {
        key: '',
        prerequisites: [],
        ...toJS(instructionProps),
        kind: FlagPrerequisitesInstructionKind.REPLACE_PREREQUISITES,
      };
    case FlagVariationsInstructionKind.ADD_VARIATION:
      return {
        ...toJS(instructionProps),
        kind: FlagVariationsInstructionKind.ADD_VARIATION,
      };
    case FlagVariationsInstructionKind.UPDATE_VARIATION:
      return {
        ...toJS(instructionProps),
        kind: FlagVariationsInstructionKind.UPDATE_VARIATION,
      };
    case FlagVariationsInstructionKind.REMOVE_VARIATION:
      return {
        variationId: '',
        ...toJS(instructionProps),
        kind: FlagVariationsInstructionKind.REMOVE_VARIATION,
      };
    case FlagVariationsInstructionKind.UPDATE_DEFAULT_VARIATION:
      return {
        ...toJS(instructionProps),
        kind: FlagVariationsInstructionKind.UPDATE_DEFAULT_VARIATION,
      };
    case ExpiringTargetsInstructionKind.ADD_EXPIRE_TARGET_DATE:
    case ExpiringTargetsInstructionKind.UPDATE_EXPIRE_TARGET_DATE:
    case ExpiringTargetsInstructionKind.REMOVE_EXPIRE_TARGET_DATE:
    case ExpiringTargetsInstructionKind.ADD_EXPIRE_USER_TARGET_DATE:
    case ExpiringTargetsInstructionKind.UPDATE_EXPIRE_USER_TARGET_DATE:
    case ExpiringTargetsInstructionKind.REMOVE_EXPIRE_USER_TARGET_DATE:
      return makeExpiringTargetSemanticInstruction(kind, toJS(instructionProps)) || instructionProps;
    default:
      return instructionProps;
  }
}
