import { ExperimentStatusIcon } from 'components/experimentation/common/components/ExperimentStatusIcon';
import { ExperimentStatus as Status, NOT_STARTED, RUNNING, STOPPED } from 'components/experimentation/common/types';

export const ExperimentStatus = ({
  status,
  className,
  kind = 'normal',
}: {
  kind?: 'minimal' | 'normal';
  status: Status | string;
  className?: string;
}) => {
  if (status === 'running') {
    return <ExperimentStatusIcon kind={kind} className={className} status={RUNNING} />;
  }

  if (status === 'stopped') {
    return <ExperimentStatusIcon kind={kind} className={className} status={STOPPED} />;
  }

  return <ExperimentStatusIcon kind={kind} className={className} status={NOT_STARTED} />;
};
