import { useState } from 'react';
import { Icon } from '@launchpad-ui/icons';
import { List, Map } from 'immutable';

import Restrict from 'components/Restrict';
import { ModuleEmptyState } from 'components/ui/module';
import UpsellFeatureModalPrerequisites from 'components/upsells/upsellFeatureModals/UpsellFeatureModalPrerequisites';
import UpsellTooltip from 'components/upsells/UpsellTooltip';
import { Prerequisite } from 'utils/flagUtils';
import { PrerequisiteValidation } from 'utils/validation/targeting';

import { LazyPrerequisiteManager } from './PrerequisiteManager';

export type ManageFlagPrerequisiteAccessProps = {
  prerequisites: List<Prerequisite>;
  originalPrerequisites: List<Prerequisite>;
  tooltip: string;
  validation: Map<string, PrerequisiteValidation>;
  canModify: boolean;
  emptyPrerequisites: boolean;
  isRestricted: boolean;
  isFlagPrerequisitesEnabled: boolean;
  onAddPrerequisite(): void;
  onDeletePrerequisite(prerequisite: Prerequisite): void;
  onEditPrerequisite(previousPrerequisiteKey: string | null, prerequisite: Prerequisite, variationId: string): void;
};

export type ManageFlagPrerequisiteAccessState = {
  modalIsOpen: boolean;
};
/* eslint-disable import/no-default-export */
export default function ManageFlagPrerequisiteAccess({
  validation,
  tooltip,
  canModify,
  emptyPrerequisites,
  isRestricted,
  prerequisites,
  onAddPrerequisite,
  onDeletePrerequisite,
  onEditPrerequisite,
  isFlagPrerequisitesEnabled,
  originalPrerequisites,
}: ManageFlagPrerequisiteAccessProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleUpsellModal = () => {
    setModalIsOpen((prevState) => !prevState);
  };
  if (!emptyPrerequisites) {
    return (
      <>
        <Restrict
          isRestricted={isRestricted}
          tooltip={tooltip}
          tooltipOptions={{
            rootElementStyle: { display: 'block' },
            placement: 'top',
          }}
        >
          <LazyPrerequisiteManager
            prerequisites={prerequisites}
            originalPrerequisites={originalPrerequisites}
            canModify={canModify}
            validation={validation}
            isFlagPrerequisitesEnabled={isFlagPrerequisitesEnabled}
            onAddPrerequisite={onAddPrerequisite}
            onDeletePrerequisite={onDeletePrerequisite}
            onEditPrerequisite={onEditPrerequisite}
          />
        </Restrict>
      </>
    );
  }
  if (isFlagPrerequisitesEnabled && emptyPrerequisites) {
    return (
      <>
        <Restrict
          isRestricted={isRestricted}
          tooltip={tooltip}
          tooltipOptions={{
            rootElementStyle: { display: 'block' },
            placement: 'top',
          }}
        >
          <ModuleEmptyState
            interactive={canModify}
            icon={canModify ? 'add' : undefined}
            onClick={canModify ? onAddPrerequisite : undefined}
          >
            {!canModify && <div>There are no prerequisites for this flag.</div>}
          </ModuleEmptyState>
        </Restrict>
      </>
    );
  }

  if (!isFlagPrerequisitesEnabled && emptyPrerequisites) {
    return (
      <>
        <UpsellTooltip targetClassName="ManageFlagTargeting-popoverTarget" className="ManageFlagTargeting--Upsell">
          <ModuleEmptyState interactive onClick={handleUpsellModal}>
            <Icon name="lock" size="small" subtle className="Icon--lock" /> Add prerequisites
          </ModuleEmptyState>
        </UpsellTooltip>
        {modalIsOpen && <UpsellFeatureModalPrerequisites isOpen={modalIsOpen} onCancel={handleUpsellModal} />}
      </>
    );
  }
  return null;
}
