import type { IconProps } from '@launchpad-ui/icons';
import { Icon } from '@launchpad-ui/icons';
import classNames from 'clsx';
import type { PopoverPlacement } from 'launchpad';
import { Tooltip } from 'launchpad';

import 'stylesheets/components/PrivateAttribute.css';

type PrivateAttributeProps = {
  attribute: string;
  placement?: PopoverPlacement;
  size?: IconProps['size'];
  hoverCloseDelay?: number;
  className?: string;
};

const content = "This attribute is 'redacted' for some users, so values may not be displayed.";

const PrivateAttribute = ({ attribute, placement, size, hoverCloseDelay, className }: PrivateAttributeProps) => (
  <>
    {attribute}{' '}
    <Tooltip content={content} placement={placement} hoverCloseDelay={hoverCloseDelay} allowBoundaryElementOverflow>
      <span>
        <Icon
          name="lock"
          data-test-id="lock-icon"
          size={size}
          subtle
          className={classNames('PrivateAttributeIcon', className)}
        />
      </span>
    </Tooltip>
  </>
);

/* eslint-disable import/no-default-export */
export default PrivateAttribute;
