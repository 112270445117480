import { ExperimentPatchBody } from 'components/experimentation/common/api/api.types';

import {
  UPDATE_ATTRIBUTES,
  UPDATE_EDIT_REASON,
  UPDATE_HYPOTHESIS,
  UPDATE_METRICS,
  UPDATE_RANDOMIZATION_UNIT,
  UPDATE_RULE_ID,
  UPDATE_TRAFFIC_RESHUFFLE,
  UPDATE_TREATMENTS,
  UpdateAttributes,
  UpdateEditReason,
  UpdateHypothesis,
  UpdateMetrics,
  UpdateRandomizationUnit,
  UpdateRuleId,
  UpdateTrafficReshuffle,
  UpdateTreatments,
} from '../../types';

import { Action, initialReducerState, RESET_STATE, State, UPDATE_INSTRUCTION } from './types';

/**
 * Util function to update the canReshuffleTraffic field on an iteration
 *
 * @param reshuffle - determines if traffic could be reshuffled
 * @param state - current state
 */
function updateTrafficReshuffle(reshuffle: UpdateTrafficReshuffle, state: State): State {
  return {
    ...state,
    iterationBody: {
      ...state.iterationBody,
      ...reshuffle,
    },
  };
}

/**
 * Util function to update the treatments field on an iteration
 *
 * @param treatments - the treatments to update
 * @param state - current state
 */
function updateTreatments(treatments: UpdateTreatments, state: State): State {
  return {
    ...state,
    iterationBody: {
      ...state.iterationBody,
      ...treatments,
    },
  };
}

/**
 *  Util function to update the metrics field on an iteration
 *
 * @param metrics - the metrics to update
 * @param state - current state
 */
function updateMetrics(metrics: UpdateMetrics, state: State): State {
  return {
    ...state,
    iterationBody: {
      ...state.iterationBody,
      ...metrics,
    },
  };
}

/**
 *  Util function to update the attributes field on an iteration
 *
 * @param attributes - the metrics to update
 * @param state - current state
 */
function updateAttributes(attributes: UpdateAttributes, state: State): State {
  return {
    ...state,
    iterationBody: {
      ...state.iterationBody,
      ...attributes,
    },
  };
}

/**
 *  Util function to update the randomization unit field on an iteration
 *
 * @param randomizationUnit - the randomizationUnit to update
 * @param state - current state
 */
function updateRandomizationUnit(randomizationUnit: UpdateRandomizationUnit, state: State): State {
  return {
    ...state,
    iterationBody: {
      ...state.iterationBody,
      ...randomizationUnit,
    },
  };
}

/**
 *  Util function to update the ruleId field on an iteration
 *
 * @param ruleId - the ruleId to update
 * @param state - current state
 */
function updateRuleId(ruleId: UpdateRuleId, state: State): State {
  return {
    ...state,
    iterationBody: {
      ...state.iterationBody,
      ...ruleId.flags,
    },
  };
}

/**
 * Util function to update the editReason field on an iteration
 *
 * @param reason - the reason for the update
 * @param state - current state
 */
function updateEditReason(reason: UpdateEditReason, state: State): State {
  return {
    ...state,
    iterationBody: {
      ...state.iterationBody,
      ...reason,
    },
  };
}
/**
 * Util function adds an instruction to the instructions array
 *
 * @param payload - the payload to update
 * @param state - current state
 */
function updateInstructions(payload: ExperimentPatchBody, state: State): State {
  return {
    ...state,
    instructions: [...state.instructions, payload],
  };
}

function updateHypothesis(hypothesis: UpdateHypothesis, state: State): State {
  return {
    ...state,
    iterationBody: {
      ...state.iterationBody,
      hypothesis: hypothesis.hypothesis || state.iterationBody.hypothesis,
    },
  };
}
/**
 * Reducer function for the updateExperiment hook
 *
 * @param state - current state
 * @param action - the action to update
 */
export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case UPDATE_INSTRUCTION:
      return updateInstructions(action.payload as ExperimentPatchBody, state);
    case UPDATE_METRICS:
      return updateMetrics(action.payload as UpdateMetrics, state);
    case UPDATE_ATTRIBUTES:
      return updateAttributes(action.payload as UpdateAttributes, state);
    case UPDATE_TREATMENTS:
      return updateTreatments(action.payload as UpdateTreatments, state);
    case UPDATE_TRAFFIC_RESHUFFLE:
      return updateTrafficReshuffle(action.payload as UpdateTrafficReshuffle, state);
    case UPDATE_RULE_ID:
      return updateRuleId(action.payload as UpdateRuleId, state);
    case UPDATE_HYPOTHESIS:
      return updateHypothesis(action.payload as UpdateHypothesis, state);
    case UPDATE_EDIT_REASON:
      return updateEditReason(action.payload as UpdateEditReason, state);
    case UPDATE_RANDOMIZATION_UNIT:
      return updateRandomizationUnit(action.payload as UpdateRandomizationUnit, state);
    case RESET_STATE:
      return initialReducerState();
    default:
      return state;
  }
}
