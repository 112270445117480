import { IconDimension } from 'components/ui/icons/types';

import { useSyncedSegmentManifests } from './SyncedSegmentsProvider';

import styles from './SyncedSegment.module.css';

export enum SegmentPagePlacement {
  row = 'row',
  details = 'details',
}

type SyncedSegmentProps = {
  integrationKey: string;
  placement: SegmentPagePlacement;
  size?: IconDimension;
};

export const SyncedSegmentLogo = ({ integrationKey, placement, size }: SyncedSegmentProps) => {
  const manifests = useSyncedSegmentManifests();
  if (!integrationKey) {
    return null;
  }

  const manifest = manifests.get(integrationKey);
  const integrationLogo = manifest?.getIn(['icons', 'square']);
  const hasSyncSegmentCapability = !!manifest?.getIn(['capabilities', 'syncedSegment']);

  if (!hasSyncSegmentCapability || !integrationLogo) {
    return null;
  }

  const className = placement === SegmentPagePlacement.row ? 'u-v-middle u-ml-s' : 'u-mr-l u-mt-m';

  const getSize = () => {
    if (size) {
      return size;
    }

    if (placement === SegmentPagePlacement.row) {
      return IconDimension.SMALL;
    }

    return IconDimension.MEDIUM;
  };

  return (
    <span className={styles.logo}>
      <img
        className={className}
        src={integrationLogo}
        alt={`Segment ${integrationKey}`}
        height={getSize()}
        width={getSize()}
        decoding="async"
        loading="lazy"
      />
    </span>
  );
};
