import { flag as _flag } from '@gonfalon/bootstrap-data';
import { halfwayIA } from '@gonfalon/dogfood-flags';
import { differenceInCalendarDays } from 'date-fns';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, OrderedMap } from 'immutable';
import { AnyAction, combineReducers } from 'redux';
import { createSelector, createStructuredSelector } from 'reselect';

import { FlagArchiveAction } from 'actions/archive';
import { DeprecateFlagAction } from 'actions/deprecate';
import { FlagAction } from 'actions/flags';
import { GlobalState } from 'reducers';
import paginate from 'reducers/paginate';
import { currentEnvironmentKeySelector, currentProjectKeySelector } from 'reducers/projects';
import { SearchParams, searchSelector } from 'reducers/router';
import { ParamsProps } from 'reducers/types/shared';
import { FlagStatus } from 'utils/flagStatusUtils';
import { createFlag, Flag } from 'utils/flagUtils';
import { PaginationType } from 'utils/paginationUtils';
import { isEmptyAction, ready } from 'utils/reduxUtils';
import { createRequest, Request, StateWithRequestInfo } from 'utils/requestUtils';

import 'epics/flagAnalytics';

import registry from './registry';

const filterCurrentFromFlagsResponse = (
  action: AnyAction & { currentFlagKey?: string },
  flagEntities: Map<string, Flag>,
) => {
  const { currentFlagKey } = action;
  if (!currentFlagKey) {
    return flagEntities;
  }
  return flagEntities.filter((f) => f.key !== currentFlagKey);
};

const flagsPagination = paginate({
  types: ['flags/REQUEST_FLAGS', 'flags/REQUEST_FLAGS_FAILED', 'flags/RECEIVE_FLAGS'],
  invalidateTypes: [
    'flags/DELETE_FLAG_DONE',
    'flags/CREATE_FLAG_DONE',
    'archive/ARCHIVE_FLAG_DONE',
    'archive/RESTORE_FLAG_DONE',
    'deprecate/DEPRECATE_FLAG_DONE',
    'deprecate/RESTORE_DEPRECATED_FLAG_DONE',
  ],
  mapActionToKey: () => 'all',
});

type FlagEntities = OrderedMap<string, Flag>;

function getFlagEntitiesInitialState() {
  const state: FlagEntities = OrderedMap<string, Flag>();

  if (halfwayIA()) {
    return state;
  }

  const flag = _flag();

  if (flag) {
    const f = createFlag(flag);
    return state.set(f.key, f);
  }

  return state;
}

const flagEntities = (
  state = getFlagEntitiesInitialState(),
  action: FlagAction | FlagArchiveAction | DeprecateFlagAction,
) => {
  if (isEmptyAction(action)) {
    return state;
  }

  if ('response' in action && action.response && action.response.hasIn(['entities', 'flags'])) {
    const { response } = action;
    const flagEntitiesFromAction = response.getIn(['entities', 'flags']);
    return state.merge(filterCurrentFromFlagsResponse(action, flagEntitiesFromAction));
  }

  if (action.type === 'flags/RECEIVE_FLAG') {
    return state.set(action.flagKey, action.flag);
  }

  if (
    action.type === 'flags/CREATE_FLAG_DONE' ||
    action.type === 'archive/ARCHIVE_FLAG_DONE' ||
    action.type === 'archive/RESTORE_FLAG_DONE' ||
    action.type === 'deprecate/DEPRECATE_FLAG_DONE' ||
    action.type === 'deprecate/RESTORE_DEPRECATED_FLAG_DONE' ||
    action.type === 'flags/UPDATE_FLAG_DONE' ||
    action.type === 'flags/UPDATE_FLAG_GOALS_DONE' ||
    action.type === 'flags/UPDATE_PROJECT_FLAG_SETTINGS_DONE' ||
    action.type === 'flags/UPDATE_ENV_FLAG_SETTINGS_DONE' ||
    action.type === 'flags/UPDATE_FLAG_DEBUG_DONE' ||
    action.type === 'flags/UPDATE_FLAG_MAINTAINER_DONE' ||
    action.type === 'flags/START_EXPERIMENT_DONE' ||
    action.type === 'flags/STOP_EXPERIMENT_DONE' ||
    action.type === 'flags/CREATE_EXPERIMENT_INTERVAL_DONE' ||
    action.type === 'flags/UPDATE_RULE_EXCLUSION_DONE'
  ) {
    return state.set(action.flag.key, action.flag);
  }

  if (action.type === 'flags/DELETE_FLAG_DONE') {
    return state.delete(action.flag.key);
  }

  return state;
};

export type DependentFlagsByEnvironment = Map<string, FlagEntities>;

export type FlagAndEnvKeys = {
  [flagKey: string]: FlagAndEnvKey[];
};

export type FlagAndEnvKey = {
  flagKey: string;
  archived?: boolean;
  archivedDate?: number;
  env: string;
  selfLink: string;
};

const dependentFlagWithEnvEntities = (
  state: Map<string, FlagAndEnvKeys> = Map(),
  action: FlagAction | FlagArchiveAction,
) => {
  if (action.type === 'flags/RECEIVE_DEPENDENT_FLAGS_WITH_ENV') {
    return state.set(action.flagKey, action.dependentFlags);
  }
  return state;
};

const dependentFlagsEntities = (state: Map<string, FlagAndEnvKeys> = Map(), action: FlagAction | FlagArchiveAction) => {
  if (action.type === 'flags/RECEIVE_DEPENDENT_FLAGS') {
    return state.set(action.flagKey, action.dependentFlags);
  }
  return state;
};

const flagListRequest = (state: Request = createRequest(), action: FlagAction) => {
  switch (action.type) {
    case 'flags/REQUEST_FLAGS':
      return state.start(action);
    case 'flags/REQUEST_FLAGS_FAILED':
      return state.failed(action);
    case 'flags/RECEIVE_FLAGS':
      return state.done(action);
    default:
      return state;
  }
};

function createFlagRequestsByKeyInitialState() {
  const state = Map<string, Request>();

  if (halfwayIA()) {
    return state;
  }

  const flag = _flag();

  if (flag) {
    const f = createFlag(flag);
    return state.update(f.key, createRequest(), (req) =>
      req.merge({
        isFetching: false,
        lastFetched: Date.now(),
        error: null,
      }),
    );
  }

  return state;
}

const flagRequestsByKey = (state = createFlagRequestsByKeyInitialState(), action: FlagAction) => {
  switch (action.type) {
    case 'flags/REQUEST_FLAG':
      return state.set(action.flagKey, createRequest({ isFetching: true }));
    case 'flags/REQUEST_FLAG_FAILED':
      return state.update(action.flagKey, createRequest(), (req) =>
        req.merge({
          isFetching: false,
          lastFetched: action.timestamp,
          error: action.error,
        }),
      );
    case 'flags/RECEIVE_FLAG':
      return state.update(action.flagKey, createRequest(), (req) =>
        req.merge({
          isFetching: false,
          lastFetched: action.timestamp,
          error: null,
        }),
      );

    case 'flags/RECEIVE_FLAGS':
      const doneReq = createRequest().done(action);

      return state.withMutations((st) => {
        filterCurrentFromFlagsResponse(action, action.response.getIn(['entities', 'flags'])).forEach((flag: Flag) => {
          st.set(flag.key, doneReq);
        });
        return st;
      });

    case 'flags/CREATE_FLAG_DONE':
      return state.update(action.flag.key, createRequest(), (req) =>
        req.merge({
          isFetching: false,
          lastFetched: action.timestamp,
          error: null,
        }),
      );
    case 'flags/DELETE_FLAG_DONE':
      return state.delete(action.flag.key);
    default:
      return state;
  }
};

const dependentFlagRequestsByKey = (state: Map<string, Request> = Map(), action: FlagAction) => {
  switch (action.type) {
    case 'flags/REQUEST_DEPENDENT_FLAGS':
      return state.set(
        action.flagKey,
        createRequest({
          isFetching: true,
        }),
      );
    case 'flags/RECEIVE_DEPENDENT_FLAGS':
      return state.update(action.flagKey, createRequest(), (req) =>
        req.merge({
          isFetching: false,
          lastFetched: action.timestamp,
          error: null,
        }),
      );
    case 'flags/RECEIVE_DEPENDENT_FLAGS_FAILED':
      return state.update(action.flagKey, createRequest(), (req) =>
        req.merge({
          isFetching: false,
          lastFetched: action.timestamp,
          error: action.error,
        }),
      );
    default:
      return state;
  }
};

const dependentFlagWithEnvRequestsByKey = (state: Map<string, Request> = Map(), action: FlagAction) => {
  switch (action.type) {
    case 'flags/REQUEST_DEPENDENT_FLAGS_WITH_ENV':
      return state.set(
        action.flagKey,
        createRequest({
          isFetching: true,
        }),
      );
    case 'flags/RECEIVE_DEPENDENT_FLAGS_WITH_ENV':
      return state.update(action.flagKey, createRequest(), (req) =>
        req.merge({
          isFetching: false,
          lastFetched: action.timestamp,
          error: null,
        }),
      );
    default:
      return state;
  }
};

const flagOrdering = (state = List(), action: AnyAction) => {
  if (action.response && action.response.hasIn(['entities', 'flags'])) {
    return action.response.getIn(['result', 'items']);
  }

  if (action.type === 'flags/CREATE_FLAG_DONE') {
    return state.concat(action.flag.key);
  }

  return state;
};

export type FlagsState = {
  listRequest: Request;
  requestsByKey: Map<string, Request>;
  entities: FlagEntities;
  ordering: List<string>;
  dependentFlagsRequest: Map<string, Request>;
  dependentFlagsWithEnvRequest: Map<string, Request>;
  dependentFlagWithEnvEntities: Map<string, FlagAndEnvKeys>;
  dependentFlagsEntities: Map<string, FlagAndEnvKeys>;
  flagsPagination: Map<string, PaginationType>;
};

export const combinedFlagsReducer = combineReducers({
  listRequest: flagListRequest,
  requestsByKey: flagRequestsByKey,
  entities: flagEntities,
  ordering: flagOrdering,
  dependentFlagsWithEnvRequest: dependentFlagWithEnvRequestsByKey,
  dependentFlagsRequest: dependentFlagRequestsByKey,
  dependentFlagWithEnvEntities,
  dependentFlagsEntities,
  flagsPagination,
});

const flagsInitialState = combinedFlagsReducer(undefined, { type: undefined });

export const flagsByProjectKey = (
  state: { [s: string]: FlagsState } = {},
  action: AnyAction & { projectKey?: string },
): { [s: string]: FlagsState } =>
  action.projectKey ? { ...state, [action.projectKey]: combinedFlagsReducer(state[action.projectKey], action) } : state;

type FlagStatusEntity = StateWithRequestInfo<{
  entity: FlagStatus | undefined;
}>;

export function createFlagStatusEntity(s?: FlagStatus, timestamp?: number): FlagStatusEntity {
  return fromJS({
    isFetching: false,
    lastFetched: timestamp,
    entity: s,
    error: null,
  });
}

export function getCheckAccessResource(state = false, action: AnyAction) {
  switch (action.type) {
    case 'flags/CHECK_ACCESS_RESOURCE':
      return action.willRemoveEditingAbility;
    case 'flags/UPDATE_PROJECT_FLAG_SETTINGS_DONE':
      return false;
    default:
      return state;
  }
}

function flagStatus(state = createFlagStatusEntity(), action: AnyAction) {
  switch (action.type) {
    case 'flags/REQUEST_FLAG_STATUS':
      return state.merge({
        isFetching: true,
        error: null,
      });
    case 'flags/REQUEST_FLAG_STATUS_FAILED':
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        error: action.error,
        entity: null,
      });
    case 'flags/RECEIVE_FLAG_STATUS':
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        entity: action.status,
        error: null,
      });
    default:
      return state;
  }
}

export type FlagStatusEntities = Map<string, FlagStatusEntity>;
export type FlagStatusesState = StateWithRequestInfo<{
  entities: FlagStatusEntities;
}>;

export function flagStatuses(
  state: FlagStatusesState = fromJS({
    lastFetched: null,
    isFetching: false,
    entities: {},
  }),
  action: AnyAction,
) {
  switch (action.type) {
    case 'flags/REQUEST_FLAG_STATUSES':
    case 'flags/REQUEST_FLAG_STATUSES_BY_ENVS_AND_FLAGS':
      return state.set('isFetching', true);
    case 'flags/REQUEST_FLAG_STATUSES_FAILED':
    case 'flags/REQUEST_FLAG_STATUSES_BY_ENVS_AND_FLAGS_FAILED':
      return state.set('isFetching', false);
    case 'flags/RECEIVE_FLAG_STATUSES_BY_ENVS_AND_FLAGS':
      const result = action.environmentKeys.reduce(
        (acc: Map<string, FlagStatusEntity>, envKey: string) =>
          acc.set(
            envKey,
            action.response.reduce((all: Map<string, FlagStatusEntity>, s: Map<string, FlagStatus>) => {
              const envFlagStatus = s.get(envKey);
              return all.set(envFlagStatus?.getFlagKey(), createFlagStatusEntity(envFlagStatus, action.timestamp));
            }, Map()),
          ),
        Map(),
      );

      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        entities: result,
      });
    case 'flags/RECEIVE_FLAG_STATUSES':
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        entities: action.response
          .getIn(['entities', 'statuses'])
          .reduce(
            (all: Map<string, FlagStatusEntity>, s: FlagStatus) =>
              all.set(s.getFlagKey(), createFlagStatusEntity(s, action.timestamp)),
            Map(),
          ),
      });
    case 'flags/REQUEST_FLAG_STATUS':
    case 'flags/REQUEST_FLAG_STATUS_FAILED':
    case 'flags/RECEIVE_FLAG_STATUS':
      return state.updateIn(['entities', action.flagKey], (s) => flagStatus(s || createFlagStatusEntity(), action));
    default:
      return state;
  }
}

const flagStatusActionTypes = [
  'flags/REQUEST_FLAG_STATUSES',
  'flags/REQUEST_FLAG_STATUSES_FAILED',
  'flags/RECEIVE_FLAG_STATUSES',
  'flags/REQUEST_FLAG_STATUS',
  'flags/REQUEST_FLAG_STATUS_FAILED',
  'flags/RECEIVE_FLAG_STATUS',
] as const;

type FlagStatusActionType = (typeof flagStatusActionTypes)[number];

type FlagStatusAction = Extract<FlagAction, { type: FlagStatusActionType }>;

function isStatusAction(action: AnyAction): action is FlagStatusAction {
  if (isEmptyAction(action)) {
    return false;
  }

  return flagStatusActionTypes.includes(action.type);
}

const multiEnvFlagStatusActionTypes = [
  'flags/REQUEST_FLAG_STATUSES_BY_ENVS_AND_FLAGS',
  'flags/REQUEST_FLAG_STATUSES_BY_ENVS_AND_FLAGS_FAILED',
  'flags/RECEIVE_FLAG_STATUSES_BY_ENVS_AND_FLAGS',
] as const;

type MultiEnvFlagStatusActionType = (typeof multiEnvFlagStatusActionTypes)[number];

type MultiEnvFlagStatusAction = Extract<FlagAction, { type: MultiEnvFlagStatusActionType }>;

function isMultiEnvStatusAction(action: AnyAction): action is MultiEnvFlagStatusAction {
  if (isEmptyAction(action)) {
    return false;
  }

  return multiEnvFlagStatusActionTypes.includes(action.type);
}

export function flagStatusesByEnvironmentKey(
  state = Map<string, Map<string, FlagStatusesState>>(),
  action: FlagAction,
) {
  if (isEmptyAction(action)) {
    return state;
  }

  if (isMultiEnvStatusAction(action)) {
    return action.environmentKeys.reduce(
      (updated: Map<string, Map<string, FlagStatusesState>>, key: string) =>
        updated.updateIn([action.projectKey, key], (prevState = flagStatuses(undefined, { type: undefined })) =>
          flagStatuses(prevState, action),
        ),
      state,
    );
  } else {
    return isStatusAction(action)
      ? state.updateIn(
          [action.projectKey, action.environmentKey],
          (prevState = flagStatuses(undefined, { type: undefined })) => flagStatuses(prevState, action),
        )
      : state;
  }
}

export const flagStatusesByProjectKeySelector = (state: GlobalState, { projectKey }: { projectKey?: string } = {}) => {
  let requestedProjectKey = currentProjectKeySelector(state);

  if (projectKey) {
    requestedProjectKey = projectKey;
  }

  return state.flagStatusesByEnvironmentKey.get(requestedProjectKey, Map<string, FlagStatusesState>());
};

export const flagStatusesSelector = (
  state: GlobalState,
  { projectKey, environmentKey }: { projectKey?: string; environmentKey?: string } = {},
) => {
  let requestedProjectKey = currentProjectKeySelector(state);
  let requestedEnvironmentKey = currentEnvironmentKeySelector(state);

  if (projectKey && environmentKey) {
    requestedProjectKey = projectKey;
    requestedEnvironmentKey = environmentKey;
  }

  const statusesByProjectKey = state.flagStatusesByEnvironmentKey.get(
    requestedProjectKey,
    Map<string, FlagStatusesState>(),
  );

  return statusesByProjectKey.get(requestedEnvironmentKey, flagStatuses(undefined, { type: undefined }));
};

export const getStatusesForFlagsEvaluatedWithinNDays = (
  state: GlobalState,
  { projectKey, environmentKey, withinNDays }: { projectKey?: string; environmentKey?: string; withinNDays: number },
) => {
  let requestedProjectKey = currentProjectKeySelector(state);
  let requestedEnvironmentKey = currentEnvironmentKeySelector(state);

  if (projectKey && environmentKey) {
    requestedProjectKey = projectKey;
    requestedEnvironmentKey = environmentKey;
  }

  return flagStatusesSelector(state, { projectKey: requestedProjectKey, environmentKey: requestedEnvironmentKey })
    .get('entities')
    .sort((a: FlagStatusEntity, b: FlagStatusEntity) => {
      const flagStatusA = a.get('entity');
      const flagStatusB = b.get('entity');
      return new Date(flagStatusB?.lastRequested || 0).getTime() - new Date(flagStatusA?.lastRequested || 0).getTime();
    })
    .filter((status: FlagStatusEntity) => {
      const entity = status.get('entity');
      return entity ? differenceInCalendarDays(new Date(), new Date(entity.lastRequested)) < withinNDays : false;
    });
};
export function isLoadingFlagStatusesSelector(
  state: GlobalState,
  {
    projectKey,
    extraEnvironmentKeys,
    ignoreCurrentEnvironmentStatus,
  }: { projectKey?: string; extraEnvironmentKeys?: string[]; ignoreCurrentEnvironmentStatus?: boolean } = {},
) {
  let requestedProjectKey = currentProjectKeySelector(state);
  let requestedEnvironmentKeys = [currentEnvironmentKeySelector(state)];

  if (projectKey && extraEnvironmentKeys) {
    requestedProjectKey = projectKey;
    requestedEnvironmentKeys = ignoreCurrentEnvironmentStatus
      ? [...extraEnvironmentKeys]
      : [...requestedEnvironmentKeys, ...extraEnvironmentKeys];
  }

  return requestedEnvironmentKeys
    .map((environmentKey) => flagStatusesSelector(state, { projectKey: requestedProjectKey, environmentKey }))
    .some((statuses) => !statuses.get('lastFetched') || statuses.get('isFetching'));
}

export const flagStatusEntitiesSelector = createSelector(flagStatusesSelector, (statuses) =>
  statuses.get('entities').map((status: FlagStatusEntity) => status.get('entity')),
);

export function latestFlagStatusSelector(
  state: GlobalState,
  { projectKey, environmentKey }: { projectKey?: string; environmentKey?: string } = {},
) {
  let requestedProjectKey = currentProjectKeySelector(state);
  let requestedEnvironmentKey = currentEnvironmentKeySelector(state);

  if (projectKey && environmentKey) {
    requestedProjectKey = projectKey;
    requestedEnvironmentKey = environmentKey;
  }

  const entities = flagStatusesSelector(state, {
    projectKey: requestedProjectKey,
    environmentKey: requestedEnvironmentKey,
  }).get('entities');

  return entities
    .sort((a: FlagStatusEntity, b: FlagStatusEntity) => {
      const entityA = a.get('entity');
      const entityB = b.get('entity');
      return new Date(entityB?.lastRequested || 0).getTime() - new Date(entityA?.lastRequested || 0).getTime();
    })
    .first<undefined>();
}

export const flagsSelector = (state: GlobalState, projectKey?: string) =>
  state.flagsByProjectKey[projectKey || currentProjectKeySelector(state)] || flagsInitialState;

export const flagListRequestSelector = (state: GlobalState) => flagsSelector(state).listRequest;

export const flagRequestsByKeySelector = (state: GlobalState) => flagsSelector(state).requestsByKey;

export const flagRequestsByKeyForProjectSelector = (state: GlobalState, projectKey?: string) =>
  flagsSelector(state, projectKey).requestsByKey;

export const flagOrderingSelector = (state: GlobalState) => flagsSelector(state).ordering;

export const flagEntitiesSelector = (state: GlobalState) => flagsSelector(state).entities;

export const flagEntitiesForProjectSelector = (state: GlobalState, projectKey?: string) =>
  flagsSelector(state, projectKey).entities;

export const dependentFlagWithEnvEntitiesSelector = (state: GlobalState) =>
  flagsSelector(state).dependentFlagWithEnvEntities;

export const dependentFlagsEntitiesSelector = (state: GlobalState) => flagsSelector(state).dependentFlagsEntities;

export const flagTagsSelector = createSelector(flagsSelector, (fs) =>
  fs.entities
    .map((f: Flag) => f.tags)
    .flatten()
    .toList(),
);

export const sortedFlagTagsSelector = createSelector(flagTagsSelector, (tags) => tags.sort());

export const flagListSelector = createSelector(flagEntitiesSelector, flagOrderingSelector, (entities, ordering) =>
  ordering.map((key) => entities.get(key)).filter((e) => !!e),
);

export const flagsOffsetSelector = createSelector(searchSelector, (query: SearchParams<{ offset: string }>) =>
  query ? query.offset : '',
);

export const flagsPaginationSelector = (state: GlobalState) => flagsSelector(state).flagsPagination.get('all');

export type FlagKeyFromPropsType = ParamsProps<{ flagKey: string }> & { flagKey?: string };

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const flagKeyFromProps = (props: FlagKeyFromPropsType) =>
  props.match
    ? props.match.params.flagKey
    : props.flagKey!; /* eslint-enable @typescript-eslint/no-non-null-assertion */

export const flagRequestByKeySelector = createSelector(
  flagRequestsByKeySelector,
  (_: GlobalState, props: FlagKeyFromPropsType) => flagKeyFromProps(props),
  (requests, flagKey) => requests.get(flagKey),
);

export const flagEntityByKeySelector = createSelector(
  flagEntitiesSelector,
  (_: GlobalState, props: FlagKeyFromPropsType) => flagKeyFromProps(props),
  (entities, flagKey) => entities.get(flagKey),
);

export const flagByKeySelector = createStructuredSelector<
  GlobalState,
  FlagKeyFromPropsType,
  { request?: Request; entity?: Flag }
>({
  request: flagRequestByKeySelector,
  entity: flagEntityByKeySelector,
});

type FlagAndProjectKeys = { flagKey: string; projKey: string };

export const flagRequestByKeyForProjectSelector = createSelector(
  (state: GlobalState) => state.flagsByProjectKey,
  (_: GlobalState, props: FlagAndProjectKeys) => props,
  (flagsByProject, { flagKey, projKey }) => flagsByProject[projKey]?.requestsByKey.get(flagKey),
);

export const flagEntityByKeyForProjectSelector = createSelector(
  (state: GlobalState) => state.flagsByProjectKey,
  (_: GlobalState, props: FlagAndProjectKeys) => props,
  (flagsByProject, { flagKey, projKey }) => flagsByProject[projKey]?.entities.get(flagKey),
);

export const flagByKeyForProjectSelector = createSelector(
  (state: GlobalState, props: FlagAndProjectKeys) => flagRequestByKeyForProjectSelector(state, props),
  (state: GlobalState, props: FlagAndProjectKeys) => flagEntityByKeyForProjectSelector(state, props),
  (request, entity) => ({ request, entity }),
);

const dependentFlagRequests = (state: GlobalState) => flagsSelector(state).dependentFlagsRequest;

const dependentFlagsWithEnvRequest = (state: GlobalState) => flagsSelector(state).dependentFlagsWithEnvRequest;

export const checkAccessResource = (state: GlobalState) => state.getCheckAccessResource;

export const getDependentFlagsWithEnvByKey = createStructuredSelector<
  GlobalState,
  {
    request: Map<string, Request>;
    dependentFlagsWithEnv: Map<string, FlagAndEnvKeys>;
  }
>({
  request: dependentFlagsWithEnvRequest,
  dependentFlagsWithEnv: dependentFlagWithEnvEntitiesSelector,
});

const getDependentFlagsByKey = createStructuredSelector<
  GlobalState,
  ParamsProps,
  {
    request: Map<string, Request>;
    dependentFlags: Map<string, FlagAndEnvKeys>;
  }
>({
  request: dependentFlagRequests,
  dependentFlags: dependentFlagsEntitiesSelector,
});

export const flagAndRelatedFlagsByKeySelector = (state: GlobalState, props: FlagKeyFromPropsType) => {
  const { request: dependentFlagsRequest, dependentFlags } = getDependentFlagsByKey(state, props);

  const flagKey = flagKeyFromProps(props);
  const dependentFlagKey = dependentFlags.get(flagKey);

  return {
    isReady: ready(dependentFlagsRequest.get(flagKey)),
    dependentFlagsWithEnvironments: dependentFlags,
    hasDependentFlags: dependentFlagKey && Object.getOwnPropertyNames(dependentFlagKey).length > 0,
  };
};

registry.addReducers({
  flagsByProjectKey,
  flagStatusesByEnvironmentKey,
  getCheckAccessResource,
});
