import { createTrackerForCategory } from '@gonfalon/analytics';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { isRelativeSchedulingEnabled } from '@gonfalon/dogfood-flags';
import { toCreateFlagWorkflow, toFlagWorkflow, toFlagWorkflows, toHref } from '@gonfalon/navigator';
import { useFlagKey, useProjectKey } from '@gonfalon/router';
// eslint-disable-next-line no-restricted-imports
import { fromJS, get, List, Map, Record } from 'immutable';

import { useProfileEntity } from 'reducers/profile';
import { denyDecision } from 'utils/accessUtils';
import { ApprovalsReviewStatus } from 'utils/approvalsUtils';
import { Flag } from 'utils/flagUtils';
import { CreateFunctionInput, ImmutableMap, toJS } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';
import { WorkflowKind } from 'utils/pendingChangesUtils';

import { makeSemanticInstruction } from './instructions/shared/helpers';
import { ScheduleKind, WaitDurationUnit } from './scheduledChangesUtils';
import { WorkflowSemanticInstruction } from './workflowUtils';

export enum CustomWorkflowExecutionStatusType {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  FAILED = 'failed',
  PENDING = 'pending',
}
export class CustomWorkflowExecution extends Record<{
  status: CustomWorkflowExecutionStatusType;
  stopDate?: number;
}>({
  status: CustomWorkflowExecutionStatusType.PENDING,
  stopDate: undefined,
}) {}

export enum CustomWorkflowConditionKindType {
  APPROVAL = 'ld-approval',
  SCHEDULE = 'schedule',
  DEFAULT = 'default',
}

export type CustomWorkflowConditionType =
  | CustomWorkflowApprovalCondition
  | CustomWorkflowScheduleCondition
  | CustomWorkflowDefaultCondition;

export type CustomWorkflowDefaultConditionProps = {
  _id: string;
  kind: CustomWorkflowConditionKindType.DEFAULT;
};
export class CustomWorkflowDefaultCondition extends Record<CustomWorkflowDefaultConditionProps>({
  _id: '',
  kind: CustomWorkflowConditionKindType.DEFAULT,
}) {
  toRep(): ImmutableMap<CustomWorkflowDefaultConditionProps> {
    return fromJS(this.toJSON());
  }
}

export type CustomWorkflowApprovalConditionProps = {
  _id: string;
  kind: CustomWorkflowConditionKindType.APPROVAL;
  description: string;
  notifyMemberIds: List<string>;
  notifyTeamKeys?: List<string>;
  reviewStatus: ApprovalsReviewStatus;
  _execution: CustomWorkflowExecution;
};

export class CustomWorkflowApprovalCondition extends Record<CustomWorkflowApprovalConditionProps>({
  _id: '',
  kind: CustomWorkflowConditionKindType.APPROVAL,
  description: '',
  notifyMemberIds: List(),
  notifyTeamKeys: undefined,
  reviewStatus: ApprovalsReviewStatus.DEFAULT,
  _execution: new CustomWorkflowExecution(),
}) {
  toRep(): ImmutableMap<CustomWorkflowApprovalConditionProps> {
    return fromJS(this.toJSON());
  }
}

export type CustomWorkflowScheduleConditionProps = {
  _id: string;
  kind: CustomWorkflowConditionKindType.SCHEDULE;
  executionDate?: number;
  _execution: CustomWorkflowExecution;
  scheduleKind?: ScheduleKind;
  waitDuration?: number;
  waitDurationUnit?: WaitDurationUnit;
};

export class CustomWorkflowScheduleCondition extends Record<CustomWorkflowScheduleConditionProps>({
  _id: '',
  kind: CustomWorkflowConditionKindType.SCHEDULE,
  executionDate: 0,
  _execution: new CustomWorkflowExecution(),
  scheduleKind: ScheduleKind.RELATIVE,
  waitDuration: 1,
  waitDurationUnit: WaitDurationUnit.MINUTE,
}) {
  toRep(): ImmutableMap<CustomWorkflowScheduleConditionProps & { executionDate?: number; executeNow?: boolean }> {
    return fromJS(this.toJSON()).withMutations((map: ImmutableMap<CustomWorkflowScheduleConditionProps>) => {
      if (!isRelativeSchedulingEnabled()) {
        map.delete('scheduleKind');
        map.delete('waitDuration');
        map.delete('waitDurationUnit');
      } else if (
        (!map.get('scheduleKind') && map.get('executionDate')) ||
        map.get('scheduleKind') === ScheduleKind.ABSOLUTE
      ) {
        map.update('scheduleKind', () => ScheduleKind.ABSOLUTE);
        map.delete('waitDuration');
        map.delete('waitDurationUnit');
      } else if (map.get('scheduleKind') === ScheduleKind.RELATIVE) {
        map.delete('executionDate');
      }
    });
  }
}

export const createCustomWorkflowScheduleCondition = (
  props: CreateFunctionInput<CustomWorkflowScheduleConditionProps> = {},
) => {
  const sc =
    props instanceof CustomWorkflowScheduleCondition ? props : new CustomWorkflowScheduleCondition(fromJS(props));

  return sc.withMutations((s) => {
    s.update('_execution', (e) => new CustomWorkflowExecution(e));
    if (isRelativeSchedulingEnabled() && !s.get('scheduleKind') && s.get('executionDate')) {
      s.update('scheduleKind', () => ScheduleKind.ABSOLUTE);
    }
  });
};

export const createCustomWorkflowApprovalCondition = (
  props: CreateFunctionInput<CustomWorkflowApprovalConditionProps> = {},
) => {
  const ac =
    props instanceof CustomWorkflowApprovalCondition ? props : new CustomWorkflowApprovalCondition(fromJS(props));
  return ac.withMutations((a) => {
    a.update('_execution', (e) => new CustomWorkflowExecution(e));
  });
};

export enum CustomWorkflowActionType {
  DEFAULT = 'default',
  PATCH = 'patch',
}

export type CustomWorkflowActionProps = {
  kind: CustomWorkflowActionType;
  instructions: List<WorkflowSemanticInstruction>;
};

export class CustomWorkflowAction extends Record<CustomWorkflowActionProps>({
  kind: CustomWorkflowActionType.DEFAULT,
  instructions: List(),
}) {
  toRep(): ImmutableMap<CustomWorkflowActionProps> {
    return fromJS(this.toJSON());
  }
}

export const createCustomWorkflowAction = (props: Partial<CustomWorkflowActionProps> | CustomWorkflowAction = {}) => {
  if (props instanceof CustomWorkflowAction) {
    return props;
  }
  let customWorkflowAction = new CustomWorkflowAction(fromJS(props));
  customWorkflowAction = customWorkflowAction.update('instructions', (vs) =>
    vs.size > 0 ? vs.map((v) => makeSemanticInstruction(get(v, 'kind', ''), v)) : List(),
  );
  return customWorkflowAction;
};

export type CustomWorkflowStageProps = {
  _id: string;
  name: string;
  conditions: List<CustomWorkflowConditionType>;
  action: CustomWorkflowAction;
  executeConditionsInSequence: boolean;
  _execution: CustomWorkflowExecution;
};
export class CustomWorkflowStage extends Record<CustomWorkflowStageProps>({
  _id: '',
  name: '',
  conditions: List(),
  action: createCustomWorkflowAction(),
  executeConditionsInSequence: false,
  _execution: new CustomWorkflowExecution(),
}) {
  toRep(): ImmutableMap<CustomWorkflowStageProps> {
    return fromJS(this.toJSON()).withMutations((map: ImmutableMap<CustomWorkflowStageProps>) => {
      map.set(
        'conditions',
        map.get('conditions').map((condition) => condition.toRep()),
      );
      map.set('action', map.get('action').toRep());
    });
  }
}

export const createCustomWorkflowStage = (props: Partial<CustomWorkflowStageProps> | CustomWorkflowStage = {}) => {
  if (props instanceof CustomWorkflowStage) {
    return props;
  }
  let customWorkflowStage = new CustomWorkflowStage(fromJS(props));
  customWorkflowStage = customWorkflowStage.withMutations((stage) => {
    stage.update('conditions', (cs) =>
      cs.map((c) => {
        if (toJS(c).kind === CustomWorkflowConditionKindType.APPROVAL) {
          return createCustomWorkflowApprovalCondition(c as CustomWorkflowApprovalConditionProps);
        } else if (toJS(c).kind === CustomWorkflowConditionKindType.SCHEDULE) {
          return createCustomWorkflowScheduleCondition(c as CustomWorkflowScheduleConditionProps);
        }
        return new CustomWorkflowDefaultCondition();
      }),
    );
    stage.update('action', (a) => createCustomWorkflowAction(a));
    stage.update('_execution', (e) => new CustomWorkflowExecution(e));
  });
  return customWorkflowStage;
};

export class CustomWorkflowConflict extends Record<{ stageId: string; message: string }>({
  stageId: '',
  message: '',
}) {}

export type CustomWorkflowProps = {
  _id: string;
  _creationDate: number;
  _maintainerId: string;
  _resourceId: string;
  _links: ImmutableMap<{
    self: Link;
    resource: Link;
  }>;
  _conflicts: List<CustomWorkflowConflict>;
  _execution: CustomWorkflowExecution;
  kind: WorkflowKind;
  name: string;
  description: string;
  stages: List<CustomWorkflowStage>;
  templateKey: string;
};
export class CustomWorkflow extends Record<CustomWorkflowProps>({
  _id: '',
  _creationDate: 0,
  _maintainerId: '',
  _resourceId: '',
  _links: Map(),
  _conflicts: List(),
  _execution: new CustomWorkflowExecution(),
  kind: WorkflowKind.DEFAULT,
  name: '',
  description: '',
  stages: List(),
  templateKey: '',
}) {
  getId() {
    return this._id;
  }
  getStatus() {
    return this._execution.status;
  }
  toRep(): ImmutableMap<CustomWorkflowProps> {
    return fromJS(this.toJSON()).withMutations((map: ImmutableMap<CustomWorkflowProps>) => {
      map.set(
        'stages',
        map.get('stages').map((stage) => stage.toRep()),
      );
    });
  }
}

export const createCustomWorkflow = (props: CreateFunctionInput<CustomWorkflow> = {}) => {
  if (props instanceof CustomWorkflow) {
    return props;
  }
  let customWorkflow = new CustomWorkflow(fromJS(props));
  customWorkflow = customWorkflow.withMutations((workflow) => {
    workflow.update('_conflicts', (conflicts) =>
      conflicts?.size > 0 ? conflicts.map((c) => new CustomWorkflowConflict(c)) : List(),
    );
    workflow.update('_execution', (e) => new CustomWorkflowExecution(e));
    workflow.update('stages', (stages) => stages.map(createCustomWorkflowStage));
  });
  return customWorkflow;
};

export const useUpdateCustomWorkflowsAccessDecision = ({ envKey, flag }: { envKey: string; flag?: Flag }) => {
  const profile = useProfileEntity();
  const checkAccess = flag?.checkAccess({ envKey, profile });
  return checkAccess ? checkAccess('updateFeatureWorkflows') : denyDecision();
};

export const TEMPLATE_SEARCH_PARAM = 'template';
export type WorkflowPathParams = {
  projKey: string;
  envKey: string;
  flagKey: string;
  workflowId?: string;
  templateKey?: string;
};

export function workflowsPath(params: WorkflowPathParams, options?: { new: boolean }): string {
  const { projKey, envKey, flagKey } = params;

  if (params.workflowId !== undefined) {
    return toHref(
      toFlagWorkflow({
        projectKey: projKey,
        environmentKey: envKey,
        flagKey,
        workflowId: params.workflowId,
      }),
    );
  }

  if (options?.new) {
    let search;
    if (params.templateKey) {
      search = { [TEMPLATE_SEARCH_PARAM]: params.templateKey };
    }

    return toHref(
      toCreateFlagWorkflow(
        { projectKey: projKey, environmentKey: envKey, flagKey },
        {
          search,
        },
      ),
    );
  }

  return toHref(toFlagWorkflows({ projectKey: projKey, environmentKey: envKey, flagKey }));
}

export function useNewCustomWorkflowURL(templateKey?: string) {
  const projKey = useProjectKey();
  const envKey = useSelectedEnvironmentKey();
  const flagKey = useFlagKey();
  return workflowsPath({ projKey, envKey, flagKey, templateKey: templateKey || '' }, { new: true });
}

export const workflowBuilderDescriptionCopy =
  'Use the workflow builder to create multi-stage workflows and better support how your teams build, ship, and control software.';

export enum WorkflowsViews {
  EMPTY = 'Empty state',
  NON_EMPTY = 'Non-empty state',
  DETAILS = 'Workflow details',
  TABLE = 'Workflows table',
}

type TrackWorkflowsEventAttributes = {
  source?: WorkflowsViews | 'Flag targeting';
  option?: string;
};

export const trackWorkflowsEvent = (event: string, attributes?: TrackWorkflowsEventAttributes) => {
  createTrackerForCategory('Workflows')(event, attributes);
};

export enum WorkflowsSortOrder {
  CREATION_DATE_ASC = 'creationDate',
  CREATION_DATE_DESC = '-creationDate',
  STOP_DATE_ASC = 'stopDate',
  STOP_DATE_DESC = '-stopDate',
}
