import { createTrackerForCategory } from '@gonfalon/analytics';
import { forEach } from '@gonfalon/es6-utils';
import { LDSingleKindContext } from 'launchdarkly-js-client-sdk';
import { v4 } from 'uuid';

import { LDContext } from 'components/Contexts/types/LDContext';
import { VariationValue } from 'utils/flagUtils';
import { UserAttributesProps as LDUser } from 'utils/userAttributeUtils';

export enum EventKind {
  FEATURE = 'feature',
  CLICK = 'click',
  PAGEVIEW = 'pageview',
  CUSTOM = 'custom',
  IDENTIFY = 'identify',
  INDEX = 'index',
  SUMMARY = 'summary',
  FLATTEN_SUMMARY = 'flattenSummary',
  DEBUG = 'debug',
  UNKNOWN = 'unknown',
}

const eventTypeLabels = {
  [EventKind.FEATURE]: 'Flag eval',
  [EventKind.CLICK]: 'Click',
  [EventKind.PAGEVIEW]: 'Page view',
  [EventKind.CUSTOM]: 'Custom',
  [EventKind.IDENTIFY]: 'Identify',
  [EventKind.INDEX]: 'Index',
  [EventKind.SUMMARY]: 'Summary',
  [EventKind.FLATTEN_SUMMARY]: 'Flatten Summary',
  [EventKind.DEBUG]: 'Debug',
  [EventKind.UNKNOWN]: 'Unknown',
};

export const getEventTypeLabel = (eventType: EventKind) => eventTypeLabels[eventType];

const eventTypeColors = {
  [EventKind.FEATURE]: '#74d3eb',
  [EventKind.CLICK]: '#8a89a6',
  [EventKind.PAGEVIEW]: '#a05d56',
  [EventKind.CUSTOM]: '#58657f',
  [EventKind.IDENTIFY]: '#cbeabb',
  [EventKind.INDEX]: '',
  [EventKind.SUMMARY]: '',
  [EventKind.FLATTEN_SUMMARY]: '',
  [EventKind.DEBUG]: '',
  [EventKind.UNKNOWN]: '',
};

export const getEventTypeColor = (eventType: EventKind) => eventTypeColors[eventType];

export type FilterType = 'all_events' | 'flags' | 'contexts' | 'goals';

export type EventContextType = LDContext;

export type BaseEventType = {
  _key: string;
  key?: string;
  kind: EventKind;
  context?: EventContextType;
  contextKeys?: { [k: string]: string };
  creationDate: number;
  id: number;
};

export type GenericEventType = BaseEventType & {
  kind: EventKind.UNKNOWN;
};

export type IdentifyEventType = BaseEventType & {
  kind: EventKind.IDENTIFY;
};

export type DebuggerEventType = BaseEventType & {
  kind: EventKind.DEBUG;
  value: VariationValue;
  version?: number;
  variation?: VariationValue;
  reason?: {
    kind: string;
  };
  default?: VariationValue;
};

export type IndexEventType = BaseEventType & {
  kind: EventKind.INDEX;
};

export type ClickEventType = BaseEventType & {
  url: string;
  selector: string;
  kind: EventKind.CLICK;
};

export type PageViewEventType = BaseEventType & {
  url: string;
  kind: EventKind.PAGEVIEW;
};

export type CustomEventType = BaseEventType & {
  data: {};
  kind: EventKind.CUSTOM;
};

export type FeatureRequestEventType = BaseEventType & {
  value: VariationValue;
  version: number;
  kind: EventKind.FEATURE | EventKind.DEBUG;
  variation: VariationValue;
  reason?: {
    inExperiment?: boolean;
    kind: string;
  };
  default?: VariationValue;
};

export type SummaryEventType = {
  _key: string;
  kind: EventKind.SUMMARY;
  startDate: number;
  endDate: number;
  features: { [k: string]: FlagEvalSummaryType };
  id: number;
};

export type FlattenSummaryEventType = BaseEventType & {
  key: string;
  kind: EventKind.FLATTEN_SUMMARY;
  features: FlagEvalSummaryType;
};

export const isSummaryEvent = (event: AnyEventType): event is SummaryEventType => event.kind === EventKind.SUMMARY;

export const isFeatureRequestEvent = (event: AnyEventType): event is FeatureRequestEventType =>
  event.kind === 'feature';

export type ContextEventType = IdentifyEventType | IndexEventType;
export type ExperimentationEventType = ClickEventType | PageViewEventType | CustomEventType;
export type FlagEventType = FeatureRequestEventType | SummaryEventType | FlattenSummaryEventType;

export type AnyEventType =
  | ContextEventType
  | ExperimentationEventType
  | FlagEventType
  | GenericEventType
  | DebuggerEventType;

export type AnyNonSummaryEventType = ContextEventType | ExperimentationEventType | FeatureRequestEventType;

export const tagEvent = (props: AnyEventType) => ({
  ...props,
  _key: props._key || v4(),
});

export type Counter = {
  _key?: string;
  value?: VariationValue;
  version: number;
  count: number;
  variation?: number;
  unknown?: boolean;
};

export type FlagEvalSummaryType = {
  key?: string;
  contextKinds?: string[];
  default?: VariationValue;
  counters: Counter[];
};

export const flattenEvents = (events: AnyEventType[]) => {
  const allEvents: AnyEventType[] = [];
  events.forEach((event) => {
    if (isSummaryEvent(event)) {
      const { endDate, id } = event;
      const allFlags = event.features;
      forEach(allFlags, (value, key) => {
        allEvents.push({
          _key: v4(),
          kind: EventKind.FLATTEN_SUMMARY,
          creationDate: endDate,
          id,
          key,
          features: value,
        });
      });
    } else {
      allEvents.push(event);
    }
  });
  return allEvents;
};

const addContextKindToV3Events = (features: { [k: string]: FlagEvalSummaryType }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const convertedV4Summary: any = {};
  forEach(features, (v, k) => {
    convertedV4Summary[k] = { ...v, contextKinds: v.contextKinds ? v.contextKinds : ['user'] };
  });
  return convertedV4Summary;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertToV4Events = (events: any) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  events.map((e: any) => {
    if (e.userKey && e.kind === EventKind.FEATURE) {
      const v4FeatureEvent = {
        ...e,
        contextKeys: { user: e.userKey },
      };
      delete v4FeatureEvent.userKey;
      return v4FeatureEvent;
    }

    if (e.user && e.kind === EventKind.CLICK) {
      const v4ClickEvent = {
        ...e,
        contextKeys: { user: e.user?.key },
      };
      delete v4ClickEvent.user;
      return v4ClickEvent;
    }

    if (e.user && e.kind === EventKind.PAGEVIEW) {
      const v4PageViewEvent = {
        ...e,
        contextKeys: { user: e.user?.key },
      };
      delete v4PageViewEvent.user;
      return v4PageViewEvent;
    }

    if (e.userKey && e.kind === EventKind.CUSTOM) {
      const v4CustomEvent = {
        ...e,
        contextKeys: { user: e.userKey },
      };
      delete v4CustomEvent.userKey;
      return v4CustomEvent;
    }

    if (e.user && e.kind === EventKind.IDENTIFY) {
      const v4IdentifyEvent = {
        ...e,
        context: legacyToSingleKind(e.user),
      };
      delete v4IdentifyEvent.user;
      return v4IdentifyEvent;
    }

    if (e.user && e.kind === EventKind.INDEX) {
      const v4IndexEvent = {
        ...e,
        context: legacyToSingleKind(e.user),
      };
      delete v4IndexEvent.user;
      return v4IndexEvent;
    }

    if (e.kind === EventKind.SUMMARY) {
      const newFeatures = addContextKindToV3Events(e.features);
      return {
        ...e,
        features: newFeatures,
      };
    }

    if (e.user && e.kind === EventKind.DEBUG) {
      const v4DebugEvent = {
        ...e,
        context: legacyToSingleKind(e.user),
      };
      delete v4DebugEvent.user;
      return v4DebugEvent;
    }
    return e;
  });

export const getCanonicalKeyFromEvent = (contextKinds: { [k: string]: string }) => {
  const canonicalKey: string[] = [];
  forEach(contextKinds, (value, key) => {
    canonicalKey.push(`${key}:${value}`);
  });
  return canonicalKey.join(':');
};

export const eventKey = (event: AnyEventType) => {
  if (event.kind === EventKind.DEBUG) {
    return event.key;
  }
};

export const renderTimestamp = (event: AnyEventType) => {
  if (event.kind === EventKind.SUMMARY) {
    return event.endDate;
  }
  return event.creationDate;
};

export const getEvaluationReason = (event: AnyEventType) => {
  if (event.kind === EventKind.FEATURE || event.kind === EventKind.DEBUG) {
    return event?.reason?.kind;
  }
  return '';
};

export const renderEventKindType = (event: AnyEventType) => {
  if (
    event.kind === EventKind.SUMMARY ||
    event.kind === EventKind.FLATTEN_SUMMARY ||
    event.kind === EventKind.FEATURE ||
    event.kind === EventKind.DEBUG
  ) {
    return 'Flag';
  }
  if (event.kind === EventKind.INDEX || event.kind === EventKind.IDENTIFY) {
    return 'Context';
  }
  if (event.kind === EventKind.CLICK || event.kind === EventKind.CUSTOM || event.kind === EventKind.PAGEVIEW) {
    return 'Experiment';
  }
  return event.kind;
};

export const renderEventKind = (event: AnyEventType) => {
  if (event.kind === EventKind.SUMMARY || event.kind === EventKind.FLATTEN_SUMMARY) {
    return 'Summary';
  }
  return event.kind;
};

export const renderEventKey = (event: AnyEventType) => {
  if (event.kind === EventKind.SUMMARY) {
    return event._key;
  }
  if (event.kind === EventKind.INDEX || event.kind === EventKind.IDENTIFY) {
    return getCanonicalKey(event.context);
  }
  if (
    event.contextKeys &&
    (event.kind === EventKind.CLICK || event.kind === EventKind.CUSTOM || event.kind === EventKind.PAGEVIEW)
  ) {
    return getCanonicalKeyFromEvent(event.contextKeys);
  }
  return event.key;
};

export const renderContextIds = (event: AnyEventType) => {
  if (event.kind === EventKind.DEBUG) {
    return getCanonicalKey(event?.context);
  }
  if (event.kind === EventKind.FEATURE && event.contextKeys) {
    return getCanonicalKeyFromEvent(event.contextKeys);
  }
  if (event.kind === EventKind.INDEX || event.kind === EventKind.IDENTIFY) {
    return getCanonicalKey(event.context);
  }
  if (
    (event.kind === EventKind.CLICK || event.kind === EventKind.CUSTOM || event.kind === EventKind.PAGEVIEW) &&
    event.contextKeys
  ) {
    return getCanonicalKeyFromEvent(event.contextKeys);
  }
};

export const renderContextKind = (event: AnyEventType) => {
  if (event.kind === EventKind.FLATTEN_SUMMARY && event.features.contextKinds) {
    const contextKinds = event.features.contextKinds;
    return contextKinds.length > 1 ? 'Multi' : contextKinds[0];
  }
  if (event.kind === EventKind.DEBUG) {
    return event?.context?.kind;
  }
  if (event.kind === EventKind.FEATURE && event.contextKeys) {
    const contextKinds = Object.keys(event.contextKeys);
    return contextKinds.length > 1 ? 'multi' : contextKinds[0];
  }
};

export const renderEventValue = (event: AnyEventType) => {
  if (event.kind === EventKind.FLATTEN_SUMMARY) {
    let value;
    forEach(event.features.counters, (v: Counter) => (value = v.value));
    return value;
  }
  if (event.kind === EventKind.DEBUG || event.kind === EventKind.FEATURE) {
    return event.value;
  }
};

export const renderEventVariation = (event: AnyEventType) => {
  if (event.kind === EventKind.FLATTEN_SUMMARY) {
    let variation = 0;
    forEach(event.features.counters, (v: Counter) => {
      if (v.variation) {
        variation = v.variation;
      }
    });
    return variation;
  }
  if (event.kind === EventKind.DEBUG || event.kind === EventKind.FEATURE) {
    return event.variation;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function defined(value: any) {
  return value !== null && value !== undefined;
}

export function legacyToSingleKind(user: LDUser): LDSingleKindContext {
  const singleKindContext: LDSingleKindContext = {
    // Key and secondary were coerced to strings for eval
    // and for events, so we can make them strings up-front.
    ...(user.custom || []),
    kind: 'user',
    key: String(user.key),
  };
  // For legacy users we never established a difference between null
  // and undefined for inputs. Because anonymous can be used in evaluations
  // we would want it to not possibly match true/false unless defined.
  // Which is different than coercing a null/undefined anonymous as `false`.
  if (defined(user.anonymous)) {
    const anonymous = !!user.anonymous;
    delete singleKindContext.anonymous;
    singleKindContext.anonymous = anonymous;
  }
  if (defined(user.secondary)) {
    delete singleKindContext.secondary;
  }
  if (user.name !== null && user.name !== undefined) {
    singleKindContext.name = user.name;
  }
  if (user.ip !== null && user.ip !== undefined) {
    singleKindContext.ip = user.ip;
  }
  if (user.firstName !== null && user.firstName !== undefined) {
    singleKindContext.firstName = user.firstName;
  }
  if (user.lastName !== null && user.lastName !== undefined) {
    singleKindContext.lastName = user.lastName;
  }
  if (user.email !== null && user.email !== undefined) {
    singleKindContext.email = user.email;
  }
  if (user.avatar !== null && user.avatar !== undefined) {
    singleKindContext.avatar = user.avatar;
  }
  if (user.country !== null && user.country !== undefined) {
    singleKindContext.country = user.country;
  }
  // We are not pulling private attributes over because we will serialize
  // those from attribute references for events.
  return singleKindContext;
}

function encodeKey(key: string) {
  if (key.includes('%') || key.includes(':')) {
    return key.replace(/%/g, '%25').replace(/:/g, '%3A');
  }
  return key;
}

export function getCanonicalKey(context: LDContext | undefined | null) {
  if (context) {
    if ((context.kind === undefined || context.kind === null || context.kind === 'user') && context.key) {
      return `user:${context.key}`;
    } else if (context.kind !== 'multi' && context.key) {
      return `${context.kind}:${encodeKey(context.key as string)}`;
    } else if (context.kind === 'multi') {
      return Object.keys(context)
        .sort()
        .filter((key) => key !== 'kind')
        .map((key) => `${key}:${encodeKey(context[key].key)}`)
        .join(':');
    }
  }
}

export const trackLiveEvents = createTrackerForCategory('LiveEvents');
