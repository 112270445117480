import cx from 'clsx';

import styles from './NumericMarker.module.css';

export type NumericMarkerProps = {
  number: number;
  isDark?: boolean;
  className?: string;
};

export function NumericMarker({ number, isDark, className }: NumericMarkerProps) {
  return (
    <div className={cx(styles.NumericMarker, className, { [styles['NumericMarker--dark']]: isDark })}>{number}</div>
  );
}
