import { type InstructionKind, type SegmentSemanticInstruction, UpdateRuleDescriptionInstruction } from './types';

export function makeUpdatedInstructions(
  instructionKind: InstructionKind,
  originalInstructions: SegmentSemanticInstruction[],
  updatedInstruction?: SegmentSemanticInstruction,
): SegmentSemanticInstruction[] {
  if (!updatedInstruction) {
    return originalInstructions;
  }

  let updatedInstructions = originalInstructions;
  const insertionIdx = originalInstructions.findIndex((instruction) => {
    if (['addExpiringTarget', 'updateExpiringTarget', 'removeExpiringTarget'].includes(instruction.kind)) {
      return (
        'contextKind' in updatedInstruction &&
        'contextKind' in instruction &&
        updatedInstruction.contextKind === instruction.contextKind &&
        'value' in updatedInstruction &&
        'value' in instruction &&
        updatedInstruction.value === instruction.value
      );
    }

    if (updatedInstruction.kind === 'updateRuleDescription') {
      return (instruction as UpdateRuleDescriptionInstruction).ruleId === updatedInstruction.ruleId;
    }

    return instruction.kind === instructionKind;
  });

  if ('values' in updatedInstruction && updatedInstruction.values.length === 0) {
    // remove instruction if there are no values to update
    updatedInstructions.splice(insertionIdx, 1);
  } else {
    updatedInstructions =
      insertionIdx !== -1
        ? originalInstructions.toSpliced(insertionIdx, 1, updatedInstruction)
        : originalInstructions.concat([updatedInstruction]);
  }
  return updatedInstructions;
}
