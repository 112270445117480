import { useCallback, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { funnelGroupMetricLimit, standardMetricGroupMetricLimit } from '@gonfalon/dogfood-flags';
import { intersection } from '@gonfalon/es6-utils';
import { useMetrics } from '@gonfalon/rest-api';
import { useProjectKey } from '@gonfalon/router';
import { Icon } from '@launchpad-ui/icons';
import { Button, FieldError, FormField, IconButton, TextField, Tooltip } from 'launchpad';

import { NumericMarker } from 'components/experimentation/common/components/NumericMarker/NumericMarker';
import { SortableContainer, SortableItem } from 'components/ui/sortable';
import { nilFilter } from 'utils/collectionUtils';

import { MetricGroupMetricSelect } from '../MetricGroupMetricSelect';

import { CreateMetricGroupFormData } from './CreateMetricGroupModal';

import styles from './CreateMetricGroupModal.module.css';

export function CreateMetricGroupSortableContainer({
  supportedRandomizationUnits,
}: {
  supportedRandomizationUnits?: string[];
}) {
  const {
    control,
    register,
    unregister,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateMetricGroupFormData>();

  const [activeField, setActiveField] = useState<string | undefined>(undefined);

  const kind = useWatch({ control, name: 'kind' });
  const steps = useWatch({ control, name: 'steps' });
  const selectedMetrics = steps.map((step) => step.metric);

  const { fields, append, move, remove } = useFieldArray({
    control,
    name: 'steps',
    rules: {
      maxLength: {
        value: kind === 'funnel' ? funnelGroupMetricLimit() : standardMetricGroupMetricLimit(),
        message: `Maximum number of steps is ${
          kind === 'funnel' ? funnelGroupMetricLimit() : standardMetricGroupMetricLimit()
        }`,
      },
    },
  });

  const projectKey = useProjectKey();
  const { data } = useMetrics({ projectKey });
  const metrics = data?.items;

  const getMetricRandomizationUnits = useCallback(
    (key?: string) => {
      if (key) {
        const metric = data?.items?.find((m) => m.key === key);
        return metric?.randomizationUnits ?? [];
      }
      return [];
    },
    [data],
  );

  const onChangeSortIndex = useCallback(
    (key: string, newIndex: number) => {
      const target = fields.findIndex((step) => step.id === key);

      if (target === -1) {
        return;
      }

      move(target, newIndex);
    },
    [fields, move],
  );

  // return [] to mean "support all"
  const getSupportedRandomizationUnits = useCallback(
    (stepNumber: number) => {
      const otherMetrics = steps
        .filter((_, i) => i !== stepNumber)
        .map((step) => step.metric)
        .filter(nilFilter);

      if (!otherMetrics.length) {
        return supportedRandomizationUnits;
      }

      return intersection(
        ...[
          supportedRandomizationUnits,
          ...otherMetrics.map((m) => m.randomizationUnits ?? getMetricRandomizationUnits(m.key)),
        ].filter((r) => r?.length),
      );
    },
    [getMetricRandomizationUnits, steps, supportedRandomizationUnits],
  );

  const onRemoveMetric = useCallback(
    (stepNumber: number) => {
      unregister(`steps.${stepNumber}.metric`);
      //remove from selectedMetrics
      const idx = selectedMetrics.findIndex((m) => m?.key === steps[stepNumber].metric?.key);
      selectedMetrics.splice(idx, 1);
      remove(stepNumber);
    },
    [remove, unregister, steps, selectedMetrics],
  );

  const onAddMetric = useCallback(() => {
    append({ metric: undefined, name: `Step ${fields.length + 1}` });
  }, [fields, append]);

  return (
    <>
      <SortableContainer className={styles.sortableContainer}>
        {fields.map((step, index) => {
          const { onChange, ...metricField } = register(`steps.${index}.metric`, { required: 'Metric is required' });
          return (
            <div className={styles.sortableItem} key={step.id}>
              {kind === 'funnel' ? (
                <NumericMarker number={index + 1} className={styles.numericMarker} />
              ) : (
                <span className={styles.numericMarker}>{index + 1}</span>
              )}
              <SortableItem
                key={step.id}
                itemId={step.id}
                isDraggable={step.id !== activeField}
                type="metric"
                hasHandle
                getSortIndex={() => index}
                onChangeSortIndex={onChangeSortIndex}
              >
                <div className={styles.sortableItemFields}>
                  {kind === 'funnel' && (
                    <FormField
                      name={`step-${step.id}`}
                      htmlFor={`step-${step.id}`}
                      label="Step name"
                      isRequired
                      errorMessage={errors.steps?.[index]?.name?.message}
                      className={styles.formField}
                    >
                      <TextField
                        className={styles.stepNameInput}
                        {...register(`steps.${index}.name`, {
                          required: kind === 'funnel' ? 'Step name is required' : undefined,
                        })}
                        onMouseEnter={() => setActiveField(step.id)}
                        onMouseLeave={() => setActiveField(undefined)}
                      />
                    </FormField>
                  )}
                  <MetricGroupMetricSelect
                    required
                    {...metricField}
                    isFunnel={kind === 'funnel'}
                    onChange={(metric) => {
                      setValue(`steps.${index}.metric`, metric);
                      if (Object.keys(errors).length > 0 && errors?.steps?.[index]?.metric) {
                        clearErrors(`steps.${index}.metric`);
                      }
                    }}
                    errorMessage={errors.steps?.[index]?.metric?.message}
                    className={styles.formField}
                    supportedRandomizationUnits={getSupportedRandomizationUnits(index)}
                    randomizationUnits={
                      steps[index]?.metric?.randomizationUnits ||
                      getMetricRandomizationUnits(steps?.[index]?.metric?.key)
                    }
                    filterMetrics={({ key }) => !selectedMetrics.find((m) => m && m.key === key)}
                    value={metrics?.find((metric) => metric.key === steps?.[index]?.metric?.key)}
                  />
                </div>
                <IconButton
                  aria-label={`delete metric ${index + 1}`}
                  kind="destructive"
                  icon={<Icon name="minus" size="medium" />}
                  onClick={() => onRemoveMetric(index)}
                  className={styles.deleteButton}
                  disabled={steps.length <= 2}
                />
              </SortableItem>
            </div>
          );
        })}
      </SortableContainer>

      <Tooltip>
        <Button
          type="button"
          kind="minimal"
          onClick={onAddMetric}
          className={styles.addMetricButton}
          icon={<Icon aria-label="add metric" name="add" />}
          renderIconFirst
          disabled={
            kind === 'funnel'
              ? steps.length >= funnelGroupMetricLimit()
              : steps.length >= standardMetricGroupMetricLimit()
          }
        >
          Add metric
        </Button>
        {kind === 'funnel' && steps.length >= funnelGroupMetricLimit() ? (
          <span>The limit of {funnelGroupMetricLimit()} metrics per funnel group has been reached</span>
        ) : null}
        {kind === 'standard' && steps.length >= standardMetricGroupMetricLimit() ? (
          <span>The limit of {standardMetricGroupMetricLimit()} metrics per metric group has been reached</span>
        ) : null}
      </Tooltip>
      <FieldError name="steps" errorMessage={errors.steps?.root?.message} />
    </>
  );
}
