import { RefObject, useCallback, useEffect, useState } from 'react';
import { debounce } from '@gonfalon/es6-utils';

export function useIsScrolling({ ref }: { ref: RefObject<HTMLElement> }) {
  const [isScrolling, setIsScrolling] = useState(false);

  const onScroll = useCallback(() => setIsScrolling(true), []);
  const onScrollEnd = useCallback(() => setIsScrolling(false), []);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    ref.current.addEventListener('scroll', onScroll);

    return () => ref.current?.removeEventListener('scroll', onScroll);
  }, [ref.current]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const debouncedHandler = debounce(onScrollEnd, 100);

    ref.current.addEventListener('scroll', debouncedHandler);

    return () => ref.current?.removeEventListener('scroll', debouncedHandler);
  }, [ref.current]);

  return { isScrolling };
}
