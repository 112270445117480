import { useState } from 'react';
import type { IconProps } from '@launchpad-ui/icons';
import cx from 'clsx';
import { Button } from 'launchpad';

import 'stylesheets/components/CollapsibleStyles.css';

type CollapsibleProps = {
  action: string;
  initiallyCollapsed?: boolean;
  className?: string;
  openIcon?: React.ReactElement<IconProps>;
  closedIcon?: React.ReactElement<IconProps>;
  trackEvent?(): void;
  children: React.ReactNode;
  openButtonClassName?: string;
  closedButtonClassName?: string;
  renderIconFirst?: boolean;
  softCollapse?: boolean; // a 'soft collapse' sets the content to display: none instead of removing from the DOM entirely
};

/* eslint-disable import/no-default-export */
export default function Collapsible({
  action,
  children,
  className,
  openIcon,
  closedIcon,
  initiallyCollapsed,
  renderIconFirst,
  softCollapse,
  openButtonClassName,
  closedButtonClassName,
  trackEvent,
}: CollapsibleProps) {
  const [collapsed, setCollapsed] = useState(typeof initiallyCollapsed === 'boolean' ? initiallyCollapsed : true);

  const toggle = () => {
    if (trackEvent) {
      trackEvent();
    }
    setCollapsed(!collapsed);
  };

  return (
    <div className={className}>
      <div className={cx({ 'u-mb-m': !collapsed })}>
        {closedIcon && collapsed && (
          <Button
            className={cx('collapsibleButton', openButtonClassName)}
            icon={closedIcon}
            kind="default"
            onClick={toggle}
            renderIconFirst={typeof renderIconFirst === 'boolean' ? renderIconFirst : true}
            size="small"
          >
            {action}
          </Button>
        )}
        {openIcon && !collapsed && (
          <Button
            className={cx('collapsibleButton', closedButtonClassName)}
            icon={openIcon}
            kind="default"
            onClick={toggle}
            renderIconFirst={typeof renderIconFirst === 'boolean' ? renderIconFirst : true}
            size="small"
          >
            {action}
          </Button>
        )}
        {!openIcon && !closedIcon && (
          <Button kind="link" onClick={toggle}>
            <span className="">{collapsed ? '+' : '-'}</span> {action}
          </Button>
        )}
      </div>
      {(typeof softCollapse === 'boolean' ? softCollapse : false) ? (
        <div className={cx({ 'u-d-none': collapsed })}>{children}</div>
      ) : (
        <div>{!collapsed && children}</div>
      )}
    </div>
  );
}
