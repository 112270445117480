import { useQuery } from '@tanstack/react-query';

import { getFlag } from '../api/flag';

type Props = {
  projectKey: string;
  flagKey: string | undefined;
  environmentKey: string;
  flagConfigVersion: number | undefined;
};

const FLAGS_KEY = 'flags';

/**
 * Hook fetches a single flag
 *
 * @param param.projectKey - the key of the project the flag belongs to
 * @param param.flagKey - the key of the flag
 * @param param.environmentKey - the key of the environment you want to search in
 */
export function useFlag({ projectKey, flagKey, environmentKey, flagConfigVersion }: Props) {
  return useQuery({
    queryKey: [FLAGS_KEY, flagKey, flagConfigVersion, projectKey, environmentKey],
    queryFn: async () => getFlag(projectKey, flagKey ?? '', environmentKey),
    enabled: Boolean(flagKey) && Boolean(flagConfigVersion),
  });
}
