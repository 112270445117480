// eslint-disable-next-line no-restricted-imports
import { fromJS, OrderedMap } from 'immutable';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import { AccessTokenAction } from 'actions/accessTokens';
import { FormAction } from 'actions/forms';
import { GlobalState } from 'reducers';
import { createRequestReducer } from 'reducers/createRequestReducer';
import registry from 'reducers/registry';
import { QueryParams, querySelector } from 'reducers/router';
import {
  AccessToken,
  AccessTokenFilters,
  AccessTokenForm,
  createAccessTokenFiltersFromQuery,
  createAccessTokenForm,
  filterAccessToken,
} from 'utils/accessTokenUtils';
import { createFormState, FormState } from 'utils/formUtils';
import { ImmutableMap } from 'utils/immutableUtils';

export const defaultTokenInlinePolicy = [
  {
    effect: 'deny',
    resources: [],
    actions: [],
  },
];

type initialStateMapType = ImmutableMap<{
  formState: FormState<AccessTokenForm>;
  forceCustom: boolean;
  forceInline: boolean;
}>;

const creationFormInitialState = (token: AccessTokenForm = createAccessTokenForm()) =>
  fromJS({
    formState: createFormState(token),
    forceCustom: !token.customRoleIds.isEmpty(),
    forceInline: !!token.inlineRole,
  });

const form = (state: initialStateMapType = creationFormInitialState(), action: FormAction | AccessTokenAction) => {
  if ('model' in action) {
    if (action.model && action.model !== 'accessTokenForm') {
      return state;
    }
  }

  switch (action.type) {
    case 'accessTokens/EDIT_ACCESS_TOKEN':
      return state.update('formState', (f: FormState<AccessTokenForm>) => {
        const { field, value } = action;
        const modified = state.get('formState').modified.set(field, value);
        return f.revalidate(modified, state.get('forceCustom'), state.get('forceInline')).trackField(field);
      });
    case 'accessTokens/SELECT_ACCESS_TOKEN_ROLE':
      return state.withMutations((st: initialStateMapType) => {
        const modified = st
          .getIn(['formState', 'modified'])
          .update('role', () => (action.role === 'custom' || action.role === 'inline' ? '' : action.role))
          .delete('customRoleIds')
          .update('inlineRole', (ir: null | string | string[]) =>
            action.role === 'inline' && !ir ? JSON.stringify(defaultTokenInlinePolicy, null, 2) : null,
          );

        return st
          .update('formState', (f: FormState<AccessTokenForm>) =>
            f.revalidate(modified, action.role === 'custom', action.role === 'inline').trackField('role'),
          )
          .set('forceCustom', action.role === 'custom')
          .set('forceInline', action.role === 'inline');
      });
    case 'accessTokens/SET_ACCESS_TOKEN_CUSTOM_ROLES':
      return state.update('formState', (f: FormState<AccessTokenForm>) => {
        const modified = state.get('formState').modified.update('customRoleIds', () => action.roleIds);
        return f.revalidate(modified, true).trackField('customRoleIds');
      });

    case 'accessTokens/CREATE_ACCESS_TOKEN':
    case 'accessTokens/UPDATE_ACCESS_TOKEN':
      return state.update('formState', (fs) => fs.submitting());
    case 'accessTokens/CREATE_ACCESS_TOKEN_DONE':
    case 'forms/DESTROY':
      return creationFormInitialState();
    case 'accessTokens/UPDATE_ACCESS_TOKEN_DONE':
      return creationFormInitialState(action.token.toForm());
    case 'accessTokens/UPDATE_ACCESS_TOKEN_FAILED':
      return state.update('formState', (fs) => fs.submitFailed(action.token, action.error));
    case 'accessTokens/CREATE_ACCESS_TOKEN_FAILED':
      return state.update('formState', (fs) => fs.submitFailed(action.token, action.error));
    case 'accessTokens/RESET_ACCESS_TOKEN_DONE':
      return creationFormInitialState(action.token.toForm());
    case 'accessTokens/RESET_ACCESS_TOKEN_FAILED':
      return state.update('formState', (fs) => fs.submitFailed(action.token.toForm(), action.error));
    case 'forms/INITIALIZE':
      return creationFormInitialState(action.initialState as AccessTokenForm);
    case 'forms/BLUR':
      return state.update('formState', (fs) => fs.handleBlur(action.field, fs.modified));
    default:
      return state;
  }
};

const entities = (state = OrderedMap(), action: AccessTokenAction) => {
  switch (action.type) {
    case 'accessTokens/REQUEST_ACCESS_TOKENS_DONE':
      return action.response.getIn(['entities', 'accessTokens']);
    case 'accessTokens/CREATE_ACCESS_TOKEN_DONE':
      return state.set(action.token._id, action.token);
    case 'accessTokens/UPDATE_ACCESS_TOKEN_DONE':
      return state.set(action.token._id, action.token);
    case 'accessTokens/RESET_ACCESS_TOKEN_DONE':
      return state.set(action.token._id, action.token);
    case 'accessTokens/DELETE_ACCESS_TOKEN_DONE':
      return state.delete(action.token._id);
    default:
      return state;
  }
};

type lastCreatedIdType = string | null | undefined;

const lastCreatedId = (state: lastCreatedIdType = null, action: AccessTokenAction) => {
  switch (action.type) {
    case 'accessTokens/CREATE_ACCESS_TOKEN_DONE':
    case 'accessTokens/RESET_ACCESS_TOKEN_DONE':
      return action.token._id;
    case 'accessTokens/REQUEST_ACCESS_TOKENS_DONE':
    case 'accessTokens/UPDATE_ACCESS_TOKEN_DONE':
    case 'accessTokens/DELETE_ACCESS_TOKEN_DONE':
      return null;
    default:
      return state;
  }
};

const request = createRequestReducer([
  'accessTokens/REQUEST_ACCESS_TOKENS',
  'accessTokens/REQUEST_ACCESS_TOKENS_DONE',
  'accessTokens/REQUEST_ACCESS_TOKENS_FAILED',
]);

export const accessTokens = combineReducers({
  entities,
  form,
  request,
  lastCreatedId,
});

export const accessTokensSelector = (state: GlobalState) => state.accessTokens;
export const accessTokenFormSelector = (state: GlobalState) => accessTokensSelector(state).form;
export const accessTokensListSelector = (state: GlobalState) => accessTokensSelector(state).entities;
export const accessTokensListRequestSelector = (state: GlobalState) => accessTokensSelector(state).request;
export const accessTokenFiltersSelector = createSelector<GlobalState, QueryParams, AccessTokenFilters>(
  querySelector,
  (query) => createAccessTokenFiltersFromQuery(query),
);
export const lastAccessTokenIdCreatedSelector = (state: GlobalState) => accessTokensSelector(state).lastCreatedId;
export const filteredAccessTokenListSelector = createSelector(
  accessTokensListSelector,
  accessTokenFiltersSelector,
  (accessTokenEntities, filters) =>
    accessTokenEntities
      .filter((token: AccessToken) =>
        filters.createdBy ? token._member && token._member._id === filters.createdBy : true,
      )
      .filter((token: AccessToken) => filterAccessToken(token, filters.accessTokenQuery, filters.accessTokenType)),
);

registry.addReducers({
  accessTokens,
});
