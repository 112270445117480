import { type Key, useState, useTransition } from 'react';
import { internalEnvironmentList } from '@gonfalon/rest-api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useFavoriteEnvironmentsForProject } from '../EnvironmentController/useFavoriteEnvironmentsForProject';
import { SelectedEnvironment } from '../types';

const minItemsForSection = 3;

type environmentItem = {
  id: string;
  name: string;
  color: string;
};
type items = Array<{
  id: string;
  name: string;
  children: environmentItem[];
}>;

export function useSelectEnvironments({
  projectKey,
  excludedIds,
  onSelect,
}: {
  projectKey: string;
  excludedIds: Set<string>;
  onSelect: (environment: SelectedEnvironment) => void;
}): {
  isPending: boolean;
  updateTextFilter: (value: string) => void;
  items: items;
  asSections: boolean;
  unsectionedItems: environmentItem[];
  handleAction: (action: Key) => void;
} {
  const [isPending, startTransition] = useTransition();
  const [filterText, setFilterText] = useState('');
  const isFiltered = filterText !== undefined && filterText !== '';
  const favorites = useFavoriteEnvironmentsForProject(projectKey);
  const environments = useSuspenseQuery(
    internalEnvironmentList({
      projectKey,
      query: {
        q: filterText,
      },
    }),
  );

  const favoriteIds = favorites.ids;

  const favoriteItems: environmentItem[] = [];
  for (const env of favorites.environments) {
    if (excludedIds.has(env._id)) {
      continue;
    }

    favoriteItems.push({ id: env._id, name: env.name, color: env.color });
  }

  const environmentItems: environmentItem[] = [];
  for (const env of environments.data.items) {
    if (excludedIds.has(env._id)) {
      continue;
    }

    // Skip if already in favorites, unless we're filtering to avoid hiding items
    if (!isFiltered && favoriteIds.has(env._id)) {
      continue;
    }

    environmentItems.push({ id: env._id, name: env.name, color: env.color });
  }

  const items = [
    { id: 'favorites', name: 'Favorites', children: favoriteItems },
    { id: 'results', name: 'All environments', children: environmentItems },
  ];

  const asSections =
    !isFiltered &&
    environments.data.totalCount > minItemsForSection &&
    favoriteItems.length > 0 &&
    environmentItems.length > 0;
  const unsectionedItems = !isFiltered ? items.flatMap((section) => section.children) : environmentItems;

  const handleAction = (action: Key) => {
    const id = String(action);

    let item: SelectedEnvironment | undefined;

    if (favoriteIds.has(id)) {
      item = favorites.environments.find((it) => it._id === id);
    } else {
      item = environments.data.items.find((it) => it._id === id);
    }

    // Fail silently
    if (item === undefined) {
      return;
    }

    onSelect(item);
  };

  const updateTextFilter = (value: string) => {
    startTransition(() => {
      setFilterText(value);
    });
  };

  return {
    isPending,
    updateTextFilter,
    items,
    asSections,
    unsectionedItems,
    handleAction,
  };
}
