import { useLatestMeasuredRollout } from './useLatestMeasuredRollout';

export function useActiveMeasuredRollout({ enabled = true }: { enabled?: boolean } = {}) {
  const result = useLatestMeasuredRollout({ enabled });

  if (result.data?.state.status !== 'archived') {
    return result;
  }

  return { ...result, data: undefined };
}
