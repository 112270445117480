import { FormField, RequiredAsterisk } from 'launchpad';

import HelpTooltip from 'components/HelpTooltip';

// wraps launchpad's FormField to include a tooltip in the label
export function FormFieldWithTooltip({
  tooltip,
  label,
  isRequired,
  ...rest
}: { tooltip: string | JSX.Element } & React.ComponentProps<typeof FormField>) {
  return (
    <FormField
      label={
        tooltip
          ? ((
              <>
                {label}
                {isRequired && <RequiredAsterisk />}
                <HelpTooltip placement="top-start">{tooltip}</HelpTooltip>
              </> // Even though it's passed as a child, lp types its label as a string
            ) as unknown as string)
          : label
      }
      // we want the required asterisk to appear to the left of the tooltip icon,
      // so it's included in the label above
      isRequired={!tooltip && isRequired}
      {...rest}
    />
  );
}
