import { useEffect } from 'react';

import { fetchPolicyActions as fetchPolicyActionsAction } from 'actions/policies';
import { AuditLogActionFilter } from 'components/auditLog/AuditLogActionFilter';
import { useDispatch } from 'hooks/useDispatch';
import { useSelector } from 'hooks/useSelector';
import { GlobalState } from 'reducers';
import { policyActionsSelector } from 'reducers/policies';
import { AuditLogViewKind, Query } from 'utils/auditLogUtils';
import { PolicyStatement } from 'utils/policyUtils';
import { ready } from 'utils/reduxUtils';

const useRedux = () => {
  const policyActions = useSelector((state: GlobalState) => policyActionsSelector(state));
  const dispatch = useDispatch();
  const fetchPolicyActions = () => dispatch(fetchPolicyActionsAction());

  return {
    policyActions: policyActions.get('entities'),
    isPolicyActionsReady: ready(policyActions),
    fetchPolicyActions,
  };
};

export function AuditLogActionFilterContainer({
  selectedFilter,
  onUpdateFilters,
  query,
}: {
  selectedFilter: AuditLogViewKind;
  onUpdateFilters(policy: PolicyStatement): void;
  query?: Query;
}) {
  const { policyActions, isPolicyActionsReady, fetchPolicyActions } = useRedux();

  useEffect(() => {
    if (!isPolicyActionsReady) {
      fetchPolicyActions();
    }
  }, [isPolicyActionsReady]);

  return (
    <AuditLogActionFilter
      policyActions={policyActions}
      selectedFilter={selectedFilter}
      isPolicyActionsReady={isPolicyActionsReady}
      query={query}
      onUpdateFilters={onUpdateFilters}
    />
  );
}
