// transformed by jscodeshift
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map } from 'immutable';
import { normalize, schema } from 'normalizr';

import { FetchSegmentsResponse } from 'actions/segments';
import http, { jsonToImmutable, jsonToImmutableError } from 'utils/httpUtils';
import { createJsonPatch } from 'utils/patchUtils';
import {
  BigSegmentIndividualTargetUpdates,
  BigSegmentUploadMode,
  createBigSegmentTarget,
  createSegment as createSegmentRecord,
  Segment,
} from 'utils/segmentUtils';

const segmentEntity = new schema.Entity(
  'segments',
  {},
  {
    idAttribute: 'key',
  },
);

export const normalizeGetSegmentsResponse = (jsonRes: unknown): FetchSegmentsResponse => {
  const data = normalize(jsonRes, { items: [segmentEntity] });
  if (data.result.items.length === 0) {
    return fromJS(data).setIn(['entities', 'segments'], List());
  }
  return fromJS(data).updateIn(['entities', 'segments'], (ss: Map<string, Segment>) =>
    ss
      .map(createSegmentRecord)
      // the 'normalizr' package orders items in order, but we
      // want to keep the order returned from the API. This is important for sorting/filtering.
      .sort((a, b) => data.result.items.indexOf(a.key) - data.result.items.indexOf(b.key)),
  );
};

export const getSegments = async (projKey: string, envKey: string, queryString?: string, signal?: AbortSignal) => {
  let url = `/api/v2/segments/${projKey}/${envKey}`;
  if (queryString) {
    url += `?${queryString}&expand=flags`;
  } else {
    url += '?expand=flags';
  }

  return http
    .get(url, { signal })
    .then(async (res) => res.json().then((responseJson) => normalizeGetSegmentsResponse(responseJson)))
    .catch(jsonToImmutableError);
};

export const createSegment = async (projKey: string, envKey: string, segment: Segment) =>
  http
    .post(`/api/v2/segments/${projKey}/${envKey}`, {
      body: segment,
    })
    .then(jsonToImmutable)
    .then(createSegmentRecord)
    .catch(jsonToImmutableError);

export const deleteSegment = async (segment: Segment) => http.delete(segment.selfLink()).catch(jsonToImmutableError);

export const getSegmentByKey = async (projKey: string, envKey: string, key: string) =>
  http
    .get(`/api/v2/segments/${projKey}/${envKey}/${key}`)
    .then(jsonToImmutable)
    .then(createSegmentRecord)
    .catch(jsonToImmutableError);

export const updateSegment = async (oldSegment: Segment, newSegment: Segment, comment = '') => {
  const patch = createJsonPatch(oldSegment, newSegment, { comment, shouldTestVersion: true });
  return http
    .patch(oldSegment.selfLink(), {
      body: patch,
    })
    .then(jsonToImmutable)
    .then(createSegmentRecord)
    .catch(jsonToImmutableError);
};

export const uploadCsvToSegment = async (
  projKey: string,
  envKey: string,
  key: string,
  file: File,
  uploadMode: BigSegmentUploadMode,
) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('mode', uploadMode);

  return http
    .post(`/api/v2/segments/${projKey}/${envKey}/${key}/imports`, {
      body: formData,
      headers: { 'LD-API-Version': 'beta' },
    })
    .catch(jsonToImmutableError);
};

export const getBigSegmentTarget = async (segment: Segment, userKey: string) =>
  http
    .get(`${segment.selfLink()}/users/${encodeURIComponent(userKey)}`, { beta: true })
    .then(jsonToImmutable)
    .then(createBigSegmentTarget)
    .catch(jsonToImmutableError);

export const getBigSegmentFileMetaData = async (
  projKey: string,
  envKey: string,
  segmentKey: string,
  exportKey: string,
) =>
  http
    .get(`/api/v2/segments/${projKey}/${envKey}/${segmentKey}/exports/${exportKey}`, {
      headers: { 'LD-API-Version': 'beta' },
    })
    .then(jsonToImmutable)
    .catch(jsonToImmutableError);

export const postSegmentExport = async (projKey: string, envKey: string, segmentKey: string) =>
  http
    .post(`/api/v2/segments/${projKey}/${envKey}/${segmentKey}/exports`, {
      headers: { 'LD-API-Version': 'beta' },
    })
    .catch(jsonToImmutableError);

export const updateBigSegmentIndividualTargets = async ({
  projKey,
  envKey,
  segmentKey,
  updates,
}: {
  projKey: string;
  envKey: string;
  segmentKey: string;
  updates: BigSegmentIndividualTargetUpdates;
}) =>
  http
    .post(`/api/v2/segments/${projKey}/${envKey}/${segmentKey}/users`, {
      body: updates.toRep(),
    })
    .catch(jsonToImmutableError);
