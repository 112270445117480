// eslint-disable-next-line no-restricted-imports
import { fromJS, List } from 'immutable';

import { createWeightedVariation, Rollout as ImmutableRollout, Variation as ImmutableVariation } from 'utils/flagUtils';

import { Flag, VariationWeights } from '../types';

/*
  Tools for converting experimentation/common/types into their Immutable.js counterparts
 */

export const createImmutableVariations = (variations: Flag['variations']): List<ImmutableVariation> =>
  List.of(...variations.map((v, i) => new ImmutableVariation(fromJS({ ...v, _key: i }))));

export const createRolloutFromVariations = (
  variations: Flag['variations'],
  variationWeights: VariationWeights,
): ImmutableRollout => {
  const rollout = new ImmutableRollout({
    variations: List.of(
      ...variations.map((v, i) =>
        createWeightedVariation({
          variation: i,
          weight: variationWeights[v._id],
        }),
      ),
    ),
  });
  return rollout;
};
