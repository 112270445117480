import { ChangeEvent } from 'react';
import { Checkbox, Tooltip } from 'launchpad';

import Restrict from 'components/Restrict';
import { Role } from 'utils/roleUtils';

type CustomRoleCheckboxProps = {
  isChecked: boolean;
  isRestricted: boolean;
  restrictedTooltip?: string;
  role: Role;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
};
const CustomRoleCheckbox = ({
  isChecked,
  isRestricted,
  onChange,
  restrictedTooltip,
  role,
}: CustomRoleCheckboxProps) => (
  <Restrict
    isRestricted={isRestricted}
    tooltip={restrictedTooltip}
    tooltipOptions={{ rootElementStyle: { display: 'block' } }}
    willDisable
  >
    <Checkbox value={role._id} checked={isChecked} onChange={onChange} labelClassName="u-c-pointer u-pv-xs">
      {role.description?.length && !isRestricted ? (
        <Tooltip placement="bottom" content={role.description}>
          <span>{role.name}</span>
        </Tooltip>
      ) : (
        <span>{role.name}</span>
      )}
    </Checkbox>
  </Restrict>
);

/* eslint-disable import/no-default-export */
export default CustomRoleCheckbox;
