import { useProject } from '@gonfalon/rest-api';
import { useProjectKey } from '@gonfalon/router';

import { useCheckAccess } from 'utils/accessUtils';

import { LegacyAddReleasePipelineFlow } from './LegacyAddReleasePipelineFlow';
import { LegacyCreateReleasePipelineFlow } from './LegacyCreateReleasePipelineFlow';

export type LegacyCreateReleaseButtonProps = {
  flagKey: string;
  flagName?: string;
  buttonLabel?: string;
  setShowAddReleaseButton: (shouldShow: boolean) => void;
  setEnableReleaseQuery: (shouldEnable: boolean) => void;
  onAddReleaseStart?: () => void;
  onAddReleaseSuccess?: () => void;
  releasePipelineKey?: string;
};

export function LegacyCreateReleaseButton({
  flagKey,
  flagName,
  buttonLabel,
  setShowAddReleaseButton,
  setEnableReleaseQuery,
  onAddReleaseStart,
  onAddReleaseSuccess,
  releasePipelineKey,
}: LegacyCreateReleaseButtonProps) {
  const checkAccess = useCheckAccess();
  const canCreateReleasePipelines = checkAccess('createReleasePipeline');
  const canAddReleasePipeline = checkAccess('addReleasePipeline');

  const projectKey = useProjectKey();
  const { isSuccess, data: project } = useProject({ projectKey });

  const releasePipelinesAvailable = isSuccess && project.defaultReleasePipelineKey;

  if (!releasePipelinesAvailable && canCreateReleasePipelines.isAllowed) {
    return <LegacyCreateReleasePipelineFlow />;
  }

  if (releasePipelinesAvailable && canAddReleasePipeline.isAllowed) {
    return (
      <LegacyAddReleasePipelineFlow
        flagKey={flagKey}
        flagName={flagName}
        buttonLabel={buttonLabel}
        setShowAddReleaseButton={setShowAddReleaseButton}
        setEnableReleaseQuery={setEnableReleaseQuery}
        onAddReleaseStart={onAddReleaseStart}
        onAddReleaseSuccess={onAddReleaseSuccess}
        releasePipelineKey={releasePipelineKey}
      />
    );
  }

  return null;
}
