import { useReleasePipeline, useReleasePipelines } from '@gonfalon/rest-api';
import { useProjectKey } from '@gonfalon/router';
import {
  ComboBox,
  Group,
  IconButton,
  Input,
  Label,
  ListBox,
  ListBoxItem,
  Popover,
  Text,
} from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';

type Item = { id: string; label: string; isProjectDefault: boolean };

export const SELECT_RELEASE_PIPELINE_DEFAULT_LABEL = 'Select release pipeline';

export function SelectReleasePipeline({
  label = SELECT_RELEASE_PIPELINE_DEFAULT_LABEL,
  className,
  onChange,
  releasePipelineKey = '',
}: {
  label?: string;
  releasePipelineKey?: string;
  className?: string;
  onChange?: (releasePipelineKey: string) => void;
}) {
  const projectKey = useProjectKey();
  const releasePipelineQuery = useReleasePipeline(
    { projectKey, releasePipelineKey },
    { enabled: !!releasePipelineKey },
  );
  const releasePipelinesQuery = useReleasePipelines({ projectKey });
  const items: Item[] = [];
  for (const releasePipeline of releasePipelinesQuery.data?.items ?? []) {
    items.push({
      id: releasePipeline.key,
      label: releasePipeline.name,
      isProjectDefault: !!releasePipeline.isProjectDefault,
    });
  }

  const projectDefaultIsSelected = releasePipelinesQuery.data?.items.find(
    (rp) => rp.key === releasePipelineKey,
  )?.isProjectDefault;

  return (
    <ComboBox
      className={className}
      menuTrigger="focus"
      items={items}
      selectedKey={releasePipelineKey}
      defaultSelectedKey={releasePipelineQuery.data ? releasePipelineQuery.data.key : undefined}
      onSelectionChange={(key) => onChange?.(key !== null ? String(key) : '')}
    >
      <Label>{label}</Label>
      <Group>
        {projectDefaultIsSelected ? <Icon name="arrow-connect-star" size="small" /> : null}
        <Input />
        <IconButton icon="chevron-down" size="small" variant="minimal" aria-label="Show suggestions" />
      </Group>
      <Popover>
        <ListBox<Item>>
          {(item) => (
            <ListBoxItem id={item.id} textValue={item.label}>
              <Text slot="label">
                {item.isProjectDefault && <Icon name="arrow-connect-star" size="small" />}
                {item.label}
              </Text>
            </ListBoxItem>
          )}
        </ListBox>
      </Popover>
    </ComboBox>
  );
}
