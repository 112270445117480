import { EnvironmentMarker } from '@gonfalon/environments';
import { toFlags } from '@gonfalon/navigator';
import { useSuspenseEnvironment } from '@gonfalon/rest-api';

import { projectFromIdentifier } from '../projectFromIdentifier';
import { environmentRegex } from '../regex';

import { EntrySummaryClickableObject } from './EntrySummaryClickableObject';

export enum resourceKinds {
  PROJECT = 'project',
  ENVIRONMENT = 'environment',
  FLAG = 'flag',
  EXPERIMENT = 'experiment',
}

export enum mapKind {
  proj = resourceKinds.PROJECT,
  env = resourceKinds.ENVIRONMENT,
  flag = resourceKinds.FLAG,
  experiment = resourceKinds.EXPERIMENT,
}

export function kindFromIdentifier(identifier: string) {
  const nodes = identifier.split(':');
  const [kind] = nodes[nodes.length - 1].split('/');
  return mapKind[kind as keyof typeof mapKind];
}

export function environmentFromIdentifier(identifier: string) {
  const match = environmentRegex.exec(identifier);
  if (match) {
    return match[match.length - 1];
  }
}

export const EnvironmentResourceV2 = ({ resourceName, id }: { resourceName?: string; id: string }) => {
  const projectKey = projectFromIdentifier(id) as string;
  const environmentKey = environmentFromIdentifier(id) as string;
  const environment = useSuspenseEnvironment({ projectKey, environmentKey, shouldIgnoreErrors: true });
  const env = environment && environment.data;

  return (
    <EntrySummaryClickableObject to={env && toFlags({ projectKey, environmentKey: env.key })} title={env && env.name}>
      {env && <EnvironmentMarker color={env.color} />}
      {env && ' '}
      {resourceName || env.name}
    </EntrySummaryClickableObject>
  );
};
