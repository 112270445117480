import { createContext, useContext } from 'react';
import nullthrows from 'nullthrows';

export interface MigrationContextType {
  isMigrationFlag: boolean;
  stageCount?: number;
  contextKind?: string;
}

export const MigrationContext = createContext<MigrationContextType | undefined>(undefined);

export function useMigrationContext({ enable }: { enable?: boolean } = { enable: true }) {
  const context = useContext(MigrationContext);

  if (!enable) {
    return;
  }

  nullthrows(context, 'useMigrationContext() may only be used inside a MigrationContext');
  return context;
}
