import { useProjectContext } from '@gonfalon/context';
import { ShortcutsModal, trackShortcutViewed, useShortcuts } from '@gonfalon/shortcuts';
import { DialogTrigger, IconButton, Tooltip, TooltipTrigger } from '@launchpad-ui/components';

export function ShortcutsTrigger() {
  const { context } = useProjectContext();
  const { routeSupportsShortcuts } = useShortcuts({ projectKey: context.projectKey });

  if (!routeSupportsShortcuts) {
    return null;
  }

  return (
    <DialogTrigger>
      <TooltipTrigger>
        <IconButton
          onPress={() => trackShortcutViewed()}
          size="medium"
          variant="minimal"
          aria-label="Save shortcut"
          icon="bolt"
        />
        <Tooltip>Save shortcut</Tooltip>
      </TooltipTrigger>
      <ShortcutsModal shortcutType={routeSupportsShortcuts} />
    </DialogTrigger>
  );
}
