import { isReleaseGuardianStatusOnFlagDashboardEnabled } from '@gonfalon/dogfood-flags';
import { serializeUIFlagListViewForAPI, UIFlagListView } from '@gonfalon/flag-filters';
import { type GetFeatureFlagQueryParams, type GetFeatureFlagsQueryParams } from '@gonfalon/openapi';
import {
  fetchFlag,
  fetchFlags,
  fetchFlagStatusForAllEnvironments,
  fetchFlagStatusForEnvironments,
  updateFlag as updateFlag_,
  updateFlagSemantically as updateFlagSemantically_,
} from '@gonfalon/rest-api';
// eslint-disable-next-line no-restricted-imports
import { fromJS, Map, OrderedMap } from 'immutable';
import { normalize, schema } from 'normalizr';
import qs from 'qs';

import { UpdateFlagOptions } from 'actions/flags';
import { ExperimentsResultQuery } from 'components/FlagExperiments/types';
import { FlagAndEnvKeys } from 'reducers/flags';
import { convertMapToOrderedMap } from 'utils/collectionUtils';
import { Environment } from 'utils/environmentUtils';
import { FlagFilters } from 'utils/flagFilterUtils';
import { createFlagJsonPatch } from 'utils/flagPatchUtils';
import { createFlagStatus, FlagStatus } from 'utils/flagStatusUtils';
import {
  createDependentFlag,
  createDependentFlagByEnv,
  createExperimentV1Results,
  createFlag,
  createFlagConfiguration,
  ExperimentResults,
  Flag,
  FlagConfiguration,
  groupDependentFlags,
  groupDependentFlagsByEnv,
} from 'utils/flagUtils';
import http, { jsonToImmutable, jsonToImmutableError, middleware, restApitoImmutableError } from 'utils/httpUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { SemanticInstruction } from 'utils/instructions/shared/types';
import { VariationSemanticInstruction } from 'utils/instructions/variations/types';
import { Link } from 'utils/linkUtils';
import Logger from 'utils/logUtils';

const logger = Logger.get('FlagApi');
const flags = new schema.Entity('flags', {}, { idAttribute: 'key' });

// TODO: flag for v2
const statusId = (s: { _links: { parent: { href: string } } }) => s._links.parent.href;

const statuses = new schema.Entity('statuses', {}, { idAttribute: statusId });

type FlagFilterQueryObject = {
  summary?: number;
  archived?: boolean;
  compare?: boolean;
  expand?: 'codereferences';
  [key: string]: number | boolean | string | undefined;
};

type FlagFilterToQueryStringType = {
  [flagFilter: string]: { key: string; valueMap(value?: boolean): number | boolean | string | undefined };
};

const flagFilterToQueryString: FlagFilterToQueryStringType = {
  fullListing: {
    key: 'summary',
    valueMap: (v: boolean) => (v ? 0 : 1),
  },
  showArchived: {
    key: 'archived',
    valueMap: (v: boolean) => (v ? true : undefined),
  },
  compare: {
    key: 'compare',
    valueMap: (v: boolean) => (v ? 1 : undefined),
  },
  withCodeRefs: {
    key: 'expand',
    valueMap: (v: boolean) => (v ? 'codeReferences' : undefined),
  },
};

export const stringifyFetchFlagsOptions = (options: FetchFlagsOptions) => {
  const qsObject: FlagFilterQueryObject = {};

  Object.keys(options).forEach((o: string) => {
    const mapping = flagFilterToQueryString[o];
    if (mapping) {
      const { key, valueMap } = mapping;
      qsObject[key] = valueMap(options[o]);
    }
  });
  return qs.stringify(qsObject);
};

export const stringifyEnvKeys = (envKeys: string[]) => {
  const sanitizedEnvKeys = envKeys.filter((k) => !!k);
  return qs.stringify({ env: sanitizedEnvKeys }, { indices: false });
};

export const createGetFlagsQueryString = (
  environmentKeys?: string[],
  options: FetchFlagsOptions = {},
  filters?: FlagFilters,
) => {
  if (!!environmentKeys?.length) {
    const envKeysQuery = stringifyEnvKeys(environmentKeys);
    const optionsQuery = stringifyFetchFlagsOptions(options);
    const filtersQuery = filters?.toBackendQueryString();

    const result = [envKeysQuery, optionsQuery, filtersQuery].filter((s) => !!s).join('&');
    return result ? `?${result}` : result;
  }

  return '';
};

export type FlagsResponse = ImmutableMap<{
  entities: ImmutableMap<{
    flags: OrderedMap<string, Flag>;
  }>;
  result: ImmutableMap<{
    _links: ImmutableMap<{ self: Link }>;
    items: string[];
  }>;
}>;

export type FetchFlagsRequestOptions = {
  controller?: AbortController;
  signal?: AbortSignal;
};

export type FetchFlagsOptions = {
  [option: string]: boolean | undefined;
  fullListing?: boolean;
  showArchived?: boolean;
  refetch?: boolean;
  compare?: boolean;
  withCodeRefs?: boolean;
  expandReleaseGuardianStatus?: boolean;
};

async function getFlags(
  projectKey: string,
  environmentKeys?: string[],
  filters?: FlagFilters,
  options: FetchFlagsOptions = {},
): Promise<FlagsResponse> {
  const expandReleaseGuardianStatuses = isReleaseGuardianStatusOnFlagDashboardEnabled();

  const coderefs = filters?.codeReferences?.toJS();
  const ui: UIFlagListView = {
    summary: !options.fullListing,
    archived: options.showArchived || undefined,
    compare: options.compare || undefined,
    limit: filters?.limit,
    offset: filters?.offset,
    sort: filters?.sort as GetFeatureFlagsQueryParams['sort'],
    filter: {
      tags: filters?.tags.toArray(),
      contextKindsEvaluated: filters?.contextKindsEvaluated?.toArray(),
      contextKindTargeted: filters?.contextKindTargeted,
      created: filters?.creationDate,
      evaluated: filters?.evaluated,
      excludeSettings: filters?.settings.toArray(),
      filterEnv: filters?.filterEnv,
      followerId: filters?.followerId,
      hasDataExport: filters?.hasDataExport,
      hasExperiment: filters?.hasExperiment,
      maintainerId: filters?.maintainerId,
      maintainerTeamKey: filters?.maintainerTeamKey,
      query: filters?.q,
      type: filters?.type,
      segmentTargeted: filters?.segmentTargeted,
      applicationEvaluated: filters?.applicationEvaluated,
      state: filters?.state,
      status: filters?.status as NonNullable<GetFeatureFlagsQueryParams['filter']>['status'],
      sdkAvailability: filters?.sdkAvailability as NonNullable<GetFeatureFlagsQueryParams['filter']>['sdkAvailability'],
      targeting: filters?.targeting as NonNullable<GetFeatureFlagsQueryParams['filter']>['targeting'],
    },
  };

  if (coderefs !== undefined) {
    ui.filter = ui.filter ?? {};
    ui.filter.codeReferences = 'min' in coderefs && coderefs.min !== undefined ? coderefs.min === 1 : false;
  }

  const api = serializeUIFlagListViewForAPI(ui);
  api.env = environmentKeys;
  api.expand = expandReleaseGuardianStatuses ? ['measuredRolloutStatus'] : undefined;

  return fetchFlags({
    projectKey,
    params: api,
  })
    .then((res) => {
      const data = normalize(res, { items: [flags] });
      return fromJS(data).withMutations((map: Map<string, Flag>) => {
        map.updateIn(['entities', 'flags'], (f: Map<string, Flag>) => convertMapToOrderedMap(f, data.result.items));
        map.updateIn(['entities', 'flags'], (flgs: Map<string, Flag>) => (flgs ? flgs.map(createFlag) : OrderedMap()));
      });
    })
    .catch(restApitoImmutableError);
}

export type FetchFlagByKeyOptions = {
  expandEvaluation?: boolean;
  expandMigrationSettings?: boolean;
};

async function getFlagByKey(
  projectKey: string,
  flagKey: string,
  environmentKeys: string[],
  options?: FetchFlagByKeyOptions,
): Promise<Flag> {
  const expansions: GetFeatureFlagQueryParams['expand'] = [];

  if (options?.expandEvaluation) {
    expansions.push('evaluation');
  }

  if (options?.expandMigrationSettings) {
    expansions.push('migrationSettings');
  }

  return fetchFlag({
    projectKey,
    flagKey,
    params: {
      env: environmentKeys,
      expand: expansions,
    },
  })
    .then((data) => fromJS(data))
    .then(createFlag)
    .catch(restApitoImmutableError);
}

async function getDependentFlagsByEnv(projectKey: string, flagKey: string, envKey: string): Promise<FlagAndEnvKeys> {
  const url = `/api/v2/flags/${projectKey}/${envKey}/${flagKey}/dependent-flags`;
  return http
    .get(url, {
      headers: { 'LD-API-Version': 'beta' },
    })
    .then(jsonToImmutable)
    .then((data) => createDependentFlagByEnv(data))
    .then((data) => groupDependentFlagsByEnv(data, envKey))
    .catch(jsonToImmutableError);
}

async function getDependentFlags(projectKey: string, flagKey: string): Promise<FlagAndEnvKeys> {
  const url = `/api/v2/flags/${projectKey}/${flagKey}/dependent-flags`;
  return http
    .get(url, {
      headers: { 'LD-API-Version': 'beta' },
    })
    .then(jsonToImmutable)
    .then((data) => createDependentFlag(data))
    .then((data) => groupDependentFlags(data))
    .catch(jsonToImmutableError);
}

async function getAllFlagStatusesForEnvironment(
  projectKey: string,
  environmentKey: string,
): Promise<Map<string, FlagStatus>> {
  const url = `/api/v2/flag-statuses/${projectKey}/${environmentKey}`;
  return http
    .get(url)
    .then(async (res) =>
      res.json().then((jsn) => {
        const data = normalize(jsn, { items: [statuses] });
        return fromJS(data).updateIn(['entities', 'statuses'], (ss: Map<string, FlagStatus>) =>
          ss ? ss.map(createFlagStatus) : Map(),
        );
      }),
    )
    .catch(jsonToImmutableError);
}

async function getFlagStatusesByEnvKeys(
  projKey: string,
  flagKey: string,
  environmentKeys: string[],
): Promise<Map<string, FlagStatus>> {
  if (environmentKeys.length === 0) {
    return fetchFlagStatusForAllEnvironments({ projectKey: projKey, flagKey })
      .then((data) => fromJS(data))
      .then((entities) => entities.get('environments').map(createFlagStatus))
      .catch(restApitoImmutableError);
  }

  return fetchFlagStatusForEnvironments({ projectKey: projKey, flagKey, environmentKeys })
    .then((data) => fromJS(data))
    .then((entities) => entities.map(createFlagStatus))
    .catch(restApitoImmutableError);
}

async function queryFlagStatusesByEnvKeysAndFlagKeys(
  projKey: string,
  environmentKeys: string[],
  flagKeys: string[],
): Promise<Map<string, FlagStatus>> {
  return http
    .post(`/api/v2/projects/${projKey}/flag-statuses/queries`, {
      headers: {
        'Ld-Api-Version': 'beta',
        'Content-Type': 'application/json',
      },
      body: {
        environmentKeys,
        flagKeys,
      },
    })
    .then(jsonToImmutable)
    .then((entities) =>
      entities
        .get('items')
        .map((item: Map<string, Map<string, Flag>>) =>
          item.get('environments')?.map((env) => createFlagStatus({ ...env, key: item.get('key') })),
        ),
    )
    .catch(jsonToImmutableError);
}

async function postFlag(feature: Flag, projectKey: string, options: { clonedFlagKey?: string } = {}): Promise<Flag> {
  const rep = feature.toGlobalRep();
  const versionUrl = `/api/v2/flags/${projectKey}`;
  const url = options.clonedFlagKey ? `${versionUrl}?clone=${options.clonedFlagKey}` : versionUrl;

  return http.post(url, { body: rep }).then(jsonToImmutable).then(createFlag).catch(jsonToImmutableError);
}

async function updateFlag(
  projectKey: string,
  oldFlag: Flag,
  newFlag: Flag,
  options: UpdateFlagOptions = {},
): Promise<Flag> {
  const { comment = '', patchOperations } = options;
  const patch =
    patchOperations ||
    createFlagJsonPatch(oldFlag, newFlag, comment, { removeEmptyFields: options?.removeEmptyFields });

  return updateFlag_({ projectKey, flagKey: oldFlag.key, comment, patch: Array.isArray(patch) ? patch : patch.patch })
    .then((data) => fromJS(data))
    .then(createFlag)
    .catch(restApitoImmutableError);
}

async function deleteFlag(flag: Flag): Promise<void> {
  return http.delete(flag.selfLink()).catch(jsonToImmutableError);
}

async function getGoalResults(flag: Flag, goalId: string): Promise<ExperimentResults> {
  return http
    .get(`/api/features/${flag.key}/goals/${goalId}/results`)
    .then(jsonToImmutable)
    .then(createExperimentV1Results)
    .catch(jsonToImmutableError);
}

async function getExperimentSummariesResults(
  flagkey: string,
  projectKey: string,
  environmentKey: string,
  metricKey: string,
  filters: ExperimentsResultQuery,
) {
  return http
    .get(`/api/v2/flags/${projectKey}/${flagkey}/experiments/${environmentKey}/${metricKey}?${qs.stringify(filters)}`, {
      beta: true,
    })
    .then(middleware.json)
    .catch(middleware.jsonError);
}

async function getExperimentSeriesResults(
  flagkey: string,
  projectKey: string,
  environmentKey: string,
  metricKey: string,
  filters: ExperimentsResultQuery,
): Promise<ExperimentResults> {
  return http
    .get(`/api/v2/flags/${projectKey}/${flagkey}/experiments/${environmentKey}/${metricKey}?${qs.stringify(filters)}`, {
      beta: true,
    })
    .then(middleware.json)
    .catch(middleware.jsonError);
}

async function postFlagDebug(flag: Flag, environment: Environment, timeInSeconds: number): Promise<FlagConfiguration> {
  return http
    .post(`/internal/account/environments/${environment._id}/flags/${flag.key}/debug`, {
      body: { debugEventsSeconds: timeInSeconds || 0 },
    })
    .then(jsonToImmutable)
    .then(createFlagConfiguration)
    .catch(jsonToImmutableError);
}

async function copyFlagConfig(
  flagkey: string,
  projectKey: string,
  targetEnvironmentKey: string,
  sourceEnvironmentKey: string,
  options: {
    comment?: string;
    includedActions?: string[];
    excludedActions?: string[];
  },
): Promise<Flag> {
  return http
    .post(`/api/v2/flags/${projectKey}/${flagkey}/copy`, {
      body: {
        source: {
          key: sourceEnvironmentKey,
        },
        target: {
          key: targetEnvironmentKey,
        },
        comment: options.comment,
        includedActions: options.includedActions,
        excludedActions: options.excludedActions,
      },
    })
    .then(jsonToImmutable)
    .then(createFlag)
    .catch(jsonToImmutableError);
}

const resetExperiment = async (projKey: string, envKey: string, flagKey: string, metricKey: string): Promise<void> => {
  try {
    const endpoint = `/api/v2/flags/${projKey}/${flagKey}/experiments/${envKey}/${metricKey}/results`;
    logger.log(`resetExperiment API: ${endpoint}`);
    await http.delete(endpoint);
  } catch (e) {
    logger.error('resetExperiment error', JSON.stringify(e));
    throw e;
  }
};

async function updateFlagSemantically(
  projectKey: string,
  flag: Flag,
  environmentKey: string,
  instructions: Iterable<SemanticInstruction | VariationSemanticInstruction>,
  options: { comment?: string } = {},
): Promise<Flag> {
  const { comment = '' } = options;

  return updateFlagSemantically_({
    projectKey,
    flagKey: flag.key,
    ignoreConflicts: true,
    environmentKey,
    instructions,
    comment,
  })
    .then((data) => fromJS(data))
    .then((flagResp) => {
      const modifiedFlag = createFlag(flagResp);
      return modifiedFlag.preserveFlagContextsEvaluation(flag);
    })
    .catch(restApitoImmutableError);
}

export {
  getFlags,
  getFlagByKey,
  getAllFlagStatusesForEnvironment,
  getFlagStatusesByEnvKeys,
  postFlag,
  updateFlag,
  updateFlagSemantically,
  deleteFlag,
  getGoalResults,
  getExperimentSummariesResults,
  getExperimentSeriesResults,
  getDependentFlagsByEnv,
  getDependentFlags,
  postFlagDebug,
  copyFlagConfig,
  resetExperiment,
  queryFlagStatusesByEnvKeysAndFlagKeys,
};
