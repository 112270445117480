import { useMemo } from 'react';
import { useMatches } from 'react-router-dom';
import { z } from 'zod';

const schema = z.object({
  DisplayPreferences: z.function().returns(z.any()),
});

export function DisplayPreferences() {
  const matches = useMatches();
  const currentRoute = matches.at(-1);

  const Preferences = useMemo(() => {
    if (currentRoute === undefined) {
      return null;
    }

    const parse = schema.safeParse(currentRoute.handle);
    if (!parse.success) {
      return null;
    }

    return parse.data.DisplayPreferences;
  }, [currentRoute]);

  return Preferences ? (
    <div>
      <Preferences />
    </div>
  ) : null;
}
