import { useState } from 'react';
import { useGenerateVariations } from '@gonfalon/rest-api';
import {
  GeneratedVariations,
  GenerateVariationsResponse,
  // eslint-disable-next-line import/no-internal-modules
} from '@gonfalon/rest-api/src/internal/postGenerateVariations';
import { Input, Label, ProgressBar, ToastQueue } from '@launchpad-ui/components';

import { AiCard } from './cards/AiCard';
import { AiGradientButton } from './buttons';

import styles from './ai.module.css';

export const AiStringVariationGenerator = ({
  addGeneratedVariations,
  existingVariationValues,
  defaultPrompt,
}: {
  existingVariationValues: string[];
  defaultPrompt?: string;
  addGeneratedVariations(variations: GeneratedVariations): void;
}) => {
  const [prompt, setPrompt] = useState(defaultPrompt);

  const handleVariationsGenerated = (variations: GeneratedVariations) => {
    addGeneratedVariations(variations);
  };

  const { isPending: isLoadingAIVariations, mutate: generateVariations } = useGenerateVariations({
    onSuccess: (data: GenerateVariationsResponse) => {
      const { variations } = data;
      variations &&
        handleVariationsGenerated(variations.map((v) => ({ ...v, originalAIValue: v.value, _isDraft: true })));
      setPrompt('');
    },
    onError: (e) => {
      // eslint-disable-next-line no-console
      console.error(e);
      ToastQueue.warning('Something went wrong generating variations. Please adjust your prompt and try again.');
    },
  });

  const handleGenerateVariations = () => {
    generateVariations({
      prompt,
      examples: existingVariationValues,
    });
  };

  return (
    <div id="generate-with-ai">
      <AiCard>
        <div className="u-flex u-flex-column u-gap-2">
          <p className="u-fs-sm">Use AI to generate new variations based on existing variations</p>

          <div>
            <form className={styles.AiPromptInput}>
              <Label htmlFor="prompt">Prompt (Optional)</Label>
              <Input
                id="prompt"
                type="text"
                name="prompt"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                disabled={isLoadingAIVariations}
                placeholder="Example: 'Optimize this call to action to increase sign ups' or 'include puns in each variation'"
              />
            </form>
          </div>

          <div className="u-mt-m u-flex u-flex-row u-flex-middle u-gap-3">
            <AiGradientButton size="small" onPress={handleGenerateVariations} isDisabled={isLoadingAIVariations}>
              Generate variations
            </AiGradientButton>

            {isLoadingAIVariations && <ProgressBar aria-label="Loading…" isIndeterminate />}
          </div>
        </div>
      </AiCard>
    </div>
  );
};
