import { useState } from 'react';

import DynamicGoaltenderFormVariable, {
  IntegrationFormContextProvider,
} from 'components/integrations/DynamicGoaltenderFormElement';
import { GoaltenderFormElementValue } from 'components/integrations/GoaltenderFormElement';
import { useCurrentEnvironmentEntity } from 'reducers/projects';
import { LaunchDarklyIntegrationsManifest } from 'types/generated/integrationSchema';

export type IntegrationConfig = Record<string, GoaltenderFormElementValue>;

type ApprovalIntegrationFieldsProps = {
  approvalServiceKind: string;
  manifest: LaunchDarklyIntegrationsManifest;
  onChange(newConfig: IntegrationConfig): void;
};

export function ApprovalIntegrationFields({ approvalServiceKind, manifest, onChange }: ApprovalIntegrationFieldsProps) {
  const environment = useCurrentEnvironmentEntity();
  const [config, setConfig] = useState<Record<string, GoaltenderFormElementValue>>({});
  const formVariables = manifest.capabilities?.approval?.approvalFormVariables;
  if (!formVariables) {
    return <></>;
  }
  return (
    <IntegrationFormContextProvider
      manifest={manifest}
      environment={environment}
      formVariableType="approvalFormVariables"
    >
      {formVariables.map((formVar) => (
        <DynamicGoaltenderFormVariable
          key={formVar.key}
          formVar={formVar}
          onChange={(_, value) => {
            config[formVar.key] = value;
            setConfig(config);
            onChange(config);
          }}
          value={undefined}
          disabled={false}
          integrationKey={approvalServiceKind}
          isRestricted={false}
        />
      ))}
    </IntegrationFormContextProvider>
  );
}
