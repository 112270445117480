import { assetBase, assetPath } from '@gonfalon/constants';
import { LegacyIcon } from '@gonfalon/icons';
import { useThemeValue } from '@gonfalon/theme';
import { Button, ButtonProps } from '@launchpad-ui/components';
import cx from 'clsx';

import styles from '../ai.module.css';

interface AiGradientButtonProps extends ButtonProps {
  showSparkleIcon?: boolean;
}

export const AiGradientButton = ({ showSparkleIcon, children, ...buttonProps }: AiGradientButtonProps) => {
  const theme = useThemeValue();

  const sparkleIconPath = `${assetBase()}/${assetPath()}/img/ai-things/${
    theme === 'dark' ? 'sparkle.svg' : 'sparkle-black.svg'
  }`;

  return (
    <Button size="small" {...buttonProps} className={cx(styles.GradientButton, buttonProps.className)}>
      <>
        {showSparkleIcon && (
          <LegacyIcon size={buttonProps.size || 'small'}>
            <img src={sparkleIconPath} srcSet={sparkleIconPath} alt="sparkle" />
          </LegacyIcon>
        )}
        &nbsp;{children}
      </>
    </Button>
  );
};
