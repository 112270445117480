import { Button } from 'launchpad';

type AuditLogPaginationProps = {
  isFetching: boolean;
  isLastPage: boolean;
  onLoadMore: () => void;
};

/* eslint-disable import/no-default-export */
export default function AuditLogPagination({ isFetching, isLastPage, onLoadMore }: AuditLogPaginationProps) {
  if (!isLastPage) {
    return (
      <Button onClick={onLoadMore} isLoading={isFetching}>
        {isFetching ? 'Loading' : 'Load more'}
      </Button>
    );
  } else {
    return null;
  }
}
