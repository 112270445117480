import UpsellInlineBanner from '../UpsellInlineBanner';

type UpsellInlineBannerResourceHistoryProps = {
  auditLogLimit: number;
};

const UpsellInlineBannerResourceHistory = ({ auditLogLimit }: UpsellInlineBannerResourceHistoryProps) => (
  <UpsellInlineBanner
    featureKind="Audit log"
    className="u-mt-l"
    title="Upgrade to see the full history"
    description={`Your plan is limited to ${auditLogLimit} days of events.`}
    componentForAnalyticTracking="Audit log"
  />
);

/* eslint-disable import/no-default-export */
export default UpsellInlineBannerResourceHistory;
