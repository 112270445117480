import { useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { enableNewProjectSettings } from '@gonfalon/dogfood-flags';
import { kebabCase, noop } from '@gonfalon/es6-utils';
import { toProjectEnvironments } from '@gonfalon/navigator';
import { getQueryClient } from '@gonfalon/react-query-client';
import { environmentsList } from '@gonfalon/rest-api';

import { createFollowResourceActionCreator } from 'actions/followPreferences';
import { useDispatch } from 'hooks/useDispatch';
import { GetState, GlobalDispatch, GlobalState } from 'reducers';
import { currentEnvironmentSelector, currentProjectSelector, makeProjectSelectorForEnvId } from 'reducers/projects';
import { reportAccesses } from 'sources/AccountAPI';
import {
  createEnvironment as environmentAPICreateEnvironment,
  deleteEnvironment as environmentAPIDeleteEnvironment,
  getCurrentProjAndEnv,
  resetMobileKey as environmentAPIResetMobileKey,
  resetSDKKey as environmentAPIResetSDKKey,
  selectEnvironment as environmentAPISelectEnvironment,
  updateEnvironment as environmentAPIUpdateEnvironment,
} from 'sources/EnvironmentAPI';
import { accessResponseDeniesUpdateTags, makeEnvironmentSpec } from 'utils/accessUtils';
import { FollowPreferences } from 'utils/accountUtils';
import { Environment, truncateKey } from 'utils/environmentUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import { getNewUrlContext } from 'utils/navigationUtils';
import { EnvironmentsFilters, Project, ProjectFilters } from 'utils/projectUtils';
import { GenerateActionType } from 'utils/reduxUtils';
import { resourceKinds } from 'utils/resourceUtils';

const projectUrl = (projectKey: string, filters: Pick<ProjectFilters, 'toQueryString'>) =>
  toProjectEnvironments({ projectKey }, { search: filters.toQueryString() });

function shouldFetchCurrentProjectAndEnvironment(state: GlobalState) {
  return (
    !state.currentEnvironment.get('entity') &&
    !state.currentEnvironment.get('isFetching') &&
    !state.currentEnvironment.get('doNotFetch')
  );
}

const requestCurrentProjectAndEnvironment = () =>
  ({ type: 'environments/REQUEST_CURRENT_PROJECT_AND_ENVIRONMENT' }) as const;

const requestCurrentProjectAndEnvironmentDone = (project: Project, environment: Environment) =>
  ({
    type: 'environments/REQUEST_CURRENT_PROJECT_AND_ENVIRONMENT_DONE',
    environment,
    project,
  }) as const;

const requestCurrentProjectAndEnvironmentFailed = (error: ImmutableServerError) =>
  ({
    type: 'environments/REQUEST_CURRENT_PROJECT_AND_ENVIRONMENT_FAILED',
    error,
  }) as const;

function fetchCurrentProjectAndEnvironment() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestCurrentProjectAndEnvironment());
    return new Promise((resolve, reject) => {
      getCurrentProjAndEnv()
        .then((response) => {
          dispatch(
            requestCurrentProjectAndEnvironmentDone(response.get('currentProject'), response.get('currentEnvironment')),
          );
          resolve(response);
        })
        .catch((error) => {
          dispatch(requestCurrentProjectAndEnvironmentFailed(error));
          reject(error);
        });
    });
  };
}

function fetchCurrentProjectAndEnvironmentIfNeeded() {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchCurrentProjectAndEnvironment(getState())) {
      return dispatch(fetchCurrentProjectAndEnvironment());
    } else {
      return Promise.resolve();
    }
  };
}

export { fetchCurrentProjectAndEnvironmentIfNeeded as fetchCurrentProjectAndEnvironment };

const select = (environmentId: string) => ({ type: 'environments/SELECT_ENVIRONMENT', environmentId }) as const;

const selectFailed = (environmentId: string, error: ImmutableServerError) =>
  ({
    type: 'environments/SELECT_ENVIRONMENT_FAILED',
    environmentId,
    error,
  }) as const;

export function selectEnvironment(
  { environmentKey, environmentId }: { environmentKey: string; environmentId: string },
  {
    previousProjectKey,
    previousEnvironmentId,
    projectKey,
  }: {
    previousProjectKey?: string;
    previousEnvironmentId?: string;
    projectKey?: string;
  } = {},
) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    dispatch(select(environmentId));
    return environmentAPISelectEnvironment(environmentId)
      .then(() => {
        const newUrlContext = getNewUrlContext(
          {
            previousProjectKey: previousProjectKey ?? currentProjectSelector(getState()).get('entity').key,
            previousEnvironmentId: previousEnvironmentId ?? currentEnvironmentSelector(getState()).get('entity')._id,
          },
          {
            projectKey: projectKey ?? makeProjectSelectorForEnvId(environmentId)(getState()).get('entity').key,
            environmentId,
            environmentKey,
          },
        );

        window.location.href = newUrlContext;
      })
      .catch((error) => {
        dispatch(selectFailed(environmentId, error));
      });
  };
}

const checkResourceAccess = (willRemoveEditingAbility: boolean) =>
  ({ type: 'environments/CHECK_ACCESS_RESOURCE', willRemoveEditingAbility }) as const;

const edit = (field: string, env: Environment, path?: string[]) =>
  ({ type: 'environments/EDIT_ENVIRONMENT', field, env, path }) as const;

export function editEnvironment(
  projectKey: string,
  environmentKey: string | undefined,
  path: string[],
  value: $TSFixMe,
  options: { isAccessWarningEnabled?: boolean } = {},
) {
  return (dispatch: GlobalDispatch, getState: GetState) => {
    const form = getState().environmentForm;
    let modified = form.modified;
    const field = path[path.length - 1];
    if (field === 'key') {
      modified = modified.setIn(path, truncateKey(value));
    } else {
      modified = modified.setIn(path, value);
    }

    if (field === 'name' && !form.wasChanged('key') && modified.isNew()) {
      modified = modified.set('key', truncateKey(kebabCase(value)));
    }

    dispatch(edit(field, modified, path));

    const { isAccessWarningEnabled } = options;
    if (isAccessWarningEnabled && field === 'tags' && environmentKey && !!form.original._access) {
      const newResourceSpec = makeEnvironmentSpec(projectKey, environmentKey, value);
      const oldAccessDenied = form.original._access.get('denied');
      reportAccesses(newResourceSpec).then((accesses) => {
        const willRemoveEditingAbility = accessResponseDeniesUpdateTags(accesses, newResourceSpec, oldAccessDenied);
        dispatch(checkResourceAccess(willRemoveEditingAbility));
      }, noop);
    }
  };
}

const create = (project: Project, env: Environment) =>
  ({ type: 'environments/CREATE_ENVIRONMENT', project, env }) as const;

const createDone = (project: Project, env: Environment) =>
  ({
    type: 'environments/CREATE_ENVIRONMENT_DONE',
    project,
    env,
  }) as const;

const createFailed = (project: Project, env: Environment, error: ImmutableServerError) =>
  ({
    type: 'environments/CREATE_ENVIRONMENT_FAILED',
    project,
    env,
    error,
  }) as const;

export const useCreateEnvironment = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const navigate = useNavigate();

  return async (project: Project, projectFilters: ProjectFilters) => {
    const formState = store.getState().environmentForm;
    const { modified } = formState;
    const { key } = project;
    dispatch(create(project, modified));
    return environmentAPICreateEnvironment(project, modified)
      .then(async (created) => {
        navigate(projectUrl(key, projectFilters));
        dispatch(createDone(project, created));
        if (enableNewProjectSettings()) {
          // todo: remove this once we port over these mutations to react-query
          await getQueryClient().invalidateQueries({
            queryKey: environmentsList({ projectKey: project.key }).queryKey,
          });
        }
      })
      .catch((error) => {
        if (error.get('status') === 403 || error.get('status') === 404) {
          navigate(projectUrl(key, projectFilters));
        }
        dispatch(createFailed(project, modified, error));
      });
  };
};

const update = (original: Environment, modified: Environment) =>
  ({ type: 'environments/UPDATE_ENVIRONMENT', original, modified }) as const;

const updateDone = (env: Environment) => ({ type: 'environments/UPDATE_ENVIRONMENT_DONE', env }) as const;

const updateFailed = (env: Environment, error: ImmutableServerError) =>
  ({
    type: 'environments/UPDATE_ENVIRONMENT_FAILED',
    env,
    error,
  }) as const;

export const useUpdateEnvironment = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const navigate = useNavigate();

  return async (projectFilters: ProjectFilters, project: Project, doNotRedirect = false) => {
    const formState = store.getState().environmentForm;
    const { original, modified } = formState;
    const { key } = project;
    dispatch(update(original, modified));
    return environmentAPIUpdateEnvironment(original, modified)
      .then(async (env) => {
        !doNotRedirect && navigate(projectUrl(key, projectFilters));
        dispatch(updateDone(env));
        if (enableNewProjectSettings()) {
          // todo: remove this once we port over these mutations to react-query
          await getQueryClient().invalidateQueries({
            queryKey: environmentsList({ projectKey: project.key }).queryKey,
          });
        }
      })
      .catch((error) => {
        if (error.get('status') === 403 || error.get('status') === 404) {
          navigate(projectUrl(key, projectFilters));
        }
        dispatch(updateFailed(modified, error));
      });
  };
};

const del = (project: Project, env: Environment) =>
  ({ type: 'environments/DELETE_ENVIRONMENT', project, env }) as const;

const deleteDone = (project: Project, env: Environment) =>
  ({ type: 'environments/DELETE_ENVIRONMENT_DONE', project, env }) as const;

const deleteFailed = (project: Project, env: Environment, error: ImmutableServerError) =>
  ({
    type: 'environments/DELETE_ENVIRONMENT_FAILED',
    project,
    env,
    error,
  }) as const;

export function useDeleteEnvironment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (project: Project, env: Environment, environmentFilters: ProjectFilters | EnvironmentsFilters) => {
    const { key } = project;
    dispatch(del(project, env));
    return environmentAPIDeleteEnvironment(env)
      .then(() => {
        navigate(projectUrl(key, environmentFilters));
        dispatch(deleteDone(project, env));
      })
      .catch((error) => {
        if (error.get('status') === 403 || error.get('status') === 404) {
          navigate(projectUrl(key, environmentFilters));
        }
        dispatch(deleteFailed(project, env, error));
      });
  };
}

const resetSDK = (env: Environment, config: { expiry: number } | null) =>
  ({ type: 'environments/RESET_API_KEY', env, config }) as const;

const resetSDKDone = (env: Environment, config: { expiry: number } | null) =>
  ({ type: 'environments/RESET_API_KEY_DONE', env, config }) as const;

const resetSDKFailed = (env: Environment, config: { expiry: number } | null, error: ImmutableServerError) =>
  ({ type: 'environments/RESET_API_KEY_FAILED', env, config, error }) as const;

export function useResetSDKKey() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (
    env: Environment,
    config: { expiry: number } | null = null,
    projectFilters: ProjectFilters,
    project: Project,
  ) => {
    const { key } = project;
    dispatch(resetSDK(env, config));
    return environmentAPIResetSDKKey(env, config)
      .then((updated) => {
        navigate(projectUrl(key, projectFilters));
        dispatch(resetSDKDone(updated, config));
      })
      .catch((error) => {
        if (error.get('status') === 403 || error.get('status') === 404) {
          navigate(projectUrl(key, projectFilters));
        }
        dispatch(resetSDKFailed(env, config, error));
      });
  };
}

const resetMobile = (env: Environment) => ({ type: 'environments/RESET_MOBILE_KEY', env }) as const;

const resetMobileDone = (env: Environment) => ({ type: 'environments/RESET_MOBILE_KEY_DONE', env }) as const;

const resetMobileFailed = (env: Environment, error: ImmutableServerError) =>
  ({ type: 'environments/RESET_MOBILE_KEY_FAILED', env, error }) as const;

export function useResetMobileKey() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (env: Environment, projectFilters: ProjectFilters, project: Project) => {
    const { key } = project;
    dispatch(resetMobile(env));
    return environmentAPIResetMobileKey(env)
      .then((updated) => {
        navigate(projectUrl(key, projectFilters));
        dispatch(resetMobileDone(updated));
      })
      .catch((error) => {
        if (error.get('status') === 403 || error.get('status') === 404) {
          navigate(projectUrl(key, projectFilters));
        }
        dispatch(resetMobileFailed(env, error));
      });
  };
}

export const filterEnvironmentsByText = (project: Project, term: string) =>
  ({
    type: 'environments/FILTER_ENVIRONMENTS_BY_TEXT',
    project,
    term,
  }) as const;

const follow = (resourceId: string, isFollowed: boolean, preferences: FollowPreferences) =>
  ({ type: 'environments/FOLLOW_ENVIRONMENT', resourceId, isFollowed, preferences }) as const;

const followDone = (resourceId: string, isFollowed: boolean, preferences: FollowPreferences) =>
  ({ type: 'environments/FOLLOW_ENVIRONMENT_DONE', resourceId, isFollowed, preferences }) as const;

const followFailed = (
  resourceId: string,
  isFollowed: boolean,
  preferences: FollowPreferences,
  error: ImmutableServerError,
) => ({ type: 'environments/FOLLOW_ENVIRONMENT_FAILED', resourceId, isFollowed, preferences, error }) as const;

export const followEnvironment = createFollowResourceActionCreator(
  resourceKinds.ENVIRONMENT,
  follow,
  followDone,
  followFailed,
);

const EnvironmentActionCreators = {
  requestCurrentProjectAndEnvironment,
  requestCurrentProjectAndEnvironmentDone,
  requestCurrentProjectAndEnvironmentFailed,
  select,
  selectFailed,
  create,
  createDone,
  createFailed,
  del,
  deleteDone,
  deleteFailed,
  update,
  updateDone,
  updateFailed,
  resetSDK,
  resetSDKDone,
  resetSDKFailed,
  resetMobile,
  resetMobileDone,
  resetMobileFailed,
  filterEnvironmentsByText,
  checkResourceAccess,
  follow,
  followDone,
  followFailed,
};

export type EnvironmentAction = GenerateActionType<typeof EnvironmentActionCreators>;
