import { Diamond } from '@gonfalon/icons';
import { Checkbox, Tooltip } from '@launchpad-ui/core';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { scaleOrdinal } from '@visx/scale';

import styles from './TimeSeriesIntervalPlotLegend.module.css';

export type LegendScale = {
  domain: string[];
  range: string[];
};

type LegendProps = {
  scale: LegendScale;
  onSelectDataset: (itemName: string) => void;
  deselectedPlots: string[];
};

export const TimeSeriesIntervalPlotLegend = ({ scale, onSelectDataset, deselectedPlots }: LegendProps) => (
  <div className={styles.plotLegend}>
    <LegendOrdinal scale={scaleOrdinal(scale)} direction="row" labelMargin="0 15px 0 0">
      {(labels) => (
        <>
          {labels.map((label, idx) => (
            <LegendItem key={`legend-label-${label.value}`}>
              <Checkbox
                labelClassName={styles.checkboxLabel}
                checked={!deselectedPlots.includes(label.value)}
                onChange={() => onSelectDataset(label.value)}
              >
                <div className={styles.checkboxLabel}>
                  <Diamond
                    className={styles.icon}
                    size="small"
                    fill={scale.domain[idx]}
                    stroke={scale.domain[idx]}
                    strokeWidth={2}
                    displayOuterStroke={false}
                  />
                  <LegendLabel align="left" margin="0 0 0 4px">
                    <Tooltip content={label.value}>
                      <span className={styles.legendLabel}>{label.value}</span>
                    </Tooltip>{' '}
                  </LegendLabel>
                </div>
              </Checkbox>
            </LegendItem>
          ))}
        </>
      )}
    </LegendOrdinal>
  </div>
);
