import { List } from 'immutable';
import qs from 'qs';

import { TagKind } from 'components/tagFilter/types';
import http, { ImmutableServerError, jsonToImmutable, jsonToImmutableError } from 'utils/httpUtils';
import { ImmutableMap } from 'utils/immutableUtils';

type TagsData = ImmutableMap<{ items: List<string> }>;

export async function fetchTags(
  pre: string | null,
  kind?: TagKind,
  archived?: boolean,
): Promise<List<string> | ImmutableServerError> {
  const limit = 50;
  const url = `/api/v2/tags?${qs.stringify({ pre, kind, archived, limit })}`;
  return http
    .get(url) // used on different pages so using a resource is tricky
    .then(jsonToImmutable)
    .then((data: TagsData) => data.get('items'))
    .catch(jsonToImmutableError);
}
