import { createContext, ReactNode, useContext, useMemo } from 'react';

import { createFlag, createFlagConfiguration, Flag, FlagConfiguration } from 'utils/flagUtils';

const FlagContext = createContext<{ flag: Flag; flagConfiguration: FlagConfiguration }>({
  flag: createFlag(),
  flagConfiguration: createFlagConfiguration(),
});

export function FlagContextProvider({
  flag,
  flagConfiguration,
  children,
}: {
  flag: Flag;
  flagConfiguration: FlagConfiguration;
  children: ReactNode;
}) {
  const contextValue = useMemo(
    () => ({
      flag,
      flagConfiguration,
    }),
    [flag, flagConfiguration],
  );

  return <FlagContext.Provider value={contextValue}>{children}</FlagContext.Provider>;
}

export function useFlagContext() {
  return useContext(FlagContext);
}

export function useDoesFlagDefaultRuleHaveExperiment() {
  return !!useFlagContext().flagConfiguration.fallthrough.rollout?.experimentAllocation;
}
