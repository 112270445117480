import { makeUpdatedInstructions } from './makeUpdatedInstructions';
import { makeUpdatedInstructionsWithUpdatedRule } from './makeUpdatedInstructionsWithUpdatedRule';
import {
  type AddExcludedTargetsInstruction,
  type AddExcludedUsersInstruction,
  type AddExpiringTargetInstruction,
  type AddIncludedTargetsInstruction,
  type AddIncludedUsersInstruction,
  type AddRuleInstruction,
  type Clause,
  type RemoveExcludedTargetsInstruction,
  type RemoveExcludedUsersInstruction,
  type RemoveExpiringTargetInstruction,
  type RemoveIncludedTargetsInstruction,
  type RemoveIncludedUsersInstruction,
  type Rule,
  type Segment,
  type SegmentSemanticInstruction,
  type UpdateExpiringTargetInstruction,
  type UpdateRuleDescriptionInstruction,
  RemoveRuleInstruction,
} from './types';

export type PendingChangesState = {
  instructions: SegmentSemanticInstruction[];
};

const actions = {
  updateIncludedTargets: (
    targets: string[],
    contextKind: string,
    kind: 'removeIncludedTargets' | 'addIncludedTargets',
  ) => ({ type: 'UPDATE_INCLUDED_TARGETS', targets, contextKind, kind }) as const,
  updateIncludedUsers: (targets: string[], kind: 'removeIncludedUsers' | 'addIncludedUsers') =>
    ({ type: 'UPDATE_INCLUDED_USERS', targets, kind }) as const,
  updateExcludedTargets: (
    targets: string[],
    contextKind: string,
    kind: 'removeExcludedTargets' | 'addExcludedTargets',
  ) => ({ type: 'UPDATE_EXCLUDED_TARGETS', targets, contextKind, kind }) as const,
  updateExcludedUsers: (targets: string[], kind: 'removeExcludedUsers' | 'addExcludedUsers') =>
    ({ type: 'UPDATE_EXCLUDED_USERS', targets, kind }) as const,
  addExpiringTarget: (contextKind: string, value: string, removalDate: number) =>
    ({ type: 'ADD_EXPIRING_TARGET', contextKind, value, removalDate }) as const,
  removeExpiringTarget: (contextKind: string, value: string) =>
    ({ type: 'REMOVE_EXPIRING_TARGET', contextKind, value }) as const,
  updateExpiringTarget: (contextKind: string, value: string, removalDate: number) =>
    ({ type: 'UPDATE_EXPIRING_TARGET', contextKind, value, removalDate }) as const,
  undoExpiringTarget: (contextKind: string, value: string) =>
    ({ type: 'UNDO_EXPIRING_TARGET', contextKind, value }) as const,
  addRule: (rule: Rule) => ({ type: 'ADD_RULE', rule }) as const,
  rempveRule: (ruleId: string) => ({ type: 'REMOVE_RULE', ruleId }) as const,
  editClause: (ruleId: string, clause: Clause, isNewRule: boolean) =>
    ({ type: 'EDIT_CLAUSE', ruleId, clause, isNewRule }) as const,
  changeRuleRollout: (ruleId: string, weight: number, isNewRule: boolean) =>
    ({ type: 'CHANGE_RULE_ROLLOUT', ruleId, weight, isNewRule }) as const,
  changeRuleRolloutContextKind: (ruleId: string, contextKind: string, isNewRule: boolean) =>
    ({ type: 'CHANGE_RULE_ROLLOUT_CONTEXT_KIND', ruleId, contextKind, isNewRule }) as const,
  changeRuleDescription: (ruleId: string, description: string, isNewRule: boolean) =>
    ({ type: 'CHANGE_RULE_DESCRIPTION', ruleId, description, isNewRule }) as const,
  save: (currentSegment: Segment) => ({ type: 'SAVE', currentSegment }) as const,
};

export type SegmentPendingChangesActionsType =
  | {
      readonly type: 'UPDATE_INCLUDED_TARGETS';
      readonly targets: string[];
      readonly contextKind: string;
      readonly kind: 'removeIncludedTargets' | 'addIncludedTargets';
    }
  | {
      readonly type: 'UPDATE_INCLUDED_USERS';
      readonly targets: string[];
      readonly kind: 'removeIncludedUsers' | 'addIncludedUsers';
    }
  | {
      readonly type: 'UPDATE_EXCLUDED_TARGETS';
      readonly targets: string[];
      readonly contextKind: string;
      readonly kind: 'removeExcludedTargets' | 'addExcludedTargets';
    }
  | {
      readonly type: 'UPDATE_EXCLUDED_USERS';
      readonly targets: string[];
      readonly kind: 'removeExcludedUsers' | 'addExcludedUsers';
    }
  | {
      readonly type: 'ADD_EXPIRING_TARGET';
      readonly contextKind: string;
      readonly value: string;
      readonly removalDate: number;
    }
  | {
      readonly type: 'REMOVE_EXPIRING_TARGET';
      readonly contextKind: string;
      readonly value: string;
    }
  | {
      readonly type: 'UPDATE_EXPIRING_TARGET';
      readonly contextKind: string;
      readonly value: string;
      readonly removalDate: number;
    }
  | {
      readonly type: 'UNDO_EXPIRING_TARGET';
      readonly contextKind: string;
      readonly value: string;
    }
  | { readonly type: 'ADD_RULE'; readonly rule: Rule }
  | { readonly type: 'REMOVE_RULE'; readonly ruleId: string }
  | { readonly type: 'EDIT_CLAUSE'; readonly ruleId: string; readonly clause: Clause; readonly isNewRule: boolean }
  | {
      readonly type: 'CHANGE_RULE_ROLLOUT';
      readonly ruleId: string;
      readonly weight: number;
      readonly isNewRule: boolean;
    }
  | {
      readonly type: 'CHANGE_RULE_ROLLOUT_CONTEXT_KIND';
      readonly ruleId: string;
      readonly contextKind: string;
      readonly isNewRule: boolean;
    }
  | {
      readonly type: 'CHANGE_RULE_DESCRIPTION';
      readonly ruleId: string;
      readonly description: string;
      readonly isNewRule: boolean;
    }
  | { readonly type: 'SAVE'; readonly currentSegment: Segment };

export function pendingChangesReducer(
  state: PendingChangesState,
  action: ReturnType<(typeof actions)[keyof typeof actions]>,
) {
  switch (action.type) {
    case 'UPDATE_INCLUDED_USERS':
      let includedUsersInstruction = state.instructions.find((instruction) => instruction.kind === action.kind);
      if (includedUsersInstruction !== undefined) {
        (includedUsersInstruction as AddIncludedUsersInstruction | RemoveIncludedUsersInstruction).values =
          action.targets;
      } else {
        includedUsersInstruction = {
          kind: action.kind,
          values: action.targets,
        };
      }

      return {
        ...state,
        instructions: makeUpdatedInstructions(action.kind, state.instructions, includedUsersInstruction),
      };
    case 'UPDATE_INCLUDED_TARGETS':
      let includedTargetsInstruction = state.instructions.find((instruction) => instruction.kind === action.kind);
      if (includedTargetsInstruction !== undefined) {
        (includedTargetsInstruction as AddIncludedTargetsInstruction | RemoveIncludedTargetsInstruction).values =
          action.targets;
      } else {
        includedTargetsInstruction = {
          kind: action.kind,
          values: action.targets,
          contextKind: action.contextKind,
        };
      }

      return {
        ...state,
        instructions: makeUpdatedInstructions(action.kind, state.instructions, includedTargetsInstruction),
      };
    case 'UPDATE_EXCLUDED_USERS':
      let excludedUsersInstruction = state.instructions.find((instruction) => instruction.kind === action.kind);
      if (excludedUsersInstruction !== undefined) {
        (excludedUsersInstruction as AddExcludedUsersInstruction | RemoveExcludedUsersInstruction).values =
          action.targets;
      } else {
        excludedUsersInstruction = {
          kind: action.kind,
          values: action.targets,
        };
      }

      return {
        ...state,
        instructions: makeUpdatedInstructions(action.kind, state.instructions, excludedUsersInstruction),
      };
    case 'UPDATE_EXCLUDED_TARGETS':
      let excludedTargetsInstruction = state.instructions.find((instruction) => instruction.kind === action.kind);
      if (excludedTargetsInstruction !== undefined) {
        (excludedTargetsInstruction as AddExcludedTargetsInstruction | RemoveExcludedTargetsInstruction).values =
          action.targets;
      } else {
        excludedTargetsInstruction = {
          kind: action.kind,
          values: action.targets,
          contextKind: action.contextKind,
        };
      }

      return {
        ...state,
        instructions: makeUpdatedInstructions(action.kind, state.instructions, excludedTargetsInstruction),
      };
    case 'ADD_EXPIRING_TARGET':
      let addExpiringTargetInstruction = state.instructions.find(
        (instruction) =>
          instruction.kind === 'addExpiringTarget' &&
          instruction.contextKind === action.contextKind &&
          instruction.value === action.value,
      );

      if (addExpiringTargetInstruction !== undefined) {
        (addExpiringTargetInstruction as AddExpiringTargetInstruction).removalDate = action.removalDate;
      } else {
        addExpiringTargetInstruction = {
          kind: 'addExpiringTarget',
          contextKind: action.contextKind,
          value: action.value,
          removalDate: action.removalDate,
        };
      }

      return {
        ...state,
        instructions: makeUpdatedInstructions('addExpiringTarget', state.instructions, addExpiringTargetInstruction),
      };
    case 'REMOVE_EXPIRING_TARGET':
      const removeExpiringTargetInstruction = {
        kind: 'removeExpiringTarget',
        contextKind: action.contextKind,
        value: action.value,
      };
      return {
        ...state,
        instructions: makeUpdatedInstructions(
          'removeExpiringTarget',
          state.instructions,
          removeExpiringTargetInstruction as RemoveExpiringTargetInstruction,
        ),
      };
    case 'UPDATE_EXPIRING_TARGET':
      let updateExpiringTargetInstruction = state.instructions.find(
        (instruction) =>
          instruction.kind === 'updateExpiringTarget' &&
          instruction.contextKind === action.contextKind &&
          instruction.value === action.value,
      );

      if (updateExpiringTargetInstruction !== undefined) {
        (updateExpiringTargetInstruction as UpdateExpiringTargetInstruction).removalDate = action.removalDate;
      } else {
        updateExpiringTargetInstruction = {
          kind: 'updateExpiringTarget',
          contextKind: action.contextKind,
          value: action.value,
          removalDate: action.removalDate,
        };
      }

      // check if a corresponding remove expiring target instruction exists
      const existingRemoveExpiringTargetIdx = state.instructions.findIndex(
        (instruction) =>
          instruction.kind === 'removeExpiringTarget' &&
          instruction.contextKind === action.contextKind &&
          instruction.value === action.value,
      );

      const updatedInstructionsForUpdateExpiringTarget = state.instructions;
      if (existingRemoveExpiringTargetIdx !== -1) {
        updatedInstructionsForUpdateExpiringTarget.splice(existingRemoveExpiringTargetIdx, 1);
      }

      return {
        ...state,
        instructions: makeUpdatedInstructions(
          'updateExpiringTarget',
          updatedInstructionsForUpdateExpiringTarget,
          updateExpiringTargetInstruction as UpdateExpiringTargetInstruction,
        ),
      };
    case 'UNDO_EXPIRING_TARGET':
      const instructionToUndoIdx = state.instructions.findIndex(
        (instruction) =>
          ['addExpiringTarget', 'updateExpiringTarget', 'removeExpiringTarget'].includes(instruction.kind) &&
          'contextKind' in instruction &&
          instruction.contextKind === action.contextKind &&
          'value' in instruction &&
          instruction.value === action.value,
      );
      if (instructionToUndoIdx !== -1) {
        const instructionsAfterRemoval = state.instructions;
        instructionsAfterRemoval.splice(instructionToUndoIdx, 1);
        return { ...state, instructions: instructionsAfterRemoval };
      }
      return { ...state };
    case 'ADD_RULE':
      const addRuleInstruction = {
        kind: 'addRule',
        rule: action.rule,
      };
      return { ...state, instructions: state.instructions.concat(addRuleInstruction as AddRuleInstruction) };
    case 'REMOVE_RULE':
      const existingRuleToAddIdx = state.instructions.findIndex(
        (instruction) => instruction.kind === 'addRule' && instruction.rule._id === action.ruleId,
      );
      if (existingRuleToAddIdx !== -1) {
        return { ...state, instruction: state.instructions.toSpliced(existingRuleToAddIdx, 1) };
      }
      const removeRuleInstruction = {
        kind: 'removeRule',
        ruleId: action.ruleId,
      } as RemoveRuleInstruction;
      return { ...state, instructions: state.instructions.concat(removeRuleInstruction) };

    case 'EDIT_CLAUSE':
      if (action.isNewRule) {
        return {
          ...state,
          instructions: makeUpdatedInstructionsWithUpdatedRule(state.instructions, action.ruleId, {
            clauseToUpdate: action.clause,
          }),
        };
      }
      // TODO: else add a new edit instruction
      return { ...state };
    case 'CHANGE_RULE_ROLLOUT':
      if (action.isNewRule) {
        // check if there is a new rule added
        return {
          ...state,
          instructions: makeUpdatedInstructionsWithUpdatedRule(state.instructions, action.ruleId, {
            weight: action.weight,
          }),
        };
      }
      // TODO: else add a new edit instruction
      return { ...state };
    case 'CHANGE_RULE_ROLLOUT_CONTEXT_KIND':
      if (action.isNewRule) {
        // check if there is a new rule added
        return {
          ...state,
          instructions: makeUpdatedInstructionsWithUpdatedRule(state.instructions, action.ruleId, {
            contextKind: action.contextKind,
          }),
        };
      }
      // TODO: else add a new edit instruction
      return { ...state };
    case 'CHANGE_RULE_DESCRIPTION':
      if (action.isNewRule) {
        // check if there is a new rule added
        return {
          ...state,
          instructions: makeUpdatedInstructionsWithUpdatedRule(state.instructions, action.ruleId, {
            description: action.description,
          }),
        };
      }
      const updateRuleDescriptionInstruction: UpdateRuleDescriptionInstruction = {
        kind: 'updateRuleDescription',
        description: action.description,
        ruleId: action.ruleId,
      };
      return {
        ...state,
        instructions: makeUpdatedInstructions(
          'updateRuleDescription',
          state.instructions,
          updateRuleDescriptionInstruction,
        ),
      };
    case 'SAVE':
      return {
        ...state,
        originalSegment: action.currentSegment,
        instructions: [] as SegmentSemanticInstruction[],
      };
    default:
      return state;
  }
}
