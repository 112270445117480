import { getQueryClient } from '@gonfalon/react-query-client';
import { measuredRolloutsList, useUpdateMeasuredRolloutMetricStates } from '@gonfalon/rest-api';

import { MeasuredRolloutMetrics } from './types';

export function useDismissMeasuredRolloutMetrics({
  flagKey,
  projectKey,
  environmentKey,
  measuredRolloutId,
  regressedMetrics,
}: {
  flagKey: string;
  projectKey: string;
  environmentKey: string;
  measuredRolloutId: string;
  regressedMetrics: MeasuredRolloutMetrics;
}) {
  const { mutateAsync, isPending } = useUpdateMeasuredRolloutMetricStates();
  const queryClient = getQueryClient();

  const dismissMetrics = async () => {
    for (const metric of regressedMetrics) {
      await mutateAsync({
        projectKey,
        flagKey,
        environmentKey,
        measuredRolloutId,
        metricKey: metric.key,
        input: {
          action: 'dismiss_regression',
        },
      });
    }
    await queryClient.invalidateQueries({
      queryKey: measuredRolloutsList({ projectKey, flagKey, params: { filter: { environmentKey } } }).queryKey,
    });
  };

  return { dismissMetrics, isPending };
}
