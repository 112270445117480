import { type CSSProperties } from 'react';
import { type To, Link } from 'react-router-dom';
import { type FeatureFlagStatus } from '@gonfalon/flags';
import { useIsInNewIA } from '@gonfalon/ia-migration';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';
import { Chip } from 'launchpad';

import { FlagEnvironmentVariationsSummary } from 'components/releases/legacy/common/FlagEnvironmentVariationsSummary';
import { LegacyReleaseTrackerPopoverFlagEnvironmentSummary } from 'components/releases/legacy/FlagReleaseSummary/LegacyReleaseTrackerPhasePopover';

import styles from './ReleasesFlagEnvironmentSummary.module.css';

export type ReleasesFlagEnvironmentSummaryProps = {
  environment: {
    name: string;
    color: `#${string}`;
    key: string;
    url: To;
  };
  className?: string;
  isTargeting: boolean;
  variations: LegacyReleaseTrackerPopoverFlagEnvironmentSummary['flagVariations'];
  flagStatus: FeatureFlagStatus['name'] | null;
};

export function ReleasesFlagEnvironmentSummary({
  environment,
  className,
  isTargeting,
  variations,
  flagStatus,
}: ReleasesFlagEnvironmentSummaryProps) {
  const isInNewIA = useIsInNewIA();

  return (
    <Link
      reloadDocument={!isInNewIA}
      to={environment.url}
      style={
        {
          // Set the environment color as a CSS custom prop, gets picked up in
          // the linked stylesheet
          '--environment-color': environment.color,
        } as CSSProperties
      }
      className={cx(styles.releasesFlagEnvironmentSummary, className)}
      title={environment.name}
    >
      <div className={styles.inner}>
        <span data-test-id="FlagEnvironmentSummary-Span" className={styles.environment}>
          {environment.name}
        </span>
        <div className={styles.columns}>
          <Chip className={styles.targetingCell} kind={isTargeting ? 'success' : undefined}>
            {isTargeting ? 'On' : 'Off'}
          </Chip>
          <div className={styles.divider} />
          <div className={styles.variationsCell}>
            <FlagEnvironmentVariationsSummary
              variationRootClassName={styles.variationRoot}
              variationDisplayClassName={styles.variationDisplay}
              variations={variations}
            />
          </div>
          <div className={styles.divider} />
          {flagStatus !== null ? (
            <div className={styles.statusCell}>
              <Icon name={`status-${flagStatus}`} size="small" />
              <span className="u-ttc u-fs-sm-even">{flagStatus}</span>
            </div>
          ) : null}
        </div>
      </div>
    </Link>
  );
}
