import { useMemo } from 'react';
import { useMatches } from 'react-router-dom';
import { z } from 'zod';

const orderingSchema = z.object({
  Ordering: z.function().returns(z.any()),
});

/**
 * Render the ordering options for the current route.
 *
 * A route defines its ordering options via its `handle`.
 */
export function Ordering() {
  const matches = useMatches();
  const currentRoute = matches.at(-1);

  const RouteOrdering = useMemo(() => {
    if (currentRoute === undefined) {
      return null;
    }

    const parse = orderingSchema.safeParse(currentRoute.handle);
    if (!parse.success) {
      return null;
    }

    return parse.data.Ordering;
  }, [currentRoute]);

  return RouteOrdering ? (
    <div>
      <RouteOrdering />
    </div>
  ) : null;
}
