import { Map } from 'immutable';
import { combineReducers } from 'redux';

import { ContextAttributesAction } from 'actions/contextAttributes';
import { ContextAttributesNames, ContextAttributesValues } from 'components/Contexts/types';
import { GlobalState } from 'reducers';
import { createRequestReducerByKey } from 'reducers/createRequestReducer';
import registry from 'reducers/registry';
import { createRequest, Request } from 'utils/requestUtils';

const contextAttributesNamesRequest = createRequestReducerByKey(
  [
    'contextAttributes/REQUEST_CONTEXT_ATTRIBUTES',
    'contextAttributes/REQUEST_CONTEXT_ATTRIBUTES_DONE',
    'contextAttributes/REQUEST_CONTEXT_ATTRIBUTES_FAILED',
  ],
  (action) => action.contextKind,
);

const contextAttributesNames = (
  state: Map<string, ContextAttributesNames[]> = Map(),
  action: ContextAttributesAction,
) => {
  switch (action.type) {
    case 'contextAttributes/REQUEST_CONTEXT_ATTRIBUTES_DONE':
      return state.set(action.contextKind, action.contextAttributes);
    default:
      return state;
  }
};

type ContextAttributeValuesState = {
  entities: ContextAttributesValues | null;
  fetchRequest: Request;
};
const contextAttributeValues = (
  state: Map<string, ContextAttributeValuesState> = Map(),
  action: ContextAttributesAction,
) => {
  switch (action.type) {
    case 'contextAttributes/REQUEST_CONTEXT_ATTRIBUTE_VALUES':
      return state.set(action.attributeKey, {
        entities: null,
        fetchRequest: createRequest({
          isFetching: true,
        }),
      });
    case 'contextAttributes/REQUEST_CONTEXT_ATTRIBUTE_VALUES_DONE':
      return state.set(action.attributeKey, {
        entities: action.contextAttributeValues,
        fetchRequest: createRequest({
          isFetching: false,
        }),
      });
    case 'contextAttributes/REQUEST_CONTEXT_ATTRIBUTE_VALUES_FAILED':
      return state.set(action.attributeKey, {
        entities: null,
        fetchRequest: createRequest({
          isFetching: false,
          error: action.error,
        }),
      });

    default:
      return state;
  }
};

export const contextAttributesNamesRequestSelector = (state: GlobalState, contextKind: string) =>
  state.contextAttributes.contextAttributesNamesRequest.get(contextKind) || new Request();
export const contextAttributesNamesSelector = (state: GlobalState, contextKind: string) =>
  state.contextAttributes.contextAttributesNames.get(contextKind) || [];

export const contextAttributes = combineReducers({
  contextAttributesNames,
  contextAttributesNamesRequest,
  contextAttributeValues,
});

registry.addReducers({ contextAttributes });
