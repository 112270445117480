import { type APIFlagFilter } from '@gonfalon/rest-api';

import { parseSDKAvailability } from './parseSDKAvailability';
import { parseStatusName } from './parseStatusName';
import { parseTargeting } from './parseTargeting';
import { parseType } from './parseType';

export function parseFlagListFilterParamFromAPI(input: string) {
  const filter: APIFlagFilter = {};

  const parts = input.split(',');

  for (const part of parts) {
    const [key, value] = splitAtFirstOccurence(part, ':');

    switch (key) {
      case 'query':
        filter.query = value;
        continue;
      case 'type':
        filter.type = parseType(value);
        continue;
      case 'status':
        filter.status = parseStatusName(value);
        continue;
      case 'sdkAvailability':
        filter.sdkAvailability = parseSDKAvailability(value);
        continue;
      case 'filterEnv':
        filter.filterEnv = value;
        continue;
      case 'maintainerId':
        filter.maintainerId = value;
        continue;
      case 'maintainerTeamKey':
        filter.maintainerTeamKey = value;
        continue;
      case 'hasExperiment':
        filter.hasExperiment = value === 'true';
        continue;
      case 'hasDataExport':
        filter.hasDataExport = value === 'true';
        continue;
      case 'targeting':
        filter.targeting = parseTargeting(value);
        continue;
      case 'evaluated':
        filter.evaluated = JSON.parse(value);
        continue;
      case 'creationDate':
        filter.creationDate = JSON.parse(value);
        continue;
      case 'followerId':
        filter.followerId = value;
        continue;
      case 'segmentTargeted':
        filter.segmentTargeted = value;
        continue;
      case 'applicationEvaluated':
        filter.applicationEvaluated = value;
        continue;
      case 'releasePipeline':
        filter.releasePipeline = value;
        continue;
      case 'tags':
        filter.tags = value.split(' ');
        continue;
      case 'codeReferences':
        filter.codeReferences = JSON.parse(value);
        continue;
      case 'contextKindTargeted':
        filter.contextKindTargeted = value;
        continue;
      case 'contextKindsEvaluated':
        filter.contextKindsEvaluated = value.split(' ');
        continue;
      case 'codeReferences.max':
        filter.codeReferences = filter.codeReferences
          ? { ...filter.codeReferences, max: parseInt(value, 10) }
          : { max: parseInt(value, 10) };
        continue;
      case 'codeReferences.min':
        filter.codeReferences = filter.codeReferences
          ? { ...filter.codeReferences, min: parseInt(value, 10) }
          : { min: parseInt(value, 10) };
        continue;
      default:
        continue;
    }
  }

  return filter;
}

function splitAtFirstOccurence(input: string, char: string) {
  const [first, ...rest] = input.split(char);
  return [first, rest.join(char)];
}
