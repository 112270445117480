import { useEffect } from 'react';
import { StylesObject } from '@gonfalon/launchpad-experimental';
import { List, Set } from 'immutable';
import type { FilterOption } from 'launchpad';

import { fetchTags } from 'actions/tags';
import TagFilter from 'components/tagFilter/TagFilter';
import { useDispatch } from 'hooks/useDispatch';
import { useSelector } from 'hooks/useSelector';
import { tagsSelector } from 'reducers/tags';

import { TagFilterType, TagKind } from './types';

type TagFilterContainerProps = {
  archived: boolean;
  filterSelectStyles?: StylesObject;
  frontendTags?: List<string> | Set<string>;
  id?: string;
  kind: TagKind;
  onClear?(): void;
  onClickFilterButton?(): void;
  onSelect(
    item: Omit<FilterOption, 'name' | 'isDisabled' | 'isDivider' | 'isChecked' | 'projKey' | 'nested' | 'groupHeader'> &
      Array<{ label: string; value: string }>,
  ): void;
  onSelectTag?(item: FilterOption): void;
  selectedTags: Set<string>;
  selectedTagValue?: string | List<string> | Array<{ value: string }>;
  type?: TagFilterType;
  showLabel?: boolean;
};

const useRedux = () => {
  const dispatch = useDispatch();
  const tags = useSelector(tagsSelector);
  const isLoading = tags.get('isFetching');
  const backendTags = tags.get('entities');
  const fetchTagsAction = (pre: string | null, kind: TagKind, archived: boolean) =>
    dispatch(fetchTags(pre, kind, archived));
  return { fetchTagsAction, backendTags: backendTags ? backendTags : List(), isLoading: isLoading ? isLoading : false };
};

/* eslint-disable import/no-default-export */
export default function TagFilterContainer({
  id,
  kind,
  archived,
  frontendTags,
  onSelect,
  selectedTags,
  selectedTagValue,
  ...props
}: TagFilterContainerProps) {
  const { fetchTagsAction, backendTags, isLoading } = useRedux();

  useEffect(() => {
    fetchTagsAction(null, kind, archived);
  }, [archived]);

  function handleInputChange(input: string) {
    fetchTagsAction(input, kind, archived);
    return input;
  }

  return (
    <TagFilter
      frontendTags={frontendTags}
      backendTags={backendTags ? backendTags : List()}
      id={id}
      isLoading={isLoading ? isLoading : false}
      onInputChange={handleInputChange}
      onSelect={onSelect}
      selectedTags={selectedTags}
      showLabel
      {...props}
    />
  );
}
