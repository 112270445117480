import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProgressBar } from '@launchpad-ui/components';
import { Alert } from 'launchpad';
import invariant from 'tiny-invariant';

import AuditLogList from 'components/auditLog/AuditLogList';
import { Query } from 'utils/auditLogUtils';

import { useResoureHistoryListContainer } from './useResourceHistoryListContainer';

export type ResourceHistoryListContainerProps = {
  query: Query;
};

export const ResourceHistoryListContainer = ({ query }: ResourceHistoryListContainerProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { isReady, hasError, entries, account, projects, savedQuery, fetchAccount, findEntries, saveAuditLogQuery } =
    useResoureHistoryListContainer(query, location.pathname);

  const firstRender = useRef(true);

  useEffect(() => {
    if (savedQuery && firstRender.current) {
      firstRender.current = false;
      navigate({ pathname: location.pathname, search: savedQuery.toHistorySearch() });
      findEntries(savedQuery);
      return;
    }

    fetchAccount();
    findEntries(query);
    saveAuditLogQuery(query);
  }, [location.search, query]);

  if (!isReady) {
    return <ProgressBar aria-label="Loading…" isIndeterminate className="u-mv-l" />;
  }

  if (hasError) {
    return <Alert kind="error">An unexpected error occurred.</Alert>;
  }

  invariant(account, 'account prop should be set if isReady is true');
  invariant(projects, 'projects prop should be set if isReady is true');
  invariant(entries, 'entries prop should be set if isReady is true');

  return <AuditLogList account={account} projects={projects} entries={entries} query={query} />;
};

/* eslint-disable import/no-default-export */
export default ResourceHistoryListContainer;
