import { ElementType } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cx from 'clsx';
import qs from 'qs';

import { FilterType } from 'components/usage/types';

import './styles.css';

type DataComponentProps = {
  filters?: FilterType;
  onFilterChange(nextFilters?: FilterType): void;
  isOverview?: boolean;
  limit?: number;
};

export type UsageDetailsProps = {
  dataComponent: ElementType<DataComponentProps>;
  isOverview?: boolean;
  limit?: number;
};
export function UsageDetails({ dataComponent: DataComponent, limit, isOverview }: UsageDetailsProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleFilterChange = (nextFilters?: FilterType) => {
    const search = qs.stringify(nextFilters, { indices: false });
    navigate({ pathname: location.pathname, search });
  };
  const filters = qs.parse(location.search, { ignoreQueryPrefix: true }) as FilterType;

  return (
    <div className={cx({ UsageDetails: !isOverview })}>
      <DataComponent filters={filters} onFilterChange={handleFilterChange} isOverview={isOverview} limit={limit} />
    </div>
  );
}
