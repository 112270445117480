import { useState } from 'react';
import { assetBase, assetPath } from '@gonfalon/constants';
import { LegacyIcon } from '@gonfalon/icons';
import { useThemeValue } from '@gonfalon/theme';
import { IconButton } from '@launchpad-ui/components';
import cx from 'clsx';

import styles from '../ai.module.css';

type AiContentProps = {
  children: React.ReactNode;
  className?: string;
  showFooter?: boolean;
  dismissable?: boolean;
  glow?: boolean;
  animate?: boolean;
};

export function AiCard({ children, className, showFooter, dismissable, glow, animate }: AiContentProps) {
  const theme = useThemeValue();
  const [showCard, setShowCard] = useState(true);

  const close = () => {
    setShowCard(false);
  };

  if (!showCard) {
    return null;
  }

  const sparkleIconPath = `${assetBase()}/${assetPath()}/img/ai-things/${
    theme === 'dark' ? 'sparkle.svg' : 'sparkle-black.svg'
  }`;

  return (
    <div
      className={cx(className, styles.AiCard, styles.AiBorder, {
        [styles.AiGlow]: glow,
        [styles.AiAnimation]: animate,
      })}
    >
      <div className={styles.AiCardInner}>
        <div className={styles.AiCardContent}>
          <div>
            <LegacyIcon size="medium">
              <img src={sparkleIconPath} srcSet={sparkleIconPath} alt="sparkle" />
            </LegacyIcon>
          </div>
          <div className={styles.AiCardContentChildren}>{children}</div>
          {dismissable && (
            <div>
              <IconButton aria-label="close" icon="cancel" size="small" variant="minimal" onPress={close} />
            </div>
          )}
        </div>

        {showFooter && (
          <div className={styles.AiCardFooter}>
            <p>Powered by AI, content quality may vary.</p>
          </div>
        )}
      </div>
    </div>
  );
}
