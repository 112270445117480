import { useContext } from 'react';
import cx from 'clsx';

import { ColumnContext } from './Columns';

import styles from './Column.module.css';

export const sizes = {
  content: styles.content,
  '1/2': styles.half,
  '1/3': styles.third,
  '2/3': styles.twoThird,
};

export type ColumnProps = {
  size?: 'content' | '1/2' | '1/3' | '2/3';
  children: React.ReactNode;
};

export function Column({ size, children }: ColumnProps) {
  const { gap } = useContext(ColumnContext);

  return (
    <div
      className={
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        cx(styles.column, styles[`gap-${gap}`], {
          [styles.fluid]: size !== 'content',
          [sizes[size!]]: !!size,
        }) /* eslint-enable @typescript-eslint/no-non-null-assertion */
      }
    >
      {children}
    </div>
  );
}
