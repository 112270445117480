import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useIsInNewIA } from '@gonfalon/ia-migration';
import { ProgressBar } from '@launchpad-ui/components';
import { List } from 'immutable';
import { Drawer } from 'launchpad';

import { SimpleAppHeader } from 'components/AppHeader';
import AuditLogPaginationContainer from 'components/auditLog/AuditLogPaginationContainer';
import AuditLogToolbar from 'components/auditLog/AuditLogToolbar';
import ResourceHistoryListContainer from 'components/auditLog/ResourceHistoryListContainer/ResourceHistoryListContainer';
import Subhead from 'components/Subhead';
import { Module, ModuleContent } from 'components/ui/module';
import UpsellInlineBannerResourceHistory from 'components/upsells/upsellInlineBanners/UpsellInlineBannerResourceHistory';
import { AuditLogViewKind, Query } from 'utils/auditLogUtils';
import { PolicyStatement } from 'utils/policyUtils';

const AuditLogItemViewerContainer = lazy(
  async () => import(/* webpackChunkName: "AuditLogItemViewerContainer" */ './AuditLogItemViewerContainer'),
);

export type ResourceHistoryProps = {
  heading?: string;
  hideSearch?: boolean;
  onCloseEntryViewer: () => void;
  onSearch: (q: Query) => void;
  onWillUnmount: () => void;
  query: Query;
  searchPlaceholder?: string;
  showHeader?: boolean;
  auditLogHistoryLimit: number;
  auditLogAccountHistoryLimit: number;
  services?: List<string>;
  auditLogView: AuditLogViewKind;
};

/* eslint-disable import/no-default-export */
export default function ResourceHistory({
  heading,
  hideSearch,
  onCloseEntryViewer,
  onSearch,
  onWillUnmount,
  query,
  searchPlaceholder,
  showHeader,
  auditLogHistoryLimit,
  auditLogAccountHistoryLimit,
  services,
  auditLogView,
}: ResourceHistoryProps) {
  const isInIA = useIsInNewIA();

  useEffect(
    () => () => {
      onWillUnmount && onWillUnmount();
    },
    [],
  );

  const auditLogLimit =
    auditLogView === AuditLogViewKind.ACCOUNT_HISTORY ? auditLogAccountHistoryLimit : auditLogHistoryLimit;
  const handleUpdateAuditLogQuery = (updatedQuery: Query) => {
    onWillUnmount();
    onSearch(updatedQuery);
  };
  const handleFilterByPolicy = (policy: PolicyStatement) => {
    if (policy.get('actions').size === 1 && policy.get('actions').first() === '*') {
      const updatedQuery = query.set('policy', List([policy])).set('actions', undefined);
      handleUpdateAuditLogQuery(updatedQuery);
    } else {
      const updatedQuery = query.set('policy', List([policy])).set('actions', policy.get('actions').join(','));
      handleUpdateAuditLogQuery(updatedQuery);
    }
  };
  return (
    <>
      {showHeader && (
        <Subhead className="u-flex u-flex-middle u-flex-between">
          <h1>{heading || 'History'}</h1>
        </Subhead>
      )}
      {isInIA && <SimpleAppHeader isSeamless title="History" description="use this page to view history." />}

      <Module className="u-mb-l">
        <ModuleContent isSnug>
          <AuditLogToolbar
            auditLogLimit={auditLogLimit}
            hideSearch={hideSearch}
            onSearch={onSearch}
            query={query}
            searchPlaceholder={searchPlaceholder || 'Search history'}
            services={services}
            auditLogView={auditLogView}
            onFilterByPolicy={handleFilterByPolicy}
          />
        </ModuleContent>
      </Module>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ResourceHistoryListContainer query={query} />

      <Routes>
        <Route
          path=":entryId/details/*"
          element={
            <Drawer onCancel={onCloseEntryViewer} size="large">
              <Suspense fallback={<ProgressBar aria-label="Loading…" isIndeterminate />}>
                <AuditLogItemViewerContainer />
              </Suspense>
            </Drawer>
          }
        />
      </Routes>

      {query.isValid() && <AuditLogPaginationContainer query={query} />}
      {auditLogLimit > 0 && <UpsellInlineBannerResourceHistory auditLogLimit={auditLogLimit} />}
    </>
  );
}
