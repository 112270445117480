import { useEffect, useRef } from 'react';

export function useEffectOnClose(callback: () => unknown, isOpen: boolean) {
  const wasOpen = useRef(isOpen);
  useEffect(() => {
    if (!isOpen && wasOpen.current) {
      callback();
    }
    wasOpen.current = isOpen;
  }, [isOpen, callback]);
}
