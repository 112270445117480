import { Component, FocusEvent } from 'react';
import { components, SingleValueProps } from 'react-select';
import { CustomSelect, OptionProps, StylesObject } from '@gonfalon/launchpad-experimental';
import cx from 'clsx';
import { List } from 'immutable';

import { EnvironmentMarker } from 'components/EnvironmentMarker';
import { Environment } from 'utils/environmentUtils';
import { ProjectWithEnvironmentEntities } from 'utils/projectUtils';
import { makeFilter } from 'utils/stringUtils';

export type EnvironmentSelectProps = {
  selectedEnvironment?: Environment;
  placeholder?: string;
  environments?: List<Environment>;
  projects?: List<ProjectWithEnvironmentEntities>;
  onChange: (environmentId: string, option?: EnvironmentSelectOption) => void;
  onInputChange?: (value: string) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  className?: string;
  id?: string;
  name?: string;
  autoFocus?: boolean;
  isLoading?: boolean;
  styles?: StylesObject;
};

export type EnvironmentSelectOption = {
  value: string;
  label: string;
  environment: Environment;
  key: string;
  projectName?: string;
  projectKey?: string;
};

type ProjectHeading = {
  label: string;
  isHeading: true;
  disabled: true;
  name: string;
  key: string;
  projectName: string;
  projectKey: string;
};

/* eslint-disable import/no-default-export */
export default class EnvironmentSelect extends Component<EnvironmentSelectProps> {
  render() {
    const { selectedEnvironment, className, styles, ...props } = this.props;
    const options = this.getOptions();

    const SingleValue = (singleValueProps: SingleValueProps<EnvironmentSelectOption>) => (
      <components.SingleValue {...singleValueProps}>
        {this.renderEnvironmentOption(singleValueProps.data)}
      </components.SingleValue>
    );

    return (
      <CustomSelect
        ariaLabel="Select an environment"
        className="EnvironmentSelect"
        placeholder="Select an environment"
        {...props}
        value={
          selectedEnvironment &&
          options.filter((option) => 'value' in option && option.value === selectedEnvironment._id)
        }
        isOptionDisabled={(option: OptionProps) => !!option.disabled}
        options={options}
        onChange={this.handleChange}
        // @ts-expect-error this prop should be refactored to use "formatOptionLabel", with "getOptionLabel" returning a string version of the label
        getOptionLabel={this.renderOption}
        filterOption={this.filterOptions}
        customComponents={{ SingleValue }}
        onInputChange={this.handleInputChange}
        isLoading={this.props.isLoading}
        styles={styles}
      />
    );
  }

  getOptions = () => {
    const { environments, projects } = this.props;
    const createEnvironmentOptions = (envs: List<Environment>): EnvironmentSelectOption[] =>
      envs
        .map((environment) => ({
          value: environment._id,
          label: environment.name,
          environment,
          key: environment.key,
        }))
        .toArray();
    if (environments && !environments.isEmpty()) {
      return createEnvironmentOptions(environments);
    }
    const createEnvironmentOptionsFromProject = (
      project: ProjectWithEnvironmentEntities,
    ): Array<EnvironmentSelectOption | ProjectHeading> =>
      project.environments
        .map((environment) => ({
          value: environment._id,
          label: environment.name,
          environment,
          key: environment.key,
          projectName: project.name,
          projectKey: project.key,
        }))
        .toArray();
    if (projects) {
      return projects.reduce<Array<EnvironmentSelectOption | ProjectHeading>>(
        (options, project) => [
          ...options,
          {
            label: project.name,
            isHeading: true,
            disabled: true,
            // create filterable
            name: project.environments
              .map((e) => e.name)
              .toJS()
              .join(','),
            key: project.environments
              .map((e) => e.key)
              .toJS()
              .join(','),
            projectName: project.name,
            projectKey: project.key,
          },
          ...createEnvironmentOptionsFromProject(project),
        ],
        [],
      );
    }

    return [];
  };

  renderProjectHeading = (option: ProjectHeading) => (
    <span className="u-subtle u-fw-semibold u-fs-sm">{option.label}</span>
  );

  renderEnvironmentOption = (option: EnvironmentSelectOption, shouldNestEnvironments?: boolean) => {
    const { environment } = option;
    return (
      <span className={cx('u-flex', 'u-flex-row', 'u-flex-middle', 'u-va-tb', { 'u-ml-l': shouldNestEnvironments })}>
        <EnvironmentMarker env={environment} />
      </span>
    );
  };

  renderOption = (option: EnvironmentSelectOption | ProjectHeading) => {
    const shouldNestEnvironments = !!this.props.projects;
    if ('isHeading' in option) {
      return option.isHeading && this.renderProjectHeading(option);
    }
    return this.renderEnvironmentOption(option, shouldNestEnvironments);
  };

  handleChange = (option: EnvironmentSelectOption | null) => {
    option ? this.props.onChange(option.value, option) : this.props.onChange('');
  };

  handleInputChange = (value: string) => {
    this.props.onInputChange?.(value);
  };

  filterOptions = (option: OptionProps, input: string) => {
    const filter = makeFilter(input, 'name', 'key', 'projectName', 'projectKey');
    return filter(option.data);
  };
}
