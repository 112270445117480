import { Children } from 'react';
import cx from 'clsx';

import { Space } from '../theme';

import styles from './Stack.module.css';

export type StackProps = {
  gap?: Space;
  align?: 'left' | 'center' | 'right';
  children: React.ReactNode;
};

export function Stack({ gap = '0', align, children, ...props }: StackProps) {
  return (
    <div {...props} className={styles.stack}>
      {Children.map(
        children,
        (child, index) =>
          child && (
            <div
              key={index}
              className={
                /* eslint-disable @typescript-eslint/no-non-null-assertion */
                cx(styles.item, styles[`gap-${gap}`], {
                  [styles.flex]: !!align,
                  [styles[align!]]: !!align,
                }) /* eslint-enable @typescript-eslint/no-non-null-assertion */
              }
            >
              {child}
            </div>
          ),
      )}
    </div>
  );
}
