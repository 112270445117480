import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { toCreateExperiment, toExperiments } from '@gonfalon/navigator';
import { useProjectKey } from '@gonfalon/router';
import { LinkButton } from '@launchpad-ui/components';
import { FlairIcon, Icon } from '@launchpad-ui/icons';

import {
  ListEmptyState,
  ListEmptyStateActions,
  ListEmptyStateDescription,
  ListEmptyStateHeading,
} from 'components/ListEmptyState/ListEmptyState';
import { useProfile } from 'hooks/useProfile';

import styles from './ExperimentDetailsNotFound.module.css';
export function ExperimentDetailsNotFound() {
  const projKey = useProjectKey();
  const envKey = useSelectedEnvironmentKey();

  const { entity: profileEntity } = useProfile();

  return (
    <ListEmptyState>
      <div className={styles.flaskContainer}>
        <FlairIcon isRounded gradient="pinkToPurple">
          <Icon name="flask" size="medium" />
        </FlairIcon>
      </div>
      <ListEmptyStateHeading>Experiment not found for this environment</ListEmptyStateHeading>
      <ListEmptyStateDescription>
        <div className={styles.descriptionContainer}>
          Experiment does not exist in this environment because experiments are environment-specific
        </div>
      </ListEmptyStateDescription>
      <ListEmptyStateActions>
        <div className={styles.actionsContainer}>
          <LinkButton
            variant="default"
            href={toExperiments({ projectKey: projKey, environmentKey: envKey })}
            isDisabled={profileEntity && profileEntity.isReader()}
          >
            Back to experiments
          </LinkButton>
          <LinkButton variant="primary" href={toCreateExperiment({ projectKey: projKey, environmentKey: envKey })}>
            Create experiment
          </LinkButton>
        </div>
      </ListEmptyStateActions>
    </ListEmptyState>
  );
}
