import { useLayoutEffect, useRef, useState } from 'react';
import { SmartMarkdown } from '@gonfalon/smart-links';
import classNames from 'clsx';
import { Button } from 'launchpad';

import './styles.css';

type DescriptionProps = {
  className?: string;
  text: string;
  useShowMoreToggle?: boolean;
  renderTextBreaks?: boolean;
  onSmartMarkdownOpen?: () => void;
  onSmartMarkdownClose?: () => void;
};

export const Description = ({
  className,
  text,
  useShowMoreToggle,
  renderTextBreaks = true,
  onSmartMarkdownOpen,
  onSmartMarkdownClose,
}: DescriptionProps) => {
  const [isOverflowed, setIsOverflowed] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const classes = classNames(className, {
    'Description--single-line': !isExpanded,
    'Description--expanded': isExpanded,
    'Description--list-view': !useShowMoreToggle,
  });

  useLayoutEffect(() => {
    if (textRef.current) {
      const childParagraph = textRef.current.children[0] as HTMLElement;
      const scrollHeightExceedsOffsetHeight = textRef.current?.scrollHeight > textRef.current?.offsetHeight;
      /* Possible cases for overflowed descriptions include
          a. A long description that overflows the parent element horizontally
          b. A description whose first child doesn't overflow horizontally, but has additional children that are hidden initially
          c. A description that is just a single child containing a list with nested list items which overflow vertically
          d. A description with line breaks
      */

      setIsOverflowed(
        childParagraph?.scrollWidth > childParagraph?.offsetWidth ||
          textRef.current.children.length > 1 ||
          scrollHeightExceedsOffsetHeight ||
          (childParagraph?.children.length > 0 && scrollHeightExceedsOffsetHeight),
      );
    }
  });

  let mdText = text;

  if (!renderTextBreaks) {
    // Have to replace double line breaks and single line breaks
    // so they don't render as new paragraphs and <br> respectively.
    mdText = text.replaceAll('\n\n', ' ').replaceAll('\n', ' ');
  }

  const allowedTags = ['a', '#text', 'p', 'li', 'ol', 'ul', 'b', 'strong', 'i', 'em', 'br', 'code'];

  return (
    <>
      <div className={classes}>
        <SmartMarkdown
          source={mdText}
          allowedTags={allowedTags}
          className={classes}
          textRef={textRef}
          onOpen={onSmartMarkdownOpen}
          onClose={onSmartMarkdownClose}
        />
      </div>
      {useShowMoreToggle && (isOverflowed || isExpanded) && (
        <Button kind="link" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? 'Show less' : 'Show more'}
        </Button>
      )}
    </>
  );
};
