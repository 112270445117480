import { useCallback } from 'react';
import { Metric, useMetricsByKeys } from '@gonfalon/metrics';
import {
  useDeleteFlagMeasuredRolloutConfiguration,
  useFlagMeasuredRolloutConfiguration,
  useUpdateFlagMeasuredRolloutConfiguration,
} from '@gonfalon/rest-api';
import { useFlagKey, useProjectKey } from '@gonfalon/router';
import { SnackbarQueue, ToastQueue } from '@launchpad-ui/components';

export function useMeasuredRolloutMetrics(onSuccess?: () => void) {
  const { mutateAsync: putConfig } = useUpdateFlagMeasuredRolloutConfiguration({
    onSuccess: () => {
      ToastQueue.success('Configuration added');
      onSuccess?.();
    },
    onError() {
      SnackbarQueue.error({ description: 'Failed to create configuration.' });
    },
  });

  const { mutateAsync: deleteConfig } = useDeleteFlagMeasuredRolloutConfiguration({
    onSuccess: () => {
      ToastQueue.success('Configuration removed');
    },
    onError() {
      SnackbarQueue.error({ description: 'Failed to delete configuration.' });
    },
  });

  const projectKey = useProjectKey();
  const flagKey = useFlagKey();

  const { data: flagMeasuredRolloutConfiguration } = useFlagMeasuredRolloutConfiguration({
    projectKey,
    flagKey,
  });

  const metricKeys = flagMeasuredRolloutConfiguration?.metrics?.map((metric) => metric.key) ?? [];
  const { metrics, isPending, isFetching } = useMetricsByKeys(metricKeys);

  const handleRemove = useCallback(
    async () => deleteConfig({ projectKey, flagKey }),
    [deleteConfig, projectKey, flagKey],
  );

  const handleSave = useCallback(
    async (data: { metrics: Metric[] }) => {
      await putConfig({
        projectKey,
        flagKey,
        body: {
          metricKeys: data.metrics.map((metric) => metric.key) ?? [],
        },
      });
    },
    [putConfig, projectKey, flagKey],
  );

  return {
    metrics,
    isPending,
    isFetching,
    handleSave,
    handleRemove,
  };
}
