import { useState } from 'react';
import { Link } from '@launchpad-ui/components';
import { Box } from 'launchpad';

import DocumentationLink from 'components/DocumentationLink';

import { ContactUsModal } from './ContactUsModal';

import styles from './LearnMoreAndGetDemo.module.css';

type LearnMoreAndGetDemoProps = {
  docsLink: string;
  componentName: string;
  justifyContent?: 'flex-start' | 'center';
};

export function LearnMoreAndGetDemo({ docsLink, componentName, justifyContent = 'center' }: LearnMoreAndGetDemoProps) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Box display="flex" gap="$600" justifyContent={justifyContent}>
        <DocumentationLink
          className={styles.link}
          href={docsLink}
          text="Learn more in docs"
          component={componentName}
          showIcon={false}
        />
        <Link onPress={() => setShowModal(true)}>Get a demo</Link>
      </Box>
      <ContactUsModal isOpen={showModal} onCancel={() => setShowModal(false)} />
    </>
  );
}
