import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { measuredRolloutsMetricResults, useMeasuredRollout } from '@gonfalon/rest-api';
import { useFlagKey, useProjectKey } from '@gonfalon/router';
import { useQueries } from '@tanstack/react-query';
import nullthrows from 'nullthrows';

import type { MeasuredRolloutResultsWithMetric } from './types';
import { useLatestMeasuredRollout } from './useLatestMeasuredRollout';

export function useMeasuredRolloutResults({ measuredRolloutId }: { measuredRolloutId?: string } = {}) {
  const projectKey = useProjectKey();
  const flagKey = useFlagKey();
  const environmentKey = useSelectedEnvironmentKey();

  const { data: latestMeasuredRollout, isPending: isLatestMeasuredRolloutPending } = useLatestMeasuredRollout({
    enabled: !measuredRolloutId,
  });
  const { data: exactMeasuredRollout, isPending: isExactMeasuredRolloutPending } = useMeasuredRollout(
    { projectKey, flagKey, environmentKey, measuredRolloutId: measuredRolloutId ?? '' },
    { enabled: !!measuredRolloutId },
  );

  const measuredRollout = measuredRolloutId ? exactMeasuredRollout : latestMeasuredRollout;

  const resultQueries = useQueries({
    queries:
      measuredRollout && measuredRollout.design.metrics
        ? measuredRollout.design.metrics.map((metric) => ({
            ...measuredRolloutsMetricResults({
              projectKey,
              flagKey,
              environmentKey,
              measuredRolloutId: measuredRollout.design.id,
              metricKey: metric.key,
            }),
            refetchInterval: 10000, // refetch every 10 seconds
          }))
        : [],
  });

  if (
    (!measuredRolloutId && isLatestMeasuredRolloutPending) ||
    (!!measuredRolloutId && isExactMeasuredRolloutPending) ||
    resultQueries.some((query) => query.isPending)
  ) {
    return { isPending: true };
  }

  const data: MeasuredRolloutResultsWithMetric[] = resultQueries.map((query, index) => ({
    metric: nullthrows(measuredRollout?.design.metrics?.[index], 'Metric does not exist at this index'),
    results: nullthrows(query.data, 'Measured rollout results do not exist'),
  }));

  return { isPending: false, data };
}
