import { useNavigate } from 'react-router-dom';
import { OrderedMap, Set } from 'immutable';

import { useDispatch } from 'hooks/useDispatch';
import { GetState, GlobalDispatch, GlobalState } from 'reducers';
import { accessTokensListRequestSelector } from 'reducers/accessTokens';
import {
  createAccessToken as accessTokenAPICreateAccessToken,
  deleteAccessToken as accessTokenAPIDeleteAccessToken,
  getAllAccessTokens as accessTokenAPIGetAllAccessTokens,
  resetAccessToken as accessTokenAPIResetAccessToken,
  updateAccessToken as accessTokenAPIUpdateAccessToken,
} from 'sources/AccessTokenAPI';
import { AccessToken, AccessTokenForm } from 'utils/accessTokenUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import { GenerateActionType } from 'utils/reduxUtils';

export type EditAccessTokenFieldTypes =
  | 'name'
  | 'role'
  | 'customRoleIds'
  | '_links'
  | 'inlineRole'
  | 'defaultApiVersion'
  | 'serviceToken';

export const editAccessToken = (field: EditAccessTokenFieldTypes, value: string) =>
  ({
    type: 'accessTokens/EDIT_ACCESS_TOKEN',
    field,
    value,
  }) as const;

export const selectAccessTokenRole = (role: string) =>
  ({
    type: 'accessTokens/SELECT_ACCESS_TOKEN_ROLE',
    role,
  }) as const;

export const setAccessTokenCustomRoles = (roleIds: Set<string>) =>
  ({
    type: 'accessTokens/SET_ACCESS_TOKEN_CUSTOM_ROLES',
    roleIds,
  }) as const;

export const createAccessTokenStart = (token: AccessTokenForm) =>
  ({
    type: 'accessTokens/CREATE_ACCESS_TOKEN',
    token,
  }) as const;

export const createAccessTokenDone = (token: AccessToken) =>
  ({
    type: 'accessTokens/CREATE_ACCESS_TOKEN_DONE',
    token,
  }) as const;

export const createAccessTokenFailed = (token: AccessTokenForm, error: ImmutableServerError) =>
  ({
    type: 'accessTokens/CREATE_ACCESS_TOKEN_FAILED',
    token,
    error,
  }) as const;

export const useCreateAccessToken = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pathName = '/settings/authorization';

  return async (token: AccessTokenForm) => {
    dispatch(createAccessTokenStart(token));
    return accessTokenAPICreateAccessToken(token).then(
      (created) => {
        navigate({ pathname: pathName });
        dispatch(createAccessTokenDone(created));
      },
      (error) => {
        if (error.get('status') === 403) {
          navigate({ pathname: pathName });
        }

        dispatch(createAccessTokenFailed(token, error));
      },
    );
  };
};

export const fetchAccessTokensStart = () => ({ type: 'accessTokens/REQUEST_ACCESS_TOKENS' }) as const;

export const fetchAccessTokensDone = (response: OrderedMap<string, AccessToken>) =>
  ({ type: 'accessTokens/REQUEST_ACCESS_TOKENS_DONE', response }) as const;

export const fetchAccessTokensFailed = (error: ImmutableServerError) =>
  ({ type: 'accessTokens/REQUEST_ACCESS_TOKENS_FAILED', error }) as const;

const fetchAccessTokens = () => async (dispatch: GlobalDispatch) => {
  dispatch(fetchAccessTokensStart());
  return accessTokenAPIGetAllAccessTokens()
    .then((response) => dispatch(fetchAccessTokensDone(response)))
    .catch((error) => dispatch(fetchAccessTokensFailed(error)));
};

const shouldFetchAccessTokens = (state: GlobalState) => accessTokensListRequestSelector(state).shouldFetch();

const fetchAccessTokensIfNeeded = () => async (dispatch: GlobalDispatch, getState: GetState) => {
  if (shouldFetchAccessTokens(getState())) {
    return dispatch(fetchAccessTokens());
  }
};

export { fetchAccessTokensIfNeeded as fetchAccessTokens };

export const fetchAccessTokensFromUrl = (url: string) => () => async (dispatch: GlobalDispatch, getState: GetState) => {
  if (shouldFetchAccessTokens(getState())) {
    return accessTokenAPIGetAllAccessTokens(url)
      .then((response) => dispatch(fetchAccessTokensDone(response)))
      .catch((error) => dispatch(fetchAccessTokensFailed(error)));
  }
  dispatch(fetchAccessTokensStart());
};

export const updateAccessTokenStart = (token: AccessTokenForm) =>
  ({
    type: 'accessTokens/UPDATE_ACCESS_TOKEN',
    token,
  }) as const;

export const updateAccessTokenDone = (token: AccessToken) =>
  ({
    type: 'accessTokens/UPDATE_ACCESS_TOKEN_DONE',
    token,
  }) as const;

export const updateAccessTokenFailed = (token: AccessTokenForm, error: ImmutableServerError) =>
  ({
    type: 'accessTokens/UPDATE_ACCESS_TOKEN_FAILED',
    token,
    error,
  }) as const;

export const useUpdateAccessToken = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pathName = '/settings/authorization';

  return async (oldToken: AccessTokenForm, newToken: AccessTokenForm) => {
    dispatch(updateAccessTokenStart(newToken));
    return accessTokenAPIUpdateAccessToken(oldToken, newToken).then(
      (updated) => {
        navigate({ pathname: pathName });
        dispatch(updateAccessTokenDone(updated));
      },
      (error) => {
        if (error.get('status') === 403) {
          navigate({ pathname: pathName });
        }

        dispatch(updateAccessTokenFailed(newToken, error));
      },
    );
  };
};

export const deleteAccessTokenStart = (token: AccessToken) =>
  ({
    type: 'accessTokens/DELETE_ACCESS_TOKEN',
    token,
  }) as const;

export const deleteAccessTokenDone = (token: AccessToken) =>
  ({
    type: 'accessTokens/DELETE_ACCESS_TOKEN_DONE',
    token,
  }) as const;

export const deleteAccessTokenFailed = (token: AccessToken, error: ImmutableServerError) =>
  ({
    type: 'accessTokens/DELETE_ACCESS_TOKEN_FAILED',
    token,
    error,
  }) as const;

export const deleteAccessToken = (token: AccessToken) => async (dispatch: GlobalDispatch) => {
  dispatch(deleteAccessTokenStart(token));
  return accessTokenAPIDeleteAccessToken(token).then(
    () => dispatch(deleteAccessTokenDone(token)),
    (error) => dispatch(deleteAccessTokenFailed(token, error)),
  );
};

export const resetAccessTokenStart = (token: AccessToken, expiry?: number) =>
  ({
    type: 'accessTokens/RESET_ACCESS_TOKEN',
    token,
    expiry,
  }) as const;

export const resetAccessTokenDone = (token: AccessToken) =>
  ({
    type: 'accessTokens/RESET_ACCESS_TOKEN_DONE',
    token,
  }) as const;

export const resetAccessTokenFailed = (token: AccessToken, error: ImmutableServerError) =>
  ({
    type: 'accessTokens/RESET_ACCESS_TOKEN_FAILED',
    token,
    error,
  }) as const;

export const useResetAccessToken = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pathName = '/settings/authorization';

  return async (token: AccessToken, expiry?: number) => {
    dispatch(resetAccessTokenStart(token));
    return accessTokenAPIResetAccessToken(token, expiry).then(
      (updatedToken) => {
        navigate({ pathname: pathName });
        dispatch(resetAccessTokenDone(updatedToken));
      },
      (error) => {
        dispatch(resetAccessTokenFailed(token, error));
      },
    );
  };
};

const AccessTokenActionCreators = {
  editAccessToken,
  selectAccessTokenRole,
  setAccessTokenCustomRoles,
  createAccessTokenStart,
  createAccessTokenDone,
  createAccessTokenFailed,
  fetchAccessTokensStart,
  fetchAccessTokensDone,
  fetchAccessTokensFailed,
  updateAccessTokenStart,
  updateAccessTokenDone,
  updateAccessTokenFailed,
  deleteAccessTokenStart,
  deleteAccessTokenDone,
  deleteAccessTokenFailed,
  resetAccessTokenStart,
  resetAccessTokenDone,
  resetAccessTokenFailed,
};

export type AccessTokenAction = GenerateActionType<typeof AccessTokenActionCreators>;
