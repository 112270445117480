import { useContext } from 'react';
import { OverlayTriggerStateContext } from 'react-aria-components';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useProjectContext } from '@gonfalon/context';
import { parseUIContextsViewFromBrowser, serializeUIContextsViewForAPI } from '@gonfalon/context-kinds';
import { parseUIFlagListViewFromBrowser, serializeUIFlagListViewForAPI } from '@gonfalon/flag-filters';
import { useParam } from '@gonfalon/router';
import { parseUISegmentsViewFromBrowser, serializeUISegmentsViewForAPI } from '@gonfalon/segments';
import { convertToKey } from '@gonfalon/strings';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  ButtonGroup,
  FieldError,
  FieldGroup,
  Form,
  Input,
  Label,
  RadioGroup,
  RadioIconButton,
  TextField,
} from '@launchpad-ui/components';
import { IconName } from '@launchpad-ui/icons';
import { z } from 'zod';

import { Shortcut } from '../../types';
import { useShortcuts } from '../../useShortcuts';

import styles from './ShortcutsForm.module.css';

export const ShortcutsIcons: IconName[] = [
  'bolt',
  'toggle-bolt',
  'package-outline-bolt',
  'chart-venn-bolt',
  'flask-bolt',
  'ruler-bolt',
  'fingerprint-bolt',
  'flag-outline',
  'toggle-on',
  'flask',
  'fingerprint',
  'ruler',
  'arrow-connect',
  'chart-venn',
  'book-outline',
  'map',
  'package-outline',
  'shield-key',
  'shield-heart-outline',
  'approval-pending',
  'approval-approved',
  'approval-denied',
  'approval-applied',
  'animal-cat',
  'animal-turtle',
  'osmo',
];

export type ShortcutsForm = {
  name: string;
  icon: string;
};

export function ShortcutsForm({ shortcutType }: { shortcutType: Shortcut['type'] }) {
  const { context } = useProjectContext();
  const { createShortcut, shortcuts } = useShortcuts({ projectKey: context.projectKey });
  const [searchParams] = useSearchParams();
  const flagKey = useParam('flagKey', { optional: true });
  const state = useContext(OverlayTriggerStateContext);

  const isUniqueKey = (name: string) =>
    shortcuts.map((shortcut: Shortcut) => shortcut.key).includes(convertToKey(name)) ? false : true;

  const shortcutSchema = z.object({
    name: z
      .string()
      .nonempty({ message: 'Shortcut name is required' })
      .max(256, 'Shortcut name must be less then 256 characters')
      .refine(isUniqueKey, 'Shortcut name must be unique'),
    icon: z.string().nonempty(),
  });

  const {
    formState: { isValid },
    handleSubmit,
    control,
  } = useForm<ShortcutsForm>({
    resolver: zodResolver(shortcutSchema),
    mode: 'onChange',
    defaultValues: {
      icon: ShortcutsIcons[0],
    },
  });

  const onSubmit = ({ name, icon }: ShortcutsForm) => {
    if (!isValid) {
      return;
    }

    let filters;
    let entityKey;

    switch (shortcutType) {
      case 'flag':
        entityKey = flagKey;
        break;
      case 'flags':
        filters = serializeUIFlagListViewForAPI(parseUIFlagListViewFromBrowser(searchParams));
        break;
      case 'contexts':
        filters = serializeUIContextsViewForAPI(parseUIContextsViewFromBrowser(searchParams));
        break;
      case 'segments':
        filters = serializeUISegmentsViewForAPI(parseUISegmentsViewFromBrowser(searchParams));
        break;
      default:
        break;
    }

    const nextShortcut = {
      name,
      key: convertToKey(name),
      icon,
      type: shortcutType,
      context,
      filters,
      visibility: 'me', // TODO: implement visibility
      entityKey,
    } as Shortcut;

    createShortcut(nextShortcut);

    state.close();
  };

  return (
    <Form id="shortcut" className={styles.body} onSubmit={handleSubmit(onSubmit)}>
      <FieldGroup className={styles.form}>
        <Controller
          control={control}
          name="name"
          render={({ field: { name, value, onChange, onBlur, ref }, fieldState: { invalid, error } }) => (
            <TextField
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              validationBehavior="aria"
              isInvalid={invalid}
            >
              <Label className={styles.label}>Name</Label>
              <Input data-1p-ignore autoFocus autoComplete="off" ref={ref} placeholder="e.g my favorite environments" />
              <FieldError>{error?.message}</FieldError>
            </TextField>
          )}
        />
        <Controller
          control={control}
          name="icon"
          render={({ field: { name, value, onChange, onBlur }, fieldState: { invalid } }) => (
            <RadioGroup
              name={name}
              value={value}
              onBlur={onBlur}
              validationBehavior="aria"
              isInvalid={invalid}
              onChange={onChange}
            >
              <Label>Icon</Label>
              <ButtonGroup className={styles.iconGroup} role="presentation" spacing="basic">
                {ShortcutsIcons.map((icon) => (
                  <RadioIconButton icon={icon} size="medium" key={icon} value={icon} aria-label={icon} />
                ))}
              </ButtonGroup>
            </RadioGroup>
          )}
        />
      </FieldGroup>
    </Form>
  );
}
