// eslint-disable-next-line no-restricted-imports
import { useState } from 'react';
import { AriaOnChangeProps, CustomSelect, OptionProps, StylesObject } from '@gonfalon/launchpad-experimental';
import { Label } from 'launchpad';
import { v4 } from 'uuid';

import { useApplicationOptions } from 'hooks/useApplicationOptions';

export type SelectApplicationProps = {
  id?: string;
  name?: string;
  value?: string;
  getOptionLabel?(option?: OptionProps): string;
  formatOptionLabel?(option?: OptionProps): string | JSX.Element;
  disabled?: boolean;
  ariaLabel?: string;
  onChange?(selectedOption: OptionProps & { _id: string }): void;
  styles?: StylesObject;
  placeholder?: string;
  hideLabelForSelectedOption?: boolean;
  customAriaLiveMessageOnChange?(props: AriaOnChangeProps): string;
};

const SelectApplication = ({
  id = v4(),
  name,
  value: selectedAppKey,
  disabled,
  styles,
  onChange,
  getOptionLabel,
  formatOptionLabel,
  ariaLabel,
  placeholder = 'Select application',
  hideLabelForSelectedOption,
  customAriaLiveMessageOnChange,
}: SelectApplicationProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const onInputChange = (input: string) => setSearchTerm(input);

  const { options, isLoading } = useApplicationOptions({ searchTerm });

  const getSelectedOption = () => {
    if (!selectedAppKey) {
      return undefined;
    }
    let option = options.find((o) => o.value === selectedAppKey);
    if (!option && selectedAppKey) {
      option = {
        value: selectedAppKey,
        label: '',
        name: '',
      };
    }

    if (hideLabelForSelectedOption) {
      return { ...option, label: null };
    }

    return option;
  };

  return (
    <>
      <Label htmlFor={id}>{ariaLabel}</Label>
      <CustomSelect
        id={id}
        name={name}
        isClearable
        isDisabled={disabled}
        isLoading={isLoading}
        placeholder={placeholder}
        // TODO this prop should be refactored to use "formatOptionLabel", with "getOptionLabel" returning a string version of the label
        getOptionLabel={getOptionLabel}
        formatOptionLabel={formatOptionLabel}
        options={options}
        value={getSelectedOption()}
        onChange={onChange}
        onInputChange={onInputChange}
        isSearchable
        styles={styles}
        aria-label={ariaLabel}
        customAriaLiveMessageOnChange={customAriaLiveMessageOnChange}
      />
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default SelectApplication;
