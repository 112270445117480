import { forwardRef, SelectHTMLAttributes } from 'react';
import { SelectField } from 'launchpad';

import { SuccessCriteria } from 'components/experimentation/common/types';

import { FormFieldWithTooltip } from './FormFieldWithTooltip';

type Props = SelectHTMLAttributes<HTMLSelectElement> & {
  errorMessage?: string;
  isSaving?: boolean;
};
export const MetricSuccessCriteria = forwardRef<HTMLSelectElement, Props>(
  ({ errorMessage, isSaving, ...rest }: Props, ref) => (
    <FormFieldWithTooltip
      name="successCriteria"
      htmlFor="successCriteria"
      label="Success criteria"
      tooltip="The baseline variation is the control variation in experiments"
      errorMessage={errorMessage}
      isRequired={false}
    >
      <SelectField {...rest} disabled={isSaving} ref={ref}>
        <option value={SuccessCriteria.HIGHER_THAN_BASELINE}>Higher than baseline</option>
        <option value={SuccessCriteria.LOWER_THAN_BASELINE}>Lower than baseline</option>
      </SelectField>
    </FormFieldWithTooltip>
  ),
);
