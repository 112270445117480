import { ErrorBoundary } from 'react-error-boundary';
import { isRESTAPIError } from '@gonfalon/rest-api';
import { trackComponentError } from '@gonfalon/telemetry';

import { EnvironmentResourceV2 } from './EnvironmentResourceV2';
import { MaybeLink } from './MaybeLink';
enum resourceKinds {
  PROJECT = 'project',
  ENVIRONMENT = 'environment',
  FLAG = 'flag',
  EXPERIMENT = 'experiment',
}

export function Resource({ name, id, kind, siteLink }: { name: string; kind: string; siteLink?: string; id: string }) {
  if (kind === resourceKinds.ENVIRONMENT) {
    return (
      <ErrorBoundary
        onError={(error, info) => {
          if (isRESTAPIError(error) && error.status !== 404) {
            trackComponentError(error, info);
          }
        }}
        fallbackRender={() => null}
      >
        <EnvironmentResourceV2 id={id} resourceName={name} />
      </ErrorBoundary>
    );
  }

  return <MaybeLink content={name} url={siteLink} />;
}
