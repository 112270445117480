// Wrapper around Modal that shows a confirmation dialog if the user has
// unsaved changes and tries to close the modal or navigate away from the page.
import { Modal } from 'launchpad';

import NavigationPromptModal from 'components/NavigationPromptModal';
import NavigationPrompt from 'routers/NavigationPrompt';

type Props = Parameters<typeof Modal>[0] & {
  shouldBlock: boolean;
  showPrompt: boolean;
  onCancelPrompt: () => void;
  onConfirmPrompt: () => void;
};

export function ModalWithCancelPrompt({
  shouldBlock,
  showPrompt,
  onCancelPrompt,
  onConfirmPrompt,
  children,
  ...rest
}: Props) {
  return (
    <Modal {...rest}>
      {children}
      <NavigationPrompt shouldBlock={shouldBlock} />
      {showPrompt ? (
        <NavigationPromptModal
          onCancelNavigation={onCancelPrompt}
          onConfirmNavigation={onConfirmPrompt}
          body="If you close this form, you will lose any unsaved changes."
          confirmText="Discard changes"
        />
      ) : null}
    </Modal>
  );
}
