import { Suspense, useState } from 'react';
import { useProjectContext } from '@gonfalon/context';
import { enableChangeHistoryDetails } from '@gonfalon/dogfood-flags';
import { CopyToClipboard, Picker, PickerItem } from '@gonfalon/launchpad-experimental';
import { ResourceSpecifier } from '@gonfalon/resource-specifiers';
import { Button, Dialog, Heading, IconButton } from '@launchpad-ui/components';
import { Box, Column, Columns, Stack } from '@launchpad-ui/core';
import { Icon } from '@launchpad-ui/icons';
import invariant from 'tiny-invariant';

import { AuditLog } from '../internal/AuditLog/AuditLog';
import { ActivityDateFilters } from '../internal/DateFilter/ActivityDateFilter';
import {
  ActivityResourceFiltersOptions,
  EnvironmentResourceFilter,
  ExperimentsResourceFilter,
  FlagResourceFilter,
  HoldoutsResourceFilter,
  MemberResourceFilter,
  ProjectResourceFilter,
  ProjectScopedResourceFilter,
  ResourceKindPicker,
  SegmentResourceFilter,
  TeamResourceFilter,
  WildCardResourceFilter,
} from '../internal/ResourceFilters/ActivityResourceFilters';
import { Search } from '../internal/Search';
import { Skeleton } from '../internal/Skeleton';
import { useChangeHistory } from '../useChangeHistory';

import {
  anyAction,
  anyResource,
  defaultContextKindPolicyList,
  defaultEnviromentPolicy,
  defaultExperimentPolicyList,
  defaultFlagAuditLogPolicyList,
  defaultHoldoutPolicyList,
  defaultLayerPolicyList,
  defaultMemberAuditLogPolicyList,
  defaultMetricPolicy,
  defaultProjectAuditLogPolicyList,
  defaultReleasePipelinePolicy,
  defaultSegmentAuditLogPolicyList,
  defaultTeamsAuditLogPolicyList,
  getEnvironments,
  getFirstResource,
} from './ChangeHistoryUtils';

import styles from './ChangeHistory.module.css';

export type Expansion = 'all' | Set<string> | undefined;

export function ChangeHistory() {
  const projectContext = useProjectContext({ optional: true });

  const [expansion, setExpansion] = useState<Expansion>();
  const handleExpandItem = (id: string) => {
    setExpansion((prev) => {
      if (prev === 'all') {
        return undefined;
      }
      if (prev instanceof Set) {
        return prev.has(id) ? new Set([...prev].filter((i) => i !== id)) : new Set([...prev, id]);
      }
      return new Set([id]);
    });
  };

  const {
    spec,
    filters,
    addResourceFilter,
    deleteResourceFilter,
    updateResourceFilter,
    resetResourceFilter,
    updateTextFilter,
    updateDateFilter,
  } = useChangeHistory();

  invariant(spec, 'Activity must have a spec defined');

  return (
    <Dialog className={styles.dialog}>
      {({ close }) => (
        <>
          <div slot="header">
            <Heading slot="title">Change history</Heading>
            <IconButton aria-label="close" icon="cancel" size="small" variant="minimal" onPress={close} />
          </div>

          <Box overflow="scroll" gap="3">
            <Stack slot="body" gap="3">
              <Stack gap="2" className={styles.filters}>
                <Box display="flex" justifyContent="space-between">
                  <h2>Filter by resource</h2>
                  <div>
                    <CopyToClipboard text={JSON.stringify(spec)} tooltip="Copy policy statements">
                      <IconButton aria-label="Copy policy statements" variant="minimal" icon="clipboard-edit" />
                    </CopyToClipboard>
                    <CopyToClipboard text={window.location.href} tooltip="Copy activity link">
                      <IconButton aria-label="Copy history link" variant="minimal" icon="link" />
                    </CopyToClipboard>
                  </div>
                </Box>
                {spec.map((s, i) => {
                  const resource = getFirstResource(s);
                  const resourceType = resource.val.type;
                  const resourceActions = s.actions;
                  return (
                    <Box display="flex" key={i} alignItems="normal" justifyContent="space-between">
                      <Box display="flex" justifyContent="space-between">
                        <Columns>
                          <Column size="content">
                            <Box marginRight="$300">
                              <ResourceKindPicker
                                value={resourceType}
                                onSelectionChange={(selection) => {
                                  // TODO: update to typeguard
                                  resetResourceFilter(i, String(selection) as ResourceSpecifier['type']);
                                }}
                              />
                            </Box>
                          </Column>
                          {resourceType === 'flag' && (
                            <FlagResourceFilter
                              environments={getEnvironments(s)}
                              project={resource.val.environment.project.name ?? projectContext?.project?.key}
                              flag={resource.val.name}
                              actions={resourceActions}
                              onChange={({ project, environments, flag, actions }) =>
                                updateResourceFilter(
                                  i,
                                  defaultFlagAuditLogPolicyList(project, environments, flag ?? anyResource, actions),
                                )
                              }
                            />
                          )}
                          {resourceType === 'env' && (
                            <EnvironmentResourceFilter
                              environments={[resource.val.name]}
                              project={resource.val.project.name ?? projectContext?.project?.key}
                              actions={resourceActions}
                              onChange={({ project, environments, actions }) =>
                                updateResourceFilter(i, defaultEnviromentPolicy(project, environments[0], actions))
                              }
                            />
                          )}
                          {resourceType === 'member' && (
                            <MemberResourceFilter
                              member={resource.val.name}
                              actions={resourceActions}
                              onChange={({ member, actions }) =>
                                updateResourceFilter(i, defaultMemberAuditLogPolicyList(member, actions))
                              }
                            />
                          )}
                          {resourceType === 'holdout' && (
                            <HoldoutsResourceFilter
                              environments={[resource.val.environment.name]}
                              project={resource.val.environment.project.name ?? projectContext?.project?.key}
                              actions={resourceActions}
                              holdout={resource.val.name}
                              onChange={({ project, environments, actions, holdoutKey }) =>
                                updateResourceFilter(
                                  i,
                                  defaultHoldoutPolicyList(project, environments, holdoutKey, actions),
                                )
                              }
                            />
                          )}
                          {resourceType === 'experiment' && (
                            <ExperimentsResourceFilter
                              environments={[resource.val.environment.name]}
                              project={resource.val.environment.project.name ?? projectContext?.project?.key}
                              actions={resourceActions}
                              onChange={({ project, environments, actions }) =>
                                updateResourceFilter(i, defaultExperimentPolicyList(project, environments, actions))
                              }
                            />
                          )}
                          {resourceType === 'team' && (
                            <TeamResourceFilter
                              team={resource.val.name}
                              actions={resourceActions}
                              onChange={({ team, actions }) =>
                                updateResourceFilter(i, defaultTeamsAuditLogPolicyList(team, actions))
                              }
                            />
                          )}
                          {resourceType === 'segment' && (
                            <SegmentResourceFilter
                              segment={resource.val.name}
                              project={resource.val.environment.project.name ?? projectContext?.project?.key}
                              environments={[resource.val.environment.name]}
                              actions={resourceActions}
                              onChange={({ project, environments, segment, actions }) =>
                                updateResourceFilter(
                                  i,
                                  defaultSegmentAuditLogPolicyList(project, environments, segment, actions),
                                )
                              }
                            />
                          )}
                          {resourceType === 'proj' && (
                            <ProjectResourceFilter
                              project={resource.val.name}
                              actions={resourceActions}
                              onChange={({ project, actions }) =>
                                updateResourceFilter(i, defaultProjectAuditLogPolicyList(project, actions))
                              }
                            />
                          )}
                          {Boolean(
                            resourceType === 'release-pipeline' ||
                              resourceType === 'context-kind' ||
                              resourceType === 'layer' ||
                              resourceType === 'metric',
                          ) && (
                            <ProjectScopedResourceFilter
                              resource={resourceType}
                              // @ts-expect-error TODO: update to typeguard
                              project={resource.val.project.name ?? projectContext?.project?.key}
                              onChange={({ project }) =>
                                updateResourceFilter(
                                  i,
                                  resourceType === 'release-pipeline'
                                    ? defaultReleasePipelinePolicy(project, anyAction)
                                    : resourceType === 'context-kind'
                                      ? defaultContextKindPolicyList(project, anyAction)
                                      : resourceType === 'layer'
                                        ? defaultLayerPolicyList(project, anyAction)
                                        : resourceType === 'metric'
                                          ? defaultMetricPolicy(project, anyAction)
                                          : defaultMetricPolicy(project, anyAction),
                                )
                              }
                            />
                          )}

                          {[
                            'acct',
                            'template',
                            'payload-filter',
                            'metric-group',
                            'integration',
                            'webhook',
                            'service-token',
                            'relay-proxy-config',
                            'application',
                            'role',
                            'code-reference-repository',
                          ].includes(resourceType) && <WildCardResourceFilter resource={resourceType} />}
                        </Columns>

                        {spec.length > 1 && (
                          <Box marginRight="$300" alignItems="center" justifyContent="center">
                            <IconButton
                              aria-label="Delete resource filter"
                              variant="minimal"
                              icon="delete"
                              onPress={() => deleteResourceFilter(i)}
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  );
                })}
                <Box display="flex">
                  <Picker
                    iconName="add"
                    placeholder="Add resource"
                    aria-label="Add resource"
                    items={ActivityResourceFiltersOptions}
                    // TODO: update to typeguard
                    onSelectionChange={(key) => addResourceFilter(key as ResourceSpecifier['type'])}
                  >
                    {(item) => (
                      <PickerItem id={item.value} textValue={item.name}>
                        {item.name}
                      </PickerItem>
                    )}
                  </Picker>
                </Box>
              </Stack>

              <div className={styles.controls}>
                <Search defaultValue={filters.q} onChange={updateTextFilter} />
                <div className={styles.internal}>
                  {enableChangeHistoryDetails() && (
                    <Button onPress={() => setExpansion((prev) => (prev === 'all' ? undefined : 'all'))} size="medium">
                      <Icon name="arrows-maximize" size="small" /> {expansion === 'all' ? 'Collapse' : 'Expand'} all
                      details
                    </Button>
                  )}
                  <ActivityDateFilters
                    before={filters.before}
                    after={filters.after}
                    onFilterChange={updateDateFilter}
                  />
                </div>
              </div>
            </Stack>

            <Suspense fallback={<Skeleton />}>
              <AuditLog
                spec={spec}
                after={filters?.after}
                before={filters?.before}
                q={filters?.q}
                sort={filters.sort}
                expansion={expansion}
                expandItem={(id) => handleExpandItem(id)}
              />
            </Suspense>
          </Box>
        </>
      )}
    </Dialog>
  );
}
