import UpsellInlineBanner from '../UpsellInlineBanner';

type UpsellInlineBannerAuditLogProps = {
  auditLogLimit: number;
};

const UpsellInlineBannerAuditLog = ({ auditLogLimit }: UpsellInlineBannerAuditLogProps) => (
  <UpsellInlineBanner
    featureKind="auditLog"
    className="u-mr-m u-mw-fitcontent"
    title="Upgrade to see the full history"
    description={`Your plan is limited to ${auditLogLimit} days.`}
    componentForAnalyticTracking="Audit log"
  />
);

/* eslint-disable import/no-default-export */
export default UpsellInlineBannerAuditLog;
