import { ProjectContext } from '@gonfalon/context';
import { UIContextsView } from '@gonfalon/context-kinds';
import { UIFlagListView } from '@gonfalon/flag-filters';
import { AppRoute } from '@gonfalon/router';
import { UISegmentsListView } from '@gonfalon/segments';

interface ShortcutBase {
  name: string;
  icon: string;
  key: string;
  visibility: ShortcutVisibility;
  context: ProjectContext;
}
interface FlagListShortcut extends ShortcutBase {
  type: 'flags';
  filters?: UIFlagListView;
}
interface ContextListShortcut extends ShortcutBase {
  type: 'contexts';
  filters?: UIContextsView;
}
interface FlagShortcut extends ShortcutBase {
  type: 'flag';
  entityKey: string;
  filters: undefined;
}
interface SegmentsShortcut extends ShortcutBase {
  type: 'segments';
  filters?: UISegmentsListView;
}

export type Shortcut = FlagListShortcut | ContextListShortcut | FlagShortcut | SegmentsShortcut;

export type availableShortcuts = Extract<
  AppRoute,
  | '/projects/:projectKey/flags'
  | '/projects/:projectKey/contexts'
  | '/projects/:projectKey/flags/:flagKey/targeting'
  | '/projects/:projectKey/segments'
>;

export const availableShortcuts: {
  [k in availableShortcuts]: Shortcut['type'];
} = {
  '/projects/:projectKey/flags': 'flags',
  '/projects/:projectKey/contexts': 'contexts',
  '/projects/:projectKey/flags/:flagKey/targeting': 'flag',
  '/projects/:projectKey/segments': 'segments',
};

export type ShortcutVisibility = 'me';
