import { lazy, Suspense } from 'react';

import type { LiveAvatarsProps } from './LiveAvatars';

const LazyLiveAvatars = lazy(async () =>
  import(/* webpackChunkName: "LiveAvatars" */ './LiveAvatars').then((module) => ({
    default: module.LiveAvatarsContainer,
  })),
);

export function LiveAvatars(props: LiveAvatarsProps) {
  return (
    <Suspense fallback={null}>
      <LazyLiveAvatars {...props} />
    </Suspense>
  );
}
