import { lazy, Suspense } from 'react';

import StaticSortableContainer from './StaticSortableContainer';

const DynamicSortableContainer = lazy(async () =>
  import(/* webpackChunkName: "sortable-dynamic" */ './dynamic').then((module) => ({
    default: module.DynamicSortableContainer,
  })),
);

export type SortableContainerProps = {
  className?: string;
  children?: React.ReactNode;
  testId?: string;
};

export function SortableContainer(props: SortableContainerProps) {
  return (
    <Suspense fallback={<StaticSortableContainer {...props} />}>
      <DynamicSortableContainer {...props} />
    </Suspense>
  );
}
