import { GetSegmentsParams, SegmentListFilter } from '@gonfalon/rest-api';

import { UISegmentsListView } from './types';

export function serializeUISegmentsViewForAPI(ui: UISegmentsListView) {
  const api: GetSegmentsParams = {
    sort: ui.sort ? (ui.sort as GetSegmentsParams['sort']) : undefined,
    filter: {
      query: ui.query ?? undefined,
      keys: ui.keys ? ui.keys : undefined,
      tags: ui.tags ? ui.tags : undefined,
      kind: ui.kind ? (ui.kind as SegmentListFilter['kind']) : undefined,
    },
  };
  return api;
}
