import { enableProgressiveRolloutRelativeScheduling } from '@gonfalon/dogfood-flags';
import { cloneDeep } from '@gonfalon/es6-utils';
import { DateFormat } from '@gonfalon/format';
import { format } from 'date-fns';

import {
  CustomWorkflowProgressiveRolloutConfig,
  CustomWorkflowType,
} from 'components/CustomWorkflows/SelectWorkflowTypeModal/utils';
import { Flag, FlagConfiguration } from 'utils/flagUtils';
import { SemanticInstructionRolloutWeights } from 'utils/instructions/rules/types';
import Logger from 'utils/logUtils';
import { WaitDurationUnit } from 'utils/scheduledChangesUtils';

import {
  createWorkflowBuilderAbsoluteScheduleStep,
  createWorkflowBuilderProgressiveRolloutStep,
  createWorkflowBuilderRelativeScheduleStep,
  createWorkflowBuilderStage,
  createWorkflowBuilderTurnFlagOnStep,
  WorkflowBuilderStateType,
} from '../utils';

const logger = Logger.get('WorkflowBuilder');

export function getProgressiveRolloutInitialState(options: {
  config: CustomWorkflowProgressiveRolloutConfig;
  initialState: WorkflowBuilderStateType;
  flag: Flag;
  flagConfiguration: FlagConfiguration;
}) {
  const { config, flag, flagConfiguration } = options;
  const initialState = cloneDeep(options.initialState);

  initialState.type = CustomWorkflowType.PROGRESSIVE_ROLLOUT;

  const flagVariation = flag.variations.find((variation) => variation._id === config.variationId);
  // if the flag variation id provided by the progressive rollout form is invalid,
  // bail out and log an error to the console
  if (!flagVariation) {
    logger.error(
      `Cannot generate progressive rollout initial state. Variation with id "${config.variationId}" is not a current flag variation.`,
    );
    return initialState;
  }

  initialState.name = `Progressive rollout of ${flagVariation.name ? flagVariation.name : flagVariation.value} - ${
    flag.name
  } - ${format(new Date(), DateFormat.MM_DD_YYYY)}`;

  const weightIncrement = config.increment * 1000;

  const originalRollout: SemanticInstructionRolloutWeights =
    // get weights from current fallthrough rollout, if any
    flagConfiguration.fallthrough.rollout?.variations.reduce((acc, weightedVariation) => {
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      const variationId = flag.variations.get(
        weightedVariation.variation,
      )!._id; /* eslint-enable @typescript-eslint/no-non-null-assertion */

      return {
        ...acc,
        [variationId]: weightedVariation.weight,
      };
    }, {}) ||
    // otherwise, create the weights from the flag variations
    flag.variations.reduce(
      (acc, variation) => ({
        ...acc,
        [variation._id]:
          variation._id === config.variationId
            ? // if this is the variation we will be incrementing, set its rollout to the starting increment
              weightIncrement
            : // otherwise, set its rollout to an equal proportion of the rollout percentage that remains
              // for example, if there are 4 variations, and we are starting at 10%, the other variations would each start at 30% (90% / 3)
              (100000 - weightIncrement) / (flag.variations.size - 1),
      }),
      {},
    );

  const initialRollout = adjustRolloutWeights(
    originalRollout,
    config.variationId,
    weightIncrement - originalRollout[config.variationId],
  );

  initialState.stages[0].name = `Start rollout on ${format(new Date(config.startDate), DateFormat.MM_DD_YYYY)}`;
  initialState.stages[0].steps.push(
    createWorkflowBuilderAbsoluteScheduleStep(config.startDate),
    createWorkflowBuilderTurnFlagOnStep(),
    createWorkflowBuilderProgressiveRolloutStep({
      weights: initialRollout,
      bucketBy: config.bucketBy,
      contextKind: config.contextKind,
    }),
  );

  const nextDate = new Date(config.startDate);
  let nextRollout = initialRollout;
  for (let i = initialRollout[config.variationId]; i < 100000; i += weightIncrement) {
    const newIncrementedWeight = adjustWeight(nextRollout[config.variationId], weightIncrement);

    nextRollout = adjustRolloutWeights(nextRollout, config.variationId, weightIncrement);

    nextDate.setDate(nextDate.getDate() + config.period);
    initialState.stages.push(
      createWorkflowBuilderStage(
        `${newIncrementedWeight / 1000}% rollout on ${format(nextDate.valueOf(), DateFormat.MM_DD_YYYY)}`,
        [
          enableProgressiveRolloutRelativeScheduling()
            ? createWorkflowBuilderRelativeScheduleStep(config.period, WaitDurationUnit.DAY)
            : createWorkflowBuilderAbsoluteScheduleStep(nextDate.valueOf()),
          createWorkflowBuilderProgressiveRolloutStep({
            weights: nextRollout,
            bucketBy: config.bucketBy,
            contextKind: config.contextKind,
          }),
        ],
      ),
    );
  }

  return initialState;
}

function adjustWeight(weight: number, adjustment: number) {
  return Math.max(Math.min(weight + adjustment, 100000), 0);
}

function balanceArrayValues(
  originalArraySum: number,
  array: number[],
  indexAdjusted: number,
  adjustmentAmount: number,
): number[] {
  // figure out how much each array value (other than the one that was adjusted)
  // will need to be adjusted to balance the array, while keeping array values proportionally similar
  // this means that larger array values will be adjusted more than smaller ones to balance the array
  const sumOfNonAdjustedValues = array.reduce((acc, n, i) => (i === indexAdjusted ? acc : acc + n), 0);
  const getProportionalBalanceAdjustment = (value: number) => {
    if (sumOfNonAdjustedValues === 0) {
      // if the sum of all non-adjusted values is zero, we should divide the adjustment amount evenly
      // between all non-adjusted values, which is always going to be one less than the number of total values
      // we multiply by -1, because we want to make the an adjustment to all other values in the OPPOSITE direction
      // from the adjustment we made to the value at "indexAdjusted"
      return (adjustmentAmount / (array.length - 1)) * -1;
    } else {
      // otherwise, multiply the adjustment amount by the ratio of THIS value over the sum of all non-adjusted values
      return ((adjustmentAmount * value) / sumOfNonAdjustedValues) * -1;
    }
  };

  // adjust the array by making proportional adjustments to each value,
  // except for the value that was originaly adjusted
  const adjustedArray = array.map((n, i) =>
    i === indexAdjusted ? n : Math.floor(Math.max(n + getProportionalBalanceAdjustment(n), 0)),
  );

  let sumDiff = adjustedArray.reduce((a, b) => a + b, 0) - originalArraySum;

  // if there is a difference in the sums,
  // distribute the difference as evenly as possible between the non-adjusted elements
  while (sumDiff !== 0) {
    const change = sumDiff > 0 ? -1 : 1;

    // find the index of either the largest or smallest element and adjust that element
    const indexToChange = adjustedArray
      .map((n, i) => [n, i])
      .filter((e) => e[1] !== indexAdjusted)
      .reduce((acc, e) => {
        if (change === 1) {
          return e[0] < acc[0] ? e : acc;
        }

        return e[0] > acc[0] ? e : acc;
      })[1];
    adjustedArray[indexToChange] = adjustedArray[indexToChange] + change;
    sumDiff = sumDiff > 0 ? sumDiff - 1 : sumDiff + 1;
  }

  return adjustedArray;
}

function adjustRolloutWeights(
  rolloutWeights: SemanticInstructionRolloutWeights,
  variationIdToAdjust: string,
  adjustment: number,
): SemanticInstructionRolloutWeights {
  const adjustmentIndex = Object.keys(rolloutWeights).findIndex((id) => id === variationIdToAdjust);

  const newWeights = balanceArrayValues(
    100000,
    Object.values(rolloutWeights).map((weight, i) =>
      i === adjustmentIndex ? adjustWeight(weight, adjustment) : weight,
    ),
    adjustmentIndex,
    adjustment,
  );

  return Object.keys(rolloutWeights).reduce((acc, variationId, i) => ({ ...acc, [variationId]: newWeights[i] }), {});
}
