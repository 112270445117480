import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Clause,
  ClauseValue,
  formatClauseContextKind,
  getAttributeFromClause,
  getClauseComponents,
  hasAppSupportedOp,
  hasSegmentOp,
  isDateOp,
  translateToText,
} from '@gonfalon/clauses';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { Time } from '@gonfalon/datetime';
import { trackTargetingRuleValuesShowLessClicked, trackTargetingRuleValuesShowMoreClicked } from '@gonfalon/flags';
import { DateFormat } from '@gonfalon/format';
import { useProjectKey } from '@gonfalon/router';
import { pluralize } from '@gonfalon/strings';
import { Button, Chip } from 'launchpad';

import { useSegments } from 'hooks/useSegments';
import { Segment } from 'utils/segmentUtils';
import { mobileAttributesMapping } from 'utils/userAttributeUtils';

import AttributeReference from './Contexts/utils/AttributeUtils';
import { useEditableModeContainerContext } from './EditableModeContainer/EditableModeContainerContextProvider';
import { ApplicationSupportedOperatorViewOnly } from './ApplicationSupportedOperatorViewOnly';

import styles from './ClauseReadOnlyView.module.css';

export type ClauseReadOnlyViewProps = {
  clause: Clause;
  clauseIndex: number;
};

const MAX_VALUE_LENGTH = 5;

export const ClauseReadOnlyView = ({ clause, clauseIndex }: ClauseReadOnlyViewProps) => {
  const projKey = useProjectKey();
  const envKey = useSelectedEnvironmentKey();

  const [shouldRenderAllValues, setShouldRenderAllValues] = useState(false);

  const { unsetGlobalContainerEvent } = useEditableModeContainerContext({
    onExpandAll: () => {
      setShouldRenderAllValues(true);
    },
  });

  const isSegmentClause = hasSegmentOp(clause);
  const isAppSupportedClause = hasAppSupportedOp(clause);

  const segmentKeys = isSegmentClause ? (clause.values as string[]) : [];

  const { segments, isReady } = useSegments({
    projKey,
    envKey,
    appliedParams: { filter: { keys: segmentKeys.sort((a, b) => a.localeCompare(b)) } },
    enabled: isSegmentClause,
  });

  const plainSegments = segments.toJS();

  const { contextKind, attribute, operation } = getClauseComponents(
    clause,
    plainSegments,
    new AttributeReference(getAttributeFromClause(clause)).toString(),
    mobileAttributesMapping,
  );
  const valuesList = clause.values;

  const attr = new AttributeReference(getAttributeFromClause(clause)).toString();
  const asText = translateToText(clause, attr, mobileAttributesMapping, plainSegments);

  const renderChip = (value: ClauseValue, index: number) => {
    if (isSegmentClause && isReady) {
      const segment = segments.find((s: Segment) => s.key === value);
      return (
        <Chip size="small" kind="info">
          <Link to={segment?.siteLink()} target="_blank" key={`value-${index}`}>
            {segment?.name}
            {`${segment?.hasTargetsOrRules() ? '' : ' (0 targets)'}`}
          </Link>
        </Chip>
      );
    }

    return renderValueChip(value);
  };

  const renderValueChip = (value: ClauseValue) => (
    <Chip size="small">
      <p>
        {isDateOp(clause.op) ? (
          <Time
            datetime={value as number}
            dateFormat={DateFormat.EE_MMM_D_YYY_H_MM_A_Z}
            notooltip
            includeTimeZone
          ></Time>
        ) : (
          value.toString()
        )}
      </p>
    </Chip>
  );

  const renderValues = () => {
    const truncatedValuesList = shouldRenderAllValues ? valuesList : valuesList.slice(0, MAX_VALUE_LENGTH);

    function onClick() {
      if (shouldRenderAllValues) {
        trackTargetingRuleValuesShowLessClicked();
      } else {
        trackTargetingRuleValuesShowMoreClicked();
      }
      unsetGlobalContainerEvent();
      setShouldRenderAllValues(!shouldRenderAllValues);
    }

    return (
      <div className={styles.valuesContainer}>
        {truncatedValuesList.map((value, index) => (
          <Fragment key={index}>{renderChip(value as ClauseValue, index)}</Fragment>
        ))}
        {valuesList.length > MAX_VALUE_LENGTH && (
          <Button kind="link" onClick={onClick}>
            {shouldRenderAllValues ? 'show less' : `+ ${valuesList.length - truncatedValuesList.length} more`}
          </Button>
        )}
      </div>
    );
  };

  const renderOperator = () =>
    isAppSupportedClause ? (
      <ApplicationSupportedOperatorViewOnly supported={!clause.negate} applicationKey={clause.values[0] as string} />
    ) : (
      operation && <span>{operation}</span>
    );

  const renderClauseAttributes = () => {
    if (isSegmentClause) {
      return (
        <>
          <p className="u-fw-semibold">Context</p>
          <span>{clause.negate ? 'is not in' : 'is in'}</span>
          <p className="u-fw-semibold">{pluralize('Segment', valuesList.length)}</p>
        </>
      );
    } else {
      return (
        <>
          {contextKind && <p className="u-fw-semibold">{formatClauseContextKind(clause)}</p>}
          <p className="u-fw-semibold">{attribute}</p>
          {renderOperator()}
        </>
      );
    }
  };

  // when the value is longer than the max length, we need to wrap the whole clause in a div for styling purposes
  if (valuesList.length > MAX_VALUE_LENGTH && shouldRenderAllValues) {
    return (
      <div className={styles.container} aria-label={asText}>
        <div className={styles.attributeContainer}>
          <span> {clauseIndex === 0 ? 'If' : 'And if'} </span> {renderClauseAttributes()}
        </div>
        {renderValues()}
      </div>
    );
  }

  return (
    <div className={styles.container} aria-label={asText}>
      <span>{clauseIndex === 0 ? 'If' : 'And if'}</span>
      {renderClauseAttributes()}
      {renderValues()}
    </div>
  );
};
