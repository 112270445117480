import { forwardRef } from 'react';
import { FormField, TextField, TextFieldProps } from 'launchpad';

type Props = TextFieldProps & {
  errorMessage?: string;
  isSaving?: boolean;
};

export const MetricUnit = forwardRef<HTMLInputElement, Props>(({ errorMessage, isSaving, ...rest }: Props, ref) => (
  <FormField name="unit" htmlFor="unit" label="Unit of measure" errorMessage={errorMessage} isRequired>
    <div className="u-w5">
      <TextField {...rest} ref={ref} placeholder="ms, $, USD" disabled={isSaving} />
    </div>
  </FormField>
));
