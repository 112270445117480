import { Icon } from '@launchpad-ui/icons';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'launchpad';

import styles from './LegacyReleaseTracker.module.css';

type LegacyCompleteReleaseModalProp = {
  onCancel: () => void;
  primaryOnClick: () => void;
  secondaryOnClick: () => void;
};

export function LegacyCompleteReleaseModal({
  onCancel,
  primaryOnClick,
  secondaryOnClick,
}: LegacyCompleteReleaseModalProp) {
  return (
    <Modal size="small" onCancel={onCancel}>
      <ModalHeader title={<div className={styles.congratsHeader}>Cross the finish line!</div>} />
      <ModalBody>
        <p>Ready to release this flag?</p>
      </ModalBody>
      <ModalFooter
        primaryButton={
          <Button kind="primaryFlair" onClick={primaryOnClick} icon={<Icon name="confetti" />} renderIconFirst>
            Complete release
          </Button>
        }
        secondaryButton={
          <Button onClick={secondaryOnClick} kind="default">
            Cancel
          </Button>
        }
      ></ModalFooter>
    </Modal>
  );
}
