const universalBOM = '\uFEFF';

export function downloadCsv(fileName: string, rows: string[]) {
  const csvContent = `data:text/csv; charset=utf-8,${encodeURIComponent(universalBOM + rows.join('\n'))}`;
  const link = document.createElement('a');
  link.setAttribute('href', csvContent);
  link.setAttribute('download', `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
  link.remove();
}
