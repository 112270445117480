import classNames from 'clsx';

type StaticSortableContainerProps = {
  className?: string;
  children?: React.ReactNode;
  testId?: string;
};

/* eslint-disable import/no-default-export */
export default function StaticSortableContainer({ className, children, testId }: StaticSortableContainerProps) {
  const classes = classNames('Sortable-list', className);

  return (
    <div className={classes} data-test-id={testId}>
      {children}
    </div>
  );
}
