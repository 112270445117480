import { ChangeEvent } from 'react';
import { VisuallyHidden } from 'react-aria';
import { components, MenuPlacement, MenuPosition, SingleValueProps } from 'react-select';
import { CustomSelect, OptionProps } from '@gonfalon/launchpad-experimental';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';
import { Map, OrderedSet, Set } from 'immutable';
import { FormGroup, Label, SelectField } from 'launchpad';

import CustomRoleCheckboxList from 'components/CustomRoleCheckboxList';
import Restrict from 'components/Restrict';
import { AccessDecision } from 'utils/accessUtils';
import { formattedRoleName, Role, roleDescription, RoleName, simpleRoles as defaultSimpleRoles } from 'utils/roleUtils';

import SelectRoleContainer from './SelectRoleContainer';

import styles from './RoleFieldGroup.module.css';

type onChangeCustomRoleMulti = (selectedCustomRoles: OrderedSet<string>) => void;
type onChangeCustomRoleSingle = (selectedCustomRole?: string) => void;

export type RoleFieldGroupProps = {
  selectedRole: RoleName | string;
  selectedCustomRoles: Set<string>;
  customRoles: Map<string, Role>;
  simpleRoles?: readonly RoleName[];
  allowCustom?: boolean;
  forceCustom: boolean;
  allowInline?: boolean;
  forceInline?: boolean;
  roleAccess: AccessDecision;
  canUpdateCustomRole: (role: Role) => boolean;
  explainRestrictionForCustomRole: (role: Role) => string;
  onChangeRole: (role: RoleName) => void;
  onChangeCustomRole: onChangeCustomRoleMulti | onChangeCustomRoleSingle;
  label?: string;
  testId?: string;
  unsetRoleSelectMaxHeight?: boolean;
  isMemberRoleSelector?: boolean;
  showCustomRoleAsDropdown?: boolean;
  isCustomRoleMulti?: boolean;
  roleDescriptionFn?: (roleName: RoleName) => string;
  hideLabel?: boolean;
  formGroupClassName?: string;
  menuPlacement?: MenuPlacement;
  menuPosition?: MenuPosition;
};

/* eslint-disable import/no-default-export */
export default function RoleFieldGroup({
  allowCustom = false,
  allowInline = false,
  isMemberRoleSelector = false,
  unsetRoleSelectMaxHeight = true,
  simpleRoles = defaultSimpleRoles,
  label = 'Role',
  selectedRole,
  selectedCustomRoles,
  customRoles,
  forceCustom,
  forceInline,
  roleAccess,
  canUpdateCustomRole,
  explainRestrictionForCustomRole,
  onChangeRole,
  onChangeCustomRole,
  showCustomRoleAsDropdown,
  testId,
  isCustomRoleMulti = true,
  roleDescriptionFn = roleDescription,
  hideLabel,
  formGroupClassName,
  menuPlacement,
  menuPosition,
}: RoleFieldGroupProps) {
  let roleOption: string | Role;
  if (forceCustom) {
    roleOption = 'custom';
  } else if (forceInline) {
    roleOption = 'inline';
  } else {
    roleOption = selectedRole;
  }

  const canUpdateAnyCustomRole = customRoles.toList().some((role: Role) => canUpdateCustomRole(role));
  const localSimpleRoles = !roleAccess.isAllowed && canUpdateAnyCustomRole ? [selectedRole] : [...simpleRoles];
  const onCustomRoleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const add = event.target.checked;
    const id = event.target.value;
    const newRoles = add ? selectedCustomRoles.concat([id]) : selectedCustomRoles.filter((role) => role !== id);
    (onChangeCustomRole as onChangeCustomRoleMulti)(OrderedSet(newRoles));
  };

  const onSelectRoleContainerChange = (selectedRoles: Role[] | Role) => {
    if (Array.isArray(selectedRoles)) {
      (onChangeCustomRole as onChangeCustomRoleMulti)(OrderedSet(selectedRoles?.map((role) => role?._id)));
      return;
    }

    (onChangeCustomRole as onChangeCustomRoleSingle)(selectedRoles?._id);
  };

  let restrictTooltip;
  if (!roleAccess.isAllowed) {
    restrictTooltip = roleAccess.isServiceToken
      ? roleAccess.getServiceTokenReason()
      : roleAccess.getModifyFieldReason();
  }

  const roleLabel = (role: RoleName) => {
    const name = formattedRoleName(role);
    const description = roleDescriptionFn(role);
    return (
      <div className="u-flex">
        <div className={styles.selectedCheckContainer}>
          {role === selectedRole && <Icon name="check" size="small" />}
        </div>
        <div>
          <div>{name}</div>
          {description.length > 0 && <div className="u-fs-sm u-color-text-ui-secondary">{description}</div>}
        </div>
      </div>
    );
  };

  const options = localSimpleRoles
    .filter((role: string) => (isMemberRoleSelector ? true : role !== 'no_access'))
    .map((role: string) => ({ value: role }));

  if (allowCustom) {
    options.push({ value: RoleName.CUSTOM });
  }

  if (allowInline) {
    options.push({ value: RoleName.INLINE });
  }

  const SingleValue = (singleValueProps: SingleValueProps<{ value: RoleName }>) => (
    <components.SingleValue {...singleValueProps}>
      {formattedRoleName(singleValueProps.data.value)}
    </components.SingleValue>
  );

  const roleSelector = isMemberRoleSelector ? (
    <CustomSelect
      id="role"
      options={options}
      formatOptionLabel={(option) => roleLabel(option?.value as RoleName)}
      getOptionLabel={(option) => {
        const role = option?.value as RoleName;
        let optionLabel = formattedRoleName(role);
        const description = roleDescriptionFn(role);
        if (description) {
          optionLabel += `. ${description}.`;
        }

        return optionLabel;
      }}
      value={options.filter((o) => o.value === roleOption)}
      customComponents={{ SingleValue }}
      onChange={(selectedOption: OptionProps) => onChangeRole(selectedOption.value as RoleName)}
      name={testId}
      styles={unsetRoleSelectMaxHeight ? { menuList: { maxHeight: 'unset' } } : {}}
      menuShouldScrollIntoView={false}
      menuPlacement={menuPlacement}
      menuPosition={menuPosition}
    />
  ) : (
    <SelectField
      id="role"
      value={roleOption}
      onChange={(event: ChangeEvent<HTMLSelectElement>) => onChangeRole(event.target.value as RoleName)}
      data-test-id={testId}
    >
      {localSimpleRoles
        .filter((role) => (isMemberRoleSelector ? true : role !== 'no_access'))
        .map((role) => (
          <option key={role} value={role}>
            {formattedRoleName(role as RoleName)}
          </option>
        ))}

      {allowCustom && (
        <option key="custom" value="custom">
          Custom
        </option>
      )}

      {allowInline && (
        <option key="inline" value="inline">
          Inline policy
        </option>
      )}
    </SelectField>
  );

  const renderFormLabel = () => {
    const labelEl = <Label htmlFor="role">{label}</Label>;
    if (hideLabel) {
      return <VisuallyHidden>{labelEl}</VisuallyHidden>;
    }

    return labelEl;
  };

  return (
    <div
      className={cx({
        'u-color-bg-ui-secondary u-ml-ns u-pt-s u-pr-s u-pb-m u-pl-s role-group':
          selectedRole === RoleName.CUSTOM && showCustomRoleAsDropdown,
      })}
    >
      <FormGroup name="role" className={formGroupClassName}>
        {renderFormLabel()}
        <Restrict
          isRestricted={!roleAccess.isAllowed && !canUpdateAnyCustomRole}
          tooltip={restrictTooltip}
          tooltipOptions={{ rootElementStyle: { display: 'block' } }}
          willDisable
        >
          {roleSelector}
        </Restrict>
      </FormGroup>

      {forceCustom && !showCustomRoleAsDropdown && (
        <CustomRoleCheckboxList
          canUpdateCustomRole={canUpdateCustomRole}
          customRoles={customRoles}
          explainRestrictionForCustomRole={explainRestrictionForCustomRole}
          forceCustom={forceCustom}
          onChangeCustomRole={onCustomRoleCheckboxChange}
          selectedCustomRoles={[...selectedCustomRoles]}
        />
      )}
      {forceCustom && showCustomRoleAsDropdown && (
        <div className="u-mt-s">
          <div className="u-flex u-flex-between">
            <Label data-test-id="custom-role-label" htmlFor="custom-role">
              Custom {isCustomRoleMulti ? <>roles</> : <>role</>}
            </Label>
          </div>
          <SelectRoleContainer
            selectedRoles={customRoles
              .filter((customRole) => selectedCustomRoles.includes(customRole?._id))
              .toList()
              .toArray()}
            onChange={onSelectRoleContainerChange}
            isMulti={isCustomRoleMulti}
            menuPlacement={menuPlacement}
          />
        </div>
      )}
    </div>
  );
}
