import { Confirmation } from 'components/ui/confirmation';

export type RemoveReleaseConfirmationModalProps = {
  confirmationName: string;
  confirmationKey: string;
  onCancel: () => void;
  onConfirm: () => void;
};

export function RemoveReleaseConfirmationModal({
  confirmationName,
  confirmationKey,
  onCancel,
  onConfirm,
}: RemoveReleaseConfirmationModalProps) {
  return (
    <Confirmation
      title="Cancel release"
      body={
        <span>
          Cancel release for <b>{confirmationName}</b>?
        </span>
      }
      confirmLabel="Cancel release"
      kind="destructive"
      onCancel={onCancel}
      onConfirm={onConfirm}
      copyableValue={confirmationKey}
      validation={{
        label: "Enter the flag's name or key to confirm",
        placeholder: confirmationKey,
        onValidate: (input: string) => input === confirmationName || input === confirmationKey,
      }}
    />
  );
}
