import { type APIFlagFilter } from '@gonfalon/rest-api';

import { creationDateToReferenceDate } from './creationDateToReferenceDate';
import { evaluationDateToReferenceDate } from './evaluationDateToReferenceDate';
import { type UIFlagFilter } from './types';

export function parseUIFlagFilterFromAPI(api: APIFlagFilter) {
  const ui: UIFlagFilter = {};
  ui.query = api.query;
  ui.hasDataExport = api.hasDataExport;
  ui.hasExperiment = api.hasExperiment;
  ui.maintainerId = api.maintainerId;
  ui.maintainerTeamKey = api.maintainerTeamKey;
  ui.tags = api.tags;
  ui.type = api.type;
  ui.sdkAvailability = api.sdkAvailability;
  ui.filterEnv = api.filterEnv;
  ui.status = api.status;
  ui.state = api.state;
  ui.targeting = api.targeting;
  ui.contextKindTargeted = api.contextKindTargeted;
  ui.contextKindsEvaluated = api.contextKindsEvaluated;
  ui.followerId = api.followerId;
  ui.applicationEvaluated = api.applicationEvaluated;
  ui.segmentTargeted = api.segmentTargeted;

  if (api.codeReferences) {
    if (api.codeReferences.min !== undefined && api.codeReferences.min > 0) {
      ui.codeReferences = true;
    } else {
      ui.codeReferences = false;
    }
  }

  const now = Date.now();

  if (api.evaluated) {
    ui.evaluated = evaluationDateToReferenceDate(new Date(api.evaluated.after).getTime(), now);
  }

  if (api.creationDate) {
    ui.created = creationDateToReferenceDate(new Date(api.creationDate.before).getTime(), now);
  }

  return ui;
}
