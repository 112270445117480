import { shortChangeDescriptions, titleVerbActions } from '@gonfalon/audit-log';
import { enableMostRecentAuditLogHistory } from '@gonfalon/dogfood-flags';
import { pickBy, sortedLastIndex, uniq } from '@gonfalon/es6-utils';
import { capitalize, startsWith } from '@gonfalon/strings';
import { endOfDay, isValid, startOfDay, subDays } from 'date-fns';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, Record } from 'immutable';
import qs from 'qs';

import { createMember, Member } from 'utils/accountUtils';
import { CreateFunctionInput, ImmutableMap } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';
import { createPolicyStatement, getFullResourceString, Policy } from 'utils/policyUtils';
import { idFromIdentifier, kindFromIdentifier, projectFromIdentifier } from 'utils/resourceUtils';

type QueryProps = {
  spec: string;
  q: string;
  before?: number;
  after?: number;
  policy?: Policy;
  pathname: string;
  limit: string;
  service?: string;
  actions?: string;
  critical?: boolean;
};

export type HistoryQuery = Partial<Pick<QueryProps, 'q' | 'before' | 'after' | 'service' | 'actions' | 'critical'>>;

export class Query extends Record<QueryProps>({
  spec: '',
  q: '',
  before: undefined,
  after: undefined,
  policy: undefined,
  service: undefined,
  actions: undefined,
  pathname: '',
  limit: '',
  critical: false,
}) {
  // This function is only used for pagination
  isValid() {
    // before and after can be undefined when the show is "Most Recent"
    if (this.before === undefined && this.after === undefined) {
      return true;
    }
    return isValid(this.before) && isValid(this.after);
  }

  toHistoryQuery() {
    const defaultQuery = createQuery();
    const historyQuery: HistoryQuery = {};

    if (this.q !== defaultQuery.q) {
      historyQuery.q = this.q;
    }
    if (this.before !== defaultQuery.before) {
      historyQuery.before = this.before;
    }
    if (this.after !== defaultQuery.after) {
      historyQuery.after = this.after;
    }
    if (this.service !== defaultQuery.service) {
      historyQuery.service = this.service;
    }

    if (this.actions !== defaultQuery.actions) {
      historyQuery.actions = this.actions;
    }

    if (this.critical !== defaultQuery.critical) {
      historyQuery.critical = this.critical;
    }

    return historyQuery;
  }

  toHistorySearch() {
    return qs.stringify(this.toHistoryQuery());
  }

  toQueryString() {
    return qs.stringify(
      pickBy({
        spec: this.spec,
        q: this.q,
        before: this.before,
        after: this.after,
        rollup: 1,
        limit: this.limit,
        service: this.service,
        actions: this.actions,
        critical: this.critical,
      }),
    );
  }

  getPathname(path = '/') {
    const currentPath = this.pathname.replace(/\/$/, '');
    return `${currentPath}${path}`;
  }

  setDates({ startDate, endDate }: { startDate?: Date | number; endDate?: Date | number }) {
    return this.merge({
      before: endDate ? endOfDay(endDate).valueOf() : endDate,
      after: startDate ? startOfDay(startDate).valueOf() : startDate,
    });
  }
}

type ResourceAccessProps = {
  action: string;
  resource: string;
};

class ResourceAccess extends Record<ResourceAccessProps>({
  action: '',
  resource: '',
}) {}

function getKind(resource: string) {
  return kindFromIdentifier(resource);
}

function getId(resource: string) {
  return idFromIdentifier(resource);
}

type SubjectResourceProps = {
  _links: ImmutableMap<{
    target: Link;
  }>;
  name: string;
  avatarUrl?: string;
};

export class SubjectResource extends Record<SubjectResourceProps>({
  _links: Map(),
  name: '',
  avatarUrl: undefined,
}) {
  targetLink() {
    if (this._links) {
      return this._links.getIn(['target', 'href']);
    }
    return null;
  }
}

type TargetResourceProps = {
  _links: ImmutableMap<{
    site: Link;
  }>;
  name: string;
  resources: List<string>;
};

export class TargetResource extends Record<TargetResourceProps>({
  _links: Map(),
  name: '',
  resources: List(),
}) {
  siteLink() {
    return this._links.getIn(['site', 'href']);
  }

  // TODO: update when we have proper resource specifier parsing
  getParentProjectId() {
    const projects = this.resources.map((r) => projectFromIdentifier(r)).toSet();
    if (projects.size > 1) {
      return;
    }
    return projects.first();
  }

  getKind() {
    return getKind(this.resources.first());
  }

  getId() {
    return getId(this.resources.first());
  }

  getResourceIdentifier() {
    return this.resources.first();
  }
}

type ParentResourceProps = {
  _links: ImmutableMap<{
    site: Link;
  }>;
  name: string;
  resource: string;
};

export class ParentResource extends Record<ParentResourceProps>({
  _links: Map(),
  name: '',
  resource: '',
}) {
  siteLink() {
    return this._links.getIn(['site', 'href']);
  }

  getKind() {
    return getKind(this.resource);
  }

  getId() {
    return getId(this.resource);
  }

  getResourceIdentifier() {
    return this.resource;
  }
}

type EntryAccessTokenProps = {
  _links: ImmutableMap<{
    site: Link;
  }>;
  _id: string;
  name: string;
  ending: string;
  token: string;
  serviceToken: boolean;
};

export class EntryAccessToken extends Record<EntryAccessTokenProps>({
  _links: Map(),
  _id: '',
  name: '',
  ending: '',
  serviceToken: false,
  token: '',
}) {
  siteLink() {
    return this._links.getIn(['site', 'href']);
  }
}

type EntryAuthorizedAppProps = {
  _links: ImmutableMap<{
    site: Link;
  }>;
  name: string;
  maintainerName: string;
  isScim: boolean;
};

export class EntryAuthorizedApp extends Record<EntryAuthorizedAppProps>({
  _links: Map(),
  isScim: false,
  name: '',
  maintainerName: '',
}) {}

type AuditLogEntryProps = {
  _links: ImmutableMap<{
    self: Link;
    site: Link;
  }>;
  _id: string;
  kind: string;
  name: string;
  subject?: SubjectResource;
  member?: Member;
  parent?: ParentResource;
  target?: TargetResource;
  date: number;
  accesses: List<ResourceAccess>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delta: Map<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  triggerBody: Map<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mergePayload: Map<string, any>;
  comment: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  previousVersion?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentVersion?: any;
  titleVerb: string;
  description: string;
  shortDescription: string;
  token?: EntryAccessToken;
  app?: EntryAuthorizedApp;
};

export class AuditLogEntry extends Record<AuditLogEntryProps>({
  _links: Map(),
  _id: '',
  kind: '',
  name: '',
  subject: undefined,
  member: undefined,
  parent: undefined,
  target: undefined,
  date: 0,
  accesses: List(),
  delta: Map(),
  triggerBody: Map(),
  mergePayload: Map(),
  comment: '',
  previousVersion: undefined,
  currentVersion: undefined,
  titleVerb: '',
  description: '',
  shortDescription: '',
  token: undefined,
  app: undefined,
}) {
  selfLink() {
    return this._links.getIn(['self', 'href']);
  }

  siteLink() {
    return this._links.getIn(['site', 'href']);
  }

  hasDiff() {
    return !!(this.currentVersion && this.previousVersion);
  }

  hasPatch() {
    return !this.delta.isEmpty();
  }

  hasMerge() {
    return !this.mergePayload.isEmpty();
  }

  hasTriggerBody() {
    return !this.triggerBody.isEmpty();
  }

  isCreation() {
    if (this.accessesIncludesAction('updateScheduledChanges') && this.titleStartsWith('scheduled')) {
      return true;
    }
    if (this.accessesIncludesAction('updateFeatureWorkflows') && this.titleStartsWith('added')) {
      return true;
    }
    if (this.accessesIncludesAction('addReleasePipeline') && this.titleStartsWith('added')) {
      return true;
    }
    if (
      this.accessesIncludesAction('autoProvisionDomainMatchedMember') ||
      this.accessesIncludesAction('approveDomainMatchedMember')
    ) {
      return true;
    }
    return this.accessesStartsWith('create', 'clone');
  }

  isDeletion() {
    if (this.accessesIncludesAction('updateScheduledChanges') && this.titleStartsWith('cancelled')) {
      return true;
    }
    if (this.accessesIncludesAction('updateFeatureWorkflows') && this.titleStartsWith('removed')) {
      return true;
    }
    if (this.accessesIncludesAction('removeReleasePipeline') && this.titleStartsWith('removed')) {
      return true;
    }
    return this.accessesStartsWith('delete');
  }

  isUpdate() {
    return this.accessesStartsWith('update');
  }

  accessesStartsWith(verb: string, optionalVerb?: string) {
    return this.accesses.every((a) => {
      if (optionalVerb) {
        return a.action.startsWith(verb) || a.action.startsWith(optionalVerb);
      } else {
        return a.action.startsWith(verb);
      }
    });
  }

  accessesIncludesAction(action: string) {
    return this.accesses.some((access) => access.action === action);
  }

  getProjectKeyFromAccesses() {
    const resourceAccess = this.accesses.get(0)?.resource;
    return resourceAccess?.split(':')[0]?.split('/')[1]?.split(';')[0];
  }

  titleStartsWith(verb: string) {
    return startsWith(this.titleVerb, verb);
  }
}

export function groupEntriesIntoTimeBuckets(
  entries: List<AuditLogEntry>,
  buckets: number[],
): Map<number, List<AuditLogEntry>> {
  // `groupBy` doesn't seem to be typed properly by immutable, so we're casting to unknown first and setting it to the proper type
  return entries
    .filter((entry) => entry.get('date') >= buckets[0])
    .groupBy<number>((entry: AuditLogEntry) => {
      const index = sortedLastIndex(buckets, entry.get('date'));
      return buckets[index ? index - 1 : 0];
    }) as unknown as Map<number, List<AuditLogEntry>>;
}

export function createAuditLogEntry(props: CreateFunctionInput<AuditLogEntry>) {
  const data = fromJS(props);
  const entry = data instanceof AuditLogEntry ? data : new AuditLogEntry(data);
  return entry.withMutations((map) => {
    map.update('subject', (s) => s && new SubjectResource(s));
    map.update('member', (m) => (m ? createMember(m) : m));
    map.update('accesses', (as) => as.map((a) => new ResourceAccess(a)));
    map.update('target', (t) => t && new TargetResource(t));
    map.update('parent', (p) => p && new ParentResource(p));
    map.update('token', (t) => t && new EntryAccessToken(t));
    map.update('app', (t) => t && new EntryAuthorizedApp(t));
    map.set('mergePayload', data.get('merge'));
  });
}

export type CreateQueryProps =
  | CreateFunctionInput<Query>
  // `before` and `after` come in from query params, so are strings that get parsed to numbers in the create fn
  | Partial<Omit<QueryProps, 'before' | 'after'> & { before: string; after: string }>;

export function createQuery(props: CreateQueryProps = {}) {
  if (props instanceof Query) {
    return props;
  }

  // Set default date range values before grabbing any values set in query
  const dateRangeDefault = enableMostRecentAuditLogHistory()
    ? {}
    : {
        before: endOfDay(new Date()).valueOf(),
        after: subDays(startOfDay(new Date()), 7).valueOf(),
      };

  const merged = {
    ...dateRangeDefault,
    ...props,
  };

  const modifiedProps = {
    ...merged,
    after: typeof merged.after === 'string' ? parseInt(merged.after, 10) : merged.after,
    before: typeof merged.before === 'string' ? parseInt(merged.before, 10) : merged.before,
  };

  return new Query(fromJS(modifiedProps));
}

export enum AuditLogViewKind {
  ACCOUNT_HISTORY = 'accountHistory',
  APPLICATION = 'application',
  MAIN = 'main',
  FLAG = 'flag',
  SEGMENT = 'segment',
  MEMBER = 'member',
  TEAM = 'team',
}

export const createPolicyStatementFromFilter = (
  resource: string,
  resourceId: string,
  action: string,
  projKey?: string,
) => {
  const resourceString = getFullResourceString(resourceId, resource, projKey);
  return createPolicyStatement({ resources: List([resourceString]), actions: List([action]), effect: 'allow' });
};

export const defaultMainAuditLogPolicyList = (projectKey: string, environmentKey: string) =>
  List([
    createPolicyStatement({
      resources: List([`proj/${projectKey}:env/${environmentKey}:flag/*`]),
      effect: 'allow',
      actions: List(['*']),
    }),
    createPolicyStatement({
      resources: List([`proj/${projectKey}:env/${environmentKey}:segment/*`]),
      effect: 'allow',
      actions: List(['*']),
    }),
  ]);

export const defaultFlagAuditLogPolicyList = (projectKey: string, environmentKey: string, flagKey: string) =>
  List([
    {
      resources: [`proj/${projectKey}:env/${environmentKey}:flag/${flagKey}`],
      effect: 'allow',
      actions: ['*'],
    },
  ]);
export const defaultSegmentAuditLogPolicyList = (projectKey: string, environmentKey: string, segmentKey: string) =>
  List([
    {
      resources: [`proj/${projectKey}:env/${environmentKey}:segment/${segmentKey}`],
      effect: 'allow',
      actions: ['*'],
    },
  ]);
export const defaultMemberAuditLogPolicyList = (memberId: string) =>
  List([
    {
      resources: [`member/${memberId}`],
      effect: 'allow',
      actions: ['*'],
    },
  ]);
export const defaultTeamsAuditLogPolicyList = (teamKey: string) =>
  List([
    {
      resources: [`team/${teamKey}`],
      effect: 'allow',
      actions: ['*'],
    },
  ]);

export const defaultAccountHistoryPolicyList = List([
  createPolicyStatement({ resources: List(['proj/*']), effect: 'allow', actions: List(['*']) }),
  createPolicyStatement({ resources: List(['proj/*:env/*']), effect: 'allow', actions: List(['*']) }),
  createPolicyStatement({ resources: List(['role/*']), effect: 'allow', actions: List(['*']) }),
  createPolicyStatement({ resources: List(['member/*']), effect: 'allow', actions: List(['*']) }),
  createPolicyStatement({ resources: List(['acct']), effect: 'allow', actions: List(['*']) }),
  createPolicyStatement({ resources: List(['relay-proxy-config/*']), effect: 'allow', actions: List(['*']) }),
  createPolicyStatement({ resources: List(['service-token/*']), effect: 'allow', actions: List(['*']) }),
  createPolicyStatement({ resources: List(['member/*:token/*']), effect: 'allow', actions: List(['*']) }),
  createPolicyStatement({ resources: List(['webhook/*']), effect: 'allow', actions: List(['*']) }),
  createPolicyStatement({ resources: List(['integration/*']), effect: 'allow', actions: List(['*']) }),
  createPolicyStatement({ resources: List(['code-reference-repository/*']), effect: 'allow', actions: List(['*']) }),
  createPolicyStatement({ resources: List(['member/*:application/*']), effect: 'allow', actions: List(['*']) }),
  createPolicyStatement({ resources: List(['team/*']), effect: 'allow', actions: List(['*']) }),
]);

export const getDefaultPolicyList = (
  auditLogView: AuditLogViewKind,
  projectKey?: string,
  environmentKey?: string,
  resourceId?: string,
) => {
  switch (auditLogView) {
    case 'accountHistory':
      return defaultAccountHistoryPolicyList;
    case 'main':
      if (projectKey && environmentKey) {
        return defaultMainAuditLogPolicyList(projectKey, environmentKey);
      }
    // eslint-disable-next-line no-fallthrough
    case 'flag':
      if (projectKey && environmentKey && resourceId) {
        return defaultFlagAuditLogPolicyList(projectKey, environmentKey, resourceId);
      }
    // eslint-disable-next-line no-fallthrough
    case 'segment':
      if (projectKey && environmentKey && resourceId) {
        return defaultSegmentAuditLogPolicyList(projectKey, environmentKey, resourceId);
      }
    // eslint-disable-next-line no-fallthrough
    case 'member':
      if (resourceId) {
        return defaultMemberAuditLogPolicyList(resourceId);
      }
    // eslint-disable-next-line no-fallthrough
    case 'team':
      if (resourceId) {
        return defaultTeamsAuditLogPolicyList(resourceId);
      }
    // eslint-disable-next-line no-fallthrough
    default:
      return List([createPolicyStatement({})]);
  }
};

export const getShortChangeDescriptionForAction = (action: string) => shortChangeDescriptions[action];

// * The title verb for `updateOn` contains a more accurate description than our generic one
// * 'updateFlagFeatureWorkflowCondition' is usually accompanied by accesses that duplicate other entries, so we use the title verb to avoid that

export const getChangeSummariesForEntry = (entry: AuditLogEntry) => {
  if (titleVerbActions.indexOf(entry.getIn(['accesses', 0, 'action'])) !== -1) {
    return [capitalize(entry.titleVerb)];
  }
  return entry.accesses.toArray().map((a) => getShortChangeDescriptionForAction(a.action));
};

export const getChangeSummariesForEntryList = (entries: List<AuditLogEntry>) =>
  uniq(entries.reduce((arr: string[], entry) => [...arr, ...getChangeSummariesForEntry(entry)], []));

export const auditLogLimitDate = (limit: number) => subDays(new Date(), limit);
