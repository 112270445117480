import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { useMeasuredRollouts } from '@gonfalon/rest-api';
import { useFlagKey, useProjectKey } from '@gonfalon/router';

export function useLatestMeasuredRollout({ enabled = true }: { enabled?: boolean } = {}) {
  const projectKey = useProjectKey();
  const flagKey = useFlagKey();
  const environmentKey = useSelectedEnvironmentKey();
  const {
    data: measuredRollouts,
    isPending,
    isFetching,
  } = useMeasuredRollouts({ projectKey, flagKey, params: { filter: { environmentKey } } }, { enabled });

  const latestMeasuredRollout = measuredRollouts
    ? [...measuredRollouts.items].sort((a, b) => b.design.createdAt - a.design.createdAt)[0]
    : undefined;

  return {
    data: latestMeasuredRollout,
    isPending,
    isFetching,
  };
}
