import { createContext, Dispatch, ReactNode, useContext, useMemo } from 'react';
import {
  type PendingChangesState,
  type SegmentSemanticInstruction,
  SegmentPendingChangesActionsType,
} from '@gonfalon/segments';

type SegmentPendingChangesContextValue = {
  pendingChangesState: PendingChangesState;
  pendingChangesDispatch?: Dispatch<SegmentPendingChangesActionsType>;
};

const SegmentPendingChangesContext = createContext<SegmentPendingChangesContextValue>({
  pendingChangesState: {
    instructions: [] as SegmentSemanticInstruction[],
  },
});

type ProviderProps = {
  children: ReactNode;
  pendingChangesState: PendingChangesState;
  pendingChangesDispatch: Dispatch<SegmentPendingChangesActionsType>;
};

export function SegmentPendingChangesProvider({
  children,
  pendingChangesState,
  pendingChangesDispatch,
}: ProviderProps) {
  const contextValue = useMemo(
    () => ({ pendingChangesState, pendingChangesDispatch }),
    [pendingChangesState, pendingChangesDispatch],
  );

  return <SegmentPendingChangesContext.Provider value={contextValue}>{children}</SegmentPendingChangesContext.Provider>;
}

export function useSegmentPendingChanges() {
  const { pendingChangesState, pendingChangesDispatch } = useContext(SegmentPendingChangesContext);
  if (pendingChangesState === undefined) {
    throw new Error('useSegmentPendingChanges must be used within a SegmentPendingChangesProvider');
  }

  return { pendingChangesState, pendingChangesDispatch };
}
