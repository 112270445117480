import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { enableHoldoutsUI } from '@gonfalon/dogfood-flags';
import { NewTabLink } from '@gonfalon/launchpad-experimental';
import { toFlagTargeting, toHref } from '@gonfalon/navigator';
import { useParam, useProjectKey } from '@gonfalon/router';
import { ButtonGroup, Text } from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';
import { List, Map, Set } from 'immutable';
import { IconButton, Label } from 'launchpad';
import invariant from 'tiny-invariant';

import FlagVariationSelect from 'components/FlagVariationSelect';
import TargetingValidationHint from 'components/TargetingValidationHint';
import { AlignValue, Cell, CellLayoutValue, Grid } from 'components/ui/grid';
import ValidatedModuleContent from 'components/ValidatedModuleContent';
import styles from 'stylesheets/components/PrerequisiteManager.module.css';
import { Prerequisite as PrerequisiteType } from 'utils/flagUtils';
import { PrerequisiteValidation } from 'utils/validation/targeting';

import { useRenderPrerequisiteFlagData } from './FlagTargeting/EditFlagTargeting/PrerequisitesSection/useRenderPrerequisiteFlagData';
import { SelectFlag } from './SelectFlag';

export type LazyPrerequisiteManagerProps = {
  prerequisites: List<PrerequisiteType>;
  originalPrerequisites: List<PrerequisiteType>;
  canModify: boolean;
  onAddPrerequisite(): void;
  onDeletePrerequisite(prerequisite: PrerequisiteType): void;
  onEditPrerequisite(previousPrerequisiteKey: string | null, prerequisite: PrerequisiteType, variationId: string): void;
  validation: Map<string, PrerequisiteValidation>;
  isFlagPrerequisitesEnabled: boolean;
};

export function LazyPrerequisiteManager({
  prerequisites,
  originalPrerequisites,
  canModify,
  onAddPrerequisite,
  onDeletePrerequisite,
  onEditPrerequisite,
  validation,
  isFlagPrerequisitesEnabled,
}: LazyPrerequisiteManagerProps) {
  const projKey = useProjectKey();
  const envKey = useSelectedEnvironmentKey();

  let existingFlagPrerequisites = prerequisites.map((p) => p.key).toSet();

  // This param exists to exclude the current flag key from the prerequisites list,
  // but only if it exists (since this component may be used during flag creation).
  const flagKey = useParam('flagKey', { optional: true });
  if (flagKey) {
    existingFlagPrerequisites = existingFlagPrerequisites.add(flagKey);
  }

  return (
    <div>
      {prerequisites.map((p, index) => (
        <LazyValidatedModuleContentPrerequisite
          key={p._key}
          index={index}
          prerequisite={p}
          projectKey={projKey}
          environmentKey={envKey}
          originalPrerequisites={originalPrerequisites}
          existingFlagPrerequisites={existingFlagPrerequisites}
          canModify={canModify}
          onDelete={() => onDeletePrerequisite(p)}
          onChange={onEditPrerequisite}
          validation={validation && p?._key ? validation.get(p._key) : undefined}
          isFlagPrerequisitesEnabled={isFlagPrerequisitesEnabled}
          onAddPrerequisite={onAddPrerequisite}
          isFirst={index === 0}
          isLast={index === prerequisites.size - 1}
        />
      ))}
    </div>
  );
}

export type LazyValidatedModuleContentPrerequisiteProps = {
  onChange(previousPrerequisiteKey: string, prerequisite: PrerequisiteType, variationId: string): void;
  isSnug?: boolean;
  originalPrerequisites: List<PrerequisiteType>;
  isFirst: boolean;
} & LazyPrerequisiteProps;

export function LazyValidatedModuleContentPrerequisite({
  onChange,
  validation,
  isSnug,
  isFlagPrerequisitesEnabled,
  prerequisite,
  originalPrerequisites,
  isFirst,
  ...props
}: LazyValidatedModuleContentPrerequisiteProps) {
  const prerequisiteState = prerequisite.getPrerequisiteState(originalPrerequisites);
  const locationHash = location.hash;

  const prerequisiteModuleClassName = cx({
    [styles.edited]: prerequisiteState === 'modified' || prerequisiteState === 'new',
    [styles.highlighted]: locationHash === `#prerequisites-${prerequisite.key}`,
  });

  return (
    <ValidatedModuleContent
      data-anchor={`#prerequisites-${prerequisite.key}`}
      validation={validation}
      isSnug={isSnug}
      isSeamless
      isCondensedVertically
      className={prerequisiteModuleClassName}
    >
      <LazyPrerequisite
        onChange={onChange}
        validation={validation}
        prerequisite={prerequisite}
        {...props}
        isFlagPrerequisitesEnabled={isFlagPrerequisitesEnabled}
      />
    </ValidatedModuleContent>
  );
}

type LazyPrerequisiteProps = {
  projectKey: string;
  environmentKey: string;
  prerequisite: PrerequisiteType;
  existingFlagPrerequisites: Set<string>;
  canModify: boolean;
  onAddPrerequisite(): void;
  onDelete(): void;
  onChange(previousPrerequisiteKey: string | null, prerequisite: PrerequisiteType, variationId: string): void;
  doNotEditFlagSelect?: boolean;
  isFlagPrerequisitesEnabled: boolean;
  validation?: PrerequisiteValidation;
  isInPortal?: boolean;
  index: number;
  isLast: boolean;
};

export function LazyPrerequisite({
  projectKey,
  environmentKey,
  prerequisite,
  existingFlagPrerequisites,
  canModify,
  onDelete,
  onChange,
  onAddPrerequisite,
  validation,
  doNotEditFlagSelect = false,
  isInPortal,
  isFlagPrerequisitesEnabled,
  index,
  isLast,
}: LazyPrerequisiteProps) {
  const flagKey = prerequisite.key;
  const scopeId = 'prerequisite-flag';

  const { data: prerequisiteFlagData } = useRenderPrerequisiteFlagData({ prerequisite, projectKey, environmentKey });

  const hasValidationProblem = validation && !validation.isEmpty();

  const selectFlagFieldId = `${scopeId}-${index + 1}`;
  const selectVariationFieldId = `select-variation-${index + 1}`;

  const isHoldoutPrerequisite = enableHoldoutsUI() && prerequisiteFlagData?._purpose === 'holdout';

  return (
    <>
      <Grid align={AlignValue.BOTTOM}>
        {hasValidationProblem && (
          <Cell layout={CellLayoutValue.AUTO}>
            <div className={styles.targetingValidationHintWrapper}>
              <TargetingValidationHint validation={validation} />
            </div>
          </Cell>
        )}
        <Cell tight>
          <Label htmlFor={selectFlagFieldId}>
            <h4 className="u-pb-s">Flag</h4>
          </Label>
          <SelectFlag
            isDisabled={!canModify || doNotEditFlagSelect || !isFlagPrerequisitesEnabled || isHoldoutPrerequisite}
            projectKey={projectKey}
            environmentKey={environmentKey}
            flagKey={prerequisite.key}
            filterFlagByKey={(key) => !existingFlagPrerequisites.has(key)}
            isInPortal={isInPortal}
            id={selectFlagFieldId}
            onChange={(nextFlag) => {
              const oldPrerequisiteKey = flagKey || null;
              const variationId = nextFlag?.variations.get(prerequisite.variation)?._id;
              if (nextFlag && variationId) {
                return onChange(
                  oldPrerequisiteKey,
                  prerequisite.setIn(['key'], nextFlag.key).delete('variation'),
                  variationId,
                );
              }
            }}
          />
        </Cell>
        <Cell tight>
          <Label htmlFor={selectVariationFieldId}>
            <h4 className="u-pb-s">Variation</h4>
          </Label>
          <FlagVariationSelect
            disabled={!canModify || !prerequisiteFlagData || !isFlagPrerequisitesEnabled || isHoldoutPrerequisite}
            clearable={false}
            noMarker={false}
            value={prerequisiteFlagData?.variations.get(prerequisite.variation)}
            variations={prerequisiteFlagData?.variations}
            onChange={(variation) => {
              invariant(prerequisiteFlagData, 'Expected flag');
              if (variation) {
                return onChange(
                  null,
                  prerequisite.setIn(
                    ['variation'],
                    prerequisiteFlagData?.variations.findIndex((v) => v === variation),
                  ),
                  variation._id,
                );
              }
            }}
            isInPortal={isInPortal}
            flag={prerequisiteFlagData}
            isFlagOn={prerequisiteFlagData ? prerequisiteFlagData.isOn(environmentKey) : false}
            id={selectVariationFieldId}
          />
        </Cell>

        <Cell layout={CellLayoutValue.AUTO}>
          <ButtonGroup>
            <IconButton
              kind="destructive"
              icon={<Icon name="minus" />}
              aria-label={`delete prerequisite flag '${prerequisiteFlagData?.name}'`}
              onClick={onDelete}
              disabled={isHoldoutPrerequisite}
            />
            {isFlagPrerequisitesEnabled && (
              <IconButton
                kind="primary"
                icon={<Icon name="add" />}
                aria-label="Add prerequisite"
                onClick={onAddPrerequisite}
                hidden={!isLast}
                aria-hidden={!isLast}
                className={styles.addBtn}
              />
            )}
          </ButtonGroup>
        </Cell>
      </Grid>
      {flagKey && !isHoldoutPrerequisite && (
        <NewTabLink
          className={styles.preReqLink}
          href={toHref(toFlagTargeting({ projectKey, flagKey, environmentKey }))}
          text="View flag"
          size="tiny"
        />
      )}
      {isHoldoutPrerequisite && (
        <Text className="u-fs-xs u-mt-xs u-color-text-ui-tertiary">
          You cannot edit a prerequisite flag when it is included in an experiment holdout
        </Text>
      )}
    </>
  );
}
