export function formatRange(
  lower: number | undefined,
  upper: number | undefined,
  formatValue: (num: number) => string,
) {
  if ((lower === undefined || lower === 0) && (upper === undefined || upper === 0)) {
    return '-';
  }
  return `[${formatResult(lower, formatValue)}, ${formatResult(upper, formatValue)}]`;
}

function formatResult(num: number | undefined, formatValue: (num: number) => string) {
  if (num === undefined) {
    return '-';
  }
  return maybeInfinity(num) ?? formatValue(num);
}

function maybeInfinity(num: number) {
  if (num > Number.MAX_VALUE * 0.9) {
    return 'Infinity';
  }
  if (num < -Number.MAX_VALUE * 0.9) {
    return '-Infinity';
  }
}
