import { m, useReducedMotion } from 'framer-motion';

import styles from './CelebrationToggles.module.css';

function OrangeToggle({ setAnimateCelebration }: { setAnimateCelebration: (animate: boolean) => void }) {
  return (
    <m.svg
      initial={{ left: '-100%' }}
      animate={{ left: '100%' }}
      transition={{ duration: 2, delay: 0.5, repeat: 0 }}
      onAnimationComplete={() => setAnimateCelebration(false)}
      className={styles.orangeToggle}
      width="286"
      height="177"
      viewBox="0 0 286 177"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.7"
        d="M235.135 88.5C235.135 67.7893 218.345 51 197.635 51L-0.00038147 51L-0.000374913 126L197.635 126C218.345 126 235.135 109.211 235.135 88.5Z"
        fill="url(#paint0_linear_375_9346)"
      />
      <g opacity="0.9" filter="url(#filter0_d_375_9346)">
        <circle cx="197.635" cy="88.5" r="37.5" fill="url(#paint1_linear_375_9346)" />
      </g>
      <defs>
        <filter
          id="filter0_d_375_9346"
          x="109.88"
          y="0.745262"
          width="175.509"
          height="175.509"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="25.1274" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.832635 0 0 0 0 0.832635 0 0 0 0 0.832635 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_375_9346" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_375_9346" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_375_9346"
          x1="-22.2976"
          y1="61.1351"
          x2="196.621"
          y2="51"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.123144" stopColor="#EBFF38" stopOpacity="0" />
          <stop offset="1" stopColor="#FF386B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_375_9346"
          x1="160.389"
          y1="134.25"
          x2="247.764"
          y2="51"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.123144" stopColor="#FF386B" />
          <stop offset="1" stopColor="#EBFF38" />
        </linearGradient>
      </defs>
    </m.svg>
  );
}

function PinkToggle({ setAnimateCelebration }: { setAnimateCelebration: (animate: boolean) => void }) {
  return (
    <m.svg
      initial={{ left: '-100%' }}
      animate={{ left: '100%' }}
      transition={{ duration: 2, delay: 1, repeat: 0 }}
      onAnimationComplete={() => setAnimateCelebration(false)}
      className={styles.pinkToggle}
      width="287"
      height="80"
      viewBox="0 0 287 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M286.117 40C286.117 17.9086 268.209 1.56778e-06 246.117 3.50173e-06L-0.000427246 2.50476e-05L-0.000420262 80L246.117 80C268.209 80 286.117 62.0914 286.117 40Z"
        fill="url(#paint0_linear_375_11957)"
      />
      <ellipse cx="246.174" cy="40" rx="39.945" ry="40" fill="url(#paint1_linear_375_11957)" />
      <defs>
        <linearGradient
          id="paint0_linear_375_11957"
          x1="-7.43207"
          y1="33.4884"
          x2="221.091"
          y2="40.9098"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.164218" stopColor="#A34FDE" stopOpacity="0" />
          <stop offset="1" stopColor="#FF38DF" stopOpacity="0.66" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_375_11957"
          x1="313.951"
          y1="5.11037e-06"
          x2="215.144"
          y2="104.264"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.123144" stopColor="#FF386B" />
          <stop offset="1" stopColor="#A34FDE" />
        </linearGradient>
      </defs>
    </m.svg>
  );
}

function GreenToggle({ setAnimateCelebration }: { setAnimateCelebration: (animate: boolean) => void }) {
  return (
    <m.svg
      initial={{ left: '-100%' }}
      animate={{ left: '100%' }}
      transition={{ duration: 2, delay: 0.75, repeat: 0 }}
      onAnimationComplete={() => setAnimateCelebration(false)}
      className={styles.greenToggle}
      width="387"
      height="212"
      viewBox="0 0 387 212"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M335.789 106C335.789 75.6243 311.165 51 280.789 51L-0.000427246 51L-0.00041763 161L280.789 161C311.165 161 335.789 136.376 335.789 106Z"
        fill="url(#paint0_linear_375_14370)"
      />
      <g filter="url(#filter0_d_375_14370)">
        <circle cx="280.789" cy="106" r="55" fill="url(#paint1_linear_375_14370)" />
      </g>
      <defs>
        <filter
          id="filter0_d_375_14370"
          x="175.534"
          y="0.745262"
          width="210.509"
          height="210.509"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="25.1274" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.921836 0 0 0 0 1 0 0 0 0 0.222269 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_375_14370" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_375_14370" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_375_14370"
          x1="-0.000455309"
          y1="106.965"
          x2="233.508"
          y2="106.965"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBFF38" stopOpacity="0" />
          <stop offset="1" stopColor="#3DD6F5" stopOpacity="0.62" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_375_14370"
          x1="360.912"
          y1="30.3291"
          x2="238.262"
          y2="152.979"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.123144" stopColor="#EBFF38" />
          <stop offset="1" stopColor="#3DD6F5" />
        </linearGradient>
      </defs>
    </m.svg>
  );
}

export function CelebrationToggles({ setAnimateCelebration }: { setAnimateCelebration: (animate: boolean) => void }) {
  const prefersReducedMotion = useReducedMotion();

  return prefersReducedMotion ? null : (
    <>
      <PinkToggle setAnimateCelebration={setAnimateCelebration} />
      <OrangeToggle setAnimateCelebration={setAnimateCelebration} />
      <GreenToggle setAnimateCelebration={setAnimateCelebration} />
    </>
  );
}
