import { AmberfloProvider } from '@amberflo/uikit/components/molecules/AmberfloProvider';
import { SDKTheme } from '@amberflo/uikit/types';
import { amberfloSessionToken } from '@gonfalon/constants';

import './styles/AmberfloContainer.css';

type Props = {
  children: JSX.Element | JSX.Element[];
  themeOptions?: SDKTheme;
};

const AmberfloContainer = ({ children, themeOptions }: Props) => {
  const session = amberfloSessionToken() ?? null;

  const defaultTheme = {
    brandColor: '#4059ff',
    fontSize: 14,
    fontFamily:
      'inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,Helvetica,Arial,sans-serif;',
  };

  return (
    <AmberfloProvider session={session} theme={{ ...defaultTheme, ...themeOptions }}>
      {children}
    </AmberfloProvider>
  );
};

/* eslint-disable import/no-default-export */
export default AmberfloContainer;
