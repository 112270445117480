import { useState } from 'react';
import { DialogTrigger } from '@launchpad-ui/components';

import { LegacyMeasuredRolloutMetricsButton, MeasuredRolloutMetricsModal } from './MeasuredRolloutMetrics';

export function LegacyManageFlagMeasuredRolloutMetricsButton() {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <DialogTrigger>
        <LegacyMeasuredRolloutMetricsButton setModalOpen={setModalOpen} />
        <MeasuredRolloutMetricsModal isOpen={isModalOpen} setIsOpen={setModalOpen} />
      </DialogTrigger>
    </>
  );
}
