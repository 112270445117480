import { CollapsibleInstructionList } from 'components/InstructionList/CollapsibleInstructionList';
import { Flag } from 'utils/flagUtils';
import { InstructionIndexToConflictsInfo, SemanticInstruction } from 'utils/instructions/shared/types';
import { VariationSemanticInstruction } from 'utils/instructions/variations/types';

import { InstructionListConflicts } from './InstructionListConflicts';

export type PendingSemanticInstructionsProps = {
  instructions: SemanticInstruction[] | VariationSemanticInstruction[];
  flag: Flag;
  projKey: string;
  envKey: string;
  instructionIndexToConflictsInfo?: InstructionIndexToConflictsInfo;
  expandTargetList?: boolean;
  hideCategoryHeaders?: boolean;
  onExit(): void;
  isApprovalRequest?: boolean;
};

export const PendingSemanticInstructions = ({
  instructions,
  flag,
  projKey,
  envKey,
  instructionIndexToConflictsInfo = {},
  onExit,
  expandTargetList,
  hideCategoryHeaders,
  isApprovalRequest,
}: PendingSemanticInstructionsProps) => {
  const hasConflicts = Object.keys(instructionIndexToConflictsInfo).length !== 0;
  return (
    <div className="PendingSemanticInstructions">
      {hasConflicts && (
        <InstructionListConflicts
          instructions={instructions as SemanticInstruction[]}
          flag={flag}
          projKey={projKey}
          envKey={envKey}
          instructionIndexToConflictsInfo={instructionIndexToConflictsInfo}
          expandTargetList={expandTargetList}
          onExit={onExit}
        />
      )}
      <CollapsibleInstructionList
        instructions={instructions}
        flag={flag}
        projKey={projKey}
        envKey={envKey}
        hideCategoryHeaders={hideCategoryHeaders}
        expandTargetList={expandTargetList}
        onExit={onExit}
        isApprovalRequest={isApprovalRequest}
      />
    </div>
  );
};
