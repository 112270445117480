// eslint-disable-next-line no-restricted-imports
import { fromJS, Map, OrderedMap } from 'immutable';
import { normalize, schema } from 'normalizr';

import { AuditLogEntry, createAuditLogEntry } from 'utils/auditLogUtils';
import { convertMapToOrderedMap } from 'utils/collectionUtils';
import http, { jsonToImmutable, jsonToImmutableError } from 'utils/httpUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';

type RequestBody = BodyInit | object;
const entries = new schema.Entity('entries', {}, { idAttribute: '_id' });

export type AuditLogEntriesResponse = ImmutableMap<{
  entities: ImmutableMap<{
    entries: OrderedMap<string, AuditLogEntry>;
  }>;
  result: ImmutableMap<{
    _links: ImmutableMap<{ self: Link }>;
    items: string[];
  }>;
}>;

async function getEntries(url: string, policy: RequestBody): Promise<AuditLogEntriesResponse> {
  let request;

  if (policy) {
    request = http.report(url, { body: policy });
  } else {
    request = http.get(url);
  }

  return request
    .then(async (res: Response) =>
      res.json().then((jsonResponse: { _links: ImmutableMap<{ site: Link }>; items: AuditLogEntry[] }) => {
        const data = normalize(jsonResponse, {
          items: [entries],
        });
        return fromJS(data).withMutations((map: Map<string, AuditLogEntry>) => {
          map.updateIn(['entities', 'entries'], (entry: Map<string, AuditLogEntry>) =>
            convertMapToOrderedMap(entry, data.result.items),
          );
          map.updateIn(['entities', 'entries'], (entry: Map<string, AuditLogEntry>) =>
            entry ? entry.map(createAuditLogEntry) : OrderedMap(),
          );
        });
      }),
    )
    .catch(jsonToImmutableError);
}

async function getEntryById(entryId: string): Promise<AuditLogEntry> {
  return http
    .get(`/api/v2/auditlog/${entryId}`)
    .then(jsonToImmutable)
    .then(createAuditLogEntry)
    .catch(jsonToImmutableError);
}

export { getEntries, getEntryById };
