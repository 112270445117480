import { cloneDeep } from '@gonfalon/es6-utils';
import { DateFormat } from '@gonfalon/format';
import { format } from 'date-fns';

import {
  CustomWorkflowMaintenanceWindowConfig,
  CustomWorkflowType,
} from 'components/CustomWorkflows/SelectWorkflowTypeModal/utils';
import { Flag } from 'utils/flagUtils';

import {
  createWorkflowBuilderAbsoluteScheduleStep,
  createWorkflowBuilderRelativeScheduleStep,
  createWorkflowBuilderStage,
  createWorkflowBuilderTurnFlagOffStep,
  createWorkflowBuilderTurnFlagOnStep,
  WorkflowBuilderStateType,
} from '../utils';

export function getMaintenanceWindowInitialState(options: {
  config: CustomWorkflowMaintenanceWindowConfig;
  initialState: WorkflowBuilderStateType;
  flag: Flag;
}) {
  const initialState = cloneDeep(options.initialState);

  initialState.type = CustomWorkflowType.MAINTENANCE_WINDOW;

  initialState.name = `Maintenance window for ${options.flag.name} on ${format(
    options.config.startDate,
    DateFormat.MM_DD_YYYY,
  )}`;

  initialState.stages = [
    createWorkflowBuilderStage(getMaintenanceWindowStartStageName(options.config.targeting), [
      createWorkflowBuilderAbsoluteScheduleStep(options.config.startDate),
      options.config.targeting === 'on'
        ? createWorkflowBuilderTurnFlagOnStep()
        : createWorkflowBuilderTurnFlagOffStep(),
    ]),
    createWorkflowBuilderStage(getMaintenanceWindowEndStageName(options.config.targeting), [
      createWorkflowBuilderRelativeScheduleStep(options.config.waitDuration, options.config.waitDurationUnit),
      options.config.targeting === 'on'
        ? createWorkflowBuilderTurnFlagOffStep()
        : createWorkflowBuilderTurnFlagOnStep(),
    ]),
  ];

  return initialState;
}

export function getMaintenanceWindowStartStageName(
  configTargeting: CustomWorkflowMaintenanceWindowConfig['targeting'],
) {
  return `Turn flag ${configTargeting.toUpperCase()}`;
}

export function getMaintenanceWindowEndStageName(configTargeting: CustomWorkflowMaintenanceWindowConfig['targeting']) {
  return `Turn flag ${configTargeting === 'on' ? 'OFF' : 'ON'}`;
}
