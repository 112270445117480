import { Icon } from '@launchpad-ui/icons';
import { format } from 'date-fns';

import styles from './ReleaseDuration.module.css';

function ReleaseDate({ label, date }: { label: string; date: number }) {
  return (
    <div>
      <div className={styles.label}>{label}</div>
      <div className={styles.date}>{format(new Date(date), 'MM/dd/yyyy')}</div>
    </div>
  );
}

export function ReleaseDuration({ startDate, completionDate }: { startDate?: number; completionDate: number }) {
  return (
    <div className={styles.container}>
      {!!startDate && (
        <>
          <ReleaseDate label="Started" date={startDate} />
          <Icon className={styles.arrow} name="arrow-right-thin" size="small" />
        </>
      )}
      <ReleaseDate label="Completed" date={completionDate} />
    </div>
  );
}
