import { Suspense, useState } from 'react';
import { useModifierKeys } from '@gonfalon/dom';
import { useKeyCombo } from '@gonfalon/react';
import { DialogTrigger, IconButton, Popover, Tooltip, TooltipTrigger } from '@launchpad-ui/components';

import { type SelectedEnvironment } from '../types';

import { AddEnvironmentDialog } from './AddEnvironmentDialog';
import { EnvironmentFallback } from './EnvironmentFallback';

import styles from './EnvironmentSelector.module.css';

export function AddEnvironmentDialogTrigger({
  projectKey,
  excludedIds,
  onSelect,
}: {
  projectKey: string;
  excludedIds: Set<string>;
  onSelect: (environment: SelectedEnvironment) => void;
}) {
  const keys = useModifierKeys();
  const [isOpen, setOpen] = useState(false);

  useKeyCombo('.', () => {
    setOpen(true);
  });

  return (
    <DialogTrigger isOpen={isOpen} onOpenChange={setOpen}>
      <TooltipTrigger>
        <IconButton aria-label="Add environment" variant="minimal" size="medium" icon="add" className={styles.add} />
        <Tooltip placement="bottom">View another environment</Tooltip>
      </TooltipTrigger>
      <Popover placement="bottom end">
        <Suspense fallback={<EnvironmentFallback />}>
          <AddEnvironmentDialog
            projectKey={projectKey}
            excludedIds={excludedIds}
            onSelect={(v) => {
              !keys.current.shiftKey && setOpen(false);
              onSelect(v);
            }}
          />
        </Suspense>
      </Popover>
    </DialogTrigger>
  );
}
