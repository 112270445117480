import { ModuleContent } from 'components/ui/module';

type ValidatedModuleContentProps = {
  validation?: {
    isIncomplete: () => boolean;
    isInvalid: () => boolean;
  };
  isSnug?: boolean;
  isSeamless?: boolean;
  addSpaceBelow?: boolean;
  children: React.ReactNode;
  className?: string;
  isCondensedVertically?: boolean;
};

/* eslint-disable import/no-default-export */
export default function ValidatedModuleContent({
  children,
  validation,
  isCondensedVertically,
  isSnug,
  isSeamless,
  addSpaceBelow,
  className,
  ...props
}: ValidatedModuleContentProps) {
  const isInvalid = validation && validation.isInvalid();
  const isIncomplete = !isInvalid && validation && validation.isIncomplete();

  return (
    <ModuleContent
      isIncomplete={isIncomplete}
      isInvalid={isInvalid}
      isSnug={isSnug}
      isSeamless={isSeamless}
      addSpaceBelow={addSpaceBelow}
      isCondensedVertically={isCondensedVertically}
      className={className}
      {...props}
    >
      {children}
    </ModuleContent>
  );
}
