import { ApplicationRep } from '@gonfalon/applications';
import { uniqBy } from '@gonfalon/es6-utils';
import { useApplication, useApplications } from '@gonfalon/rest-api';

export type AppOption = {
  label: string;
  name: string;
  value: string;
};

const mapApplicationRepToOption = (a: ApplicationRep) => ({
  label: a.name,
  name: a.name,
  value: a.key,
});

export const useApplicationOptions = ({ searchTerm }: { searchTerm?: string }) => {
  const { data: applications, isPending } = useApplications({
    params: {
      filter: {
        key: searchTerm || undefined,
        name: searchTerm || undefined,
      },
      sort: 'name',
    },
  });

  const options: AppOption[] = applications?.items?.map(mapApplicationRepToOption) || [];

  return {
    options,
    isLoading: isPending,
  };
};

/**
 * Fetch a list of application options with search filter applied and always with the selected app
 */
export const useApplicationOptionsWithSelectedApp = ({
  searchTerm,
  selectedAppKey,
}: {
  searchTerm?: string;
  selectedAppKey: string;
}) => {
  const { data: collection, isPending: areApplicationsLoading } = useApplications({
    params: {
      filter: {
        key: searchTerm || undefined,
        name: searchTerm || undefined,
      },
      sort: 'name',
    },
  });
  const { data: application, isPending: isAppLoading } = useApplication({ applicationKey: selectedAppKey });

  let reps: ApplicationRep[] = [];
  if (collection?.items) {
    reps = [...collection?.items];
  }
  if (application) {
    reps = [...reps, application];
  }

  const options: AppOption[] = uniqBy(reps, (r) => r.key)
    .map(mapApplicationRepToOption)
    .sort((a, b) => a.name.localeCompare(b.name));

  return {
    options,
    isLoading: areApplicationsLoading && isAppLoading,
  };
};
