import { Time } from '@gonfalon/datetime';
import { DateFormat } from '@gonfalon/format';
import { Banner } from 'launchpad';

type ArchiveExperimentBannerProps = {
  archivedDate: number;
};

export const ArchiveExperimentBanner = ({ archivedDate }: ArchiveExperimentBannerProps) => (
  <Banner kind="info">
    <span className="u-color-text-ui-primary">
      <span>This experiment was archived on</span>{' '}
      <Time dateFormat={DateFormat.MMM_D_YYYY} datetime={archivedDate} notooltip />
    </span>
  </Banner>
);
