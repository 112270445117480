import { Dispatch } from 'react';
import { Immutable } from 'immer';

import { ExperimentPatchBody, IterationBody } from '../../../../api/api.types';
import {
  ExperimentUpdateBody,
  UPDATE_ATTRIBUTES,
  UPDATE_EDIT_REASON,
  UPDATE_HYPOTHESIS,
  UPDATE_METRICS,
  UPDATE_RANDOMIZATION_UNIT,
  UPDATE_RULE_ID,
  UPDATE_TRAFFIC_RESHUFFLE,
  UPDATE_TREATMENTS,
} from '../../types';

export const UPDATE_INSTRUCTION = 'updateInstruction';
export const RESET_STATE = 'resetState';

export type ActionType =
  | typeof UPDATE_INSTRUCTION
  | typeof UPDATE_METRICS
  | typeof UPDATE_ATTRIBUTES
  | typeof UPDATE_TREATMENTS
  | typeof UPDATE_TRAFFIC_RESHUFFLE
  | typeof UPDATE_RULE_ID
  | typeof UPDATE_HYPOTHESIS
  | typeof UPDATE_EDIT_REASON
  | typeof UPDATE_RANDOMIZATION_UNIT
  | typeof RESET_STATE;

export type Action = {
  type: ActionType;
  payload: ExperimentUpdateBody | null;
};

export type Dispatcher = Dispatch<Action>;

export type State = Immutable<{
  instructions: ExperimentPatchBody[];
  iterationBody: IterationBody;
}>;

export const initialReducerState = (): State => ({
  instructions: [],
  iterationBody: {
    hypothesis: '',
    canReshuffleTraffic: undefined,
    metrics: [],
    flags: {},
    treatments: [],
  },
});
