import { enableReleaseAutomation } from '@gonfalon/dogfood-flags';
import { trackReleasePipelineReplaced, trackReleaseStarted } from '@gonfalon/release-pipelines';
import {
  flagsList,
  releasePipelinesDetailReleaseProgressions,
  useReleasePipeline,
  useUpdateFlag,
  useUpdateRelease,
} from '@gonfalon/rest-api';
import { useProjectKey } from '@gonfalon/router';
import { SnackbarQueue, ToastQueue } from '@launchpad-ui/components';
import { useQueryClient } from '@tanstack/react-query';

export function useStartRelease({
  releasePipelineKey = '',
  onMutate,
  onSuccess,
  onError,
  previousReleasePipelineKey,
}: {
  releasePipelineKey: string | undefined;
  onMutate?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
  previousReleasePipelineKey?: string;
}) {
  const projectKey = useProjectKey();
  const { data: releasePipeline } = useReleasePipeline(
    {
      projectKey,
      releasePipelineKey,
    },
    { enabled: !!releasePipelineKey },
  );

  const queryClient = useQueryClient();

  const { mutate: addReleasePipeline, isPending: isAddPending } = useUpdateFlag({
    onMutate,
    onSuccess: async () => {
      previousReleasePipelineKey ? trackReleasePipelineReplaced() : trackReleaseStarted();
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: releasePipelinesDetailReleaseProgressions({ projectKey, releasePipelineKey }).queryKey,
        }),
        previousReleasePipelineKey &&
          queryClient.invalidateQueries({
            queryKey: releasePipelinesDetailReleaseProgressions({
              projectKey,
              releasePipelineKey: previousReleasePipelineKey,
            }).queryKey,
          }),
      ]);
      ToastQueue.success(`Started release on ${releasePipeline?.name}`);
      onSuccess?.();
    },
    onError: () => {
      SnackbarQueue.error({ description: `Failed to start release on ${releasePipeline?.name}. Try again later.` });
      onError?.();
    },
  });

  const { mutate: replaceReleasePipeline, isPending: isReplacePending } = useUpdateRelease({
    onMutate,
    onSuccess: async () => {
      previousReleasePipelineKey ? trackReleasePipelineReplaced() : trackReleaseStarted();
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: flagsList({ projectKey }).queryKey }),
        previousReleasePipelineKey &&
          queryClient.invalidateQueries({
            queryKey: releasePipelinesDetailReleaseProgressions({
              projectKey,
              releasePipelineKey: previousReleasePipelineKey,
            }).queryKey,
          }),
      ]);
      ToastQueue.success(`Started release on ${releasePipeline?.name}`);
      onSuccess?.();
    },
    onError: () => {
      SnackbarQueue.error({ description: `Failed to start release on ${releasePipeline?.name}. Try again later.` });
      onError?.();
    },
  });

  const isPending = enableReleaseAutomation() ? isReplacePending : isAddPending;

  function startRelease(flagKey: string) {
    if (releasePipeline) {
      if (enableReleaseAutomation()) {
        replaceReleasePipeline({
          projectKey,
          flagKey,
          input: {
            releasePipelineKey,
            releaseVariationId: undefined,
          },
        });
      } else {
        const op = previousReleasePipelineKey ? 'replace' : 'add';
        addReleasePipeline({
          projectKey,
          flagKey,
          input: {
            patch: [
              {
                op,
                path: '/releasePipelineKey',
                value: releasePipeline.key,
              },
            ],
          },
        });
      }
    }
  }

  return { startRelease, isPending } as const;
}
