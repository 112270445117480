import { useQuery } from '@tanstack/react-query';

import { getFlagByKey } from 'sources/FlagAPI';
import { Prerequisite } from 'utils/flagUtils';

type Props = {
  prerequisite: Prerequisite;
  projectKey: string;
  environmentKey: string;
};

export function useRenderPrerequisiteFlagData({ prerequisite, projectKey, environmentKey }: Props) {
  const flagKey = prerequisite.key;
  const scopeId = 'prerequisite-flag';

  const flagQuery = useQuery({
    queryKey: [scopeId, projectKey, environmentKey, 'flags', flagKey],
    enabled: flagKey !== '',
    refetchOnWindowFocus: false,
    queryFn: async () => getFlagByKey(projectKey, flagKey, [environmentKey]),
  });

  return flagQuery;
}
