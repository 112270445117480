import { useMutation } from '@tanstack/react-query';

import { updateExperimentName } from 'components/experimentation/common/api/experiment';

type Props = {
  projKey: string;
  envKey: string;
  experimentKey: string;
};

export function useUpdateExperimentName({ projKey, envKey, experimentKey }: Props) {
  return useMutation({
    mutationFn: async (newName: string) => updateExperimentName(projKey, envKey, experimentKey, newName),
  });
}
