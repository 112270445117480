import UpsellImagePrerequisites from 'components/upsells/upsellImages/UpsellImagePrerequisites';
import { UpsellModal } from 'components/upsells/UpsellModal';
import { getDocumentationUrl } from 'utils/urlUtils';

const flagPrerequisitesDocLink = getDocumentationUrl('home/flags/prereqs');

type UpsellFeatureModalPrerequisitesProps = {
  isOpen: boolean;
  onCancel(): void;
};

const UpsellFeatureModalPrerequisites = ({ isOpen, onCancel }: UpsellFeatureModalPrerequisitesProps) => (
  <UpsellModal
    featureKind="flagPrerequisites"
    isEnterpriseOnlyFeature
    isOpen={isOpen}
    onCancel={onCancel}
    title="Create flag prerequisites"
    featureImage={<UpsellImagePrerequisites />}
    featureBullets={[
      'Create conditional feature dependencies',
      'Group 1 or more flags behind a prerequisite flag',
      'Make dependencies transparent to your entire team',
    ]}
    learnMoreUrl={flagPrerequisitesDocLink}
    componentForAnalyticTracking="Prerequisites"
  />
);

/* eslint-disable import/no-default-export */
export default UpsellFeatureModalPrerequisites;
