import { createTrackerForCategory } from '@gonfalon/analytics';
import { Map } from 'immutable';

import { FlagStatus } from 'utils/flagStatusUtils';
import { Flag, FlagConfiguration, Rollout } from 'utils/flagUtils';

export const ACTIVE_FLAGS = 'serving multiple variations';
export const NO_VARIATION = 'variation not set';

export const filterStatus = (productionEnvironments: string[], statuses: Map<string, FlagStatus>) => {
  let selectedStatuses: Map<string, FlagStatus> = Map();
  statuses.mapKeys((env: string) => {
    if (productionEnvironments.includes(env)) {
      const status = statuses.get(env);
      if (status) {
        selectedStatuses = selectedStatuses.set(env, status);
      }
    }
  });
  return selectedStatuses;
};

export function getRolloutValue(
  flagConfig: FlagConfiguration,
  status: FlagStatus,
  flag: Flag,
): {
  variationValue: string | undefined;
  rollout: Rollout | undefined;
  variationIndex?: number | undefined;
} {
  const { rollout } = flagConfig.fallthrough;
  let variationIndex: number | undefined;
  let variationValue: string | undefined;

  if (status.name === 'active' && flagConfig.on) {
    return { variationValue: ACTIVE_FLAGS, rollout };
  }

  //first check if flag is on
  if (flagConfig.on) {
    variationIndex = flagConfig.fallthrough.variation;
    //if there is a fallthrough variation and no rollout, render that value
    if (variationIndex !== undefined && variationIndex !== null) {
      if (Number.isInteger(variationIndex) && !rollout) {
        variationValue = flag.variations.get(variationIndex)?.getDisplay();
      }
    }
  }

  const offVariation = flagConfig.offVariation;
  //if the flag is off use the offvariation
  if (!variationIndex && !flagConfig.on) {
    if (offVariation !== undefined && offVariation >= 0) {
      variationIndex = flagConfig.offVariation;
      variationValue = flag.variations.get(offVariation)?.getDisplay();
    } else if (status.default) {
      //if there is not off variation, use the default status (set in sdk)
      const variationValues = flag.variations.map((d) => d.value);
      variationIndex = variationValues.indexOf(status.default);
      variationValue = flag.variations.get(variationIndex)?.name;
    } else {
      //if none of these exist, we do not know what the flag will evaluate to
      variationValue = NO_VARIATION;
    }
  }

  return { variationValue, rollout, variationIndex };
}

export enum trackArchivePropertyStatus {
  PASS = 'pass',
  FAIL = 'fail',
  UNKNOWN = 'unknown',
}

export enum trackArchiveFlagAction {
  RESTORE = 'Restore',
  ARCHIVE = 'Archive',
}
export enum trackArchiveUserAction {
  CLOSED = 'Closed',
  VIEWED = 'Viewed',
  SUBMITTED = 'Submitted',
  ENV_PINNED = 'Env Pinned',
  ENV_UNPINNED = 'Env Unpinned',
}

export type trackArchivePropertiesType = {
  checkPrerequisites: trackArchivePropertyStatus;
  checkRequests?: trackArchivePropertyStatus;
  checkCodeReferences?: trackArchivePropertyStatus;
  checkVariations?: trackArchivePropertyStatus;
  environmentCount: number;
  environmentKey: string;
  flagKey?: string;
  flagStatus?: string;
  maintainerId?: string;
};

type TrackArchivePropertiesTypeWithFlagProps = trackArchivePropertiesType & {
  createdDate: number;
  flagPermanent: boolean;
  lastUpdated: number;
};

export const trackArchiveEvent = createTrackerForCategory('Archive');
export const trackDeprecateEvent = createTrackerForCategory('Deprecate');

const formatTrackArchivePanelProperties = (
  flagAction: trackArchiveFlagAction,
  {
    checkPrerequisites,
    checkRequests,
    checkCodeReferences,
    checkVariations,
    createdDate,
    environmentCount,
    environmentKey,
    flagKey,
    flagPermanent,
    flagStatus,
    lastUpdated,
    maintainerId,
  }: TrackArchivePropertiesTypeWithFlagProps,
) => {
  const restoreProperties = {
    checkVariations: checkVariations || trackArchivePropertyStatus.UNKNOWN,
    createdDate,
    environmentCount,
    environmentKey,
    flagPermanent,
    lastUpdated,
  };
  if (flagAction === trackArchiveFlagAction.RESTORE) {
    return restoreProperties;
  }
  return {
    ...restoreProperties,
    checkPrerequisites,
    checkRequests: checkRequests || trackArchivePropertyStatus.UNKNOWN,
    checkCodeReferences: checkCodeReferences || trackArchivePropertyStatus.UNKNOWN,
    flagKey,
    flagStatus: flagStatus || trackArchivePropertyStatus.UNKNOWN,
    maintainerId,
  };
};

export const trackArchivePanelEvent = (
  // TODO - ensure props for current env available sc-158445
  flag: Flag,
  {
    userAction,
    properties,
  }: {
    userAction: trackArchiveUserAction;
    properties: trackArchivePropertiesType;
  },
) => {
  const flagAction = flag.archived ? trackArchiveFlagAction.RESTORE : trackArchiveFlagAction.ARCHIVE;
  const env = flag.environments.get(properties.environmentKey);
  const flagProps = {
    createdDate: flag.creationDate,
    flagKey: flag.key,
    flagPermanent: !flag.temporary,
    lastUpdated: env?.lastModified ?? 0,
    maintainerId: flag.maintainerId,
    maintainerTeamKey: flag.maintainerTeamKey,
  };
  const formattedProperties = formatTrackArchivePanelProperties(flagAction, { ...flagProps, ...properties });
  trackArchiveEvent(`${flagAction} Flag Panel ${userAction}`, formattedProperties);
};

export const getArchivePanelTrackingProperties = (
  hasDependentFlags: boolean,
  productionEnvironmentKeys: string[],
  environmentKey: string,
  flagStatus?: string,
): trackArchivePropertiesType => ({
  checkPrerequisites: hasDependentFlags ? trackArchivePropertyStatus.FAIL : trackArchivePropertyStatus.PASS,
  environmentCount: productionEnvironmentKeys.length,
  environmentKey,
  flagStatus: flagStatus || '',
});

export const trackArchiveRestoreButtonClickEvent = (flag: Flag, location: string) => {
  const label = flag.archived ? 'Restore' : 'Archive';
  trackArchiveEvent(`${label} Flag Button Clicked`, {
    location,
    flagKey: flag.key,
    maintainerId: flag.maintainerId,
    maintainerTeamKey: flag.maintainerTeamKey,
  });
};
