import { createTrackerForCategory } from '@gonfalon/analytics';

import { EnvironmentFlagSettings, FlagConfiguration, RuleExclusionSettings } from 'utils/flagUtils';

export const renderEventsSendingText = (
  isDataExportEnabled: boolean,
  isExperimentationEnabled: boolean,
  noReferenceToDestinations?: boolean,
) => {
  if (isDataExportEnabled && isExperimentationEnabled) {
    if (noReferenceToDestinations) {
      return 'experiments and data export';
    }
    return 'experiments and data export destinations';
  }
  if (isExperimentationEnabled) {
    return 'experiments';
  }
  if (isDataExportEnabled) {
    if (noReferenceToDestinations) {
      return 'data export';
    }
    return 'data export destinations';
  }
};

export const isAnyRuleSendingEvents = (
  flagConfig: FlagConfiguration | EnvironmentFlagSettings | RuleExclusionSettings,
) => {
  const customRulesWithTrackEventsEnabled = flagConfig.rules.filter((rule) => rule.trackEvents);
  return flagConfig.trackEventsFallthrough || customRulesWithTrackEventsEnabled.size > 0;
};

export const trackAllEventsForFlag = (
  trackEvents: boolean,
  hasExperiments: boolean,
  flagConfig: FlagConfiguration | RuleExclusionSettings,
) => trackEvents || (hasExperiments && !isAnyRuleSendingEvents(flagConfig));

export const getTrackEventsCount = (flagConfig: FlagConfiguration, hasExperiments: boolean) => {
  const trackEvents = flagConfig.trackEvents;
  const totalTargetingRules = flagConfig.countTargetingRules();
  const totalRulesSendingEvents = trackAllEventsForFlag(trackEvents, hasExperiments, flagConfig)
    ? totalTargetingRules
    : flagConfig.countTargetingRulesTrackingEvents();

  return { active: totalRulesSendingEvents, total: totalTargetingRules };
};

export const getValidationAlertMessage = (hasExperiments: boolean) => {
  if (hasExperiments) {
    return 'This flag is participating in one or more experiments, so you must choose at least one targeting rule.';
  }
  return 'You did not select a targeting rule. If you save this setting, LaunchDarkly will not send detailed events to data export destinations.';
};

export const trackRuleExclusionEvent = createTrackerForCategory('RuleExclusion');
