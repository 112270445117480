import { Component, ReactNode } from 'react';
import { isEqual, pick } from '@gonfalon/es6-utils';
import { formatCount } from '@gonfalon/format';
import { pluralize } from '@gonfalon/strings';
import type { ReferenceLine as RechartsReferenceLine } from 'recharts';

import { UsageOptionType } from 'actions/usage';
import { ErrorAlert } from 'components/ui/alert/ErrorAlert';
import { TimeSeries } from 'components/ui/datavis';
import { DataOnlySeries, MetadataType, TimeSeriesComponentProps } from 'components/ui/datavis/TimeSeriesComponent';
import { RequestErrorType } from 'reducers/types';
import { ImmutableServerError } from 'utils/httpUtils';

import { FilterType, TypeOfUsage, UsageMetadataType } from './types';

export type UsageDataProps<T = MetadataType> = Omit<TimeSeriesComponentProps<T>, 'series'> & {
  series: DataOnlySeries[];
  fetchData(filters: FilterType, options: UsageOptionType): void;
  filters: FilterType;
  filterWatchList?: string[];
  error?: Error | ImmutableServerError | RequestErrorType;
  type?: 'overview' | 'details';
  renderFilters: (
    finalProps: Omit<UsageDataProps, 'error' | 'renderFilters' | 'type' | 'fetchData' | 'renderVisualization'>,
  ) => ReactNode;
  defaultFromDate: string;
  disableGraphAndDisplayMessage?: string;
  usageType?: TypeOfUsage;
  areaChartLimit?: number;
  useAverageLineLegend?: boolean;
  renderTotal?: boolean;
  // If renderVisualization is not provided, default to a basic time series line chart
  renderVisualization?: (
    finalProps: Omit<UsageDataProps, 'error' | 'renderFilters' | 'type' | 'fetchData' | 'renderVisualization'>,
  ) => ReactNode;
};

export class UsageData<T extends { [key: string]: unknown } = UsageMetadataType> extends Component<UsageDataProps<T>> {
  static defaultProps = {
    isUTC: false,
    type: 'details',
    useAverageLineLegend: true,
  };

  componentDidMount() {
    const { filters, fetchData, usageType, defaultFromDate, renderTotal } = this.props;
    fetchData(filters, { typeOfUsage: usageType, defaultFromDate, renderTotal });
  }

  componentDidUpdate(prevProps: UsageDataProps<T>) {
    const { filters, filterWatchList, fetchData, usageType, defaultFromDate, renderTotal } = this.props;

    const prevFilters = filterWatchList ? pick(prevProps.filters, filterWatchList) : prevProps.filters;
    const nextFilters = filterWatchList ? pick(filters, filterWatchList) : filters;

    if (!isEqual(prevFilters, nextFilters) || prevProps.renderTotal !== renderTotal) {
      fetchData(filters, { typeOfUsage: usageType, defaultFromDate, renderTotal });
    }
  }

  render() {
    const { error, renderFilters, type, fetchData, renderVisualization, ...finalProps } = this.props;

    if (this.props.isReady && error) {
      return <ErrorAlert error={error} />;
    }

    return (
      <div className="UsageDataModule">
        <div className={`UsageModule--${type}`}>
          <div className="UsageModule-toolbar">{renderFilters(finalProps)}</div>
          <div className="UsageDataModule-visualization">{this.renderVisualization(finalProps)}</div>
        </div>
      </div>
    );
  }

  renderAggregateLegend = () => {
    const { dataLabel } = this.props;
    const average = this.getAverage();

    return (
      <>
        <svg width="40px" height="10px" viewBox="0 0 10 10">
          <line stroke="#5ebcf9" strokeDasharray="5 5" strokeWidth={2} x1="0" y1="5" x2="40" y2="5" />
        </svg>
        <span className="u-xs-pl-m u-xs-pr-xl">
          {pluralize(`${formatCount(average)} average ${dataLabel}`, average)}
        </span>
      </>
    );
  };

  renderPlanLimitLegend = () => {
    const { limit } = this.props;
    if (!limit) {
      return null;
    }
    return (
      <>
        <svg width="40px" height="10px" viewBox="0 0 10 10">
          <line stroke="#999" strokeDasharray="5 5" strokeWidth={2} x1="0" y1="5" x2="40" y2="5" />
        </svg>
        <span className="u-xs-pl-m u-xs-pr-xl">{`Limit: ${formatCount(limit)}`}</span>
      </>
    );
  };

  renderLegend = () => {
    const showAverageLine = this.showAverage();
    return (
      <div className="ChartLegend">
        {this.props.limit && this.renderPlanLimitLegend()}
        {showAverageLine && this.renderAggregateLegend()}
      </div>
    );
  };

  renderAverageLine = (
    _: TimeSeriesComponentProps, // eslint-disable-line no-unused-vars
    { ReferenceLine }: { ReferenceLine: typeof RechartsReferenceLine },
  ) => (
    <ReferenceLine y={Math.round(this.getAverage())} stroke="#5ebcf9" strokeDasharray="5 5" ifOverflow="extendDomain" />
  );

  renderVisualization(
    props: Omit<UsageDataProps, 'error' | 'renderFilters' | 'type' | 'fetchData' | 'renderVisualization'>,
  ) {
    const {
      renderVisualization,
      type,
      chartType,
      metadata,
      useAverageLineLegend,
      areaChartLimit,
      disableGraphAndDisplayMessage,
      renderReferenceLines,
    } = this.props;
    const tickCount = type === 'overview' ? 4 : 6;

    return renderVisualization ? (
      renderVisualization(props)
    ) : (
      <TimeSeries
        {...props}
        tickCount={tickCount}
        renderLegend={useAverageLineLegend ? this.renderLegend : undefined}
        useMarkerLegend={!!metadata?.length}
        renderReferenceLines={this.showAverage() ? this.renderAverageLine : renderReferenceLines}
        maxSeries={chartType === 'area' && areaChartLimit ? areaChartLimit : undefined}
        disableAndDisplayMessage={disableGraphAndDisplayMessage}
        emptyChartState={
          disableGraphAndDisplayMessage
            ? { lead: <div className="UsageDataError">{disableGraphAndDisplayMessage}</div> }
            : undefined
        }
      />
    );
  }

  showAverage = () => {
    const { chartType } = this.props;
    return chartType === 'line' || chartType === 'bar';
  };

  getAverage = () => {
    const { series } = this.props;
    const sum = series.reduce((a, b) => a + b[0], 0);
    return sum === 0 ? 0 : Math.round(sum / series.length);
  };
}
