import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { findEntries } from 'actions/auditLog';
import AuditLogPagination from 'components/auditLog/AuditLogPagination';
import { GlobalState } from 'reducers';
import { auditLogPaginationSelector } from 'reducers/auditLog';
import { Query } from 'utils/auditLogUtils';

export const PAGE_SIZE = 10;

type OwnProps = {
  query: Query;
};

const mapStateToProps = createSelector(
  auditLogPaginationSelector,
  (_: GlobalState, props: OwnProps) => props.query,
  (pagination, query) => {
    if (!pagination) {
      return { isReady: false, isFetching: false, isLastPage: true };
    }

    return {
      query,
      isReady: pagination.get('pageCount') === 0 ? !pagination.get('isFetching') : true,
      isFetching: pagination.get('isFetching'),
      isLastPage: !pagination.get('nextPageUrl'),
    };
  },
);

const mapDispatchToProps = (dispatch: $TSFixMe, { query }: OwnProps) => ({
  onLoadMore: () => dispatch(findEntries(query, true)),
});

/* eslint-disable import/no-default-export */
export default connect(mapStateToProps, mapDispatchToProps)(AuditLogPagination);
