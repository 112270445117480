import { Time } from '@gonfalon/datetime';
import { DateFormat } from '@gonfalon/format';
import { Link } from '@launchpad-ui/components';
import { Banner } from 'launchpad';

import { trackArchiveEvent } from 'utils/archiveUtils';

type ArchiveBannerProps = {
  flagLink: string;
  archivedDate: number;
};

export const ArchiveBanner = ({ flagLink, archivedDate }: ArchiveBannerProps) => (
  <Banner kind="info">
    <span className="u-color-text-ui-primary">
      <span>This flag was archived on</span>{' '}
      <Time dateFormat={DateFormat.MMM_D_YYYY} datetime={archivedDate} notooltip />.{' '}
      <Link
        href={flagLink}
        onPress={() => trackArchiveEvent('Banner Restore Flag Button Clicked', { location: 'Flag Settings' })}
      >
        Restore this flag
      </Link>
    </span>
  </Banner>
);
