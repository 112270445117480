import { MutableRefObject, useEffect, useLayoutEffect, useRef, useState } from 'react';

export function useIsOverflowing<T extends HTMLElement>(providedRef?: MutableRefObject<T | undefined>) {
  const defaultRef = useRef<T>();

  const ref = providedRef || defaultRef;

  const [isOverflowing, setIsOverflowing] = useState({
    height: false,
    width: false,
  });

  function checkOverflow() {
    if (ref.current) {
      const width = ref.current.scrollWidth > ref.current.offsetWidth;
      const height = ref.current.scrollHeight > ref.current.offsetHeight;
      if (width !== isOverflowing.width || height !== isOverflowing.height) {
        setIsOverflowing({
          width,
          height,
        });
      }
    }
  }

  useEffect(() => {
    checkOverflow();
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', checkOverflow);

    return () => window.removeEventListener('resize', checkOverflow);
  });

  return { ref, isOverflowing };
}
