import { useState } from 'react';
import { Alert } from 'launchpad';

export const EXPERIMENTATION_ALERT_HEADER = 'Experiment data may be impacted by the changes you are making.';
export const EXPERIMENTATION_ALERT_TEXT =
  'Iteration results and confidence intervals may update because of changes you’ve made to this flag’s targeting rules.';

/* eslint-disable import/no-default-export */
export default function ExperimentationAlert() {
  const [hide, setHide] = useState(false);
  return !hide ? (
    <section className="u-mt-l">
      <Alert
        kind="warning"
        size="small"
        dismissible
        onDismiss={() => setHide(true)}
        header={EXPERIMENTATION_ALERT_HEADER}
      >
        {EXPERIMENTATION_ALERT_TEXT}
      </Alert>
    </section>
  ) : null;
}
