import { useState } from 'react';
import { v1ReleasePipelines } from '@gonfalon/dogfood-flags';
import {
  trackMoveToPipelineClicked,
  trackReleaseRemoved,
  trackRemoveReleasePipelineButtonClicked,
} from '@gonfalon/release-pipelines';
import { useIsMutatingFlag, useUpdateFlag } from '@gonfalon/rest-api';
import { useProjectKey } from '@gonfalon/router';
import { SnackbarQueue, ToastQueue } from '@launchpad-ui/components';

import { useReleaseQuery } from 'components/releases/common/releaseQueries';
import { RemoveReleaseConfirmationModal } from 'components/releases/legacy/ManageReleasePipelinePopover/RemoveReleaseConfirmationModal';
import { SkeletonCells } from 'components/skeletons/GridSkeleton';
import { Cell, CellLayoutValue } from 'components/ui/grid';

import { useStartRelease } from '../../common/useStartRelease';

import { LegacyStartReleasePipelineModal } from './LegacyAddReleasePipelineFlow';
import { LegacyCreateReleaseButton } from './LegacyCreateReleaseButton';
import { ManageReleasePopover } from './ManageReleasePopover';

import styles from '../FlagReleaseSummary/LegacyReleaseTracker.module.css';

export type ManageReleasePipelinePopoverProps = {
  isArchived: boolean;
  flagKey: string;
  flagName: string;
  showAddReleaseButton: boolean;
  enableReleaseQuery: boolean;
  setShowAddReleaseButton: (shouldShow: boolean) => void;
  setEnableReleaseQuery: (shouldEnable: boolean) => void;
};

export function ManageReleasePipelinePopover({
  isArchived,
  flagKey,
  flagName,
  showAddReleaseButton,
  enableReleaseQuery,
  setShowAddReleaseButton,
  setEnableReleaseQuery,
}: ManageReleasePipelinePopoverProps) {
  const releaseQuery = useReleaseQuery(flagKey, {
    enabled: enableReleaseQuery,
    retry: false,
  });
  const [showRemoveReleaseModal, setShowRemoveReleaseModal] = useState(false);
  const [showStartReleasePipelineModal, setShowStartReleasePipelineModal] = useState(false);
  const [selectedReleasePipelineKey, setSelectedReleasePipelineKey] = useState(releaseQuery.data?.releasePipelineKey);
  const projKey = useProjectKey();
  const isV1RPNotAvailable = v1ReleasePipelines() !== 'Available';
  const isReleaseUpdating = useIsMutatingFlag();
  const { mutate: removeReleasePipeline } = useUpdateFlag({
    onSuccess: () => {
      ToastQueue.success('Flag removed from release pipeline');
      trackReleaseRemoved();
      setShowAddReleaseButton(true);
      setShowRemoveReleaseModal(false);
    },
    onError: () => {
      SnackbarQueue.error({ description: 'Failed to remove release pipeline. Try again later.' });
      setShowAddReleaseButton(false);
      setEnableReleaseQuery(true);
    },
  });

  const handleRemoveReleasePipeline = () => {
    removeReleasePipeline({
      projectKey: projKey,
      flagKey,
      input: {
        patch: [
          {
            op: 'remove',
            path: '/releasePipelineKey',
          },
        ],
      },
    });
    setEnableReleaseQuery(false);
    trackRemoveReleasePipelineButtonClicked();
  };

  const { startRelease: moveRelease } = useStartRelease({
    releasePipelineKey: selectedReleasePipelineKey,
    onMutate() {
      trackMoveToPipelineClicked();
      closeModal();
    },
    onSuccess() {
      setShowAddReleaseButton(false);
      setEnableReleaseQuery(true);
    },
    previousReleasePipelineKey: releaseQuery.data?.releasePipelineKey,
  });

  function closeModal() {
    setShowStartReleasePipelineModal(false);
  }

  if (showAddReleaseButton && !isReleaseUpdating) {
    if (isArchived || isV1RPNotAvailable) {
      return null;
    }
    return (
      <LegacyCreateReleaseButton
        flagKey={flagKey}
        setShowAddReleaseButton={setShowAddReleaseButton}
        setEnableReleaseQuery={setEnableReleaseQuery}
      />
    );
  }

  if (releaseQuery.isFetching || isReleaseUpdating) {
    return (
      <Cell flex layout={CellLayoutValue.TWO_OF_TWELVE} className="u-flex-middle">
        <SkeletonCells cells={1} className={styles.rpSkeletonNameCell} testId="progress-indicator" />
      </Cell>
    );
  }
  if (releaseQuery.isError) {
    return null;
  }

  if (releaseQuery.isSuccess) {
    const releasePipelineKey = selectedReleasePipelineKey ?? releaseQuery.data.releasePipelineKey;
    return (
      <>
        <ManageReleasePopover
          isArchived={isArchived}
          release={releaseQuery.data}
          setShowRemoveReleaseModal={setShowRemoveReleaseModal}
          setShowStartReleasePipelineModal={setShowStartReleasePipelineModal}
        />
        {showRemoveReleaseModal && (
          <RemoveReleaseConfirmationModal
            confirmationName={flagName}
            confirmationKey={flagKey}
            onCancel={() => setShowRemoveReleaseModal(false)}
            onConfirm={handleRemoveReleasePipeline}
          />
        )}
        {showStartReleasePipelineModal && (
          <LegacyStartReleasePipelineModal
            flagName={flagName}
            onStart={() => moveRelease(flagKey)}
            releasePipelineKey={releasePipelineKey}
            setSelectedReleasePipelineKey={setSelectedReleasePipelineKey}
            shouldShowMultipleReleasePipelines
            onCancel={() => setShowStartReleasePipelineModal(false)}
            startDisabled={releasePipelineKey === releaseQuery.data.releasePipelineKey}
            previousReleasePipelineKey={releaseQuery.data.releasePipelineKey}
          />
        )}
      </>
    );
  }

  return null;
}
