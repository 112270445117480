import { useReducer, useRef } from 'react';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { exposeExperimentationErrors } from '@gonfalon/dogfood-flags';
import { getQueryClient } from '@gonfalon/react-query-client';
import { holdoutsDetailRoot, holdoutsListRoot } from '@gonfalon/rest-api';
import { useParam, useProjectKey } from '@gonfalon/router';
import { SnackbarQueue } from '@launchpad-ui/components';
import { useMutation } from '@tanstack/react-query';

import { ServerErrorBody, ServerResponse } from '../../../../api/api.types';
import { updateExperimentWithIteration } from '../../../../api/experiment';
import { useAlerts } from '../../../../hooks/useAlerts';
import { ExperimentStatus, ExperimentV2, Iteration } from '../../../../types';
import { UpdateField } from '../../types';
import { useUpdateIteration } from '../useUpdateIteration';

import { updateExperimentStatus, updateField } from './actions';
import { reducer } from './reducer';
import { initialReducerState, RESET_STATE } from './types';

/**
 * Hook to update an experiment.
 */
export function useUpdateExperiment() {
  const [state, dispatch] = useReducer(reducer, initialReducerState(), initialReducerState);
  const envKey = useSelectedEnvironmentKey();
  const projKey = useProjectKey();
  const experimentKey = useParam('experimentKey');
  const { updateIteration } = useUpdateIteration({ projectKey: projKey, envKey, experimentKey });
  const { alertExperimentUpdateSucceeded, alertExperimentUpdateFailed } = useAlerts();

  //semaphore used to prevent an infinite retries in updating the iteration
  const retried = useRef(false);

  const mutation = useMutation({
    mutationFn: async ({ iteration }: { iteration: ExperimentV2['currentIteration'] }) => {
      try {
        await updateIteration({ iteration, updatedFields: state.iterationBody });

        if (state.instructions.length > 0) {
          return await updateExperimentWithIteration(projKey, envKey, experimentKey, [...state.instructions]);
        }

        // stubbed until we have copy for all success banners
        alertExperimentUpdateSucceeded();

        return;
      } catch (e) {
        const serverError = e as ServerResponse<ServerErrorBody>;
        const error = await serverError.json();

        if (!retried.current && error.message.includes('configuration has been updated since drafting')) {
          await updateIteration({ iteration });

          retried.current = true;
          return updateExperimentWithIteration(projKey, envKey, experimentKey, [...state.instructions]);
        }

        if (exposeExperimentationErrors()) {
          SnackbarQueue.error({ description: `Could not start iteration: ${error.message ?? error.code}` });
        } else {
          alertExperimentUpdateFailed(error.code);
        }
      } finally {
        // reset our state after each mutation
        dispatch({ type: RESET_STATE, payload: null });
      }
    },
    onSuccess: async (data) => {
      if (data?.holdoutId) {
        const queryClient = getQueryClient();
        await queryClient.invalidateQueries({ queryKey: holdoutsListRoot });
        await queryClient.invalidateQueries({ queryKey: holdoutsDetailRoot });
      }
    },
  });

  return {
    reset: () => dispatch({ type: RESET_STATE, payload: null }),
    saveExperimentChanges: async (iteration: Iteration) => mutation.mutateAsync({ iteration }),
    updateField: (key: UpdateField, payload: unknown) => updateField(key, payload, dispatch),
    updateExperimentStatus: (status: ExperimentStatus, treatmentId?: string, reason?: string) =>
      updateExperimentStatus({ status, treatmentId, reason, dispatch }),
  };
}
