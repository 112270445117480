import { Flag, Iteration, Variation } from 'components/experimentation/common/types';

export function variationLabel(v: Variation): string {
  if (v.name) {
    return v.name;
  }
  if (typeof v.value === 'object') {
    return JSON.stringify(v.value);
  }
  return String(v.value);
}

export function hasExperimentAllocation(flag: Flag, environmentKey: string) {
  const ruleHasExperimentAllocation = Boolean(
    flag.environments[environmentKey].rules.find((rule) => {
      if (!rule.rollout) {
        return false;
      }

      return Object.keys(rule.rollout).includes('experimentAllocation');
    }),
  );

  const fallthroughHasExperimentAllocation = Boolean(
    flag.environments[environmentKey].fallthrough.rollout?.experimentAllocation,
  );

  return ruleHasExperimentAllocation || fallthroughHasExperimentAllocation;
}

export function experimentExists(experiments: Flag['experiments'], metricId: string) {
  return Boolean(experiments.items.find((experiment) => experiment._metric._id === metricId));
}

// until we support multiple flags per iteration each iteration will only have a single flag
// this function helps you get the one flag key from your iteration
export function singleFlagKey(iteration: Iteration | undefined) {
  if (!iteration) {
    return undefined;
  }
  const [flagKey] = Object.keys(iteration.flags);
  return flagKey;
}
