import qs from 'qs';

import { Flag, FlagStatus, Link, WeightedExperimentRollout } from 'components/experimentation/common/types';
import { constructFilterParam, FlagFilterProps } from 'utils/flagFilterUtils';
import http, { middleware } from 'utils/httpUtils';

export async function getFlag(projectKey: string, flagKey: string, environmentKey: string): Promise<Flag> {
  const url = `/api/v2/flags/${projectKey}/${flagKey}?env=${environmentKey}`;
  const request = await http.get(url);
  const json: Flag = await middleware.json(request).catch(middleware.jsonError);
  return json;
}

type GetAllFlagsJson = {
  items: Flag[];
  totalCount: number;
  _links: {
    self: Link;
  };
};

export async function getAllFlags(
  projectKey: string,
  environmentKeys?: string[],
  filters?: FlagFilterProps,
  options: { fullListing?: boolean; showArchived?: boolean } = {},
): Promise<GetAllFlagsJson> {
  let queryString = '';
  let url = `/api/v2/flags/${projectKey}`;

  if (filters) {
    const filter = constructFilterParam(filters);

    const query: {
      filter?: string;
      limit?: number;
      offset?: number;
      sort?: string;
    } = {};

    if (filter !== '') {
      query.filter = filter;
    }

    if (filters.limit && filters.limit > 0) {
      query.limit = filters.limit;
    }

    if (filters.offset && filters.offset > 0) {
      query.offset = filters.offset;
    }

    if (filters.sort) {
      query.sort = filters.sort;
    }

    queryString = qs.stringify(query, { indices: false });
  }

  if (environmentKeys && environmentKeys.length > 0) {
    url = `${url}?${environmentKeys
      .filter((key: string) => !!key)
      .map((key: string) => `env=${key}`)
      .join('&')}`;
  }

  if (Object.keys(options).length > 0) {
    url = `${url}${options.fullListing ? '&summary=0' : '&summary=1'}${options.showArchived ? '&archived=true' : ''}`;
  }

  if (queryString !== '') {
    url = `${url}&${queryString}`;
  }

  const request = await http.get(url);
  const json: GetAllFlagsJson = await middleware.json(request).catch(middleware.jsonError);
  return json;
}

export type GetFlagStatusByEnvJson = {
  readonly _links: {
    parent: Link;
    self: Link;
  };
  key: string;
  environments: {
    [key: string]: FlagStatus;
  };
};

export async function getFlagStatusByEnvs(
  projectKey: string,
  flagKey: string,
  environmentKeys: string[],
): Promise<GetFlagStatusByEnvJson> {
  let url = `/api/v2/flag-status/${projectKey}/${flagKey}`;

  if (environmentKeys && environmentKeys.length > 0) {
    url = `${url}?${environmentKeys.map((envKey: string) => `env=${envKey}`).join('&')}`;
  }

  const request = await http.get(url, { beta: true });
  const json: GetFlagStatusByEnvJson = await middleware.json(request).catch(middleware.jsonError);
  return json;
}

export async function setExperimentAllocation(
  projectKey: string,
  flagKey: string,
  environmentKey: string,
  rollout: WeightedExperimentRollout,
  ruleId?: string,
) {
  const url = `/api/v2/flags/${projectKey}/${flagKey}?ignoreConflicts=true&ignoreApprovals=true`;

  const body = {
    comment: 'Create experiment flow - set audience allocation',
    environmentKey,
    instructions: [
      {
        kind: ruleId ? 'updateRuleVariationOrRollout' : 'updateFallthroughVariationOrRollout',
        rolloutWeights: rollout.variationWeights,
        experimentAllocation: {
          canReshuffle: rollout.canReshuffle,
          defaultVariation: rollout.defaultVariation,
        },
        ruleId,
      },
    ],
  };

  const request = await http.patch(url, {
    body,
    beta: true,
    headers: {
      'Content-Type': 'application/json; domain-model=launchdarkly.semanticpatch',
    },
  });

  const json: Flag = await middleware.json(request).catch(middleware.jsonError);
  return json;
}
