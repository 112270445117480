import { SetURLSearchParams } from 'react-router-dom';
import { createTrackerForCategory } from '@gonfalon/analytics';

import { ReviewersCount } from 'utils/approvalsUtils';

enum PendingChangesSource {
  ACTIVE_WORKFLOW_SUMMARY = 'active-workflow-summary',
  APPROVALS_DASHBOARD = 'approvals-dashboard',
  CUSTOM_WORKFLOW = 'custom-workflow',
  TARGETING = 'targeting-pending-changes',
  VARIATIONS = 'variations-pending-changes',
}

export enum PageSource {
  APPROVAL_DETAILS = 'Approval details',
  APPROVALS_DASHBOARD = 'Approvals Dashboard',
  TARGETING = 'Targeting',
  TARGETING_PENDING_CHANGES = 'Targeting Pending Changes Drawer',
  COMPARE_AND_COPY = 'Compare and copy',
  CONTEXTS = 'Contexts',
  USERS = 'Users',
  FLAG_DASHBOARD = 'Flag dashboard',
  DELETE_PENDING_CHANGES = 'Delete pending changes',
  VARIATIONS = 'Variations',
  VARIATIONS_PENDING_CHANGES = 'Variations Pending Changes Drawer',
  WORKFLOW_PROGRESS_BAR = 'Workflow progress bar',
  WORKFLOW_READ_ONLY = 'Workflow read-only',
}

const PendingChangesSourceToPageSource = {
  [PendingChangesSource.ACTIVE_WORKFLOW_SUMMARY]: PageSource.WORKFLOW_PROGRESS_BAR,
  [PendingChangesSource.APPROVALS_DASHBOARD]: PageSource.APPROVALS_DASHBOARD,
  [PendingChangesSource.CUSTOM_WORKFLOW]: PageSource.WORKFLOW_READ_ONLY,
  [PendingChangesSource.TARGETING]: PageSource.TARGETING_PENDING_CHANGES,
  [PendingChangesSource.VARIATIONS]: PageSource.VARIATIONS_PENDING_CHANGES,
};

export function getPageSourceForTracking(pathname: string) {
  if (pathname.includes('/features') && pathname.endsWith('/targeting')) {
    return PageSource.TARGETING;
  }

  if (pathname.includes('/variations')) {
    return PageSource.VARIATIONS;
  }

  if (pathname.includes('/features/compare')) {
    return PageSource.COMPARE_AND_COPY;
  }

  if (pathname.includes('/contexts')) {
    return PageSource.CONTEXTS;
  }

  if (pathname.includes('/features')) {
    return PageSource.FLAG_DASHBOARD;
  }
}

type TrackApprovalRequestEventAttributes = {
  approvalSystem?: string;
  source?: string;
  changeKind?: string;
  location?: string;
  reviewerType?: string;
  reviewersCount?: ReviewersCount;
  numFlagsChanged?: number;
};

type ApprovalRequestTrackEventInfo = {
  isRequired?: boolean;
  pageSource?: PageSource;
  fromSaveChanges?: boolean; // true if user selects approval request in modal
  bypassApprovals?: boolean;
  approvalSystem?: string;
};

type ReviewActionsInfo = {
  timeSinceCreation: number;
  timeSinceApproval?: number;
};

export const trackApprovalRequestEvent = (
  event: string,
  attributes?: TrackApprovalRequestEventAttributes | ApprovalRequestTrackEventInfo,
) => {
  createTrackerForCategory('Approval Request')(event, attributes);
};

export const trackApprovalPanelLoadedEvent = () => {
  trackApprovalRequestEvent('Approval Settings Panel Loaded');
};

export const trackApprovalRequestView = (
  searchParams: URLSearchParams,
  updateSearchParams: SetURLSearchParams,
  approvalSystem?: string,
) => {
  // Blank source denotes that the approval request was viewed from direct link
  const trackingSource = PendingChangesSourceToPageSource[searchParams.get('source') as PendingChangesSource] || '';
  trackApprovalRequestEvent('Approval Request Viewed', { pageSource: trackingSource, approvalSystem });

  const cleanParams = new URLSearchParams(searchParams);
  cleanParams.delete('source');

  if (cleanParams.size > 0) {
    updateSearchParams(cleanParams, { replace: true });
  }
};

export const trackCreateApprovalRequestButtonClick = (attributes: ApprovalRequestTrackEventInfo) =>
  trackApprovalRequestEvent('Request Approval Button Clicked', attributes);

export const trackApprovalRequestDropdownButtonClick = (attributes: ApprovalRequestTrackEventInfo) =>
  trackApprovalRequestEvent('Approval Request Dropdown Button Clicked', attributes);

export const trackApprovalRequestApproveButtonClick = (attributes: ApprovalRequestTrackEventInfo & ReviewActionsInfo) =>
  trackApprovalRequestEvent('Approval Request Approve Button Clicked', attributes);

export const trackApprovalRequestApplyButtonClick = (attributes: ApprovalRequestTrackEventInfo & ReviewActionsInfo) =>
  trackApprovalRequestEvent('Approval Request Apply Button Clicked', attributes);

export const trackApprovalRequestDeclineButtonClick = (attributes: ApprovalRequestTrackEventInfo & ReviewActionsInfo) =>
  trackApprovalRequestEvent('Approval Request Decline Button Clicked', attributes);

export const trackApprovalRequestCommentButtonClick = (attributes: ApprovalRequestTrackEventInfo) =>
  trackApprovalRequestEvent('Approval Request Comment Button Clicked', attributes);

export const trackApprovalRequestDeleteSubmitted = (attributes: ApprovalRequestTrackEventInfo) =>
  trackApprovalRequestEvent('Approval Request Delete Submitted', attributes);

export type ApprovalRequestViewButtonSource =
  | 'Flag variations'
  | 'Flag targeting'
  | 'Approvals dashboard / Status'
  | 'Approvals dashboard / Reason'
  | 'Approvals dashboard / View details';

export const trackApprovalRequestViewButtonClicked = (attributes: {
  approvalSystem: string;
  source: ApprovalRequestViewButtonSource;
}) => trackApprovalRequestEvent('Approval Request View Button Clicked', attributes);

export const trackApprovalRequestApplyChangesViewButtonClicked = (attributes: {
  approvalSystem: string;
  source: ApprovalRequestViewButtonSource;
}) => trackApprovalRequestEvent('Approval Request Apply Changes View Button Clicked', attributes);
