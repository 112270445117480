import { useState } from 'react';
import { useDebounce } from '@gonfalon/async';
// eslint-disable-next-line no-restricted-imports
import { Label } from 'launchpad';

import SelectTeamOrMember from 'components/SelectTeamOrMember/SelectTeamOrMember';
import { TeamOrMemberOptionType } from 'components/SelectTeamOrMember/SelectTeamOrMemberUtils';
import useTeamOrMemberOptions from 'hooks/useTeamOrMemberOptions';
import { Member } from 'utils/accountUtils';
import { trackNewMaintainerSelectedFromDropdown } from 'utils/flagUtils';
import { Team } from 'utils/teamsUtils';

export type SelectTeamOrMemberContainerProps = {
  disabled?: boolean;
  id?: string;
  onChange(maintainer?: Member | Team): void;
  placeholder?: string;
  value?: string;
  showLabel?: boolean;
  ariaLabel?: string;
  className?: string;
  teamOnly?: boolean;
};

const SelectTeamOrMemberContainer = ({
  disabled,
  id,
  onChange,
  placeholder,
  value,
  ariaLabel,
  showLabel,
  className,
  teamOnly,
}: SelectTeamOrMemberContainerProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { isReady, options } = useTeamOrMemberOptions({ searchQuery, selectedValue: value, teamOnly });

  const handleChange = (option: TeamOrMemberOptionType) => {
    trackNewMaintainerSelectedFromDropdown(option?.kind);
    // We pass the selected member or team back to the form
    onChange(option.member || option.team);
  };

  const handleInputChange = useDebounce(setSearchQuery, 150);

  return (
    <>
      {showLabel && ariaLabel && id && <Label htmlFor={id}>{ariaLabel}</Label>}
      <SelectTeamOrMember
        className={className}
        disabled={disabled}
        id={id}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        placeholder={placeholder}
        value={value}
        isLoading={!isReady}
      />
    </>
  );
};

/* eslint-disable import/no-default-export */
export default SelectTeamOrMemberContainer;
