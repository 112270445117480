import { constants } from 'utils/reduxUtils';

/* eslint-disable import/no-default-export */
export default constants('auditLog', [
  'REQUEST_ENTRIES',
  'REQUEST_ENTRIES_FAILED',
  'REQUEST_ENTRIES_DONE',
  'REQUEST_ENTRY',
  'REQUEST_ENTRY_FAILED',
  'REQUEST_ENTRY_DONE',
  'INVALIDATE_ENTRIES',

  'SHOW_INSIGHTS_DETAIL',
  'HIDE_INSIGHTS_DETAIL',
]);
