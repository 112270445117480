import { useMemo } from 'react';
import { useMatches } from 'react-router-dom';
import { z } from 'zod';

const filtersSchema = z.object({
  Filters: z.function().returns(z.any()),
});

/**
 * Render the filters for the current route.
 *
 * A route defines its filters via its `handle`.
 */
export function Filters() {
  const matches = useMatches();
  const currentRoute = matches.at(-1);

  const RouteFilters = useMemo(() => {
    if (currentRoute === undefined) {
      return null;
    }

    const parse = filtersSchema.safeParse(currentRoute.handle);
    if (!parse.success) {
      return null;
    }

    return parse.data.Filters;
  }, [currentRoute]);

  return RouteFilters ? (
    <div>
      <RouteFilters />
    </div>
  ) : null;
}
