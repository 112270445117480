import { useEffect, useState } from 'react';
import { disallowNewAttributeValues } from '@gonfalon/dogfood-flags';
import { isString } from '@gonfalon/es6-utils';
import { CustomSelect, OptionProps } from '@gonfalon/launchpad-experimental';
import { Icon } from '@launchpad-ui/icons';
import { Label, Tooltip } from 'launchpad';
import { v4 } from 'uuid';

import { ContextAttributesNames } from 'components/Contexts/types';
import { makeSelectAttributeOptionsForContextKind } from 'components/Contexts/utils/contextTargetingUtils';
import PrivateAttribute from 'components/PrivateAttribute';

import { SelectAttributeContainerProps } from './SelectAttributeContainer';

export type SelectContextAttributeProps = SelectAttributeContainerProps & {
  contextAttributeNames: ContextAttributesNames[];
  isReady: boolean;
};

export type AttributeOption = {
  value: string;
  label: string;
  contextKind?: string;
  redacted?: boolean;
};

export const defaultLoadingOption = [{ label: 'Loading...', value: 'loading', isDisabled: true }];

export function SelectContextAttribute({
  backspaceRemoves,
  className,
  disabled,
  isClearable,
  onChange,
  specialAttributeOptions,
  placeholder,
  value,
  contextKind,
  isInPortal = false,
  isReady,
  contextAttributeNames,
  ariaLabel = 'Select attribute',
  id = v4(),
}: SelectContextAttributeProps) {
  // use internal state for tracking options so users can add their own values and we can populate the select
  const [_options, _setOptions] = useState(
    makeSelectAttributeOptionsForContextKind(contextKind, value, contextAttributeNames, specialAttributeOptions),
  );

  useEffect(() => {
    _setOptions(
      makeSelectAttributeOptionsForContextKind(contextKind, value, contextAttributeNames, specialAttributeOptions),
    );
  }, [contextKind, specialAttributeOptions, contextAttributeNames]);

  const onAttributeChange = (v: OptionProps | null) => {
    let optionValue = null;

    if (isString(v)) {
      optionValue = v;
    } else if (v) {
      optionValue = v.value;
    }

    onChange(optionValue);
  };

  return (
    <>
      <Label htmlFor={id}>Attribute</Label>
      <CustomSelect
        id={id}
        componentType={disallowNewAttributeValues() ? 'Select' : 'Creatable'}
        ariaLabel={ariaLabel}
        backspaceRemoves={backspaceRemoves}
        className={className}
        classNamePrefix="SelectContextAttribute"
        disabled={disabled}
        formatCreateLabel={formatCreateLabel}
        formatOptionLabel={formatOptionLabel}
        isClearable={isClearable}
        onChange={onAttributeChange}
        styles={{
          menu: {
            width: 'unset',
          },
        }}
        options={isReady ? _options : defaultLoadingOption}
        placeholder={placeholder}
        value={_options.find((option) => option.value === value)}
        isInPortal={isInPortal}
        isLoading={!isReady}
        isValidNewOption={(input: string) => input.length > 0 && !_options.find((option) => input === option.value)}
      />
    </>
  );
}

const formatOptionLabel = (option: OptionProps) => {
  const hasLeadingWhiteSpace = /^\s/.test(option.label as string);
  const hasTrailingWhiteSpace = /\s$/.test(option.label as string);

  return (
    <>
      {hasLeadingWhiteSpace && (
        <Tooltip placement="bottom" content="This attribute name contains leading whitespace">
          <span className="SelectAttribute-whitespace" />
        </Tooltip>
      )}
      {option.redacted ? (
        <PrivateAttribute attribute={option.value} size="small" placement="right" className="TargetingPage" />
      ) : (
        <span>
          <span>{option.label}</span>
        </span>
      )}
      {hasTrailingWhiteSpace && (
        <Tooltip placement="bottom" content="This attribute name contains trailing whitespace">
          <span className="SelectAttribute-whitespace" />
        </Tooltip>
      )}
    </>
  );
};

const formatCreateLabel = (inputValue: string) => (
  <div className="SelectContextAttributeOption-label">
    <Icon name="add-circle" className="SelectContextAttributeOption-addIcon" size="small" />
    Add "<span className="u-fw-semibold">{inputValue}</span>"
  </div>
);
