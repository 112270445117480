import { ReactNode, useMemo, useState } from 'react';
import { isFlagStatusEnabled } from '@gonfalon/dogfood-flags';
import { toFlagTargeting } from '@gonfalon/navigator';
import {
  ReleasePhase,
  ReleaseTrackerPhaseStatus,
  trackMarkPhaseComplete,
  trackMarkPhaseCompleteAttempted,
  trackMarkReleaseComplete,
  trackPhaseButtonClicked,
  trackReleasedButtonClicked,
} from '@gonfalon/release-pipelines';
import { useFlag, useFlagStatusForEnvironments, useUpdateFlagRelease } from '@gonfalon/rest-api';
import { useFlagKey, useProjectKey } from '@gonfalon/router';
import { ProgressBar, SnackbarQueue, ToastQueue } from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';
import { m } from 'framer-motion';
import { Button, Popover } from 'launchpad';

import { determinePhaseStatus } from '../../common/determinePhaseStatus';
import { determineLastCompletedPhase, getFlagEnvironmentSummaryVariations } from '../../common/releaseUtils';

import { LegacyCompleteReleaseModal } from './LegacyCompleteReleaseModal';
import {
  LegacyReleasedPopover,
  LegacyReleaseTrackerPhasePopover,
  LegacyReleaseTrackerPopoverFlagEnvironmentSummary,
} from './LegacyReleaseTrackerPhasePopover';

import styles from './LegacyReleaseTracker.module.css';

function ReleasePipelinePhaseConnectorSVG({
  active,
  shouldAnimateConnector,
}: {
  active?: boolean;
  shouldAnimateConnector?: boolean;
}) {
  return (
    <svg className={cx(styles.releaseTrackerPhaseConnector)} width="50" height="16" xmlns="http://www.w3.org/2000/svg">
      <line x1="0" y1="8" x2="100%" y2="8" strokeWidth="1" />
      {active && (
        <line
          className={cx(styles.releaseTrackerPhaseConnectorActive, {
            [styles.releaseTrackerPhaseConnectorAnimated]: shouldAnimateConnector,
          })}
          x1="0"
          y1="8"
          x2="100%"
          y2="8"
          strokeWidth="1"
        />
      )}
    </svg>
  );
}

export function LegacyReadOnlyPhaseItem({ name }: { name: string }) {
  return <div className={styles.releaseTrackerPhaseLabel}>{name}</div>;
}

type PhaseItemProps = {
  name: string;
  status: ReleaseTrackerPhaseStatus;
};

export function LegacyPhaseItem({ name, status }: PhaseItemProps) {
  const classes = cx(styles.releaseTrackerPhaseButton, {
    [styles.activePhase]: status === 'active',
    [styles.completedPhase]: status === 'complete' || status === 'released',
    [styles.inactivePhase]: status === 'inactive',
  });

  let icon;
  switch (status) {
    case 'active':
      icon = (
        <Icon
          data-test-id="release-tracker-active-phase-icon"
          className={styles.activeIcon}
          name="star-circle"
          size="medium"
        />
      );
      break;
    case 'complete':
      icon = (
        <Icon
          data-test-id="release-tracker-completed-phase-icon"
          className={styles.completedIcon}
          name="check"
          size="medium"
        />
      );
      break;
    case 'released':
      icon = (
        <Icon
          data-test-id="release-tracker-released-phase-icon"
          className={styles.completedIcon}
          name="confetti"
          size="medium"
        />
      );
      break;
    case 'inactive':
      if (name === 'Released') {
        icon = (
          <Icon
            data-test-id="release-tracker-released-phase-icon"
            className={styles.completedIcon}
            name="confetti"
            size="medium"
          />
        );
      }
      break;
    default:
      icon = undefined;
  }

  return (
    <div
      className={cx(styles.phaseContainer, {
        [styles.activePhaseContainer]: status === 'active',
      })}
    >
      <Button icon={icon} renderIconFirst className={classes} size={status === 'active' ? 'medium' : 'small'}>
        {name}
      </Button>
    </div>
  );
}

function ReleasedItem({ status, completionDate }: { status: ReleaseTrackerPhaseStatus; completionDate?: number }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Popover
        popoverContentClassName={styles.releaseTrackerPopover}
        placement="bottom-start"
        isOpen={isOpen}
        onInteraction={() => {
          setIsOpen(!isOpen);
          trackReleasedButtonClicked();
        }}
      >
        <LegacyPhaseItem name="Released" status={status} />
        <m.div initial={false} transition={{ ease: 'easeIn', duration: 1 }}>
          <LegacyReleasedPopover completionDate={completionDate} />
        </m.div>
      </Popover>
    </>
  );
}

function ReleaseTrackerBase(props: { children: ReactNode; className?: string }) {
  return (
    <div className={cx(styles.releaseTrackerPhases, props.className)} data-test-id="release-tracker">
      {props.children}
    </div>
  );
}

function PhaseItemBase({
  index,
  phaseCount = 0,
  phaseStatuses,
  renderPhaseItem,
  shouldAnimateConnector,
}: {
  index: number;
  phaseCount?: number;
  phaseStatuses?: ReleaseTrackerPhaseStatus[];
  renderPhaseItem: () => ReactNode;
  shouldAnimateConnector?: boolean;
}) {
  const numChildren = phaseStatuses?.length ?? phaseCount;

  return (
    <>
      <div className={styles.releaseTrackerPhase} key={`phase-${index}`}>
        {renderPhaseItem()}
      </div>
      {index !== numChildren - 1 ? (
        <ReleasePipelinePhaseConnectorSVG
          active={phaseStatuses && phaseStatuses[index + 1] !== 'inactive'}
          shouldAnimateConnector={shouldAnimateConnector}
        />
      ) : undefined}
    </>
  );
}

export type LegacyReadOnlyReleaseTrackerProps = {
  className?: string;
  phases: string[];
};

export function LegacyReadOnlyReleaseTracker({ phases, className }: LegacyReadOnlyReleaseTrackerProps) {
  return (
    <ReleaseTrackerBase className={cx(styles.readOnlyReleaseTracker, className)}>
      {phases.map((phase, idx) => (
        <PhaseItemBase
          key={`read-only-phase-${idx}-${phase}`}
          index={idx}
          phaseCount={phases.length}
          renderPhaseItem={() => <LegacyReadOnlyPhaseItem name={phase} />}
        />
      ))}
    </ReleaseTrackerBase>
  );
}

export type LegacyReleaseTrackerProps = {
  className?: string;
  phases: ReleasePhase[];
  setAnimateCelebration: (shouldAnimate: boolean) => void;
  isV2Pipeline?: boolean;
};

// #start
export function LegacyReleaseTracker({
  phases,
  className,
  setAnimateCelebration,
  isV2Pipeline,
}: LegacyReleaseTrackerProps) {
  const phaseStatuses = useMemo(() => phases.map(determinePhaseStatus), [phases]);
  const validReactivatePhase = useMemo(() => phases.findLast(determineLastCompletedPhase), [phases]);
  const projectKey = useProjectKey();
  const flagKey = useFlagKey();

  // Collect a list of environments for the release:
  // - For each phase in the release, add the environment key from each audience in the phase
  // - Use a Set to filter duplicates (environments can appear in multiple phases)
  // - Convert the set back into an array so that it can be used in requests
  const environmentsInRelease = Array.from(
    phases.reduce((accum, phase) => {
      phase._audiences.forEach(({ environment }) => accum.add(environment.key));
      return accum;
    }, new Set<string>()),
  );

  // TODO: We should wrap these hooks together to make unit testing easier
  const {
    data: flagStatuses,
    isPending: isLoadingStatuses,
    isError: flagStatusesIsError,
  } = useFlagStatusForEnvironments(
    { projectKey, flagKey, environmentKeys: environmentsInRelease },
    { enabled: isFlagStatusEnabled() },
  );
  const {
    data: flag,
    isPending: isLoadingFlag,
    isError: flagIsError,
  } = useFlag({
    projectKey,
    flagKey,
    params: {
      env: environmentsInRelease,
      expand: ['evaluation'],
    },
  });

  const getPhaseCanBeReactivated = (id: string) => validReactivatePhase && id === validReactivatePhase._id;

  const isReleaseCompleted = phases.every((phase) => phase.complete);
  const releasedItemStatus = isReleaseCompleted ? 'released' : 'inactive';
  const releaseCompletedDate = phases[phases.length - 1]._completionDate;
  const statuses = phaseStatuses.concat([releasedItemStatus]);

  const [shouldAnimateConnector, setShouldAnimateConnector] = useState(phaseStatuses.map(() => false));

  if (flagStatusesIsError || flagIsError) {
    const renderErrorPhaseItem = (phase: ReleasePhase, i: number) => (
      <Phase
        isError
        phase={phase}
        status={phaseStatuses[i]}
        completionDate={phase._completionDate}
        index={i}
        canReactivated={getPhaseCanBeReactivated(phase._id)}
        isV2Pipeline={!!isV2Pipeline}
        isLoading={isLoadingStatuses || isLoadingFlag}
        flagEnvironmentSummaries={[]}
        isLastPhase={i === phases.length - 1}
        setShouldAnimateConnector={setShouldAnimateConnector}
        setAnimateCelebration={setAnimateCelebration}
      />
    );

    return (
      <ReleaseTrackerBase className={className}>
        {phases.map((phase, i) => (
          <PhaseItemBase
            key={`popover-phase-${phase._id}`}
            index={i}
            phaseStatuses={statuses}
            renderPhaseItem={() => renderErrorPhaseItem(phase, i)}
          />
        ))}
        <PhaseItemBase
          index={statuses.length - 1}
          phaseStatuses={statuses}
          renderPhaseItem={() => <ReleasedItem status={releasedItemStatus} completionDate={releaseCompletedDate} />}
        />
      </ReleaseTrackerBase>
    );
  }

  const renderPhaseItem = (phase: ReleasePhase, i: number) => (
    <Phase
      phase={phase}
      status={phaseStatuses[i]}
      completionDate={phase._completionDate}
      index={i}
      canReactivated={getPhaseCanBeReactivated(phase._id)}
      isV2Pipeline={!!isV2Pipeline}
      isLoading={isLoadingStatuses || isLoadingFlag}
      flagEnvironmentSummaries={phase._audiences.map((audience) => ({
        environment: {
          name: flag?.environments?.[audience.environment.key]?._environmentName ?? 'Env name not found',
          color: `#${audience.environment.color}`,
          key: audience.environment.key,
          url: toFlagTargeting({
            projectKey,
            environmentKey: audience.environment.key,
            flagKey,
          }),
        },
        isTargeting: !!flag?.environments?.[audience.environment.key]?.on,
        flagStatus: (flagStatuses?.[audience.environment.key]?.name ??
          'Flag status not found') as LegacyReleaseTrackerPopoverFlagEnvironmentSummary['flagStatus'],
        flagVariations: flag ? getFlagEnvironmentSummaryVariations(flag, audience.environment.key) : [],
      }))}
      isLastPhase={i === phases.length - 1}
      setShouldAnimateConnector={setShouldAnimateConnector}
      setAnimateCelebration={setAnimateCelebration}
    />
  );

  return (
    <ReleaseTrackerBase className={className}>
      {phases.map((phase, i) => (
        <PhaseItemBase
          key={`popover-phase-${phase._id}`}
          index={i}
          phaseStatuses={statuses}
          renderPhaseItem={() => renderPhaseItem(phase, i)}
          shouldAnimateConnector={shouldAnimateConnector[i]}
        />
      ))}
      <PhaseItemBase
        index={statuses.length - 1}
        phaseStatuses={statuses}
        shouldAnimateConnector={shouldAnimateConnector[shouldAnimateConnector.length - 1]}
        renderPhaseItem={() => <ReleasedItem status={releasedItemStatus} completionDate={releaseCompletedDate} />}
      />
    </ReleaseTrackerBase>
  );
}

type PhaseProps = {
  status: ReleaseTrackerPhaseStatus;
  isError?: boolean;
  completionDate: number | undefined;
  index: number;
  isLoading: boolean;
  phase: ReleasePhase;
  flagEnvironmentSummaries: LegacyReleaseTrackerPopoverFlagEnvironmentSummary[];
  isLastPhase?: boolean;
  canReactivated?: boolean;
  isV2Pipeline: boolean;
  setShouldAnimateConnector: React.Dispatch<React.SetStateAction<boolean[]>>;
  setAnimateCelebration: (shouldAnimate: boolean) => void;
};

function Phase({
  status,
  isError,
  index,
  isLoading,
  phase,
  canReactivated,
  isV2Pipeline,
  completionDate,
  flagEnvironmentSummaries,
  isLastPhase,
  setShouldAnimateConnector,
  setAnimateCelebration,
}: PhaseProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isSaveModalOpen, setSaveModalOpen] = useState(false);
  const flagKey = useFlagKey();
  const projectKey = useProjectKey();

  const { mutate: completePhase } = useUpdateFlagRelease({
    onSuccess: () => {
      setShouldAnimateConnector((previousArray) => {
        // eslint-disable-next-line no-param-reassign
        previousArray[index] = true;
        return previousArray;
      });
      if (isLastPhase) {
        setAnimateCelebration(true);

        setTimeout(() => {
          SnackbarQueue.success({
            title: 'Congratulatons!',
            description: 'Your flag is released! Maintainers will be notified.',
          });
        }, 3000);
        trackMarkReleaseComplete();
      } else {
        ToastQueue.success(`${phase._name} phase completed`);
        trackMarkPhaseComplete();
      }
    },
    onError: () => {
      if (isLastPhase) {
        SnackbarQueue.error({ description: 'Completing release unsuccessful. Try again later' });
      } else {
        SnackbarQueue.error({ description: 'Failed to complete phase. Try again later.' });
      }
    },
  });

  const markPhaseComplete = () => {
    completePhase({
      projectKey,
      flagKey,
      input: {
        patch: [
          {
            op: 'replace',
            path: `/phases/${index}/complete`,
            value: true,
          },
        ],
      },
    });
    if (isLastPhase) {
      setSaveModalOpen(false);
    } else {
      setIsOpen(false);
      trackMarkPhaseCompleteAttempted();
    }
  };

  return (
    <>
      <Popover
        popoverContentClassName={styles.releaseTrackerPopover}
        placement="bottom-start"
        isOpen={isOpen}
        onInteraction={() => {
          setIsOpen(!isOpen);
          trackPhaseButtonClicked();
        }}
      >
        <LegacyPhaseItem name={phase._name} status={status} />
        <m.div initial={false} transition={{ ease: 'easeIn', duration: 1 }}>
          {isLoading ? (
            <>
              <div className="u-d-grid u-flex-center">
                <ProgressBar aria-label="Loading…" isIndeterminate size="large" />
              </div>
              <div className="u-mt-l">Loading phase details</div>
            </>
          ) : (
            <LegacyReleaseTrackerPhasePopover
              isError={isError}
              status={status}
              completionDate={completionDate}
              index={index}
              setIsOpen={setIsOpen}
              canReactivated={canReactivated}
              isV2Pipeline={isV2Pipeline}
              phaseName={phase._name}
              phaseId={phase._id}
              setSaveModalOpen={setSaveModalOpen}
              markPhaseComplete={markPhaseComplete}
              isLastPhase={isLastPhase}
              flagEnvironmentSummaries={flagEnvironmentSummaries}
            />
          )}
        </m.div>
      </Popover>
      {isSaveModalOpen && (
        <LegacyCompleteReleaseModal
          onCancel={() => setSaveModalOpen(false)}
          primaryOnClick={markPhaseComplete}
          secondaryOnClick={() => setSaveModalOpen(false)}
        />
      )}
    </>
  );
}
