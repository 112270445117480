import { relativeDateString } from '@gonfalon/datetime';
import { DateFormat } from '@gonfalon/format';
import { format, isToday } from 'date-fns';

import { USER_CONTEXT_KIND } from 'utils/constants';

import { ContextKind } from '../types';

export const getCreatedFromSource = (createdFrom?: string, creationDate?: number) => {
  if (!createdFrom && !creationDate) {
    return '';
  }

  let formattedDate: string | number = '';

  if (creationDate) {
    formattedDate = isToday(creationDate)
      ? relativeDateString(new Date(creationDate))
      : format(creationDate, DateFormat.MM_DD_YYYY);
  }

  switch (createdFrom) {
    case 'default':
      return 'Built in default';
    case 'auto-add':
      return `Auto added: ${formattedDate}`;
    default:
      return `Manually added: ${formattedDate}`;
  }
};
export const isInvalidKey = (key: string) => {
  const containsInvalidChars = /^(\w|\.|-)+$/.test(key);
  return !containsInvalidChars || key === 'kind' || key === 'multi';
};

export const getContextNameOrKey = (contextKind: ContextKind) => {
  if (contextKind?.name) {
    return contextKind.name;
  }
  return contextKind.key;
};

export function getDefaultTargetingContextKind(contextKinds: ContextKind[]): ContextKind {
  const availableContextKinds = contextKinds.filter((ck) => !ck.archived);

  if (availableContextKinds.length === 0) {
    return { name: 'no context kinds available', key: 'no context kinds available' };
  }

  const userContextKind = availableContextKinds.find((ck) => ck.key === USER_CONTEXT_KIND);

  return (
    userContextKind || availableContextKinds.reduce((a, b) => ((a.lastModified ?? 0) > (b.lastModified ?? 0) ? a : b))
  );
}
