import { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';

import {
  fetchSubscription as fetchSubscriptionAction,
  fetchSubscriptionUsage as fetchSubscriptionUsageAction,
} from 'actions/billing';
import { subscriptionFetchRequestSelector, subscriptionUsageFetchRequestSelector } from 'reducers/billing';
import { ready } from 'utils/reduxUtils';

const mapStateToProps = createSelector(
  subscriptionUsageFetchRequestSelector,
  subscriptionFetchRequestSelector,
  (usageRequest, subscriptionRequest) => ({
    localIsReady: ready(usageRequest, subscriptionRequest),
  }),
);

const mapDispatchToProps = {
  fetchSubscription: fetchSubscriptionAction,
  fetchSubscriptionUsage: fetchSubscriptionUsageAction,
};

type WithSubscriptionAndUsageProps = {
  localIsReady: boolean;
  fetchSubscriptionUsage: () => void;
  fetchSubscription: () => void;
};

export const withSubscriptionAndUsage =
  <PassedComponentProps extends object>(Component: ComponentType<PassedComponentProps>) =>
  (props: PassedComponentProps & WithSubscriptionAndUsageProps) => {
    useEffect(() => {
      const { localIsReady: isReady, fetchSubscription, fetchSubscriptionUsage } = props;
      if (!isReady) {
        fetchSubscription();
        fetchSubscriptionUsage();
      }
    }, []);

    const { fetchSubscriptionUsage, fetchSubscription, localIsReady, ...finalProps } = props;
    return <Component {...(finalProps as PassedComponentProps)} />;
  };

const tmp = compose<ComponentType>(connect(mapStateToProps, mapDispatchToProps), withSubscriptionAndUsage);

/* eslint-disable import/no-default-export */
export default tmp;
