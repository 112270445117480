import { Button } from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';

export function LearnMoreButton({
  link,
  trackEvent,
  className,
}: {
  link: string;
  trackEvent?: () => void;
  className?: string;
}) {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer" onClick={trackEvent}>
      <Button variant="minimal" size="small" aria-label="Learn more" className={className}>
        <Icon name="book-outline" size="small"></Icon>
        Learn more
      </Button>
    </a>
  );
}
