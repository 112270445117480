import { List, Set } from 'immutable';
import { TagGroup, TagGroupProps, TagItem } from 'launchpad';

export type TagListProps = {
  tags: List<string> | Set<string>;
  className?: string;
  onFilterTagClick?: (tag: string) => void;
  size?: TagGroupProps<object>['size'];
  testId?: string;
};

/* eslint-disable import/no-default-export */
export default function TagList({ tags, className, onFilterTagClick, testId, size }: TagListProps) {
  if (tags.size === 0) {
    return null;
  }

  return (
    <div className={className} data-test-id={testId}>
      <TagGroup
        aria-label="Tags"
        size={size}
        onTagClick={
          onFilterTagClick
            ? (key) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                onFilterTagClick !== undefined ? onFilterTagClick(key as string) : null;
              }
            : undefined
        }
      >
        {tags
          .toArray()
          .sort()
          .map((tag: string) => (
            <TagItem
              key={tag}
              data-test-id={`tag-list-chip-${tag}`}
              as={onFilterTagClick ? 'button' : 'div'}
              style={{ border: 'none' }}
            >
              {tag}
            </TagItem>
          ))}
      </TagGroup>
    </div>
  );
}
