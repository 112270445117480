import { useChangeHistory } from '@gonfalon/change-history';
import { IconButton, Tooltip, TooltipTrigger } from '@launchpad-ui/components';

export function ChangeHistoryTrigger() {
  const { open, hasRouteSpec } = useChangeHistory();

  if (!hasRouteSpec) {
    return null;
  }

  return (
    <TooltipTrigger>
      <IconButton
        onPress={() => open()}
        size="medium"
        variant="minimal"
        aria-label="Show change history"
        icon="clock-history"
      />
      <Tooltip>Show change history</Tooltip>
    </TooltipTrigger>
  );
}
